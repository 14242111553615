import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import SignInLayout from "../layouts/SignInLayout";
import Auth from "../containers/auth";
import Activate from "../containers/auth/activate";
import ProtectedRoute from "../components/ui/ProtectedRoute";
import NotFound from "../components/ui/NotFound";
import Register from "../containers/auth/register";
import {
    MainPage,
    ProfilePage,
    WorkspacePage,
    CalendarPage,
    ReferencePage,
    ServicesPage,
    Contacts,
    VacationPage,
    BirthdaysPage,
    EmployeesPage,
    ProfileColleagues,
    FavoritesPage,
    FavoritesEmployeesPage,
    FavoritesNewsPage,
    FavoritesPolls,
    FavoritesCompetitions,
    NewsPage,
    NewsShowPage,
    PollsPage,
    BlogsPage,
    BlogsDraftsPage,
    BlogsShowPage,
    PollsCreatePage,
    BlogsCreatePage,
    ServicesCreatePage,
    ServicesEducationPage,
    UserProfilePage,
    WorkspaceSettingsPage,
    ServicesWorkplacePage, IdeasPage, IdeasCreateEdit, ServicesShowPage, ServicesViewPage
} from "../containers";
import MainLayout from "../layouts/MainLayout";
import { useEffect, useState } from "react";
import InnerLayout from "../layouts/InnerLayout";
import ContestsPage from "../containers/contests/ContestsPage";
import ContestsCreateEditPage from "../containers/contests/ContestsCreateEdit";
import ContestsPageApplications from "../containers/contests/ContestsPageApplications";
import StructurePage from "../containers/services/structure/StructurePage";
import TrainingPage from "../containers/sport/TrainingPage";
import TrainingSessionViewPage from "../containers/sport/view/TrainingSessionViewPage";
import SportCreateEditPage from "../containers/sport/SportCreateEdit";
import TrainerLayout from "../layouts/TrainerLayout";
import TrainerViewPage from "../containers/sport/view/TrainerViewPage";
import SportUsersBlockedPage from "../containers/sport/SportUsersBlocked/SportUsersBlockedPage";
import NewsCreateEditPage from "../containers/news/NewsCreateEdit";
import { IdeasShowPage } from "../containers/ideas/IdeasShow";
import { NewsDraftPage } from "../containers/news/NewsDraftPage";
import GroupsPage from "../containers/groups/GroupsPage";
import GroupCreatePage from "../containers/groups/GroupCreate";
import WorkspaceSettingGroupPage from "../containers/workspace-settings/WorkspaceSettingGroupPage";
import WorkspaceSettingGroupBanners
    from "../containers/workspace-settings/groups/WorkspaceSettingGroupBanners";
import WorkspaceSettingGroupUsers
    from "../containers/workspace-settings/groups/WorkspaceSettingGroupUsers";
import WorkspaceSettingGroupModules
    from "../containers/workspace-settings/groups/WorkspaceSettingGroupModules";
import WorkspaceSettingGroupEdit
    from "../containers/workspace-settings/groups/WorkspaceSettingGroupEdit";
import AuthLayout from "../layouts/AuthLayout";
import { NotificationsPage } from "../containers/notifications";
import WorkspaceSettingModules from "../containers/workspace-settings/WorkspaceSettingModules";
import WorkspaceSettingBanners from "../containers/workspace-settings/WorkspaceSettingBanners";
import { WorkspaceUsersBlock } from "../components/workspace-users";
import WorkspaceSettingFieldUser from "../containers/workspace-settings/WorkspaceSettingFieldUser";
import { ProfileEditPage } from "../containers/profile/edit";
import WorkspaceSettingAccrual from "../containers/workspace-settings/WorkspaceSettingAccrual";
import WorkspaceSettingAll from "../containers/workspace-settings/WorkspaceSettingAll";

const RoutesComponent = () => {
    const pathname = window.location.pathname
    const [ isScrolled, setIsScrolled ] = useState(false)

    useEffect(() => {
        const handleScroll = () => {
            const slider = document.getElementById("sliderRouters");

            if (!slider) return;

            const sliderRect = slider.getBoundingClientRect();

            setIsScrolled(sliderRect.top <= 80);
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [pathname]);

    useEffect(() => {
        if (document.body) {
            const body = document.body;
            body.scrollTo(1000, 1000);
        }
    }, [pathname]);

    return (
        <BrowserRouter>
            <Routes>
                <Route
                    element={<SignInLayout />}
                >
                    <Route path="/auth" element={<Auth />} />

                    <Route path="/verification" element={<Activate />} />
                    <Route path="/register" element={<Register />} />
                </Route>

                <Route
                    element={<AuthLayout/>}
                >
                    <Route path="/" element={<ProtectedRoute />}>
                        <Route element={<MainLayout isScrolled={isScrolled}/>}>
                            <Route index element={<MainPage isScrolled={isScrolled} />} />

                            <Route path='workspace' element={<WorkspacePage/>}/>

                            <Route path='calendar'>
                                <Route path='' element={<CalendarPage/>}/>
                            </Route>

                            <Route path='reference' element={<ReferencePage/>}/>

                            <Route path='contacts' element={<Contacts/>}/>

                            <Route path='services' element={<ServicesPage/>}/>\

                            <Route path='blogs' element={<BlogsPage/>}/>

                            <Route path='sport' element={<TrainingPage/>}/>
                        </Route>
                    </Route>
                </Route>

                <Route path={"/"} element={<InnerLayout />}>
                    <Route path='calendar'>
                        <Route path='vacation' element={<VacationPage/>}/>
                        <Route path='medical' element={<VacationPage/>}/>
                        <Route path='business' element={<VacationPage/>}/>
                    </Route>

                    <Route path='employees'>
                        <Route path='' element={ <EmployeesPage /> }/>
                        <Route path='structure' element={<StructurePage/>}/>
                    </Route>

                    <Route path="profile" >
                        <Route path='' element={<ProfilePage/>}/>
                        <Route path='colleagues' element={<ProfileColleagues/>}/>
                        <Route path='edit' element={<ProfileEditPage/>}/>
                    </Route>

                    <Route path='birthdays' element={<BirthdaysPage/>}></Route>

                    <Route path='favorites' >
                        <Route path='' element={<FavoritesPage/>}/>
                        <Route path='employees' element={<FavoritesEmployeesPage/>}/>
                        <Route path='news' element={<FavoritesNewsPage/>}/>
                        <Route path='polls' element={<FavoritesPolls/>}/>
                        <Route path='competitions' element={<FavoritesCompetitions/>}/>
                    </Route>

                    <Route path='news'>
                        <Route path='' element={<NewsPage/>}/>
                        <Route path="show/:id" element={ <NewsShowPage/> }/>
                        <Route path='edit/:id' element={<NewsCreateEditPage/>}/>
                        <Route path='create' element={<NewsCreateEditPage/>}/>
                        <Route path='drafts' element={<NewsDraftPage/>}/>
                    </Route>

                    <Route path='ideas'>
                        <Route path='' element={<IdeasPage/>}/>
                        <Route path='show/:id' element={<IdeasShowPage/>}/>
                        <Route path='create' element={<IdeasCreateEdit/>}/>
                        <Route path='edit/:id' element={<IdeasCreateEdit/>}/>
                    </Route>

                    <Route path='polls' >
                        <Route path='' element={<PollsPage/>}/>
                        <Route path='edit' element={<PollsCreatePage/>}/>
                        <Route path='create' element={<PollsCreatePage/>}/>
                    </Route>

                    <Route path='competitions'>
                        <Route path='' element={<ContestsPage/>}/>
                        <Route path='application' element={<ContestsPageApplications/>}/>
                        <Route path='create' element={<ContestsCreateEditPage/>}/>
                        <Route path='edit' element={<ContestsCreateEditPage/>}/>
                    </Route>

                    <Route path='blogs'>
                        <Route path='drafts' element={<BlogsDraftsPage/>}/>
                        <Route path='show/:id' element={<BlogsShowPage/>}/>
                        <Route path='create' element={<BlogsCreatePage/>}/>
                        <Route path='edit/:id' element={<BlogsCreatePage/>}/>
                    </Route>

                    <Route path='services'>
                        <Route path='create' element={<ServicesCreatePage/>}/>
                        <Route path='edit/:id' element={<ServicesCreatePage/>}/>
                        <Route path='show/:id' element={<ServicesShowPage/>}/>
                        <Route path='view/:id' element={<ServicesViewPage/>}/>
                        <Route path='education' element={<ServicesEducationPage/>}/>
                        <Route path='workplace' element={<ServicesWorkplacePage/>}/>
                    </Route>

                    <Route path='sport'>
                        <Route path='view/:id' element={<TrainingSessionViewPage/>} />
                        <Route path='edit/:id' element={<SportCreateEditPage/>}/>
                        <Route path='create' element={<SportCreateEditPage/>}/>
                        <Route path='block-users' element={<SportUsersBlockedPage/>}/>
                    </Route>

                    <Route path='workspace-settings' element={<WorkspaceSettingsPage/>}>
                        <Route path='navigation' element={<WorkspaceSettingModules/>}/>
                        <Route path='banners' element={<WorkspaceSettingBanners/>}/>
                        <Route path='users' element={<WorkspaceUsersBlock/>}/>
                        <Route path='user_fields' element={<WorkspaceSettingFieldUser/>}/>
                        <Route path='market' element={<Navigate to={"https://cstore-itelma-dev.svoi.club/"}/>}/>
                        {/*<Route path='vacation' element={<WorkspaceUsersBlock/>}/>*/}
                        <Route path='accrual' element={<WorkspaceSettingAccrual/>}/>
                        <Route path='setting' element={<WorkspaceSettingAll/>}/>
                    </Route>

                    <Route path='groups'>
                        <Route path='' element={<GroupsPage/>}/>
                        <Route path='create' element={<GroupCreatePage/>}/>
                        <Route path='setting' element={<WorkspaceSettingGroupPage/>}>
                            <Route path='banners' element={<WorkspaceSettingGroupBanners/>}/>
                            <Route path='modules' element={<WorkspaceSettingGroupModules/>}/>
                            <Route path='settings' element={<WorkspaceSettingGroupEdit/>}/>
                            <Route path='users' element={<WorkspaceSettingGroupUsers/>}/>
                        </Route>
                    </Route>

                    <Route path='notifications'>
                        <Route path='' element={<NotificationsPage/>}/>
                    </Route>
                </Route>

                <Route path={"/"} element={<InnerLayout isShowBreadcrumb={false}/>}>
                    <Route path='user/:id'>
                        <Route path='' element={<UserProfilePage/>} />
                    </Route>

                    {/*<Route path='sport'>*/}
                    {/*    <Route path='' element={<TrainingPage/>} />*/}
                    {/*</Route>*/}
                </Route>

                <Route path={"/"} element={<TrainerLayout/>}>
                    <Route path='trainer'>
                        <Route path='' element={<TrainingPage isTrainerRole={true}/>} />
                        <Route path='view/:id' element={<TrainerViewPage/>} />
                    </Route>
                </Route>

                <Route
                    path={"*"}
                    element={<NotFound />}
                />
            </Routes>
        </BrowserRouter>
    );
};
export default RoutesComponent;
