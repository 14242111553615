import api from '../../../api'
import { IApiHandlerParams } from '../../interface'

export const $getStructureList = (
    handler?: IApiHandlerParams
): Promise<any> => api
    .get(`integrations/v1/receive/structure-list`, {
        // mock: require('./mocks/structure.company.list.json'),
        handler: {
            ...handler,
            newApi: true
        },
    })
export const $getStructure = (
    data: any,
    handler?: IApiHandlerParams
): Promise<any> => api
    .get(`integrations/v1/receive/structure-company`, {
        // mock: require('./mocks/structure.company.json'),
        request: {
            params: data
        },
        handler: {
            ...handler,
            newApi: true
        },
    })

export const $updateStructure = (
    data: any,
    handler?: IApiHandlerParams
): Promise<any> => api
    .put("integrations/v1/receive/structure-company/update?is_admin=1", data,{
        // mock: require('./mocks/structure.company.update.json'),
        handler: {
            ...handler,
            newApi: true
        },
    })

export const $getStructureSearch = (
    data: any,
    handler?: IApiHandlerParams
): Promise<any> => api
    .get(`integrations/v1/receive/structure-search`, {
        // mock: require('./mocks/structure.search.json'),
        request: {
            params: data
        },
        handler: {
            ...handler,
            newApi: true
        },
    })

export const $getStructureUser = (
    data: any,
    handler?: IApiHandlerParams
): Promise<any> => api
    .get(`integrations/v1/receive/structure-by-user`, {
        // mock: require('./mocks/structure.user.json'),
        request: {
            params: data
        },
        handler: {
            ...handler,
            newApi: true
        },
    })
