import { ArrowExitIcon } from "../../../img";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { SearchItem } from "../../../components/ui/form/SearchItem";
import { Controller, useForm } from "react-hook-form";
import Dropdown from "../../../components/ui/form/Dropdown/Dropdown";
import DatePickerForm from "../../../components/ui/form/DatePickerForm";
import { ServicesAcceptanceList, ServicesApplicationsList } from "../../../components/services";
import { $getServiceApplications } from "../../../api/requests/services";
import { IServicesApplications } from "../../../api/requests/services/interface";
import { isMd } from "../../../utils";
import {
  ServicesApplicationsHistory
} from "../../../components/services/services-education/ServicesApplicationsHistory";

interface DropdownItem {
  label: string;
  value: number | string;
}

export const ServicesEducationPage = () => {
  const isAdmin = false;
  const isHr = true;
  const navigate = useNavigate();
  const isMobile = isMd();

  const [ servicesApplicationsData, setServicesApplicationsData ] = useState<IServicesApplications[]>([])

  const [ activeType, setActiveType ] = useState('acceptance')
  const [ types, setTypes ] = useState(isAdmin || isHr ? [
      {
        id: 2,
        key: 'acceptance',
        title: 'Согласование заявок',
      },
      {
        id: 3,
        key: 'history',
        title: 'История заявок'
      }
    ] : [
      {
        id: 1,
        key: 'applications',
        title: 'Мои заявки'
      },
      {
        id: 2,
        key: 'acceptance',
        title: 'Согласование заявок',
      },
      {
        id: 3,
        key: 'history',
        title: 'История заявок'
      }
    ]
  )

  useEffect(() => {
    $getServiceApplications(30, true, {newApi: true} ).then(res => {

    })
  }, [])

  return (
    <div className='services-education'>
      <div className='services-education-header'>
        { !isMobile && (
          <div className='services-education-header__left'>
            <div className='header__exitIcon' onClick={ () => navigate(-1) }>
              <ArrowExitIcon />
            </div>

            <h1>Заявка на обучение</h1>
          </div>
        ) }

        <div className='services-education-header__right'>
          <SearchItem params={ '' } onChange={ () => '' } />
        </div>
      </div>

      <div className='services-education-content'>
        <div className='services-education-content__top'>
          { types.map((
            item,
            idx
          ) => (
            <div
              key={ idx }
              className={ `services-education-content__top__block ${ activeType === item.key ? 'active' : '' }` }
              onClick={ () => setActiveType(item.key) }
            >
              { item.title }
            </div>
          )) }
        </div>

        { !isAdmin && activeType === 'applications' && (
          <ServicesApplicationsList data={ servicesApplicationsData } />
        ) }

        { activeType === 'acceptance' && (
          <ServicesAcceptanceList isAdmin={isAdmin} isHr={isHr}/>
        ) }

        { activeType === 'history' && (
          <ServicesApplicationsHistory isAdmin={isAdmin} isHr={isHr}/>
        ) }
      </div>
    </div>
  )
}