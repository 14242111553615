import { FC, useEffect } from "react";

import { useAppDispatch, useAppSelector } from "./redux";

import Cookies from "js-cookie";
import RoutesComponent from "./routes";
import { selectLoading } from "./redux";
import Preloader from "./components/ui/preloader/Preloader";

const App: FC = () => {
    const dispatch = useAppDispatch();

    const loadingIsActive = useAppSelector(selectLoading);

    useEffect(() => {
        if (Cookies.get('token')) {
            // dispatch(useSelector(getProfileData));
        }
    }, [ dispatch ]);


    return (
        <>
            <RoutesComponent />

            {loadingIsActive && (
                <div
                    className={"w-100 spinner__wrapper"}
                    style={{
                        height: "100vh",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center"
                    }}
                >
                    <Preloader size={"large"} />
                </div>
            )}
        </>
    )
}
export default App;
