import { useEffect, useState } from "react";
import DialogContainer from "../../components/ui/dialog/DialogContainer";
import { CloseIcon, PlusIcon2 } from "../../img";
import Button from "../../components/ui/form/Buttons/Button";
import { useNavigate } from "react-router-dom";
import UserSelectListModal, { BtnDataItem } from "../../components/ui/dialog/UserSelectListModal";
import { setGroupCreated, setGroupRemoveId } from "../../redux/reducers/workplace/workplaceSlice";
import { useAppDispatch } from "../../redux";
import { GetGroupData } from "../../api/requests/groups/interface";
import { $createGroup, $joinGroup } from "../../api/requests/groups";
import CompleteModal from "../../components/ui/dialog/CompleteModal";
import TextField from "../../components/ui/form/Fields/TextField";
import { failureNotify } from "../../utils";

interface AddGroupModalProps {
    isOpen: boolean;
    setIsOpen: () => void;
    onClose: () => void;
    list: GetGroupData[];
}

const AddGroupModal = (
    {
        isOpen,
        setIsOpen,
        list,
        onClose
    }: AddGroupModalProps
) => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const [groupFilterList, setGroupFilterList] = useState<any[]>([]);
    const [isGroupCreated, setIsGroupCreated] = useState(false);
    const [isGroupPassword, setIsGroupPassword] = useState("");
    const [titleValue, setTitleValue] = useState("");
    const [passwordValue, setPasswordValue] = useState("");

    const btnList = [
        {
            status: 0,
            icon: (key: string) => (
                <div
                    className={"btn-icon groups-btn"}
                    style={{
                        backgroundColor: "#FF00001A"
                    }}
                    onClick={() => handleClose(key)}
                >
                    <CloseIcon
                        width={12}
                        height={12}
                        color={"#FF0000"}
                        opacity={1}
                    />
                </div>
            )
        },
        {
            status: 1,
            icon: (key: string) => (
                <div
                    style={{
                        backgroundColor: "#00A7B51A"
                    }}
                    className={"btn-icon groups-btn"}
                    onClick={() => handleAdd(key)}
                >
                    <PlusIcon2 />
                </div>
            )
        }
    ];

    const btnListCreated = [
        {
            key: 1,
            text: "Создать",
            className: "btn-primary w-100"
        },
        {
            key: 2,
            text: "Отмена",
            className: "btn-light w-100"
        }
    ]

    const btnListPassword = [
        {
            key: 1,
            text: "Подтвердить",
            className: "btn-primary-revert w-100"
        },
        {
            key: 2,
            text: "Отмена",
            className: "btn-light w-100"
        }
    ]

    const getChangeKeyItem = (item: any) => {
        const rightFind = btnList.find(i =>
            (+item.role > 0 && i.status === 0)
            || (+item.role === 0 && i.status === 1)
        );

        return {
            id: item.id,
            image: item.image,
            name: item.title,
            department: item.text,
            right: rightFind
        };
    };

    useEffect(() => {
        if (list && list.length) init();
    }, [list]);

    function init() {
        setGroupFilterList([...list].map(item => getChangeKeyItem(item)));
    }

    function handleChangeList(btnItem: BtnDataItem | null, updateList: number[]) {
        setIsOpen();
    }

    function handleClose(id: string) {
        setIsOpen();
        dispatch(setGroupRemoveId(id));
    }

    function handleAdd(id: string) {
        const findGroup = list.find(item => item.id === id)

        if (findGroup && findGroup?.status) {
            if (+findGroup.status === 2) {
                setIsGroupPassword(id);

                return;
            }
        }

        $joinGroup({ id: id.toString() }, { formData: true }).then(res => {
            onClose();
        });
    }

    function handleClickModalBtn(selectBtn: BtnDataItem) {
        const requestData = {
            title: titleValue
        }

        if (selectBtn.key === 1) {
            if (!titleValue) return;

            $createGroup(requestData, { formData: true }).then(res => {
                if (res?.code) return;

                navigate(`/?group_id=${res?.id}`);
                dispatch(setGroupCreated(true));
            });
        }

        setIsGroupCreated(false);
        setTitleValue("");
    }

    function handleClickPasswordModalBtn(selectBtn: BtnDataItem) {
        const requestData = {
            id: isGroupPassword,
            password: passwordValue
        }

        if (selectBtn.key === 1) {
            if (!isGroupPassword) return;

            $joinGroup(requestData, { formData: true, dontShowErrorMessage: true }).then(res => {
                if (res.error && res.error === "INCORRECT_PASS") return failureNotify("Пароль не верный");
                if (res.error || res.response) return;

                setIsGroupPassword("");
                setPasswordValue("");
                onClose();
            });

            return;
        }

        setIsGroupPassword("");
        setPasswordValue("");
    }

    if (isGroupCreated) return (
        <CompleteModal
            isOpen={isGroupCreated}
            setIsOpen={() => setIsGroupCreated(false)}
            icon={"🖐🏻"}
            title={"Создание группы"}
            text={"Добро пожаловать в создание группы. Для начала введите название"}
            content={(
                <TextField
                    value={titleValue}
                    className=""
                    title="Название группы"
                    placeholder={"Введите название"}
                    name="title"
                    onChange={setTitleValue}
                />
            )}
            btnList={btnListCreated}
            onClick={handleClickModalBtn}
        />
    )

    if (isGroupPassword) return (
        <CompleteModal
            isOpen={!!isGroupPassword}
            setIsOpen={() => setIsGroupPassword("")}
            icon={"👀"}
            title={"Введите код доступа"}
            content={(
                <TextField
                    type={"password"}
                    value={passwordValue}
                    className="mt-2"
                    placeholder={"Введите код"}
                    name="password"
                    onChange={setPasswordValue}
                />
            )}
            btnList={btnListPassword}
            onClick={handleClickPasswordModalBtn}
        />
    )

    return (
        <DialogContainer
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            isShowCloseBtn={false}
            classNameModal={"p-0"}
            widthProps={428}
        >
            <UserSelectListModal
                list={groupFilterList}
                title={"Все группы"}
                onClose={handleChangeList}
                isSearch
                isClose
                isSingleSelect
            />

            <div className={"contests__winner pt-0"}>
                <Button
                    text={"Создать группу"}
                    className={`btn btn-primary w-100`}
                    onClick={() => setIsGroupCreated(true)}
                />
            </div>
        </DialogContainer>
    );
};

export default AddGroupModal;