import { Member, MembersAdd } from "./MembersAction";

interface MembersBlockData {
    booking: any;
    setBooking: (prev: any) => void;
}

export const MembersBlock = (
    {
        booking,
        setBooking
    }: MembersBlockData
) => {
    const { photo, firstname, lastname, staff } = booking?.member || {};

    const handleAdd = (member: any) => {
        if (member?.isSelected) {
            handleRemove();

            return;
        }

        setBooking((prev: any) => {
            return {
                ...prev,
                request: {
                    ...prev?.request,
                    for_user_id: member?.id
                },
                member
            };
        });
    };

    const handleRemove = () => {
        setBooking((prev: any) => {
            return {
                ...prev,
                request: {
                    ...prev?.request,
                    for_user_id: null
                },
                member: null
            };
        });
    };

    return (
        <div className={"calendar____members__block__wrapper"}>
            <div className={"calendar____members__block__head"}>
                <div className={"calendar____members__block__title"}>Сотрудник</div>
            </div>

            <div className={"calendar____members__block__body"}>
                {!booking?.member && <MembersAdd booking={booking} handleAdd={handleAdd} />}

                {booking?.member && (
                    <Member
                        photo={photo}
                        name={`${firstname} ${lastname}`}
                        position={staff?.title}
                        type="edit"
                        onClick={handleRemove}
                    />
                )}
            </div>
        </div>
    );
};
