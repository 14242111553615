import { IApiHandlerParams } from "../../interface";
import api from "../../index";
import { IRequestComment, IRequestExpertComment } from "./interface";

export const $postComment = (
  data: IRequestComment,
  url: string,
  handler?: IApiHandlerParams
): Promise<any> =>
  api.post(`api${url}`, data, {
    handler,
  })

export const $deleteComment = (
  id: any,
  url: string,
  handler?: IApiHandlerParams
): Promise<any> =>
  api.post(`api${url}`, id, {
    handler,
  })

export const $likeComment = (
  id: any,
  url: string,
  handler?: IApiHandlerParams
): Promise<any> =>
  api.post(`api${url}`, id, {
    handler,
  })

export const $pickExpertComment = (
    data: IRequestExpertComment,
    url: string,
    handler?: IApiHandlerParams
): Promise<any> =>
    api.post(`api${url}`, data, {
        handler,
    })