import React, { FC } from 'react'
import { IBaseIconProps } from "./types"

export const DownloadIcon: FC<IBaseIconProps> = ({
    color = '#00A7B5',
    size = 24,
}) => {
    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M4.5 20.3827C4.90471 20.778 5.45361 21 6.02595 21H18.9741C19.5464 21 20.0953 20.778 20.5 20.3827M12.5012 3V14.9425M12.5012 14.9425L17.4338 10.3793M12.5012 14.9425L7.56859 10.3793"
                stroke={color}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}
