import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from "../../store";

export interface initialStateType {
    structure: any;
    structureLoading: boolean;
    departments: any[];
    department: any;
    cities: any[];
    buildings: any[];
    floors: any[];
    selected: any;
    plan: any;
    shapeData: any;
    popupData: any;
    toggleControl: boolean;
    openWorkplacePopup: boolean;
    openMeetingPopup: boolean;
    openRoomPopup: boolean;
    openBookingTypePopup: boolean;
}

const initialState: initialStateType = {
    structure: null,
    structureLoading: false,
    departments: [],
    department: null,
    cities: [],
    buildings: [],
    floors: [],
    selected: {
        city: null,
        building: null,
        floor: null,
    },
    plan: null,
    shapeData: null,
    popupData: null,
    toggleControl: false,
    openWorkplacePopup: false,
    openMeetingPopup: false,
    openRoomPopup: false,
    openBookingTypePopup: false,
};

export const workplaceManagementSlice = createSlice({
    name: 'workplaceManagement',
    initialState,
    reducers: {
        setSructure: (state, { payload }: PayloadAction<any>) => {
            state.structure = payload;
        },
        setSructureLoading: (state, { payload }: PayloadAction<any>) => {
            state.structureLoading = payload;
        },
        setDepartments: (state, { payload }: PayloadAction<any>) => {
            state.departments = payload;
        },
        setDepartment: (state, { payload }: PayloadAction<any>) => {
            state.department = payload;
        },
        setCities: (state, { payload }: PayloadAction<any>) => {
            state.cities = payload;
        },
        setBuildings: (state, { payload }: PayloadAction<any>) => {
            state.buildings = payload;
        },
        setFloors: (state, { payload }: PayloadAction<any>) => {
            state.floors = payload;
        },
        setSelected: (state, { payload }: PayloadAction<any>) => {
            state.selected = { ...state.selected, ...payload };
            localStorage.setItem('booking-back', JSON.stringify({ ...state.selected, ...payload }));
        },
        setPlan: (state, { payload }: PayloadAction<any>) => {
            state.plan = payload;
        },
        setShapeData: (state, { payload }: PayloadAction<any>) => {
            state.shapeData = payload;
        },
        setPopupData: (state, { payload }: PayloadAction<any>) => {
            state.popupData = payload;
        },
        setControl: (state) => {
            state.toggleControl = !state.toggleControl;
        },
        setWorkplaceOpenPopup: (state) => {
            state.openWorkplacePopup = !state.openWorkplacePopup;
        },
        setMeetingOpenPopup: (state) => {
            state.openMeetingPopup = !state.openMeetingPopup;
        },
        setRoomOpenPopup: (state) => {
            state.openRoomPopup = !state.openRoomPopup;
        },
        openBookingTypePopup: (state) => {
            state.openBookingTypePopup = true;
        },
        closeBookingTypePopup: (state) => {
            state.openBookingTypePopup = false;
        },
    },
});

export const {
    setSructure,
    setSructureLoading,
    setDepartments,
    setDepartment,
    setCities,
    setBuildings,
    setFloors,
    setSelected,
    setPlan,
    setShapeData,
    setPopupData,
    setControl,
    setWorkplaceOpenPopup,
    setMeetingOpenPopup,
    setRoomOpenPopup,
    openBookingTypePopup,
    closeBookingTypePopup,
} = workplaceManagementSlice.actions;

export const getStructure = (state: RootState) => state.workplaceManagement.structure;
export const getStructureLoading = (state: RootState) => state.workplaceManagement.structureLoading;
export const getDepartments = (state: RootState) => state.workplaceManagement.departments;
export const getDepartment = (state: RootState) => state.workplaceManagement.department;
export const getCities = (state: RootState) => state.workplaceManagement.cities;
export const getBuildings = (state: RootState) => state.workplaceManagement.buildings;
export const getFloors = (state: RootState) => state.workplaceManagement.floors;
export const getSelected = (state: RootState) => state.workplaceManagement.selected;
export const getPlan = (state: RootState) => state.workplaceManagement.plan;
export const getShapeData = (state: RootState) => state.workplaceManagement.shapeData;
export const getPopupData = (state: RootState) => state.workplaceManagement.popupData;
export const getControl = (state: RootState) => state.workplaceManagement.toggleControl;
export const getWorkplaceOpenPopup = (state: RootState) => state.workplaceManagement.openWorkplacePopup;
export const getMeetingOpenPopup = (state: RootState) => state.workplaceManagement.openMeetingPopup;
export const getRoomOpenPopup = (state: RootState) => state.workplaceManagement.openRoomPopup;
export const getOpenBookingTypePopup = (state: RootState) => state.workplaceManagement.openBookingTypePopup;

export const workplaceManagementSliceReducer = workplaceManagementSlice.reducer;
