export const ProfileBlockAddIcon = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path
                d="M11 20C6.12121 20 3 18.433 3 16.5C3 15 7.13401 13.5 11 13.5M14 7.05501C14 8.60963 12.6569 10 11 10C9.34315 10 8 8.60963 8 7.05501C8 5.50039 9.34315 4 11 4C12.6569 4 14 5.50039 14 7.05501Z"
                stroke="black"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M14.9006 14.2021V13.7446C14.9006 12.2242 16.1057 11 17.6023 11C19.099 11 20.3041 12.2242 20.3041 13.7446V14.2021M14.9006 14.2021C14.4053 14.2021 14 14.6138 14 15.117V19.6914C14 20.1946 14.4053 20.6062 14.9006 20.6062H20.3041C20.7994 20.6062 21.2047 20.1946 21.2047 19.6914V15.117C21.2047 14.6138 20.7994 14.2021 20.3041 14.2021M14.9006 14.2021H20.3041"
                stroke="black"
                strokeWidth="1.5"
            />
            <path
                d="M18.5043 17.4041C18.5043 16.901 18.099 16.4893 17.6037 16.4893C17.1084 16.4893 16.7031 16.901 16.7031 17.4041C16.7031 17.9073 17.1084 18.319 17.6037 18.319C18.099 18.319 18.5043 17.9073 18.5043 17.4041Z"
                stroke="black"
            />
        </svg>
    );
};
