import React, { useEffect, useRef, useState } from "react";
import {
    IServiceCategories, IServiceCities,
    IServices, IServicesApplications,
    IServicesGroups
} from "../../api/requests/services/interface";
import {
    $deleteService, $getCities, $getServiceApplications,
    $getServiceCategories,
    $getServices,
    $getServicesGroups
} from "../../api/requests/services";
import { useNavigate } from "react-router-dom";
import { BlankAva } from "../../components/ui/avatars/BlankAva";
import Dropdown from "../../components/ui/form/Dropdown/Dropdown";
import { BlankImage } from "../../components/ui/blank-image/BlankImage";
import {
    PlusIcon2, ServicesBookingIcon,
    ServicesEducationIcon,
    ServicesTripIcon,
    ServicesVacationIcon,
    ServicesWorkplaceIcon,
    SliderFeedIcon,
    ServicesEmbulanceIcon, ServicesSubstituteIcon, ThreeDotsIcon, SettingIcon
} from "../../img";
import { di } from "@fullcalendar/core/internal-common";
import CompleteModal from "../../components/ui/dialog/CompleteModal";
import {
    ServicesCreateGroupModal
} from "../../components/services/services-modals/ServicesCreateGroupModal";
import { ServicesApplicationsTable } from "../../components/services";

interface DropdownItem {
    label: string;
    value: number | string;
}

export const ServicesPage = () => {
    const navigate = useNavigate();
    const searchParams = new URLSearchParams(location.search);
    const catId = searchParams.get("catId") || 0;
    const page = searchParams.get("page");

    const dropdownRef = useRef<any>(null);

    const [applicationsData, setApplicationsData] = useState<IServicesApplications[]>([]);

    const [isHoveringId, setIsHoveringId] = useState(0);
    const [dropdownOpen, setDropdownOpen] = useState(0);

    const [addModalOpen, setAddModalOpen] = useState(0);
    const [deleteModalOpen, setDeleteModalOpen] = useState(0);
    const [isLoading, setIsLoading] = useState(false);

    const [selectedChapter, setSelectedChapter] = useState<IServiceCategories>()
    const [categories, setCategories] = useState<IServiceCategories[]>([]);
    const [servicesStaticData, setServicesStaticData] = useState([
        {
            id: 1,
            image: <ServicesEducationIcon />,
            title: "Заявка на обучение",
            category: "Обучение",
            url: "education"
        },
        {
            id: 2,
            image: <ServicesVacationIcon />,
            title: "Отпуск",
            category: "Отпуск",
            url: ""
        },
        {
            id: 3,
            image: <ServicesTripIcon />,
            title: "Командировка",
            category: "Командировка",
            url: ""
        },
        {
            id: 4,
            image: <ServicesBookingIcon />,
            title: "Бронирование переговорных",
            category: "Бронирование",
            url: ""
        },
        {
            id: 5,
            image: <ServicesWorkplaceIcon />,
            title: "Карта офиса",
            category: "Офис",
            url: "workplace"
        },
        {
            id: 6,
            image: <ServicesEmbulanceIcon />,
            title: "Больничный",
            category: "Больничный",
            url: ""
        },
        {
            id: 7,
            image: <ServicesSubstituteIcon />,
            title: "Замещающий",
            category: "Замещиющий",
            url: ""
        }
    ]);

    const [servicesDynamicData, setServicesDynamicData] = useState([]);

    const [citiesData, setCitiesData] = useState([]);
    const [selectedCity, setSelectedCity] = useState(
        {
            label: "Все ",
            value: "all"
        }
    );

    const [groupsData, setGroupsData] = useState<IServicesGroups[]>([]);

    const btnListRemove = [
        {
            text: "Удалить",
            key: 0,
            className: "btn btn-danger w-100"
        },
        {
            text: "Отмена",
            key: 1,
            className: "btn btn-light w-100"
        }
    ];


    const handleMouseOver = (id: number) => {
        if (!dropdownOpen) {
            setIsHoveringId(id);
        }
    };
    const handleMouseOut = () => {
        if (dropdownOpen == isHoveringId) {
            setIsHoveringId(isHoveringId);
        } else {
            setIsHoveringId(0);
        }
    };

    const handleDeleteService = (select: any) => {
        if (select.key == 0 ) {
            setIsLoading(true);
            $deleteService(deleteModalOpen, { newApi: true }).then(res => {
                setDeleteModalOpen(0);
                setIsLoading(false);
            });
        }

        setDeleteModalOpen(0);
    };

    const handleChangeCity = (item: any) => {
        setSelectedCity(item);
    };

    useEffect(() => {
        if (page === "my-applications" || page === "my-applications-in-work") {
            $getServiceApplications(30, page === "my-applications", { newApi: true }).then(res => {
                setApplicationsData(res?.data?.sample_applications?.data);
            });
        }

        $getServices(catId || 0, selectedCity.value !== "all" && +selectedCity.value, { newApi: true }).then(res => {
            if (!res.data) return;

            setServicesDynamicData(res?.data?.samples?.data);
        });

        $getServiceCategories({ newApi: true }).then(res => {
            if (!res.data) return;

            const updatedList = [
                {
                    id: 0,
                    title: "Все сервисы",
                    icon: <SliderFeedIcon />
                },
                ...res?.data?.categories
            ];
            setCategories(updatedList);
        });

        $getCities({ formData: true }).then(res => {
            if (!res) return;

            const updatedList = res?.map((item: IServiceCities) => {
                return {
                    value: item.id,
                    label: item.city,
                    ...item
                };
            });

            setCitiesData(updatedList);
        });
    }, [catId, isLoading, selectedCity, page]);

    useEffect(() => {
        const handleClickOutside = (event: any) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setDropdownOpen(0);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <div className="services">
            <CompleteModal
                icon={"🥲"}
                title={"Вы действительно хотите удалить шаблон?"}
                isOpen={deleteModalOpen !== 0}
                setIsOpen={() => setDeleteModalOpen(0)}
                btnList={btnListRemove}
                onClick={handleDeleteService}
            />

            <ServicesCreateGroupModal
                editedData={selectedChapter}
                isEdit={addModalOpen == 2}
                setIsLoading={setIsLoading}
                isOpen={addModalOpen == 1 || addModalOpen == 2}
                onClose={() => {
                    setSelectedChapter(undefined)
                    setAddModalOpen(0)
                }}
            />

            <div className="services-header">
                <div className="services-header__left">
                    <h5
                        className={`${!page ? "services-header__title" : "services-header__paragraph"}`}
                        onClick={() => navigate("")}
                    >
                        Сервисы
                    </h5>

                    <h5 className={`${page === "my-applications" ? "services-header__title" : "services-header__paragraph"}`}
                        onClick={() => navigate("?page=my-applications")}
                    >
                        Мои заявки
                    </h5>
                    <h5 className={`${page === "my-applications-in-work" ? "services-header__title" : "services-header__paragraph"}`}
                        onClick={() => navigate("?page=my-applications-in-work")}
                    >
                        Заявки в работе
                    </h5>
                </div>


                <div className="services-header__dropdown">
                    <Dropdown
                        // className="workspace-select"
                        placeholder={"Статус"}
                        options={[
                            {
                                label: "Все ",
                                value: "all"
                            },
                            ...citiesData
                        ]}
                        value={selectedCity}
                        onChange={(e) => handleChangeCity(e)}
                    />
                </div>
            </div>

            {!page && (
                <>
                    <div className="services-chapters">
                        <div className="services-chapters__item" onClick={() => setAddModalOpen(1)}>
                            <PlusIcon2 />

                            <p className="services-chapters__item__title">Добавить раздел</p>
                        </div>

                        {categories?.map((category, index) => (
                            <div onMouseOver={() => handleMouseOver(category.id)}
                                 onMouseOut={handleMouseOut}
                                 className={`services-chapters__item ${category.id == catId ? "active" : ""}`}
                                 key={index}
                                 onClick={() => navigate(`/services?catId=${category.id}`)}>
                                {category.id == 0 ? (
                                    <>
                                        <div className="services-chapters__item__icon">
                                            {category.icon}
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <BlankImage image={category?.icon_url} classname="services-chapters__item__icon"/>
                                    </>
                                )}

                                <p className="services-chapters__item__title">{category?.title}</p>

                                {(category.id == isHoveringId && category.id !== 0 ) && (
                                    <div className='services-chapters__item__settingIcon' onClick={(e) => {
                                        e.stopPropagation()
                                        setSelectedChapter(category)
                                        setAddModalOpen(2)
                                    }
                                    }>
                                        <SettingIcon color='#00A7B5'/>
                                    </div>
                                )}
                            </div>
                        ))}
                    </div>

                    <div className="services-content">
                        <div className="services-addBlock"
                             onClick={() => navigate("/services/create")}>
                            <div className="services-addBlock__icon">
                                <PlusIcon2 />
                            </div>

                            <h5 className="services-addBlock__title">
                                Добавить сервис
                            </h5>
                        </div>

                        {servicesDynamicData?.map((item: any, idx) => (
                            <div className="services-block"
                                 key={idx}
                                 onMouseOver={() => handleMouseOver(item.id)}
                                 onMouseOut={handleMouseOut}
                                 onClick={(e) => {
                                     e.stopPropagation();
                                     navigate(`/services/show/${item.id}`);
                                 }}
                            >
                                <BlankImage image={item?.image_file}
                                            classname="services-block__imageSecond" />

                                <h1 className="services-block__title">{item.title}</h1>

                                <div className="services-block__category">
                                    {item?.category?.title || "Без категории"}
                                </div>

                                {item.id === isHoveringId && (
                                    <div className="services-block__right"
                                         onClick={(e) => {
                                             e.stopPropagation();
                                             dropdownOpen == 0 && setDropdownOpen(item.id);
                                         }
                                         }>
                                        <ThreeDotsIcon />
                                    </div>
                                )}

                                {item.id == dropdownOpen && (
                                    <div className="services-block__dropdown" ref={dropdownRef}>
                                        <p className="services-block__dropdown__item"
                                           onClick={(e) => {
                                               e.stopPropagation();
                                               navigate(`/services/edit/${item.id}`);
                                           }
                                           }
                                        >
                                            Редактировать шаблон
                                        </p>

                                        <div className="services-block__dropdown__line"></div>

                                        <p className="services-block__dropdown__item"
                                           onClick={(e) => {
                                               e.stopPropagation();
                                               setDeleteModalOpen(item.id);
                                           }}>
                                            Удалить
                                            шаблон</p>
                                    </div>
                                )}
                            </div>
                        ))}

                        {catId == 0 && (
                            <>
                                {servicesStaticData?.map((item, idx) => (
                                    <div
                                        className="services-block"
                                        key={idx}
                                        onClick={() => navigate(item.url)}
                                    >
                                        <div className="services-block__image">
                                            {item.image}
                                        </div>

                                        <h1 className="services-block__title">{item.title}</h1>

                                        <div className="services-block__category">
                                            {item.category}
                                        </div>
                                    </div>
                                ))}
                            </>
                        )}
                    </div>

                    <div>
                        <div className="services-content">
                            {groupsData.map((item, idx) => (
                                <div className="services-block" key={idx}>
                                    {item.icon ? (
                                        <img src={item?.icon} alt=""
                                             className="services-block__image" />
                                    ) : (
                                        <div className="services-block__image">
                                            {item.title[0]}
                                        </div>
                                    )}

                                    <h1 className="services-block__title">{item.title}</h1>

                                    {/*<div className='services-block__category'>*/}
                                    {/*  /!*{item.category}*!/*/}
                                    {/*</div>*/}
                                </div>
                            ))}
                        </div>
                    </div>
                </>
            )}

            {(page === "my-applications" || page === "my-applications-in-work") && (
                <ServicesApplicationsTable data={applicationsData} />
            )}
        </div>
    );
};