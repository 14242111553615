import DialogContainer from "../../../components/ui/dialog/DialogContainer";
import UserSelectListModal, {
    BtnDataItem
} from "../../../components/ui/dialog/UserSelectListModal";
import { useEffect, useState } from "react";
import Button from "../../../components/ui/form/Buttons/Button";
import { $getUserSportAllList, $joinEnrollSport } from "../../../api/requests/sport";

interface TrainerModalSingUserProps {
    isOpen: boolean;
    onClose: () => void;
    setIsOpen: () => void;
    scheduleId?: number;
}

const TrainerModalSingUser = (
    {
        isOpen,
        onClose,
        scheduleId,
        setIsOpen
    }: TrainerModalSingUserProps
) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [departmentItems, setDepartmentItems] = useState<any[]>([]);

    const [btnList, setBtnList] = useState([
        {
            key: 1,
            text: "Выбрать",
            className: "btn-primary w-100"
        }
    ])

    const [isStatusComplete, setIsStatusComplete] = useState(false);

    const onChangeData = (list: any[]) => {
        return [...list].map(i => ({
            ...i,
            image: i.photo
        }))
    }

    useEffect(() => {
        init();
    }, [scheduleId]);

    function init(search?: string) {
        if (!scheduleId) return;
        setIsLoading(true);

        $getUserSportAllList(scheduleId, { search }, { newApi: true }).then(res => {
            setIsLoading(false);
            if (res.error || res.response) return;

            setDepartmentItems(onChangeData(res.data.data))
        })
    }

    function handleChangeList(btnItem: BtnDataItem | null, updateList: number[]) {
        if (!btnItem) return setIsOpen();

        updateList.map((index: number) => {
            const findItem = departmentItems.find((i, idx) => idx === index);

            if (findItem) {
                $joinEnrollSport(
                    true,
                    {
                        user_id: findItem.id,
                        schedule_id: scheduleId,
                    },
                    { newApi: true }
                ).then(res => {
                    if (res.error || res.response) return;

                    setIsStatusComplete(true);
                })
            }
        })
    }

    function handleClose() {
        setIsStatusComplete(false);
        onClose();
    }

    return (
        <DialogContainer
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            isShowCloseBtn={false}
            classNameModal={"p-0"}
            widthProps={427}
        >
            {isStatusComplete ? (
                <div className={"sport__view__modal contests__winner"}>
                    <div className={"sport__view__modal__content-complete"}>
                        <span>💪</span>

                        <h3>Успешно</h3>

                        <p>
                            Вы успешно записали участника.
                        </p>
                    </div>

                    <div className={"sport__view__modal__btn"}>
                        <Button
                            text={"Отлично"}
                            className={"btn btn-light w-100"}
                            onClick={handleClose}
                            loading={isLoading}
                        />
                    </div>
                </div>
            ) : (
                <UserSelectListModal
                    list={departmentItems}
                    btnList={btnList}
                    title={"Записать участника"}
                    onClose={handleChangeList}
                    isClose
                    isSearch
                    isSearchApi={(val) => init(val)}
                    isSingleSelect
                />
            )}
        </DialogContainer>
    )
}

export default TrainerModalSingUser;