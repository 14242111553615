import React, { forwardRef } from "react";
import { FieldJSXProps } from "./types";
import EditorJs from "./Editors/EditorJs";

type Ref = HTMLInputElement;

const EditorField = forwardRef<Ref, FieldJSXProps>(
    (
        {
            className = "",
            id = "",
            title,
            value,
            onChange,
            errors,
            name = "",
        },
        ref
    ) => {
        return (
            <div className={"form-group"}>
                {title && (
                    <label
                        htmlFor={id}
                        className={"form-label"}
                    >
                        {title}
                    </label>
                )}

                <div
                    className={`field ${className} ${!!(value || "") ? "active" : ""}`}
                >
                    <EditorJs
                        jsonCode={value}
                        setSaveJsonEditor={(value) => onChange && onChange(value)}
                    />
                </div>

                {errors?.[name] && (
                    <div className="d-flex flex-column">
                        <p className="field__error">
                            {errors?.[name]?.message as any}
                        </p>
                    </div>
                )}
            </div>
        );
    }
);

EditorField.displayName = "EditorField";

export default EditorField;
