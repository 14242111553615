import { FC } from "react";
import { IBaseIconProps } from "../types";

export const ProfilePersonalIcon: FC<IBaseIconProps> = (
    {
        color = "#00A7B5",
        size = 24
    }) => {
    return (
        <svg width={size} height={size} viewBox="0 0 24 24" fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path
                d="M3.23438 19.5C4.55979 17.2892 7.46466 15.7762 11.9967 15.7762C16.5286 15.7762 19.4335 17.2892 20.7589 19.5M15.5967 8.1C15.5967 10.0882 13.9849 11.7 11.9967 11.7C10.0084 11.7 8.39665 10.0882 8.39665 8.1C8.39665 6.11177 10.0084 4.5 11.9967 4.5C13.9849 4.5 15.5967 6.11177 15.5967 8.1Z"
                stroke={color} stroke-width="2" stroke-linecap="round" />
        </svg>

    );
};
