import { ArrowExitIcon } from "../../../img";
import { SearchItem } from "../../../components/ui/form/SearchItem";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getYearMonthDay, isMd } from "../../../utils";
import { Controller, useForm } from "react-hook-form";
import Dropdown from "../../../components/ui/form/Dropdown/Dropdown";
import DatePickerForm from "../../../components/ui/form/DatePickerForm";
import { $getCitiesAvailable, $getWorkplaceBuildings } from "../../../api/requests/workplace";
import { useDispatch, useSelector } from "react-redux";
import {
    getBuildings,
    getFloors,
    getSelected,
    setBuildings, setCurrentDate, setFloors, setPlan,
    setSelected
} from "../../../redux/reducers/workplace/workplaceSlice";
import { ServicesWorkplaceMap } from "../../../components/services";
import WorkplaceAvailablePlaces
    from "../../../components/services/services-workplace/components/WorkplaceAvailablePlaces";

interface DropdownItem {
    label: string;
    value: number | string;
}

interface FormData {
    city: string;
    date: Date;
    building: string;
    floor: string;
}

export const ServicesWorkplacePage = () => {
    const navigate = useNavigate();
    const isMobile = isMd();

    const dispatch = useDispatch();
    const { city, building: buildingState, floor: floorState } = useSelector(getSelected);
    const buildings = useSelector(getBuildings);
    const floors = useSelector(getFloors);

    const [citiesDropdownData, setCitiesDropdownData] = useState<DropdownItem[]>([]);
    const [buildingsDropdownData, setBuildingsDropdownData] = useState<DropdownItem[]>([]);
    const [floorsDropdownData, setFloorsDropdownData] = useState<DropdownItem[]>([]);

    const {
        handleSubmit,
        control,
        watch,
        register,
        reset,
        formState: { isValid },
        setValue
    } = useForm<FormData>(
        // {
        //     values: {
        //         city,
        //         buildings: buildingState,
        //         floor: floorState,
        //         date: new Date(),
        //     }
        // }
    );

    const getDropdownItem = (data: any, value: any) => {
        return data?.map((item: any) => {
            return {
                ...item,
                value: item.id,
                label: value === "number" ? `Этаж ${item[value]}` : item[value]
            };
        });
    };

    useEffect(() => {
        $getCitiesAvailable({ newApi: true }).then((res) => {
            if (!res.data) return;

            setCitiesDropdownData(getDropdownItem(res?.data, "name"));
        });

        dispatch(setCurrentDate(getYearMonthDay(new Date())));
    }, []);

    useEffect(() => {
        if (city) {
            $getWorkplaceBuildings(city?.value, { newApi: true }).then(res => {
                if (!res) return;

                setBuildingsDropdownData(getDropdownItem(res?.data, "name"));
                dispatch(setBuildings(res?.data));
            });
        }
    }, [city]);

    const handleSelect = (type: string, e: any) => {
        if (type === "city") {
            setValue("city", e);
            const getSelectedCity = citiesDropdownData?.find((item: any) => item.id == e.value);

            setValue("building", "");
            setValue("floor", "");
            dispatch(setSelected({ city: getSelectedCity, building: null, floor: null }));
        } else if (type === "building") {
            const getSelectedBuilding = buildings.find((item) => item.id == e.value);

            setValue("floor", "");
            dispatch(setSelected({ building: getSelectedBuilding, floor: null }));
            dispatch(setFloors(getSelectedBuilding?.floors));

            setFloorsDropdownData(getDropdownItem(getSelectedBuilding?.floors, "number"));
        }

        if (type === "floor") {
            const getSelectedFloor = floors.find((item) => item.id == e.value);

            dispatch(setSelected({ floor: getSelectedFloor }));

            if (getSelectedFloor?.map) {
                dispatch(setPlan(getSelectedFloor?.map?.filename_url));
            }
        }
    };

    function setDate(value: Date) {
        dispatch(setCurrentDate(getYearMonthDay(value)));
        setValue("date", value);
    }

    return (
        <div className="services-workplace">
            <div className="services-header">
                {!isMobile && (
                    <div className="services-education-header__left">
                        <div className="header__exitIcon" onClick={() => navigate(-1)}>
                            <ArrowExitIcon />
                        </div>

                        <h1>Карта офиса</h1>
                    </div>
                )}

                <div className="services-education-header__right">
                    <SearchItem params={""} onChange={() => ""} />
                </div>
            </div>

            <div className="services-workplace-content mb-3">
                <div className="services-workplace-content__header">
                    <Controller
                        name="city"
                        control={control}
                        rules={{
                            required: {
                                value: true,
                                message: ""
                            }
                        }}
                        render={({ field: { onChange, value } }) => (
                            <Dropdown
                                className={"form-select"}
                                placeholder={"Все"}
                                label={"Город"}
                                options={[
                                    ...citiesDropdownData
                                ]}
                                value={value}
                                onChange={(e) => {
                                    onChange(e)
                                    handleSelect("city", e)
                                }}
                                isSearchable={true}
                            />
                        )}
                    />

                    <Controller
                        name="building"
                        control={control}
                        rules={{
                            required: {
                                value: true,
                                message: ""
                            }
                        }}
                        render={({ field: { onChange, value } }) => (
                            <Dropdown
                                className={"form-select"}
                                placeholder={"Все"}
                                label={"Офис"}
                                options={buildingsDropdownData}
                                value={value}
                                onChange={(e) => {
                                    onChange(e)
                                    handleSelect("building", e);
                                }}
                                isSearchable={true}
                            />
                        )}
                    />

                    <Controller
                        name="floor"
                        control={control}
                        rules={{
                            required: {
                                value: true,
                                message: ""
                            }
                        }}
                        render={({ field: { onChange, value } }) => (
                            <Dropdown
                                className={"form-select"}
                                placeholder={"Все"}
                                label={"Этаж"}
                                options={floorsDropdownData}
                                value={value}
                                onChange={(e) => {
                                    onChange(e)
                                    handleSelect("floor", e);
                                }}
                                isSearchable={true}
                            />
                        )}
                    />

                    <Controller
                        name="date"
                        control={control}
                        rules={{
                            required: {
                                value: true,
                                message: "Выберете дату"
                            }
                        }}
                        render={({ field: { onChange, value } }) => (
                            <DatePickerForm
                                className=""
                                label="Дата"
                                placeholder={"Не выбрано"}
                                onChange={(e) => {
                                    onChange(e)
                                    setDate(e);
                                }}
                                isIcon
                                isRightIcon
                                minDate={new Date()}
                                startDate={value ? value : new Date()}
                            />
                        )}
                    />

                    <WorkplaceAvailablePlaces/>
                </div>

                <ServicesWorkplaceMap />
            </div>
        </div>
    );
};