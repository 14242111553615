import { IApiHandlerParams } from "../../../interface";
import api from "../../../index";
import {
    CreateGroupsBannerRequestData,
    CreateGroupsBannerResponseData, EditGroupsBannerRequestData,
    GetGroupsBannerResponseData
} from "./interface";

export const $getMainBannerList = (
    handler?: IApiHandlerParams
): Promise<GetGroupsBannerResponseData[]> => api
.post("api/workspaces/bannerList", null, {
    handler
});

export const $createMainBanner = (
    data: CreateGroupsBannerRequestData,
    handler?: IApiHandlerParams
): Promise<CreateGroupsBannerResponseData> => api
.post(`api/workspaces/bannerCreate`, data, {
    // mock: require("./mocks/news.item.json"),
    handler
});

export const $editMainBanner = (
    data: EditGroupsBannerRequestData,
    handler?: IApiHandlerParams
): Promise<boolean> => api
.post(`api/workspaces/bannerEdit`, data, {
    // mock: require("./mocks/news.item.json"),
    handler
});

export const $deleteMainBannerById = (
    data: { id: string },
    handler?: IApiHandlerParams
): Promise<boolean> => api
.post(`api/workspaces/bannerDelete`, data, {
    // mock: require("./mocks/news.item.json"),
    handler
});