import { FC } from "react";
import { IBaseIconProps } from "./types";

export const UserProfileAdd: FC<IBaseIconProps> = ({
  size = 24,
  color = 'white'
}) => {
  return (
    <svg
        width={size}
        height={size}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
      <path
          d="M1.39844 21.6003C1.39838 22.1526 1.84604 22.6003 2.39833 22.6004C2.95061 22.6005 3.39838 22.1528 3.39844 21.6005L1.39844 21.6003ZM2.39884 18L3.39884 18.0001L2.39884 18ZM12.5984 15.4004C13.1507 15.4004 13.5984 14.9527 13.5984 14.4004C13.5984 13.8481 13.1507 13.4004 12.5984 13.4004V15.4004ZM21.5984 16.6004C22.1507 16.6004 22.5984 16.1527 22.5984 15.6004C22.5984 15.0481 22.1507 14.6004 21.5984 14.6004V16.6004ZM16.1984 14.6004C15.6462 14.6004 15.1984 15.0481 15.1984 15.6004C15.1984 16.1527 15.6462 16.6004 16.1984 16.6004V14.6004ZM17.8984 18.3002C17.8984 18.8525 18.3462 19.3002 18.8984 19.3002C19.4507 19.3002 19.8984 18.8525 19.8984 18.3002H17.8984ZM19.8984 12.9002C19.8984 12.348 19.4507 11.9002 18.8984 11.9002C18.3462 11.9002 17.8984 12.348 17.8984 12.9002H19.8984ZM13.3984 6.00039C13.3984 7.43633 12.2344 8.60039 10.7984 8.60039V10.6004C13.3389 10.6004 15.3984 8.5409 15.3984 6.00039H13.3984ZM10.7984 8.60039C9.36249 8.60039 8.19844 7.43633 8.19844 6.00039H6.19844C6.19844 8.5409 8.25792 10.6004 10.7984 10.6004V8.60039ZM8.19844 6.00039C8.19844 4.56445 9.36249 3.40039 10.7984 3.40039V1.40039C8.25792 1.40039 6.19844 3.45988 6.19844 6.00039H8.19844ZM10.7984 3.40039C12.2344 3.40039 13.3984 4.56445 13.3984 6.00039H15.3984C15.3984 3.45988 13.3389 1.40039 10.7984 1.40039V3.40039ZM3.39844 21.6005L3.39884 18.0001L1.39884 17.9999L1.39844 21.6003L3.39844 21.6005ZM5.99884 13.4004C3.45853 13.4004 1.39913 15.4596 1.39884 17.9999L3.39884 18.0001C3.399 16.5643 4.56302 15.4004 5.99884 15.4004V13.4004ZM5.99884 15.4004H12.5984V13.4004H5.99884V15.4004ZM21.5984 14.6004H18.8984V16.6004H21.5984V14.6004ZM18.8984 14.6004H16.1984V16.6004H18.8984V14.6004ZM19.8984 18.3002V15.6004H17.8984V18.3002H19.8984ZM19.8984 15.6004V12.9002H17.8984V15.6004H19.8984Z"
          fill={color}
      />
    </svg>
  )
}