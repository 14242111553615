import { configureStore } from '@reduxjs/toolkit'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import {
    loadingReducer,
    profileReducer,
    mainPageReducer,
    newsReducer,
    vacationReducer,
    routeTitleReducer,
    structureReducer,
} from "./reducers";
import { workplaceSliceReducer } from "./reducers/workplace/workplaceSlice";
import {
    workplaceManagementSliceReducer
} from "./reducers/workplace-management/workplaceManagementSlice";

export const store = configureStore({
    reducer: {
        loading: loadingReducer,
        profile: profileReducer,
        mainPage: mainPageReducer,
        news: newsReducer,
        vacation: vacationReducer,
        routeTitle: routeTitleReducer,
        structure: structureReducer,
        workplace: workplaceSliceReducer,
        workplaceManagement: workplaceManagementSliceReducer,
    },
})
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export const useAppDispatch: () => AppDispatch = useDispatch
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
