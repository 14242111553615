import { FC } from "react";
import { IBaseIconProps } from "../types";

export const ServicesSubstituteIcon: FC<IBaseIconProps> = (
    {
        size = 36,
        color = "#00A7B5"
    }) => {
    return (
        <svg width={size} height={size} viewBox="0 0 36 36" fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path
                d="M3.60156 32.4L3.60217 26.9994C3.6025 24.0173 6.02007 21.6 9.00217 21.6H18.0016M26.3572 27.45L22.5001 23.85M22.5001 23.85L26.3572 20.25M22.5001 23.85H32.4001M21.6016 8.99997C21.6016 11.9823 19.1839 14.4 16.2016 14.4C13.2192 14.4 10.8016 11.9823 10.8016 8.99997C10.8016 6.01764 13.2192 3.59998 16.2016 3.59998C19.1839 3.59998 21.6016 6.01764 21.6016 8.99997Z"
                stroke={color} strokeWidth="3" stroke-linecap="round" strokeLinejoin="round" />
        </svg>
    );
};