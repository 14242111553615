import ContentLoader from 'react-content-loader';

import { Box } from "@mui/material";
import { styled } from "@mui/system";
import { useSelector } from "react-redux";
import { getProfileVarsData } from "../../../redux";


const StyledCardSkeleton = styled(Box)({
  // width: 'calc(100% / 4 - 40px - (10px / 4))',

})

// const useStyles = makeStyles => ({
//     skeleton: {F
//         width: 'calc(100% / 4 - 20px - (10px / 4))',
//         height: '230px',
//         backgroundColor: whiteColor,
//         boxShadow: '0px 8px 30px rgba(0, 0, 0, 0.08)',
//         borderRadius: '8px',
//         padding: '20px',
//         [breakpoints.down('sm')]: {
//             width: '100%',
//             height: '112px',
//             padding: '16px',
//         },
//     },
// }));

export const CardSkeleton = () => {
  const vars = useSelector(getProfileVarsData);
  const isMobile = window.innerWidth < 965

  // const { skeleton } = useStyles();

  return (
    <div style={{
      width: '100%',
      height: '330px',
      backgroundColor: vars.colors.fourthColor,
      boxShadow: '0px 8px 30px rgba(0, 0, 0, 0.1)',
      borderRadius: '8px',
      padding: '20px',
    }}>
      <ContentLoader
        title=''
        height='210'
        backgroundColor={'#E3E3E3'}
        foregroundColor={'#F9F9F9'}
        gradientRatio={ 1 }
      >
        <rect rx='50%' ry='50%' width={ isMobile ? '50' : '80' } height={ isMobile ? '50' : '80' } />

        <rect
          x={ isMobile ? 'calc(50px + 16px)' : '0' }
          y={ isMobile ? '6px' : 'calc(80px + 24px)' }
          rx='4'
          ry='4'
          width='70%'
          height={ isMobile ? '10' : '12' }
        />

        <rect
          x={ isMobile ? 'calc(50px + 16px)' : '0' }
          y={ isMobile ? 'calc(6px + 10px + 8px)' : 'calc(80px + 24px + 12px + 8px)' }
          rx='4'
          ry='4'
          width='30%'
          height={ isMobile ? '10' : '12' }
        />

        <rect
          x={ isMobile ? 'calc(50px + 16px)' : '0' }
          y={ isMobile ? 'calc(100% - 26px)' : 'calc(100% - 28px)' }
          rx={ isMobile ? '13' : '14' }
          ry={ isMobile ? '13' : '14' }
          width={ isMobile ? '75' : '90' }
          height={ isMobile ? '26' : '28' }
        />
      </ContentLoader>
    </div>
  );
};
