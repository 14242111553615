import { Controller, useForm } from "react-hook-form";
import { useState } from "react";
import { CoinCompetitionIcon } from "../../img";
import TextField from "../ui/form/Fields/TextField";
import DialogContainer from "../ui/dialog/DialogContainer";
import Button from "../ui/form/Buttons/Button";
import { $setBalanceWorkspace } from "../../api/requests/workspace-settings";
import { useSelector } from "react-redux";
import { getPersonData } from "../../redux";

interface TrainingSessionViewModalCancelProps {
    isOpen: boolean;
    onClose: (value: boolean) => void;
    setIsOpen: (value: boolean) => void;
    data?: any;
}

interface FormData {
    balance: string;
}

const WorkspaceUsersAccrual = (
    {
        data,
        isOpen,
        onClose,
        setIsOpen
    }: TrainingSessionViewModalCancelProps
) => {
    const personalData = useSelector(getPersonData);

    const [isStatusComplete, setIsStatusComplete] = useState(false);

    const {
        handleSubmit,
        control,
        reset,
        formState: { isValid, errors },
    } = useForm<FormData>();

    function handleCloseModal() {
        onClose(false);
        reset();
        setIsStatusComplete(false);
    }

    function onSubmit(request: any) {
        const requestData = {
            ...request,
            id: personalData.workspace_id,
            user_id: data.id,
        };

        $setBalanceWorkspace(requestData, { formData: true }).then(res => {
            if (res.error || res.response) return;

            setIsStatusComplete(true);
        })
    }

    return (
        <DialogContainer
            isOpen={isOpen}
            setIsOpen={handleCloseModal}
            isShowCloseBtn={!isStatusComplete}
            widthProps={427}
            label={!isStatusComplete && "Начисление баллов"}
        >
            {isStatusComplete ? (
                <div className={"sport__view__modal"}>
                    <div className={"sport__view__modal__content-complete"}>
                        <span>👍🏻</span>

                        <h3>Успешно</h3>

                        <p>
                            Вы успешно начислили баллы.
                        </p>
                    </div>

                    <div className={"sport__view__modal__btn"}>
                        <Button
                            text={"Отлично"}
                            className={"btn btn-light w-100"}
                            onClick={handleCloseModal}
                        />
                    </div>
                </div>
            ) : (
                <form className={"sport__view__modal"} onSubmit={handleSubmit(onSubmit)}>
                    <div className="sport__view__modal__content sport__view__modal__content__cancel mb-3 pb-1">
                        <Controller
                            name="balance"
                            control={control}
                            rules={{
                                required: {
                                    value: true,
                                    message: ""
                                }
                            }}
                            render={({ field: { onChange, value } }) => (
                                <TextField
                                    value={value}
                                    className=""
                                    type={"number"}
                                    title="Кол-во баллов"
                                    placeholder={"Введите баллы"}
                                    name="balance"
                                    onChange={onChange}
                                    prepend={<CoinCompetitionIcon />}
                                    errors={errors}
                                />
                            )}
                        />
                    </div>

                    <div className={"sport__view__modal__btn"}>
                        <Button
                            type={"submit"}
                            text={"Отправить"}
                            className={"btn btn-primary w-100"}
                            disabled={!isValid}
                        />
                    </div>
                </form>
            )}
        </DialogContainer>
    );
};

export default WorkspaceUsersAccrual;