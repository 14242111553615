import { fabric } from 'fabric';

import { generateUUID, getDrawnShapePosition, setDrawnShapeSettings } from './utils';
import { API_URL_NEW } from "../../../api/config";

export function getMeetingRoomShape(container: any, canvas: any, details: any, onSuccess: any) {
    const center = getDrawnShapePosition(canvas);

    const defaultOption = {
        id: generateUUID(),
        left: center.x,
        top: center.y,
    };

    const quadWidth = 200;
    const quadHeight = 140;

    defaultOption.left -= quadWidth;
    defaultOption.top -= quadHeight;

    const bg = new fabric.Rect({
        fill: 'rgba(255, 255, 255, 0.8)',
        width: quadWidth,
        height: quadHeight,
        rx: 8,
        ry: 8,
    });

    const title = new fabric.Text(details.request.room.name, {
        fill: '#000000',

        top: 20,
        left: quadWidth / 2,
        originX: 'center',

        fontSize: 14,
        fontWeight: 600,
        fontFamily: 'Montserrat',
        textAlign: 'center',
    });

    const desc = new fabric.Text(`${details.request.room.workplace_count} мест`, {
        fill: '#000000',

        top: 42,
        left: quadWidth / 2,
        originX: 'center',

        fontSize: 12,
        fontWeight: 400,
        fontFamily: 'Montserrat',
        textAlign: 'center',
    });

    fabric.Image.fromURL(`${API_URL_NEW}/booking_add_button.png`, (img: any) => {
        img.scaleToWidth(quadWidth - 40);
        img.scaleToHeight(40);

        img.top = quadHeight - 60;
        img.left = 20;

        const item = new fabric.Group([bg, title, desc, img], {
            ...defaultOption,
            width: quadWidth,
            height: quadHeight,
        });

        setDrawnShapeSettings(item);

        onSuccess(item);
    });
}