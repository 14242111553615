import { EmailIcon, PenIcon, PhoneIcon, SearchIcon } from "../../img";
import DialogContainer from "../ui/dialog/DialogContainer";
import { useState } from "react";

export const UserVacationCard = () => {
  const replacer = {
    id: 1,
    image: "/testImages/people/Image-1.png",
    name: "Краюшкин Александр Витальевич",
    position: "Руководитель",
    icon: "/testImages/statuses/statuses-1.png",
    department: 'Департамент испытания и сертификации'
  }

  return (
    <div className='user-vacation'>
      {/*<div className='layout-vacation__header'>*/}
      {/*  <h5>До отпуска</h5>*/}

      {/*  <h1>8 дней</h1>*/}
      {/*</div>*/}

      <div className='user-vacation__dateWrapper'>
        <h3>Отпуск</h3>

        <div className='user-vacation__dateContent'>
          <p>21.02.2023</p>
          <p>21.02.2023</p>
        </div>
      </div>

      {/*<div className='layout-vacation__body'>*/}
      {/*  <p>Количество неотгуленных дней</p>*/}
      {/*  <h3>14</h3>*/}
      {/*</div>*/}

      {replacer && (
        <div className='user-vacation-replacer'>
          <p className='user-vacation-replacer__title'>Замещающий</p>

          <div className='user-vacation-replacer__content'>
            <div className='user-vacation-replacer__content__top'>
              <img src={replacer?.image} alt="" />

              <h1>{replacer?.name}</h1>
            </div>

            <div className='user-vacation-replacer__content__desc'>


              <div className='user-vacation-replacer__content__contact'>
                <PhoneIcon/>
                <p>+7 925 353-01-10</p>
              </div>

              <div className='user-vacation-replacer__content__contact'>
                <EmailIcon/>
                <p>ivanov@itelma.ru</p>
              </div>
            </div>

          </div>
        </div>
      )}
    </div>
  )
}