import { FC } from "react";
import { IBaseIconProps } from "../types";

export const WorkspaceUsersIcon: FC<IBaseIconProps> = ({
  size = 24,
  color = '#060606'
}) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.39844 18.2168C3.49708 16.3843 5.90495 15.1301 9.66155 15.1301C13.4182 15.1301 15.826 16.3843 16.9247 18.2168M17.6197 13.7406C19.6091 14.7352 20.6037 15.7299 21.5984 17.7193M15.5506 6.1376C16.4867 6.64115 17.1231 7.62991 17.1231 8.7673C17.1231 9.87183 16.523 10.8362 15.631 11.3522M12.6456 8.76727C12.6456 10.4153 11.3096 11.7513 9.66155 11.7513C8.0135 11.7513 6.67749 10.4153 6.67749 8.76727C6.67749 7.11922 8.0135 5.7832 9.66155 5.7832C11.3096 5.7832 12.6456 7.11922 12.6456 8.76727Z"
        stroke={color} strokeWidth="2" strokeLinecap="round" />
    </svg>
  )
}