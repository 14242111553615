import { FC, ReactElement } from "react";
import { Navigate, Outlet } from "react-router-dom";
import Cookies from "js-cookie";
import { useSelector } from "react-redux";
import { getCurrentRole } from "../../redux";

interface ProtectedRouteProps {
    redirectPath?: string;
    children?: ReactElement | null;
}

const ProtectedRoute: FC<ProtectedRouteProps> = (
    {
        redirectPath = '/auth',
        children
    }
) => {
    const token = Cookies.get('token') || localStorage.getItem("token");
    const currentRole = useSelector(getCurrentRole);

    const redirectTo = (url: string): string => {
        if (window.location.pathname !== url) window.location.href = url;

        return url;
    };

    const isRouterMain = (): string => {
        // if (!currentRole) return "/auth";

        if (currentRole === 3) return redirectTo("/trainer");

        // return currentRole?.centerId === null ? "/non/game" : "/";
        return "/"
    };

    const isCheckRole = (): boolean => {
        // if (!currentRole) return "/auth";

        if (currentRole === 3) return true;

        // return currentRole?.centerId === null ? "/non/game" : "/";
        return false;
    };

    if (!token) {
        return <Navigate to={redirectPath} replace />;
    }

    if (isCheckRole()) {
        return <Navigate to={isRouterMain()} replace/>;
    }

    return children ? children : <Outlet/>;
};
export default ProtectedRoute;
