import { CloseIcon, MessengerSendIcon, PaperClipIcon } from "../../img";
import { CommentMessengerChatBox } from "./CommentMessengerChatBox";
import React, { FC, useState } from "react";
import { ICommentsMessenger } from "../../api/requests/blogs/interface";
import { isTillMd } from "../../utils";
import { $postComment } from "../../api/requests/comment";
import ToggleBlock from "../ui/form/Toggle/ToggleBlock";
import { $editIdeasNotification } from "../../api/requests/ideas";
import { useSelector } from "react-redux";
import { getPersonData } from "../../redux";
import { BlankAva } from "../ui/avatars/BlankAva";

interface CommentMessengerPros {
    id?: number;
    comments_data: ICommentsMessenger[];
    setIsLoading?: any;
    url: string;
    accept?: string;
    isNotification?: number;
    isExpert?: boolean
}

export const CommentMessenger: FC<CommentMessengerPros> = (
    {
        comments_data,
        id,
        setIsLoading,
        url,
        accept = ".png, .jpg",
        isNotification,
        isExpert
    }
) => {
    const isMobile = isTillMd();

    const profileData = useSelector(getPersonData);
    const [text, setText] = useState("");
    const [attachments, setAttachments] = useState<any>([]);
    const [isNotificationValue, setIsNotificationValue] = useState<number>(isNotification || 0);//notifications_comments

    const handlePostComment = () => {
        setIsLoading(true);
        if (id) {
            $postComment({
                id,
                text,
                attachments
            }, `${url}/createcomment`, { formData: true }).then(res => {
                setIsLoading(false);
                setText("");
                setAttachments([]);
            });
        }
    };

    const handleGetCommentImages = ({ target }: any) => {
        const file = target?.files[0];

        if (file) {
            // const imageURL = URL.createObjectURL(file);
            setAttachments([...attachments, file]);
        }
    };

    const handleDeleteCommentImages = (index: number) => {
        const updatedList = attachments.filter((
            item: any,
            idx: number
        ) => idx !== index);

        setAttachments(updatedList);
    };

    function handleNotification() {
        const updateNot = isNotificationValue === 1 ? 0 : 1

        const requestData = {
            id,
            on: updateNot
        }

        $editIdeasNotification(requestData, { formData: true }).then(res => {
            if (!res) return

            setIsNotificationValue(updateNot)
        })
    }

    return (
        <div className="news-messenger">
            <div className="news-messenger__top">
                {!isMobile && (
                    <>
                        {profileData?.photo ? (
                            <img className="news-messenger__top__avatar"
                                 src={profileData?.photo} alt="" />
                        ) : (
                            <div className="news-messenger__top__avatar">
                                <BlankAva
                                    colorBg={"#CCD9E0"}
                                    colorText={"black"}
                                    text={profileData?.name}
                                />
                            </div>
                        )}
                    </>
                )}

                <div className="news-messenger__top__textarea__right">
                    <div className="news-messenger__top__textarea">
                        <input
                            type="text"
                            multiple
                            value={text}
                            placeholder="Оставить комментарий"
                            className="news-messenger__top__input"
                            onChange={(e) => setText(e.target.value)}
                        />

                        <div className="news-messenger__top__textarea__buttons">
                            <label className="news-messenger__top__textarea__buttons__imageButton">
                                <PaperClipIcon color={isMobile ? "#00A7B5" : "#000"} />

                                <input
                                    type="file"
                                    accept={accept}
                                    onChange={handleGetCommentImages}
                                    hidden
                                />
                            </label>

                            {isMobile ? (
                                <MessengerSendIcon />
                            ) : (
                                <div className="news-messenger__top__textarea__buttons__enterButton"
                                     onClick={handlePostComment}>
                                    Опубликовать
                                </div>
                            )}
                        </div>
                    </div>

                    <div className="news-messenger__top__textarea__attachments">
                        {attachments?.map((
                            item: any,
                            idx: number
                        ) => (
                            <div className="news-messenger__top__textarea__attachments__item"
                                 key={idx}>
                                <div className="closeIcon"
                                     onClick={() => handleDeleteCommentImages(idx)}>
                                    <CloseIcon color={"#FF0000"} />
                                </div>

                                <p>
                                    {idx !== attachments.length - 1 ? `${item?.name},` : item?.name}
                                </p>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            {(isNotification || typeof isNotification === "number") && (
                <ToggleBlock
                    isChecked={ isNotificationValue === 1 }
                    className="ideas__create__form-toggle"
                    desc="Сообщать о новых коментариях"
                    handleChange={ handleNotification }
                />
            )}

            {id && (
                <CommentMessengerChatBox
                    comments_data={comments_data}
                    item_id={id}
                    url={url}
                    setIsLoading={setIsLoading}
                    isExpert={isExpert}
                />
            )}
        </div>
    );
};