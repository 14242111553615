import { IApiHandlerParams } from "../../interface";
import api from "../../index";

export const $getWorkspaceUsers = (
    data: any,
    handler?: IApiHandlerParams
): Promise<any> =>
    api.post("api/workspaces/users", data, {
        // mock: require('./mocks/complete.form.calculate.json'),
        handler
    });

export const $changeWorkspaceDisableItems = (
    data: any,
    handler?: IApiHandlerParams
): Promise<any> =>
    api.post("api/workspaces/disableItems", data, {
        // mock: require('./mocks/complete.form.calculate.json'),
        handler
    });

export const $setBalanceWorkspace = (
    data: { id: string, user_id: string, balance: string },
    handler?: IApiHandlerParams
): Promise<any> =>
    api.post("api/workspaces/setbalance", data, {
        // mock: require('./mocks/complete.form.calculate.json'),
        handler
    });