export const ThreeDotsIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.5">
        <path
          d="M12.0016 7.1999C10.6761 7.1999 9.60156 6.12539 9.60156 4.7999C9.60156 3.47442 10.6761 2.3999 12.0016 2.3999C13.327 2.3999 14.4016 3.47442 14.4016 4.7999C14.4016 6.12539 13.327 7.1999 12.0016 7.1999Z"
          stroke="black" strokeWidth="2" />
        <path
          d="M12.0016 14.3999C10.6761 14.3999 9.60156 13.3254 9.60156 11.9999C9.60156 10.6744 10.6761 9.5999 12.0016 9.5999C13.327 9.5999 14.4016 10.6744 14.4016 11.9999C14.4016 13.3254 13.327 14.3999 12.0016 14.3999Z"
          stroke="black" strokeWidth="2" />
        <path
          d="M12.0016 21.5999C10.6761 21.5999 9.60156 20.5254 9.60156 19.1999C9.60156 17.8744 10.6761 16.7999 12.0016 16.7999C13.327 16.7999 14.4016 17.8744 14.4016 19.1999C14.4016 20.5254 13.327 21.5999 12.0016 21.5999Z"
          stroke="black" strokeWidth="2" />
      </g>
    </svg>
  )
}