import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getPersonData } from "../../redux";

interface IWork {
    position: string,
    department: string,
    workplace: string,
    birth: string,
    number: string,
    email: string,
    skills: ISkills[]
}

interface ISkills {
    id: number,
    value: string
}
export const PersonalWorkBlock = () => {
    const personalData = useSelector(getPersonData);

    const [data, setData] = useState<any>([
        {
            "id": 344,
            "name": "Офис",
            "sort": 5,
            "is_hidden": 0,
            "hidden_in_type": 0,
            "value": "риро"
        },
        {
            "id": 342,
            "name": "Город",
            "sort": 6,
            "is_hidden": 0,
            "hidden_in_type": 2,
            "value": "рирор"
        },
        {
            "id": 341,
            "name": "Внутренний табельный номер",
            "sort": 7,
            "is_hidden": 0,
            "hidden_in_type": 0,
            "value": "римри"
        }
    ]);

    const [hashtags, setHashtags] = useState<any>([]);

    useEffect(() => {
        if (personalData?.data) {
            personalData?.data?.my_job && setData(personalData?.data.my_job)
        }
    }, [personalData]);

    useEffect(() => {
        if (personalData?.hashtags) {
            if (!personalData?.hashtags.length) return;

            if (Array.isArray(personalData?.hashtags)) {
                return setHashtags(personalData?.hashtags);
            }

            const hashtagsNon = personalData?.hashtags?.replace(/#/g, '');

            setHashtags(hashtagsNon.split(','));
        }
    }, [personalData?.hashtags]);

    return (
        <div className='profile-work'>
            <div className='profile-work__content'>
                {data.map((item: any, idx: number) => (
                    <div
                        key={`data-profile-work-${idx}`}
                        className="profile-work__block">
                        <p className="profile-work__block__placeholder">{item.name}</p>

                        <h1 className="profile-work__block__value">{item.value}</h1>
                    </div>
                ))}
            </div>

            <div className="profile-work__line"></div>

            <div className="profile-work__skills">
                <h1 className="profile-work__skills__title">Навыки</h1>

                <div className='profile-work__skills__content'>
                    {hashtags.length ? hashtags.map((name: string, idx: number) => (
                        <div key={`skills-item-${idx}`} className='profile-work__skills__block'>
                            {name}
                        </div>
                    )) : <div>Навыки не добавлены</div>}
                </div>
            </div>
        </div>
    )
}