import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { ContestApplicationUsersItem } from "../../../api/requests/contest/interface";
import { $getContestUserApplicationList } from "../../../api/requests/contest";
import { ArrowExitIcon, SearchIcon } from "../../../img";
import Dropdown from "../../../components/ui/form/Dropdown/Dropdown";
import TextField from "../../../components/ui/form/Fields/TextField";
import {
    CompetitionsApplicationCard
} from "../../../components/competitions/CompetitionsApplicationCard";
import ContestsViewApplication from "./ContestsViewApplication";
import { isMd } from "../../../utils";

interface DropdownItem {
    label: string;
    value: number | string;
}

const ContestsPageApplications = () => {
    const navigate = useNavigate();
    const isMobile = isMd();

    const [userList, setUserList] = useState<ContestApplicationUsersItem[]>([]);
    const [filterList, setFilterList] = useState<ContestApplicationUsersItem[]>([]);

    const [statusList, setStatusList] = useState<DropdownItem[]>([
        {
            label: "Активный",
            value: "active"
        },
        {
            label: "Завершенный",
            value: "finish"
        },
        {
            label: "Скрытый",
            value: "hide"
        }
    ]);
    const [statusSelect, setStatusSelect] = useState<DropdownItem | null>(null);

    const [selectData, setSelectData] = useState<ContestApplicationUsersItem | null>(null);

    const [isOpenUserModal, setIsOpenUserModal] = useState(false);

    const [searchValue, setSearchValue] = useState<string>("");

    const handleNavigation = () => {
        navigate(-1);
    };

    useEffect(() => {
        $getContestUserApplicationList().then(res => {
            if (!res.data) return;

            setUserList(res.data);
            setFilterList(res.data);
        });
    }, []);

    function handleChangeStatusDropdown(select: DropdownItem) {
        setStatusSelect(select);

        const filterList = [...userList].filter(item => {
            const isStatus = statusSelect ? (item.status === statusSelect?.value) : true;

            if (select.value === "all") return isStatus;

            return (item.status === select.value) && isStatus;
        });

        setFilterList(filterList);
    }

    function onChangeSearch(value: string) {
        setSearchValue(value);

        const filterList = [...userList].filter(item => {
            const isCategory = statusSelect ? (item.status === statusSelect?.value) : true;

            return (item.name.toLowerCase().includes(value.toLowerCase())) && isCategory;
        });

        setFilterList(filterList);
    }

    return (
        <div className="favorites-news">
            <div className="layout-inner__header profile-header">
                {!isMobile && (
                    <div className="layout-inner__header__title">
                        <div className="header__exitIcon" onClick={handleNavigation}>
                            <ArrowExitIcon />
                        </div>
                        <h1>Заявки</h1>
                    </div>
                )}

                <div className="layout-inner__header__selects">
                    <Dropdown
                        className={"contests__application__dropdown"}
                        placeholder={"Категория"}
                        options={[
                            {
                                label: "Все",
                                value: "all"
                            },
                            ...statusList
                        ]}
                        value={statusSelect}
                        onChange={handleChangeStatusDropdown}
                    />

                    <div className={"form-group contests__application__search"}>
                        <TextField
                            value={searchValue}
                            onChange={onChangeSearch}
                            placeholder={"Поиск"}
                            prepend={<SearchIcon color={"#00000080"} />}
                        />
                    </div>
                </div>
            </div>

            <div className="container py-4">
                <div className="row contests__application__items">
                    {filterList.map((
                        item,
                        idx
                    ) => (
                        <div key={`contests-application-item-${idx}`} className={"col-lg-4 col-md-6 col-12"}>
                            <CompetitionsApplicationCard
                                data={item}
                                handleOpenItem={(data) => {
                                    setSelectData(data);

                                    setIsOpenUserModal(true);
                                }}
                            />
                        </div>
                    ))}
                </div>
            </div>

            <ContestsViewApplication
                isOpen={isOpenUserModal}
                onClose={() => setIsOpenUserModal(false)}
                data={selectData}
            />
        </div>
    );
};

export default ContestsPageApplications;