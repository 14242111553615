import { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    changeMainPage,
    getMainPageData,
    getProfileData, getPersonData, changePersonData, changeProfileVarsData
} from "../redux";
import Cookies from "js-cookie";
import { $mainPageNewData } from "../api/requests/main";
import Preloader from "../components/ui/preloader/Preloader";
import HeaderHome from "../components/header/HeaderHome";
import FooterHome from "../components/header/FooterHome";
import { Link, Navigate, Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import ScrollToTop from "../utils/scroll/ScrollToTop";
import AsideHome from "../components/header/AsideHome";
import { isMd } from "../utils";
import { profileData } from "../utils/loadData/profileData";
import { $getPersonalPage } from "../api/requests/user";
import { useVskAccess } from "../hooks";

interface IListPages {
    id: number;
    key: string;
    route: string;
    title: string;
    isFooterAvailable: boolean;
    is_drafted?: boolean;
}

interface IInnerLayout {
    isShowBreadcrumb?: boolean;
}

const InnerLayout: FC<IInnerLayout> = (
    {
        isShowBreadcrumb = true
    }
) => {
    const isMobile = isMd();
    const location = useLocation();

    const mainData = useSelector(getMainPageData);
    const localPersonData = useSelector(getPersonData);

    const {id} = useParams();

    const dispatch = useDispatch();

    const token = Cookies.get("token") || localStorage.getItem("token");

    const isBackgroundWhite =
        !location.pathname.includes("services")
        && location.pathname.includes("create")
        || location.pathname.includes("/services/education")
        || location.pathname.includes("/polls/edit")
        || location.pathname.includes("/blogs/edit");

    const routeLinks = useSelector((state: any) => state.routeTitle);

    const [listMenu, setListMenu] = useState([
        {
            img: "/images/icons/home.svg",
            text: "Главная страница",
            key: "home",
            count: 0,
            active: true,
            link: "/"
        },
        // {
        //     img: "/images/icons/facebook_like.svg",
        //     text: "Благодарности",
        //     key: "urls",
        //     count: 2,
        //     active: false,
        //     backColorCount: "#FFD600",
        //     colorCount: "#000000",
        //     link: "/"
        // },
        {
            img: "/images/icons/news.svg",
            text: "Новости",
            key: "news",
            count: 0,
            active: false,
            backColorCount: "#C7EF71",
            colorCount: "#000000",
            link: "/news"
        },
        {
            img: "/images/icons/megaphone.svg",
            text: "Конкурсы",
            key: "news",
            active: false,
            count: 0,
            link: "/competitions"
        },
        {
            img: "/images/icons/check_all.svg",
            text: "Опросы",
            key: "news",
            active: false,
            count: 0,
            link: "/polls"
        },
        {
            img: "/images/icons/user.svg",
            text: "Новые сотрудники",
            key: "news",
            active: false,
            count: 0,
            link: "/employees"
        },
        {
            img: "/images/icons/user.svg",
            text: "Структура компании",
            key: "news",
            active: false,
            count: 0,
            link: "/employees/structure"
        },
        {
            img: "/images/icons/user.svg",
            text: "Идеи",
            key: "ideas",
            active: false,
            count: 0,
            link: "/ideas"
        }
    ]);

    const listPages: IListPages[] = [
        {
            id: 1,
            key: "birthdays",
            route: "/birthdays",
            title: "Дни рождения",
            isFooterAvailable: !isMobile
        },
        {
            id: 2,
            key: "employees",
            route: "/employees",
            title: "Новые сотрудники",
            isFooterAvailable: !isMobile
        },
        {
            id: 2,
            key: "user",
            route: "/user",
            title: "Профиль",
            isFooterAvailable: true
        },
        {
            id: 3,
            key: "profile",
            route: "/profile",
            title: "Моя страница",
            isFooterAvailable: true
        },
        {
            id: 20,
            key: "news-blogs-show",
            route: "/news/blogs-show",
            title: "Моя страница",
            isFooterAvailable: true
        },
        {
            id: 8,
            key: "favorites",
            route: "/favorites",
            title: "Моя страница",
            isFooterAvailable: true
        },
        {
            id: 9,
            key: "favorites_employees",
            route: "/favorites/employees",
            title: "Моя страница",
            isFooterAvailable: true
        },
        {
            id: 10,
            key: "favorites_polls",
            route: "/favorites/polls",
            title: "Моя страница",
            isFooterAvailable: true
        },
        {
            id: 15,
            key: "news",
            route: "/news",
            title: "Новости",
            isFooterAvailable: true
        },
        {
            id: 12,
            key: "favorites_news",
            route: "/favorites/news",
            title: "Моя страница",
            isFooterAvailable: true
        },
        {
            id: 13,
            key: "favorites_competitions",
            route: "/favorites/competitions",
            title: "Моя страница",
            isFooterAvailable: true
        },
        {
            id: 6,
            key: "profile/colleagues",
            route: "/profile/colleagues",
            title: "Мои сотрудники",
            isFooterAvailable: !isMobile
        },
        {
            id: 3,
            key: "vacation",
            route: "/calendar/vacation",
            title: "Отпуска",
            isFooterAvailable: true
        },
        {
            id: 21,
            key: "polls",
            route: "/polls",
            title: "Опросы",
            isFooterAvailable: true
        },
        {
            id: 22,
            key: "blogs",
            route: "/blogs",
            title: "Опросы",
            isFooterAvailable: true,
            is_drafted: true
        },
        {
            id: 23,
            key: "ideas",
            route: "/ideas" || "/ideas/edit" ,
            title: "Идеи",
            isFooterAvailable: true
        },
        {
            id: 24,
            key: "ideas_show",
            route: `/ideas/show/${id}` ,
            title: "Идеи",
            isFooterAvailable: true
        },
        {
            id: 25,
            key: "ideas_edit",
            route: `/ideas/edit/${id}` ,
            title: "Редактировать идею",
            isFooterAvailable: true
        },
        {
            id: 25,
            key: "ideas_create",
            route: `/ideas/create` ,
            title: "Предложить идею",
            isFooterAvailable: true
        },
    ];

    const [isOpenMenu, setIsOpenMenu] = useState(false);
    const [modal, setModal] = useState("")

    const [personalData, setPersonalData] = useState(localPersonData || []);

    const { jsonData } = useVskAccess({ data: personalData });

    const getContentData = listPages.find((item) => item.route === location.pathname );

    const pathnames = location.pathname.split("/").filter(x => x);

    const getLinkTitle = () => {
        let title: any;
        for (const key in routeLinks) {
            if (routeLinks[key]?.route === location?.pathname) {
                title = routeLinks[key]?.title;
            }
        }

        return title;
    };

    const getBreadcrumbs = (
        pathname: any,
        routes: any
    ) => {
        const pathnames: any = pathname.split("/").filter((x: any) => x);
        const breadcrumbs = [];

        if (pathnames.length === 0) {
            breadcrumbs.push({ title: "Главная", route: "/" });
        } else {
            breadcrumbs.push({ title: "Главная", route: "/" });

            let currentPath = "";
            pathnames.forEach((segment: any) => {
                currentPath += `/${segment}`;
                const routeKey = Object.keys(routes).find(key => routes[key].route === currentPath);
                if (routeKey) {
                    breadcrumbs.push(routes[routeKey]);
                }
            });
        }

        return breadcrumbs;
    };

    useEffect(() => {
        !localPersonData && getFuncPersonData();
    }, []);

    useEffect(() => {
        if (mainData) return;

        getMainPage();
    }, []);

    useEffect(() => {
        setIsOpenMenu(false);
    }, [location]);

    useEffect(() => {
        const defaultColorScheme = {
            primaryColor: "#00A7B5", //blueColor
            secondaryColor: "#00A7B5", //lightBlue
            thirdColor: "#E9F0F9", //grayColor
            fourthColor: "#FFF" //whiteColor
        };

        if (jsonData) {
            dispatch(changePersonData(jsonData));
            dispatch(changeProfileVarsData({
                colors: jsonData.workspace_design
                    ? JSON.parse(jsonData.workspace_design)
                    : defaultColorScheme,
            }));
        }
    }, [jsonData]);

    function getFuncPersonData() {
        $getPersonalPage(null, { newApi: true }).then(res => {
            if (!res) return console.error("error person data");

            setPersonalData(res.data);
        });
    }

    function getMainPage() {
        $mainPageNewData().then((res) => {
            if (!res || res.error) return;

            dispatch(changeMainPage(res));
        });
    }

    function openMenu(): void {
        setIsOpenMenu(!isOpenMenu);
    }

    function openSearchModal(): void {
        setModal("search")
    }

    function onClickMenuFooter(i: any): void {
        if (i.key === "navigation") openMenu();
        if (i.key === 'search') openSearchModal();
    }

    if (!token) {
        return <Navigate to={"/auth"} replace />;
    }

    if (!localPersonData)
        return null

    return (
        <>
            <HeaderHome
              openMenu={ openMenu }
              modal={modal}
              setModal={setModal}
              title={ getContentData?.title }
              is_drafted={getContentData?.is_drafted}
            />

            <AsideHome
                isActive={isOpenMenu}
                openMenu={openMenu}
                menu={listMenu}
            />

            <div className={"inner__layout"}
                 style={{ background: isBackgroundWhite ? "#fff" : "" }}>
                {!isMobile && isShowBreadcrumb && (
                    <div className="breadcrumbs">
                        {getBreadcrumbs(location.pathname, routeLinks).map((
                            value: any,
                            index: any
                        ) => (
                            <Link
                                key={`breadcrumb-item-${index}`}
                                to={index !== getBreadcrumbs(location.pathname, routeLinks).length - 1 && value.route}
                                className={`breadcrumbs__link 
                                ${index === getBreadcrumbs(location.pathname, routeLinks).length - 1 ? "active" : ""}`}
                            >
                                {index !== 0 ? (
                                    <span>{">"}</span>
                                ) : ""}
                                <p>{value.title}</p>
                            </Link>
                        ))}
                    </div>
                )}

                <Outlet />
            </div>

            {getContentData ? (
                <>
                    {getContentData.isFooterAvailable && (
                        <FooterHome
                            isOpenMenu={ isOpenMenu }
                            openMenu={ openMenu }
                            onClickMenuFooter={ onClickMenuFooter }
                            setSearchModalOpen={openSearchModal}
                        />
                    )}
                </>
            ) : (
                <FooterHome
                    isOpenMenu={ isOpenMenu }
                    openMenu={ openMenu }
                    onClickMenuFooter={ onClickMenuFooter }
                    setSearchModalOpen={openSearchModal}
                />
            )}

            <ScrollToTop />
        </>
    );
};

export default InnerLayout;
