import { useCallback, useState } from 'react';

export const useDetectSwipes = ({ func = () => {}, direction = 'left' }) => {
  const [touchStart, setTouchStart] = useState(null);
  const [touchEnd, setTouchEnd] = useState(null);

  const minSwipeDistance = 50;

  const onTouchStart = useCallback((e: any) => {
    setTouchEnd(null);
    if (direction === 'left') {
      setTouchStart(e.targetTouches[0].clientX);
    } else {
      setTouchStart(e.targetTouches[0].clientY);
    }
  }, []);

  const onTouchMove = useCallback((e: any) => {
    if (direction === 'left') {
      setTouchEnd(e.targetTouches[0].clientX);
    } else {
      setTouchEnd(e.targetTouches[0].clientY);
    }
  }, []);

  const onTouchEnd = () => {
    if (!touchStart || !touchEnd) return;
    const distance = touchStart - touchEnd;
    const isLeftSwipe = distance > minSwipeDistance;
    const isRightSwipe = distance < -minSwipeDistance;
    const isSwipeBottom = distance < -minSwipeDistance;

    if ((isLeftSwipe || isRightSwipe) && direction === 'left') {
      func();
    } else if (isSwipeBottom && direction === 'bottom') {
      func();
    }
  };

  return {
    onTouchStart,
    onTouchMove,
    onTouchEnd,
  };
};
