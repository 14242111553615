import { FC } from "react";
import { IBaseIconProps } from "../types";

export const ServicesEducationIcon: FC<IBaseIconProps> = (
    {
        size = 36,
        color = '#00A7B5'
    }) => {
    return (
        <svg width={size} height={size} viewBox="0 0 36 36" fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path
                d="M22.4994 3.60004V9.00004C22.4994 9.99415 23.3053 10.8 24.2994 10.8H29.6994M12.5994 10.8H16.1994M12.5994 16.2H23.3994M12.5994 21.6H23.3994M26.9994 6.30004C26.1983 5.58325 25.367 4.7331 24.8422 4.18095C24.4929 3.81354 24.0104 3.60004 23.5035 3.60004H9.89897C7.91075 3.60004 6.29898 5.2118 6.29897 7.20002L6.29883 28.7999C6.29882 30.7882 7.91058 32.3999 9.89881 32.4L26.0989 32.4C28.0871 32.4 29.6988 30.7883 29.6989 28.8001L29.6994 9.71678C29.6994 9.25653 29.5238 8.81409 29.2044 8.48273C28.6137 7.87001 27.6273 6.86181 26.9994 6.30004Z"
                stroke={color} strokeWidth="3" stroke-linecap="round" strokeLinejoin="round" />
        </svg>
    );
};