import { useState, useEffect, useRef, FC } from "react";
import { NotificationIcon, SearchIcon } from "../../../img";
import { isMd, isTillMd } from "../../../utils";
import { $headerSearchPost } from "../../../api/requests/workplace";
import { HeaderNotificationPopup } from "./HeaderNotificationPopup";
import HeaderNotificationMobilePopup from "./HeaderNotificationMobilePopup";

interface IHeaderNotification {
    isPopup: boolean,
    setPopup: any
}

export const HeaderNotification: FC<IHeaderNotification> = (
    {
        isPopup,
        setPopup
    }) => {

    const isMobile = isTillMd();

    const popupRef = useRef<HTMLDivElement>(null);

    const handleOutsideClick = (event: MouseEvent) => {
        if ((popupRef.current && !popupRef.current.contains(event.target as Node))) {
            setPopup(false);
        }
    };

    useEffect(() => {
        if (isPopup) {
            document.addEventListener("mousedown", handleOutsideClick);
        } else {
            document.removeEventListener("mousedown", handleOutsideClick);
        }

        return () => {
            document.removeEventListener("mousedown", handleOutsideClick);
        };
    }, [isPopup]);


    return (
        <div ref={popupRef} className="header-notification">
            <div
                onClick={() => setPopup("notification")}
                className={"d-flex cursor-pointer"}
            >
                <NotificationIcon color={"#000"} />
            </div>

            {isMobile && (
                <HeaderNotificationMobilePopup isOpen={isPopup} setIsOpen={setPopup} />
            )}
            {isPopup && !isMobile && <HeaderNotificationPopup setPopup={setPopup} />}
        </div>
    );
};