import { DragDropContext, Draggable, Droppable } from "@hello-pangea/dnd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    getIsOpenCreateBanner, setIsOpenCreateBanner
} from "../../redux/reducers/workplace/workplaceSlice";
import { MenuIcon, SettingIcon } from "../../img";
import Button from "../../components/ui/form/Buttons/Button";
import { TrashIcon } from "../../img/icons/TrashIcon";
import { GetGroupsBannerResponseData } from "../../api/requests/groups/banners/interface";
import CompleteModal, { BtnDataItem } from "../../components/ui/dialog/CompleteModal";
import WorkspaceSettingGroupModalCreateEditBanner
    from "./groups/WorkspaceSettingGroupModalCreateEditBanner";
import {
    $createMainBanner,
    $deleteMainBannerById,
    $editMainBanner,
    $getMainBannerList
} from "../../api/requests/workspace-settings/banners";
import { getPersonData } from "../../redux";
import { $createGroupsBanner, $editGroupsBanner } from "../../api/requests/groups/banners";

const WorkspaceSettingBanners = () => {
    const dispatch = useDispatch();

    const personalData = useSelector(getPersonData);
    const isOpenCreateBanner = useSelector(getIsOpenCreateBanner);

    const btnListRemove = [
        {
            text: "Удалить",
            key: 0,
            className: "btn btn-red w-100"
        },
        {
            text: "Отмена",
            key: 1,
            className: "btn btn-light w-100"
        }
    ];

    const [state, setState] = useState<GetGroupsBannerResponseData[]>([]);

    const [isOpenDeleteBanner, setIsOpenDeleteBanner] = useState(false);
    const [isOpenCreateEditBanner, setIsOpenCreateEditBanner] = useState(false);
    const [selectBanner, setSelectBanner] = useState<GetGroupsBannerResponseData | undefined>(undefined);

    useEffect(() => {
        if (personalData) {
            init();
        }
    }, [personalData]);
    useEffect(() => {
        setIsOpenCreateEditBanner(isOpenCreateBanner);
    }, [isOpenCreateBanner]);

    function init() {
        $getMainBannerList().then(res => {
            setState(res);
        });
    }

    function onDragEnd(result: any) {
        const { destination, source, type } = result;

        if (!destination) {
            return;
        }

        if (destination.droppableId === source.droppableId && destination.index === source.index) {
            return;
        }

        if (type === "modules") {
            const sourceIndex = source.index;
            const targetIndex = destination.index;

            const workValue = [...state];
            const [deletedItem] = workValue.splice(sourceIndex, 1);
            workValue.splice(targetIndex, 0, deletedItem);

            handleEdit(targetIndex, workValue[sourceIndex], workValue);
        }
    }

    function handleEdit(targetIndex: number, currentItem: any, workValue: any[]) {
        const requestData: any = {
            id: currentItem.id,
            sort: targetIndex
        };

        $editMainBanner(requestData, { formData: true }).then(res => {
            setState(workValue);
        });
    }

    function handleEditBanner(select: GetGroupsBannerResponseData) {
        setSelectBanner(select);
        setIsOpenCreateEditBanner(true);
    }

    function handleDeleteBanner(select: GetGroupsBannerResponseData) {
        setSelectBanner(select);
        setIsOpenDeleteBanner(true);
    }

    function handleClickBtn(select: BtnDataItem) {
        if (select.key === 0 && selectBanner) {
            $deleteMainBannerById(
                { id: selectBanner.id.toString() },
                { formData: true }
            ).then(res => {
                setIsOpenDeleteBanner(false);
                setSelectBanner(undefined);
                init();
            });

            return;
        }

        setIsOpenDeleteBanner(false);
        setSelectBanner(undefined);
    }

    return (
        <>
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="list" type="modules">
                    {(provided) => (
                        <div
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                            className={"workspace-settings-group__banners"}
                        >
                            {state.length ? state.map((item, index) => (
                                <React.Fragment key={`banners-item-${index}`}>
                                    <Draggable
                                        draggableId={`banners-item-${index}`}
                                        index={index}
                                    >
                                        {(provided) => (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                className={"drag-and-drop__block workspace-settings-group__banners__left"}
                                            >
                                                <div className={""}>
                                                    <MenuIcon color={"#00000080"} />

                                                    <p>{item.sort ? item.sort + 1 : 1}</p>
                                                </div>

                                                <div className={"slider-banners w-100"}>
                                                    <div className={"w-100"}>
                                                        <img
                                                            src={item.image}
                                                            className="removeBackFace"
                                                            style={{ position: "absolute" }}
                                                        />
                                                    </div>

                                                    <div className="slider-banners__content">
                                                        {/*<h3>*/}
                                                        {/*    {item.src}*/}
                                                        {/*</h3>*/}

                                                        {/*<p>*/}
                                                        {/*    {item.text}*/}
                                                        {/*</p>*/}
                                                    </div>

                                                    <div className="slider-banners__setting">
                                                        <Button
                                                            className={"btn-icon"}
                                                            onClick={() => handleEditBanner(item)}
                                                            text={<SettingIcon width={24}
                                                                               height={24} />}
                                                        />

                                                        <Button
                                                            className={"btn-icon"}
                                                            onClick={() => handleDeleteBanner(item)}
                                                            text={<TrashIcon color={"white"} />}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </Draggable>
                                </React.Fragment>
                            )) : (
                                <div className={"empty__list"}>
                                    <h4>Нет баннеров</h4>

                                    <p>На данный момент вы еще не добавили ни одного баннера</p>
                                </div>
                            )}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>

            <WorkspaceSettingGroupModalCreateEditBanner
                isOpen={isOpenCreateEditBanner}
                onClose={() => {
                    setIsOpenCreateEditBanner(false);
                    dispatch(setIsOpenCreateBanner(false));
                    init();
                }}
                editData={selectBanner}
                createRequest={$createMainBanner}
                editRequest={$editMainBanner}
            />

            <CompleteModal
                isOpen={isOpenDeleteBanner}
                setIsOpen={() => {
                    setIsOpenDeleteBanner(false);
                    setSelectBanner(undefined);
                }}
                icon={"🥲"}
                title={"Вы уверены, что хотите удалить банер?"}
                btnList={btnListRemove}
                onClick={handleClickBtn}
            />
        </>
    );
};

export default WorkspaceSettingBanners;