import { Controller, useForm } from "react-hook-form";
import FileField from "../../../ui/form/FileField/FileField";
import React, { useEffect, useState } from "react";
import { BlankImage } from "../../../ui/blank-image/BlankImage";
import { $getPersonalPage } from "../../../../api/requests/user";
import { EditProfileFormData } from "../personal";
import moment from "moment";
import { $setProfileData } from "../../../../api/requests/auth";
import Dropdown from "../../../ui/form/Dropdown/Dropdown";
import Button from "../../../ui/form/Buttons/Button";

export interface EditConfidentialProfileFormData {
    whoSeeMyProfile: string;
    whoSeeMyPhone: string;
    whoSeeMyMail: string;
    whoSeeMyBirth: string;
    whoWriteMe: string;
}

export const ProfileConfidentialEditBlock = () => {

    const valuesList = [
        { label: "Все", value: "all" },
        { label: "Мои друзья", value: "my_friends" }
    ];

    const [userData, setUserData] = useState<EditProfileFormData>();
    const [editData, setEditData] = useState<EditConfidentialProfileFormData | null>(null);

    useEffect(() => {
        init();
    }, []);

    function init() {
        getProfile();
        getSettings();
    }

    function getProfile() {
        $getPersonalPage(null, { newApi: true }).then(res => {
            if (!res) return;

            const user = res.data;

            setUserData(user);
        });
    }

    function getSettings() {
        setEditData({
            whoSeeMyProfile: "all",
            whoSeeMyPhone: "all",
            whoSeeMyMail: "all",
            whoSeeMyBirth: "all",
            whoWriteMe: "all"
        });
    }

    const {
        handleSubmit,
        control,
        watch,
        reset,
        formState: { errors, isValid },
        trigger
    } = useForm<EditConfidentialProfileFormData>(
        (editData) ? {
            values: {
                whoSeeMyProfile: editData.whoSeeMyProfile,
                whoSeeMyPhone: editData.whoSeeMyPhone,
                whoSeeMyMail: editData.whoSeeMyMail,
                whoSeeMyBirth: editData.whoSeeMyBirth,
                whoWriteMe: editData.whoWriteMe
            }
        } : {
            values: {
                whoSeeMyProfile: "all",
                whoSeeMyPhone: "all",
                whoSeeMyMail: "all",
                whoSeeMyBirth: "all",
                whoWriteMe: "all"
            }
        }
    );

    const checkKeyDown = (e: any) => {
        if (e.key === "Enter") e.preventDefault();
    };

    const getValue = (
        opts: { label: string; value: string | number }[],
        val: number | string | undefined
    ) => {
        if (val) {
            return opts.filter((o) => o.value == val);
        }
        return null;
    };

    function onSubmit(data?: EditConfidentialProfileFormData) {

    }

    return (
        <div className="profile-edit__mainbar">
            <form
                onSubmit={handleSubmit((obj) => onSubmit(obj))}
                onKeyDown={(e) => checkKeyDown(e)}
            >
                <div className="profile-edit__header">
                    <div className="profile-edit__photo">
                        <BlankImage image={""} />
                    </div>

                    <p className="profile-edit__fulname">
                        {`${userData?.firstname ?? ""} ${userData?.lastname ?? ""}\n${userData?.familyName ?? ""}`}
                    </p>
                </div>

                <div className="profile-edit__block">
                    <Controller
                        name="whoSeeMyProfile"
                        control={control}
                        rules={{
                            required: {
                                value: true,
                                message: "Выберете"
                            }
                        }}
                        render={({ field: { onChange, value } }) => (
                            <Dropdown
                                className={"form-select"}
                                placeholder={"не выбран"}
                                label={"Кто видит мою страницу"}
                                options={valuesList}
                                value={getValue(valuesList, value)}
                                onChange={(select) => onChange(select.value)}
                            />
                        )}
                    />

                    <Controller
                        name="whoSeeMyPhone"
                        control={control}
                        rules={{
                            required: {
                                value: true,
                                message: "Выберете"
                            }
                        }}
                        render={({ field: { onChange, value } }) => (
                            <Dropdown
                                className={"form-select"}
                                placeholder={"не выбран"}
                                label={"Кто видит мой номер телефона"}
                                options={valuesList}
                                value={getValue(valuesList, value)}
                                onChange={(select) => onChange(select.value)}
                            />
                        )}
                    />

                    <Controller
                        name="whoSeeMyMail"
                        control={control}
                        rules={{
                            required: {
                                value: true,
                                message: "Выберете"
                            }
                        }}
                        render={({ field: { onChange, value } }) => (
                            <Dropdown
                                className={"form-select"}
                                placeholder={"не выбран"}
                                label={"Кто видит мой Email"}
                                options={valuesList}
                                value={getValue(valuesList, value)}
                                onChange={(select) => onChange(select.value)}
                            />
                        )}
                    />
                </div>

                <div className="profile-edit__block">
                    <Controller
                        name="whoSeeMyBirth"
                        control={control}
                        rules={{
                            required: {
                                value: true,
                                message: "Выберете"
                            }
                        }}
                        render={({ field: { onChange, value } }) => (
                            <Dropdown
                                className={"form-select"}
                                placeholder={"не выбран"}
                                label={"Кто видит мою дату рождения"}
                                options={valuesList}
                                value={getValue(valuesList, value)}
                                onChange={(select) => onChange(select.value)}
                            />
                        )}
                    />

                    <Controller
                        name="whoWriteMe"
                        control={control}
                        rules={{
                            required: {
                                value: true,
                                message: "Выберете"
                            }
                        }}
                        render={({ field: { onChange, value } }) => (
                            <Dropdown
                                className={"form-select"}
                                placeholder={"не выбран"}
                                label={"Кто может слать мне сообщения"}
                                options={valuesList}
                                value={getValue(valuesList, value)}
                                onChange={(select) => onChange(select.value)}
                            />
                        )}
                    />

                    <div style={{ width: "100%" }}></div>
                </div>

                <div className="profile-edit__bottom" style={{ marginTop: 100 }}>
                    <Button
                        text={"Отмена"}
                        className={"btn btn-light w-100"}
                        onClick={() => ""}
                    />

                    <Button
                        type={"submit"}
                        text={"Сохранить"}
                        className={"btn btn-primary w-100"}
                        onClick={() => onSubmit(watch())}
                    />
                </div>

                <div className="profile-edit__links">
                    <a href="#">Политика конфиденциальности персональных данных</a>
                    <a href="#">Информация о специальных предложениях партнёров</a>
                </div>
            </form>
        </div>
    );
};