import DialogContainer from "../../ui/dialog/DialogContainer";
import { FC, useState } from "react";
import { CloseIcon } from "../../../img";
import { ProfileBalanceIcon } from "../../../img/icons/layout-components";
import { $editIdea } from "../../../api/requests/ideas";
import { setLoading } from "../../../api/handler";

interface IIdeaPointModal {
    id: number,
    isOpen: boolean
    onClose: () => void
    setLoading: any,
}

export const IdeasPointsModal: FC<IIdeaPointModal> = (
    {
        isOpen,
        onClose,
        id
    }
) => {
    const [points, setPoints] = useState<any>(0);

    const handleChangeValue = (e: any) => {
        let value = e.target.value;

        if (/^\d*\.?\d*$/.test(value)) {
            value = value.replace(/^0+(?!$)/, "");

            if (value.startsWith(".")) {
                value = "0" + value;
            }

            setPoints(value);
        }
    };

    const sendPoint = () => {
        setLoading(true);

        $editIdea({ id: id || 0, points }, { formData: true }).then(res => {
            onClose();
            setLoading(false);
        });
    };

    return (
        <DialogContainer isOpen={isOpen} closeModal={onClose} isShowCloseBtn={false}>
            <div className="ideas-modal-points__header">
                <h5 className="ideas-modal-points__title">Баллы за идею</h5>

                <div className="ideas-modal-points__closeIcon" onClick={onClose}>
                    <CloseIcon color={"#000"} />
                </div>
            </div>

            <div className="ideas-modal-points__content">
                <p className="ideas-modal-points__label">Кол-во баллов</p>

                <div className="ideas-modal-points__input">
                    <ProfileBalanceIcon />

                    <input
                        value={points || 0}
                        type="number"
                        onChange={handleChangeValue}
                    />
                </div>
            </div>

            <div className="ideas-modal-points__button" onClick={sendPoint}>
                Отправить
            </div>
        </DialogContainer>
    );
};