import { FC, useState, useEffect, ReactNode } from "react";
import { SliderRunningIcon, SliderPracticesIcon, SliderWellbeingIcon } from "../../../img";
import { useNavigate } from "react-router-dom";

interface ISlides {
  id: number;
  title: string;
  type?: string;
  typeTitle: string;
  image: string;
  background?: any;
  src?: any;
  image_mobile?: string;
}

interface IFeedGroupSlider {
  slidersNumber: number;
  slides: ISlides[];
}

export const FeedGroupsSlider: FC<IFeedGroupSlider> = ({
  slidersNumber,
  slides
}) => {
  const navigate = useNavigate();

  const [ isCurSlide, setCurSlide ] = useState(0);

  const [ isSlideChange, setSlideChange ] = useState(false);

  // useEffect(() => {
  //     let interval: any;
  //     if (isSlideChange) {
  //         clearInterval(interval);
  //         setSlideChange(false);
  //         return;
  //     }
  //     interval = setInterval(
  //         () =>
  //             setCurSlide((s) => {
  //                 if (s === slidersNumber - 1) return 0;
  //                 return s + 1;
  //             }),
  //         5000,
  //     );
  //
  //     return () => {
  //         clearInterval(interval);
  //     };
  // }, [isSlideChange]);

  const handleSlideChange = (index: number) => {
    setCurSlide(index);
    setSlideChange(true);
  };

  const [ touchStart, setTouchStart ] = useState(null);
  const [ touchEnd, setTouchEnd ] = useState(null);

  const minSwipeDistance = 50;

  const onTouchStart = (e: any) => {
    setTouchEnd(null); // otherwise the swipe is fired even with usual touch events
    setTouchStart(e.targetTouches[0].clientX);
  };

  const onTouchMove = (e: any) => setTouchEnd(e.targetTouches[0].clientX);

  const onTouchEnd = () => {
    if (!touchStart || !touchEnd) return;
    const distance = touchStart - touchEnd;
    const isLeftSwipe = distance > minSwipeDistance;
    const isRightSwipe = distance < -minSwipeDistance;
    if (isLeftSwipe || isRightSwipe) {
      const isLeft = isLeftSwipe;

      setCurSlide((s) => {
        if (isLeft && s === 0) return slidersNumber - 1;
        if (!isLeft && s === slidersNumber - 1) return 0;
        return isLeft ? s - 1 : s + 1;
      });

      setSlideChange(true);
    }
  };

  return (
    <div className='feed-groups-slider'
         onTouchStart={ onTouchStart }
         onTouchMove={ onTouchMove }
         onTouchEnd={ onTouchEnd }
    >
      <div className='feed-groups-slider__backgroundContent'></div>

      { slides.map((
        {title, type, typeTitle, id},
        index
      ) => (
        <div key={ index }>
          { isCurSlide === index && (
              <div className="feed-groups-slider__typeContent">
                {/*{ type === 'sport' && <SliderRunningIcon /> }*/}
                {/*{ type === 'wellbeing' && <SliderWellbeingIcon /> }*/}
                {/*{ type === 'practices' && <SliderPracticesIcon /> }*/}

                <p>
                  {typeTitle}
                </p>
              </div>
          )}

          {isCurSlide === index && <h1 className="feed-groups-slider__title">{title}</h1>}
        </div>
      ))}

      <div className="feed-groups-slider__dotContainer">
        {[...new Array(slidersNumber)].map((
            _,
            index
        ) => (
            <div
                key={index}
                className={ `feed-groups-slider__dot ${ isCurSlide === index ? 'main-groups-slider__activeDot' : '' }` }
            style={ {
              backgroundColor: isCurSlide === index ? '#fff' : 'rgba(255, 255, 255, 0.2)',
              // opacity: isCurSlide === index ? 'unset' : '.2',
              border:
                isCurSlide === index
                  ? `1px solid #fff`
                  : `unset`,
            } }
            onClick={ () => handleSlideChange(index) }
          />
        )) }
      </div>

      { slides.map((
        {image, background, src, image_mobile},
        index
      ) => {
        const chooseImage = () => {
          // if (isMobile) return image_mobile;
          // if (isMobile) return image_mobile;
          if (background) return background;

          return image;
        };

        return (
          <div
            key={ index }
            className={ `fade ${ index === isCurSlide ? "fade-enter-active" : "fade-exit-active" }` }
          >
            <img
              src={ chooseImage() }
              className="removeBackFace"
              style={ {position: "absolute"} }
              onClick={ () => {
                if (!src) return;

                if (src.includes("http") || src.includes("https")) {
                  window.open(src, "_blank");
                } else {
                  window.open(`//${ src }`, "_blank");
                }
              } }
            />
          </div>
        );
      }) }

      <img
        // src={chooseImage()}
        src={ slides[0]?.background || slides[0]?.image }
        className="removeBackFace"
        style={ {visibility: 'hidden'} }
      />
    </div>
  )
}