import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getPersonData } from "../../../redux";
import { BadgesModalCard } from "./BadgesModalCard";

export const BadgesCard = () => {
    const [badgesData, setBadgesList] = useState<any[]>([
        {
            badge_id: 264,
            causes: "За честный труд! ",
            card: "/testImages/badges/badges-1.png",
            image: "/testImages/badges/badges-1.png",
            comment: "",
            date: 1713372793,
            privacy: '1',
            sender_id: 164795,
            user_id: 232236,
            workspace_badge: {
                id: 264,
                card: "/testImages/badges/badges-1.png",
                image: "/testImages/badges/badges-1.png",
            },
            sender: {
                photo: "/testImages/people/Image-3.png",
                name: "Агишев Тимур Шамилевич"
            }
        },
        {
            badge_id: 264,
            causes: "За честный труд! ",
            card: "/testImages/badges/badges-1.png",
            image: "/testImages/badges/badges-1.png",
            comment: "",
            date: 1713372794,
            privacy: '1',
            sender_id: 164795,
            user_id: 232236,
            workspace_badge: {
                id: 264,
                card: "/testImages/badges/badges-1.png",
                image: "/testImages/badges/badges-1.png",
            },
            sender: {
                photo: "/testImages/people/Image-3.png",
                name: "Агишев Тимур Шамилевич"
            }
        },
        {
            badge_id: 264,
            causes: "За честный труд! ",
            card: "/testImages/badges/badges-1.png",
            image: "/testImages/badges/badges-1.png",
            comment: "",
            date: 1713372794,
            privacy: '1',
            sender_id: 164795,
            user_id: 232236,
            workspace_badge: {
                id: 264,
                card: "/testImages/badges/badges-1.png",
                image: "/testImages/badges/badges-1.png",
            },
            sender: {
                photo: "/testImages/people/Image-3.png",
                name: "Агишев Тимур Шамилевич"
            }
        },
        {
            badge_id: 264,
            causes: "За честный труд! ",
            card: "/testImages/badges/badges-1.png",
            image: "/testImages/badges/badges-1.png",
            comment: "",
            date: 1713372794,
            privacy: '1',
            sender_id: 164795,
            user_id: 232236,
            workspace_badge: {
                id: 264,
                card: "/testImages/badges/badges-1.png",
                image: "/testImages/badges/badges-1.png",
            },
            sender: {
                photo: "/testImages/people/Image-3.png",
                name: "Агишев Тимур Шамилевич"
            }
        },
        {
            badge_id: 264,
            causes: "За честный труд! ",
            card: "/testImages/badges/badges-1.png",
            image: "/testImages/badges/badges-1.png",
            comment: "",
            date: 1713372794,
            privacy: '1',
            sender_id: 164795,
            user_id: 232236,
            workspace_badge: {
                id: 264,
                card: "/testImages/badges/badges-1.png",
                image: "/testImages/badges/badges-1.png",
            },
            sender: {
                photo: "/testImages/people/Image-3.png",
                name: "Агишев Тимур Шамилевич"
            }
        },
        {
            badge_id: 265,
            causes: "За честный труд! ",
            card: "/testImages/badges/badges-2.png",
            image: "/testImages/badges/badges-2.png",
            comment: "",
            date: 1713372797,
            privacy: '1',
            sender_id: 164795,
            user_id: 232236,
            workspace_badge: {
                id: 264,
                card: "/testImages/badges/badges-2.png",
                image: "/testImages/badges/badges-2.png",
            },
            sender: {
                photo: "/testImages/people/Image-3.png",
                name: "Агишев Тимур Шамилевич"
            }
        },
        {
            badge_id: 266,
            causes: "За честный труд! ",
            card: "/testImages/badges/badges-3.png",
            image: "/testImages/badges/badges-3.png",
            comment: "",
            date: 1713372797,
            privacy: '1',
            sender_id: 164795,
            user_id: 232236,
            workspace_badge: {
                id: 264,
                card: "/testImages/badges/badges-3.png",
                image: "/testImages/badges/badges-3.png",
            },
            sender: {
                photo: "/testImages/people/Image-3.png",
                name: "Агишев Тимур Шамилевич"
            }
        },
        {
            badge_id: 267,
            causes: "За честный труд! ",
            card: "/testImages/badges/badges-4.png",
            image: "/testImages/badges/badges-4.png",
            comment: "",
            date: 1713372797,
            privacy: '1',
            sender_id: 164795,
            user_id: 232236,
            workspace_badge: {
                id: 264,
                card: "/testImages/badges/badges-4.png",
                image: "/testImages/badges/badges-4.png",
            },
            sender: {
                photo: "/testImages/people/Image-3.png",
                name: "Агишев Тимур Шамилевич"
            }
        },
        {
            badge_id: 272,
            causes: "За честный труд! ",
            card: "/testImages/badges/badges-5.png",
            image: "/testImages/badges/badges-5.png",
            comment: "",
            date: 1713372797,
            privacy: '1',
            sender_id: 164795,
            user_id: 232236,
            workspace_badge: {
                id: 267,
                card: "/testImages/badges/badges-5.png",
                image: "/testImages/badges/badges-5.png",
            },
            sender: {
                photo: "/testImages/people/Image-3.png",
                name: "Агишев Тимур Шамилевич"
            }
        },

        {
            badge_id: 268,
            causes: "За честный труд! ",
            card: "/testImages/badges/badges-1.png",
            image: "/testImages/badges/badges-1.png",
            comment: "",
            date: 1713372797,
            privacy: '1',
            sender_id: 164795,
            user_id: 232236,
            workspace_badge: {
                id: 264,
                card: "/testImages/badges/badges-1.png",
                image: "/testImages/badges/badges-1.png",
            },
            sender: {
                photo: "/testImages/people/Image-3.png",
                name: "Агишев Тимур Шамилевич"
            }
        },
        {
            badge_id: 269,
            causes: "За честный труд! ",
            card: "/testImages/badges/badges-2.png",
            image: "/testImages/badges/badges-2.png",
            comment: "",
            date: 1713372797,
            privacy: '1',
            sender_id: 164795,
            user_id: 232236,
            workspace_badge: {
                id: 264,
                card: "/testImages/badges/badges-2.png",
                image: "/testImages/badges/badges-2.png",
            },
            sender: {
                photo: "/testImages/people/Image-3.png",
                name: "Агишев Тимур Шамилевич"
            }
        },
        {
            badge_id: 270,
            causes: "За честный труд! ",
            card: "/testImages/badges/badges-3.png",
            image: "/testImages/badges/badges-3.png",
            comment: "",
            date: 1713372797,
            privacy: '1',
            sender_id: 164795,
            user_id: 232236,
            workspace_badge: {
                id: 264,
                card: "/testImages/badges/badges-3.png",
                image: "/testImages/badges/badges-3.png",
            },
            sender: {
                photo: "/testImages/people/Image-3.png",
                name: "Агишев Тимур Шамилевич"
            }
        },
        {
            badge_id: 270,
            causes: "За честный труд! ",
            card: "/testImages/badges/badges-4.png",
            image: "/testImages/badges/badges-4.png",
            comment: "",
            date: 1713372797,
            privacy: '1',
            sender_id: 164795,
            user_id: 232236,
            workspace_badge: {
                id: 264,
                card: "/testImages/badges/badges-4.png",
                image: "/testImages/badges/badges-4.png",
            },
            sender: {
                photo: "/testImages/people/Image-3.png",
                name: "Агишев Тимур Шамилевич"
            }
        },
    ]);

    const personalData = useSelector(getPersonData);
    const [isOpenModal, setIsOpenModal] = useState(false)

    const groupData = (): any => {
        return [{}, ...badgesData].reduce((acc: any, curr: any) => {
            const key = curr.badge_id;
            if (!acc[key]) {
                acc[key] = [];
            }
            acc[key].push(curr);
            return acc;
        });
    };

    const filterData = badgesData?.filter((v: any, i: number, a: any) => a.findIndex((t: any) => t.badge_id === v.badge_id) === i);

    const checkBadgesSize = (badgeId: number) => groupData()[badgeId]?.length;

    useEffect(() => {
        // if (personalData?.data) {
        //     personalData?.data.badges && setBadgesList(personalData?.data.badges?.personal)
        // }
    }, [personalData]);

    function openBadgesListModal(item: any) {}

    const handleOpenModal = () => {
        setIsOpenModal(true)
    }

    return (
        <div className='layout-badges'>
            <div className="layout-congratulations__header">
                <h1>Благодарности</h1>

                <p onClick={handleOpenModal}>Показать все</p>
            </div>

            {filterData?.length ? (
                <div className='layout-badges__list'>
                    {filterData.slice(0, 4)?.map((item: any, idx: number) => {
                        const isBadgesSize = checkBadgesSize(item.badge_id);

                        return (
                            <div
                                key={idx}
                                className='layout-badges__item'
                                onClick={(e) => openBadgesListModal(item)}
                            >
                                <img src={item.workspace_badge.image} />

                                {isBadgesSize > 1 &&
                                  <div className='layout-badges__itemCount'>{isBadgesSize}</div>}
                            </div>
                        );
                    })}
                </div>
            ) : <div className={"empty"}>Список пуст</div>}

            <BadgesModalCard
              isOpen={isOpenModal}
              onClose={() => setIsOpenModal(false)}
              data={badgesData}
            />
        </div>
    )
}
