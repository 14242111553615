export const LocationIcon = ({
    color = '#00A7B5',
    width = 20,
    height = 20,
}) => (
    <svg
        width={width}
        height={height}
        viewBox="0 0 21 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M10.4992 18C10.4992 18 16.76 12.4348 16.76 8.26087C16.76 4.80309 13.9569 2 10.4992 2C7.04137 2 4.23828 4.80309 4.23828 8.26087C4.23828 12.4348 10.4992 18 10.4992 18Z"
            stroke={color}
            strokeWidth="1.66667"
        />
        <path
            d="M12.4994 8.00013C12.4994 9.1047 11.604 10.0001 10.4994 10.0001C9.39484 10.0001 8.49941 9.1047 8.49941 8.00013C8.49941 6.89556 9.39484 6.00013 10.4994 6.00013C11.604 6.00013 12.4994 6.89556 12.4994 8.00013Z"
            stroke={color}
            strokeWidth="1.66667"
        />
    </svg>
)
