import { IApiHandlerParams } from "../../../interface";
import api from "../../../index";
import { StatusResponseData } from "./interface";

export const $getStatuses = (
    data: { id: string },
    handler?: IApiHandlerParams
): Promise<StatusResponseData> =>
    api.post("api/workspaces/get_statuses", data,{
        // mock: require('./mocks/complete.form.calculate.json'),
        handler
    });

export const $createStatus = (
    data: { title: string, icon: File },
    handler?: IApiHandlerParams
): Promise<any> =>
    api.post("api/workspaces/store_status", data, {
        // mock: require('./mocks/complete.form.calculate.json'),
        handler
    });

export const $updateStatus = (
    data: { title: string, icon: File, id: string },
    handler?: IApiHandlerParams
): Promise<any> =>
    api.post("api/workspaces/update_status", data, {
        // mock: require('./mocks/complete.form.calculate.json'),
        handler
    });

export const $deleteStatus = (
    data: { id: string },
    handler?: IApiHandlerParams
): Promise<any> =>
    api.post("api/workspaces/delete_status", data, {
        // mock: require('./mocks/complete.form.calculate.json'),
        handler
    });