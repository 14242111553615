import { useSelector } from 'react-redux'
import { getPersonData } from "../../redux";
import decryptAes from './decryptAes'

/* decrypt AES-256 cbc fields for given workspace */
export const decryptFields = (
    d: string | any[] | any,
    { isDecryptError, setDecryptError }: any = {}
) => {
    const { secret_key: secret } = useSelector(getPersonData)

    if (isDecryptError) return d

    // check for crypt prefix
    const checkForCrypt = (v: any) => {
        if (typeof v !== 'string') return { v, isCrypt: false }

        if (v.substr(0, 13) === 'crypto_public') {
            return { v: v.substr(13), isCrypt: false, isPublicCrypt: true }
        }
        if (v.substr(0, 5) === 'crypt') {
            return { v: v.substr(5), isCrypt: true }
        }
        return { v, isCrypt: false }
    }

    const publicData = {
        isDecryptError,
        setDecryptError,
        isPublic: true,
        secret,
    }

    // decrypt string fields
    if (typeof d === 'string') {
        const { v, isCrypt, isPublicCrypt } = checkForCrypt(d)

        if (isCrypt) return decryptAes(v, { isDecryptError, setDecryptError })
        if (isPublicCrypt) return decryptAes(v, publicData)

        return v
    }

    // decrypt array fields
    if (Array.isArray(d)) {
        return d.map(({ value, ...rest }) => {
            const { v, isCrypt, isPublicCrypt } = checkForCrypt(value)

            if (isPublicCrypt)
                return { ...rest, value: decryptAes(v, publicData) }

            if (isCrypt) {
                return {
                    ...rest,
                    value: decryptAes(v, { isDecryptError, setDecryptError }),
                }
            }
            return { ...rest, value }
        })
    }

    // decrypt object fields
    if (typeof d === 'object') {
        return Object.keys(d).reduce((acc, key) => {
            const { v, isCrypt, isPublicCrypt } = checkForCrypt(d[key])

            if (isPublicCrypt)
                return { ...acc, [key]: decryptAes(v, publicData) }

            if (isCrypt) {
                return {
                    ...acc,
                    [key]: decryptAes(v, { isDecryptError, setDecryptError }),
                }
            }
            return { ...acc, [key]: v }
        }, {})
    }

    return d
}
