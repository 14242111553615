import moment from 'moment';

export const hoursTime = [
    {
        time: '00:00',
    },
    {
        time: '00:30',
    },
    {
        time: '01:00',
    },
    {
        time: '01:30',
    },
    {
        time: '02:00',
    },
    {
        time: '02:30',
    },
    {
        time: '03:00',
    },
    {
        time: '03:30',
    },
    {
        time: '04:00',
    },
    {
        time: '04:30',
    },
    {
        time: '05:00',
    },
    {
        time: '05:30',
    },
    {
        time: '06:00',
    },
    {
        time: '06:30',
    },
    {
        time: '07:00',
    },
    {
        time: '07:30',
    },
    {
        time: '08:00',
    },
    {
        time: '08:30',
    },
    {
        time: '09:00',
    },
    {
        time: '09:30',
    },
    {
        time: '10:00',
    },
    {
        time: '10:30',
    },
    {
        time: '11:00',
    },
    {
        time: '11:30',
    },
    {
        time: '12:00',
    },
    {
        time: '12:30',
    },
    {
        time: '13:00',
    },
    {
        time: '13:30',
    },
    {
        time: '14:00',
    },
    {
        time: '14:30',
    },
    {
        time: '15:00',
    },
    {
        time: '15:30',
    },
    {
        time: '16:00',
    },
    {
        time: '16:30',
    },
    {
        time: '17:00',
    },
    {
        time: '17:30',
    },
    {
        time: '18:00',
    },
    {
        time: '18:30',
    },
    {
        time: '19:00',
    },
    {
        time: '19:30',
    },
    {
        time: '20:00',
    },
    {
        time: '20:30',
    },
    {
        time: '21:00',
    },
    {
        time: '21:30',
    },
    {
        time: '22:00',
    },
    {
        time: '22:30',
    },
    {
        time: '23:00',
    },
    {
        time: '23:30',
    },
    {
        time: '24:00',
    },
];

export const findTime = (value: number) => {
    return hoursTime?.find(({ time }) => moment.duration(time).asSeconds() === value);
};

export const timeFormat = (value: string) => value?.split(':')?.slice(0, 2)?.join(':');
