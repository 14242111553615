import { IApiHandlerParams } from "../../../interface";
import api from "../../../index";
import {
    CreateGroupsBannerRequestData,
    CreateGroupsBannerResponseData, EditGroupsBannerRequestData,
    GetGroupsBannerResponseData
} from "./interface";

export const $getGroupsBannerList = (
    handler?: IApiHandlerParams
): Promise<GetGroupsBannerResponseData[]> => api
.post("api/groups/bannerList", null, {
    handler
});

export const $createGroupsBanner = (
    data: CreateGroupsBannerRequestData,
    handler?: IApiHandlerParams
): Promise<CreateGroupsBannerResponseData> => api
.post(`api/groups/bannerCreate`, data, {
    // mock: require("./mocks/news.item.json"),
    handler
});

export const $editGroupsBanner = (
    data: EditGroupsBannerRequestData,
    handler?: IApiHandlerParams
): Promise<boolean> => api
.post(`api/groups/bannerEdit`, data, {
    // mock: require("./mocks/news.item.json"),
    handler
});

export const $deleteGroupsBannerById = (
    data: { id: string },
    handler?: IApiHandlerParams
): Promise<boolean> => api
.post(`api/groups/bannerDelete`, data, {
    // mock: require("./mocks/news.item.json"),
    handler
});