import { useState } from 'react'
import { Link } from "react-router-dom";
import CodeTextField from "../../../components/ui/form/Fields/CodeTextField";
import TextField from "../../../components/ui/form/Fields/TextField";
import Button from "../../../components/ui/form/Buttons/Button";

export default function Activate() {
    const [passwordNew, setPasswordNew] = useState<string>('')

    const [errorMessage, setErrorMessage] = useState<string>('')

    const [isEye, setIsEye] = useState<boolean>(false)

    const changeTypePassword = (type: boolean): void => {
        setIsEye(!type)
    }

    return (
        <>
            <Link to={'/'} className="auth__steps registration__click">
                <span className="auth__steps-icon">
                    <img
                        width={24}
                        height={24}
                        src="/images/icons/close.svg"
                        alt="close"
                    />
                </span>

                <span className="auth__steps-text">Активация</span>
            </Link>

            <div className="auth__content_left_block activate">
                <div className="auth__content_left_item">
                    <div className="auth__content_left_block_header register">
                        <h2>СВОИ</h2>
                    </div>

                    <div className="auth__content_left_block_content auth__activate">
                        <div className="auth__activate__content">
                            <form className="auth__login__form">
                                <div className={"auth__login auth__click"}>
                                    <div className="field__number">
                                        <div className="field__number__content">
                                            {/*<div className="field__number__content-right">*/}
                                            {/*    <Image*/}
                                            {/*        width={240}*/}
                                            {/*        height={120}*/}
                                            {/*        src="/images/auth/phone-number.png"*/}
                                            {/*        alt="phone-number"*/}
                                            {/*    />*/}
                                            {/*</div>*/}

                                            <div className="field__number__content-left">
                                                <p>
                                                    <strong
                                                        className={'d-block'}
                                                    >
                                                        Сейчас Вам поступит СМС
                                                        с кодом.
                                                    </strong>
                                                    Введите <b>4</b> цифры из
                                                    СМС для подтверждения вашего
                                                    номера телефона.
                                                </p>
                                            </div>
                                        </div>

                                        <div className="field__number__fields">
                                            <CodeTextField />
                                        </div>
                                    </div>

                                    <div className="form-block">
                                        <TextField
                                            type={isEye ? 'text' : 'password'}
                                            value={passwordNew}
                                            onChange={setPasswordNew}
                                            append={
                                                <>
                                                    <Button
                                                        className="btn btn-link"
                                                        text={
                                                            <img
                                                                src={
                                                                    isEye
                                                                        ? '/images/icons/eye.svg'
                                                                        : '/images/icons/hide-eye.svg'
                                                                }
                                                                width="24"
                                                            />
                                                        }
                                                        onClick={() => changeTypePassword(isEye)}
                                                    />
                                                </>
                                            }
                                            label={'Придумайте пароль'}
                                            errors={{ password: { message: errorMessage || "" } }}
                                            isSingle
                                        />
                                    </div>

                                    <Link
                                        to={'/'}
                                        className="btn btn-primary"
                                    >
                                        Активировать
                                    </Link>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                <div className="auth__content_left_block_footer">
                    <div className="auth__content_step">
                        <Link
                            to={'/'}
                            className="btn btn-link registration__click show"
                        >
                            Есть аккаунт? <span>Войти</span>
                        </Link>
                    </div>

                    <div className="auth__content_help">
                        <p>
                            Помощь
                            <Link to={'tel:88007707867'}>
                                {' '}
                                8 (800) 770-78-67
                            </Link>
                        </p>
                    </div>
                </div>
            </div>
        </>
    )
}
