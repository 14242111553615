import WorkplaceAvailablePlacesModal from "./WorkplaceAvailablePlacesModal";
import { useState } from "react";
import Button from "../../../ui/form/Buttons/Button";

const WorkplaceAvailablePlaces = () => {
    const [ isOpenModal, setIsOpenModal ] = useState(false);

    return (
        <>
            <div className={"mt-auto"}>
                <Button
                    text={"Доступные места"}
                    className={"btn btn-primary"}
                    onClick={() => setIsOpenModal(true)}
                />

                <WorkplaceAvailablePlacesModal
                    isOpen={isOpenModal}
                    onClose={() => setIsOpenModal(false)}
                />
            </div>
        </>
    );
};

export default WorkplaceAvailablePlaces;