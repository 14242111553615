import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { getProfileData } from '../redux'

export const useCheckCrypt = (autoOpen: boolean, profileData: any) => {
    const [isOpen, setIsOpen] = useState(false)

    const handleOpenVsk = () => setIsOpen(!isOpen)
    const saveCheckState = () => localStorage.setItem('vskModal', '1')

    useEffect(() => {
        const isModalClosed = localStorage.getItem('vskModal')

        if (isModalClosed) return
        if (!profileData?.id) return
        if (!autoOpen) return

        // check if token exists
        const isSecret = localStorage.getItem('secret')

        // check if ws id is correct
        const cryptWsId: string = process.env["NEXT_PUBLIC_CRYPT_WS_ID"] || ''
        const isCryptWs = profileData?.wsId === +cryptWsId

        // if not open Modal with VSK auth
        if (!isSecret && isCryptWs) {
            setTimeout(() => handleOpenVsk(), 1200)
        }
    }, [])

    const vskModal = useMemo(
        () => (
            <div></div>
            // <VskModal
            //     // id={'crypt-Modal'}
            //     saveCheckState={saveCheckState}
            //     // isSlideOpen={isOpen === id}
            //     handleClose={handleOpenVsk}
            //     onClose={handleOpenVsk}
            // />
        ),
        [isOpen]
    )

    return { vskModal, handleOpenVsk }
}
