import React, { FC } from "react";
import { IBaseIconProps } from "../types";

export const EyeIcon2: FC<IBaseIconProps> = (
    {
        size = "1.25rem",
        color = "black"
    }
) => {
    return (
        <svg width={size} height={size} viewBox="0 0 20 20" fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path
                d="M9.99967 11.6668C10.4417 11.6668 10.8656 11.4912 11.1782 11.1787C11.4907 10.8661 11.6663 10.4422 11.6663 10.0002C11.6663 9.55814 11.4907 9.13421 11.1782 8.82165C10.8656 8.50909 10.4417 8.3335 9.99967 8.3335C9.55765 8.3335 9.13372 8.50909 8.82116 8.82165C8.5086 9.13421 8.33301 9.55814 8.33301 10.0002C8.33301 10.4422 8.5086 10.8661 8.82116 11.1787C9.13372 11.4912 9.55765 11.6668 9.99967 11.6668Z"
                stroke={color}
                strokeWidth="1.66667"
                strokeLinecap="round"
                strokeLinejoin="round"
            />

            <path
                d="M17.5 10C15.9258 12.4925 13.0983 15 10 15C6.90167 15 4.07417 12.4925 2.5 10C4.41583 7.63167 6.66 5 10 5C13.34 5 15.5842 7.63167 17.5 10Z"
                stroke={color}
                strokeWidth="1.66667"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
