import { FC } from "react";
import { IBaseIconProps } from "./types";

export const FileEditIcon: FC<IBaseIconProps> = ({
  size = 24,
  color = 'black',
}) => {
  return (
      <svg width={size} height={size} viewBox="0 0 24 24" fill="none"
           xmlns="http://www.w3.org/2000/svg">
          <path
              d="M9.55547 21.5574H4.75546C3.42997 21.5574 2.35546 20.4828 2.35547 19.1574L2.35556 4.75741C2.35557 3.43193 3.43008 2.35742 4.75556 2.35742H15.5558C16.8813 2.35742 17.9558 3.43194 17.9558 4.75742V9.55742M13.1557 18.2484L18.2468 13.1573L21.6409 16.5514L16.5498 21.6426H13.1557V18.2484Z"
              stroke={color}
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
          />
      </svg>
  )
}