import React, { useEffect, useState } from "react";
import { updateRouteTitle } from "../../../redux";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { $createServiceApplications, $getServiceItem } from "../../../api/requests/services";
import { IUsers } from "../../../api/requests/user/interface";
import {
    IRequestServiceApplications, IServiceApplicationsFields,
    IServices,
    IServicesField
} from "../../../api/requests/services/interface";
import { AirplaneIcon, ArrowExitIcon } from "../../../img";
import { failureNotify, getYearMonthDay, isMd } from "../../../utils";
import { BlankImage } from "../../../components/ui/blank-image/BlankImage";
import { Controller, useForm } from "react-hook-form";
import TextArea from "../../../components/ui/form/Fields/TextArea";
import { ServicesEmployeeModal } from "../../../components/services";
import PlusIcon2 from "../../../img/icons/vacation/PlusIcon2";
import TextField from "../../../components/ui/form/Fields/TextField";
import DatePickerForm from "../../../components/ui/form/DatePickerForm";
import Dropdown from "../../../components/ui/form/Dropdown/Dropdown";
import FileField from "../../../components/ui/form/FileField/FileField";
import Button from "../../../components/ui/form/Buttons/Button";

export const ServicesShowPage = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { id: serviceId } = useParams();
    const isMobile = isMd();

    const [serviceData, setServiceData] = useState<IServices>();
    const [supervisorData, setSupervisorData] = useState<IUsers>();

    const [modalOpen, setModalOpen] = useState(0);

    const {
        handleSubmit,
        control,
    } = useForm<any>();

    const getDropdownItems = (data: any) => {
        return data?.map((item: any, idx: number) => {
            return {
                value: idx,
                label: item,
                ...item
            };
        });
    };

    const getCheckboxValues = (list: any, item: any) => {
        let updatedList: any = []
        if (list?.includes(item)) {
            updatedList = list.filter((itemToRemove: string) => itemToRemove !== item)
        }
        else {
            updatedList = [...list, item]
        }

        return updatedList
    }
    const handleChangeValues = (item: IServicesField, e: any) => {
        if (serviceData?.fields) {
            const updatedList = serviceData?.fields?.map((valueToChange) =>
                valueToChange.id == item.id ?
                    {
                        ...valueToChange,
                        value: item.type === "checkbox" ? getCheckboxValues(valueToChange.value, e) : e
                    }
                    :
                    valueToChange
            );

            setServiceData({ ...serviceData, fields: updatedList });
        }
    };

    useEffect(() => {
        dispatch(updateRouteTitle({ key: "services_show", title: serviceData?.title }));
    }, [serviceData]);

    useEffect(() => {
        if (serviceId) {
            $getServiceItem(+serviceId, { formData: true, newApi: true }).then(res => {
                setServiceData(res.data.sample);
            });
        }

    }, [serviceId]);

    function onSubmit() {
        const updatedList: IRequestServiceApplications | any = serviceData?.fields?.map((item) => {
                return {
                    id: item.id,
                    value: item.value,
                    name: item.name
                }
        })

        const getRequiredItems = serviceData?.fields?.filter((item) => item.required)
        if (  getRequiredItems?.every((item: IServiceApplicationsFields) => item.required && item.value)) {
            $createServiceApplications({fields: updatedList, sample_id: serviceData?.id || 0}, {newApi: true}).then((res) => {
                navigate('/services')
            })
        }
        else {
            failureNotify('Не все поля заполнены')
        }
    }

    return (
        <div className="services-show">
            <div className="layout-inner__header profile-header">
                {!isMobile && (
                    <div className="layout-inner__header__title">
                        <div className="header__exitIcon" onClick={() => navigate(-1)}>
                            <ArrowExitIcon />
                        </div>
                        <h1>{serviceData?.title}</h1>
                    </div>
                )}
            </div>

            <div className="services-show-content">
                <div className="services-show-block">
                    <BlankImage image={serviceData?.image_file || ""}
                                classname="services-show__image" />

                    <div className="services-show__header">
                        <h5 className="services-show__header__title">{serviceData?.title}</h5>

                        <div className="services-show__header__category">
                            {serviceData?.category_title}
                        </div>
                    </div>

                    <div className="services-show__form">
                        <form
                            onSubmit={handleSubmit(() => onSubmit())}
                            className="services-create-form"
                        >
                            <Controller
                                name="description"
                                control={control}
                                rules={{
                                    required: {
                                        value: true,
                                        message: "Напишите описание"
                                    }
                                }}
                                render={({ field: { onChange, value } }) => (
                                    <TextArea
                                        value={serviceData?.description || ""}
                                        className=""
                                        title="Описание"
                                        placeholder={"Введите описание"}
                                        onChange={onChange}
                                        disabled={true}
                                    />
                                )}
                            />

                            <div className="services-create-form__employees">
                                {supervisorData && (
                                    <div className={"services-create-form__employees__list"}
                                         onClick={() => setModalOpen(1)}>
                                        <div className="layout-vacation-modal__block">
                                            <div className="layout-vacation-modal__block__right">
                                                <BlankImage image={supervisorData?.photo || ""} />

                                                <div className="layout-vacation-modal__block__desc">
                                                    <h1>{supervisorData?.name}</h1>

                                                    <p className="layout-vacation-modal__block__desc__position">HR
                                                        менеджер</p>

                                                    <p className="layout-vacation-modal__block__desc__department">Департамент
                                                        испытания и сертификации</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}

                                {!supervisorData?.id && (
                                    <div className={"services-create-form__employees__bottom"}>
                                        <div className="services-create-form__employees__addButton"
                                             onClick={() => setModalOpen(1)}>
                                            <PlusIcon2 />
                                        </div>
                                    </div>
                                )}
                            </div>

                            {serviceData?.fields?.map((item, idx) => (
                                <div key={idx}>
                                    {item.type === "text" && (
                                        <Controller
                                            name={item.type}
                                            control={control}
                                            rules={{
                                                required: {
                                                    value: true,
                                                    message: "Напишите название"
                                                }
                                            }}
                                            render={({ field: { onChange, value } }) => (
                                                <TextField
                                                    value={item.value || ""}
                                                    className=""
                                                    title={`${item.name}${item.required && '*'}`}
                                                    placeholder={"Введите "}
                                                    name="name"
                                                    onChange={(e) => handleChangeValues(item, e)}
                                                />
                                            )}
                                        />
                                    )}

                                    {item.type === "date_from" && (
                                        <Controller
                                            name="date_from"
                                            control={control}
                                            rules={{
                                                required: {
                                                    value: true,
                                                    message: "Выберете дату"
                                                }
                                            }}
                                            render={({ field: { onChange, value } }) => (
                                                <DatePickerForm
                                                    value={item.value}
                                                    className=""
                                                    label={`${item.name}${item.required ? '*' : ''}`}
                                                    placeholder={"Не выбрано"}
                                                    onChange={(e) => handleChangeValues(item, e)}
                                                    isIcon
                                                    isRightIcon
                                                />
                                            )}
                                        />
                                    )}

                                    {item.type === "radiolist" && (
                                        <Controller
                                            name="type"
                                            control={control}
                                            rules={{
                                                required: {
                                                    value: true,
                                                    message: ""
                                                }
                                            }}
                                            render={({ field: { onChange, value } }) => (
                                                <Dropdown
                                                    className={"form-select"}
                                                    placeholder={"Тип сервиса"}
                                                    label={`${item.name}${item.required ? '*' : ''}`}
                                                    options={getDropdownItems(item.values)}
                                                    value={item.value}
                                                    onChange={(e) => handleChangeValues(item, e)}
                                                />
                                            )}
                                        />
                                    )}

                                    {item.type === "file" && (
                                        <div className="services-create-form__icon">
                                            <Controller
                                                name="icon"
                                                control={control}
                                                rules={{
                                                    required: {
                                                        value: true,
                                                        message: ""
                                                    }
                                                }}
                                                render={({ field: { onChange, value } }) => (
                                                    <FileField
                                                        iconFile={item.value ? " " :
                                                            <AirplaneIcon />}
                                                        className={"form-image"}
                                                        upload={item.value}
                                                        label={`${item.name}${item.required ? '*' : ''}`}
                                                        accept="image/svg+xml"
                                                        setUpload={(e) => handleChangeValues(item, e)}
                                                    />
                                                )}
                                            />

                                            <p className="services-create-form__icon__text">
                                                Прикрепить файл
                                            </p>
                                        </div>
                                    )}

                                    {item.type === "radio" && (
                                        <div className="services-show__form__checkboxes">
                                            <p className="services-show__form__label">{item.name}${item.required ? '*' : ''}</p>

                                            <div className="layout-contest__content">
                                                {(Array.isArray(item?.values) ? item?.values : JSON.parse(item?.values)).map((check: any, idx: number) => (
                                                    <div className="layout-contest__block"
                                                         key={idx}>
                                                        <input
                                                            type="checkbox"
                                                            checked={item.value === check}
                                                            onChange={() =>
                                                                handleChangeValues(
                                                                    item,
                                                                    check === item.value ? 0 : check
                                                                )}
                                                        />

                                                        <h3>{check}</h3>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    )}

                                    {item.type === "checkbox" && (
                                        <div className="services-show__form__checkboxes">
                                            <p className="services-show__form__label">mnogo${item.required ? '*' : ''}</p>

                                            <div className="layout-contest__content">
                                                {item?.values?.map((check: any, idx: number) => (
                                                    <div className="layout-contest__block"
                                                         key={idx}>
                                                        <input
                                                            type="checkbox"
                                                            checked={item.value.includes(check)}
                                                            onChange={(e) => handleChangeValues(item,  check)}
                                                        />

                                                        <h3>{check}</h3>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    )}
                                </div>
                            ))}

                            <div className="services-create-buttons" style={{marginTop: 20}}>
                                <p style={{fontSize: 14, marginBottom: 10}}>Поля отмеченные*обязательны для заполнения.</p>
                                <div className={"d-flex gap-3"}>
                                    <Button
                                        text={"История"}
                                        className={"btn btn-light w-100"}
                                        onClick={() => ""}
                                    />


                                    <Button
                                        onClick={onSubmit}
                                        type={"submit"}
                                        text={'Отправить'}
                                        className={"btn btn-primary w-100"}
                                    />
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            <ServicesEmployeeModal
                isOpen={modalOpen == 1}
                onClose={() => setModalOpen(0)}
                data={supervisorData}
                setData={setSupervisorData}
            />
        </div>
    );
};