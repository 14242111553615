import React, { useEffect, useState } from 'react'
import noImageBanner from '../../../img/noimage.png'

interface Props {
  image: string,
  style?: React.CSSProperties,
  classname?: string,
  onClick?: () => void
}

export const BlankImage = ({
  image,
  style,
  classname,
  onClick
}: Props) => {
  const [imageUrl, setImageUrl] = useState<any>(null)

  useEffect(() => {
    const img = new Image();
    img.src = image;

    img.onload = () => {
      setImageUrl(image);
    };

  }, [image]);

  return (
      <img src={imageUrl || noImageBanner} alt="" style={style} className={`blank-image ${classname}`} onClick={onClick}/>
  )
}
