export default function redirectUrl(url: string, self?: boolean): void {
    if ((navigator.userAgent.includes('Safari') && !navigator.userAgent.includes('Chrome')) || self) {
        window.location.href = url;
        return;
    }

    const element = document.createElement('a');
    element.href = url;
    element.target = '_blank';
    element.rel = 'noReference';

    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
}