import { IApiHandlerParams } from './interface';
import * as process from 'process';

export const HOST_URL: any = process.env.REACT_APP_API_URL

export const API_URL: any = HOST_URL + ''
export const API_URL_NEW: any = process.env.REACT_APP_NEW_API_URL

export const API_DEF_CONFIG: IApiHandlerParams = {
    dontShowErrorMessage: false,
    disableLoader: false,
    dontUseApiUrl: false,
    returnData: true,
    dontAuth: false,
    formData: false,
    newApi: false,
    delay: 200,
}
