import React, { useEffect, useState } from "react";

import { useNavigate, useParams } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";

import NewsCreateEditPreview from "./NewsCreateEditPreview";
import TextField from "../../../components/ui/form/Fields/TextField";
import DatePickerForm from "../../../components/ui/form/DatePickerForm/index";
import DatePickerTimeField from "../../../components/ui/form/DatePickerForm/DatePickerTimeField";
import ToggleBlock from "../../../components/ui/form/Toggle/ToggleBlock";
import Button from "../../../components/ui/form/Buttons/Button";

import { ArrowExitIcon, LikeIcon, PlusIcon2 } from "../../../img";
import { isMd } from "../../../utils";
import Dropdown from "../../../components/ui/form/Dropdown/Dropdown";
import { NewsItemData } from "../../../api/requests/news/interface";
import HashtagsField from "../../../components/ui/form/Hashtags/HashtagsField";
import EditorField from "../../../components/ui/form/Fields/EditorField";
import {
    $createNews,
    $deleteNews,
    $editNews, $getListCategories,
    $getNewsById
} from "../../../api/requests/news";
import FileField from "../../../components/ui/form/FileField/FileField";
import FontList from "fontawesome-5-icons-list"
import FontAwesome from "../../../components/ui/font-awesome/FontAwesome";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { getPersonData, getRedactorNewsListData, setRedactorNewsListData } from "../../../redux";
import { $getGroupList } from "../../../api/requests/groups";
import { withDebounce } from "../../../functions";

interface DropdownItem {
    label: string;
    value: number | string;
}

export interface CreateNewsFormData {
    image: File | string | null;
    title: string;
    text: string;
    json: any;
    cat_id: string | number;
    hashtags: string[];
    date: Date | undefined;
    comments: number;
    like_image?: string;
    like_image_file?: File | string | null;
    like_name?: string;
    like_type?: number;
    likes_count?: number;
    is_locked?: number;
    pinned?:  number;
    status?:  number;
    groups_ids?: DropdownItem[];
}

const NewsCreateEditPage = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const isMobile = isMd();

    const pathname = location.pathname;
    const { id } = useParams();

    const htmlData = useSelector(getRedactorNewsListData);
    const { group_id } = useSelector(getPersonData);

    const isEdit: boolean = pathname.includes("edit");

    const [editData, setEditData] = useState<CreateNewsFormData | null>(null);

    const [isShow, setIsShow] = useState(true);

    const [categoryList, setCategoryList] = useState<DropdownItem[]>([]);

    const [groupList, setGroupList] = useState<DropdownItem[]>([]);

    const checkIconList = [
        {
            label: "По умолчанию",
            value: 1,
        },
        {
            label: "Выбрать иконку",
            value: 2,
        },
        {
            label: "Загрузить иконку",
            value: 3,
        },
    ];

    const fontListIcons = FontList?.all?.map((i: any) => {
        i.value = i.name;
        i.iconLeft = <FontAwesome iconName={i.name}/>;

        return i;
    });

    const testBtnCreate = isEdit ? "Сохранить" : "Опубликовать";

    const {
        handleSubmit,
        control,
        watch,
        reset,
        formState: { errors, isValid },
        trigger,
    } = useForm<CreateNewsFormData>(
        (isEdit && editData) ? {
            values: {
                image: editData.image,
                title: editData.title,
                text: editData.text,
                json: editData.json,
                cat_id: editData?.cat_id,
                hashtags: editData.hashtags || [],
                date: editData.date,
                comments: editData.comments,
                like_image: editData.like_image,
                like_image_file: editData.like_image_file,
                like_name: editData.like_name,
                like_type: editData.like_type,
                likes_count: editData.likes_count,
                is_locked: editData.is_locked,
                pinned:  editData.pinned,
                groups_ids:  editData.groups_ids,
            }
        } : {
            values: {
                image: "",
                title: "",
                text: "",
                json: undefined,
                cat_id: 0,
                hashtags: [],
                date: undefined,
                comments: 0,
                like_image: "",
                like_image_file: "",
                like_name: "",
                like_type: 1,
                likes_count: 0,
                is_locked: 0,
                pinned:  0,
            }
        }
    );

    const handleNavigation = () => {
        navigate(-1);
    };

    const onChangeFormItem = (item: NewsItemData): CreateNewsFormData => {
        const hashtagsList = item.groups_ids ? item.groups_ids?.split(",") : []

        return {
            ...item,
            image: item.image,
            title: item.title,
            text: item.text,
            json: item.json,
            cat_id: item.category_id || "",
            hashtags: item.hashtags ? (item.hashtags as string).split(", ") : [],
            date: new Date(item.date * 1000),
            comments: item.comments,
            groups_ids: hashtagsList ? hashtagsList.map(item => {
                const findSelect = groupList.find(i => i.value === item);

                return {
                    ...findSelect,
                    value: item,
                    label: findSelect?.label || ""
                }
            }) : [],
        }
    }

    const getValue = (
        opts: { label: string; value: string | number }[],
        val: number | string | undefined
    ) => {
        if (val) {
            return opts.filter((o) => o.value == val);
        }
        return null;
    };

    const checkKeyDown = (e: any) => {
        if (e.key === 'Enter') e.preventDefault();
    };

    useEffect(() => {
        init();
    }, []);


    useEffect(() => {
        if (isEdit && id) {
            withDebounce(() => {
                $getNewsById({ id }, { formData: true }).then(res => {
                    if (res.code) return;

                    setEditData(onChangeFormItem(res));
                    dispatch(setRedactorNewsListData(res.text));
                });
            })
        }
    }, [groupList, categoryList]);

    function init() {
        getCategory();
        getGroup();
    }

    function getCategory() {
        $getListCategories().then(res => {
            if (!res || !res.length) return;

            setCategoryList([...res].map(i => ({
                ...i,
                label: i.title,
                value: i.id
            })))
        })
    }

    function getGroup() {
        $getGroupList().then(res => {
            if (!res || !res.length) return;

            const updateList: DropdownItem[] = [...res]
            .filter(({ role }: any) => {
                return role !== '0';
            })
            .sort((prev: any, next: any) => {
                if (next.id === group_id) {
                    return 1;
                }

                if (prev.id === group_id) {
                    return -1;
                }

                return 0;
            })
            .map(i => ({
                ...i,
                label: i.title || "",
                value: i.id
            }))

            updateList.unshift({
                label: "Общая группа",
                value: "0"
            })

            setGroupList(updateList)
        })
    }

    function onSubmit(data: CreateNewsFormData, isDraft?: boolean) {
        const requestData: any = {
            ...editData,
            ...data,
            date: moment(data.date).unix(), //.format("DD.MM.yyyyTHH:mm"),
            status: isDraft ? 0 : 1,
            text: htmlData,
            json: data.json ? JSON.stringify(data.json) : undefined,
            groups_ids: data.groups_ids?.map(item => item.value),
            hashtags: data.hashtags ? data.hashtags.join(', ') : undefined,
            like_image: data.like_image || ""
        };

        isDraft && trigger(["title", "cat_id"])

        if (isDraft && !data.title && !data.cat_id) return;

        if (isEdit) return handleEditData(requestData);

        $createNews(requestData, { formData: true }).then(res => {
            if (res.code) return;

            reset();
            navigate("/news");
        })
    }

    function handleEditData(requestData: any) {
        $editNews(requestData, { formData: true }).then(res => {
            if (res.code) return;

            reset();
            navigate(`/news/show/${id}`);
        })
    }

    function handleDelete() {
        if (!id) return;

        $deleteNews({ id }, { formData: true }).then(res => {
            if (res.code) return;

            reset();
            navigate("/news");
        })
    }

    return (
        <div className="contests__create">
            <div className="layout-inner__header profile-header contests__create__header">
                {!isMobile && (
                    <div className="layout-inner__header__title">
                        <div className="header__exitIcon" onClick={handleNavigation}>
                            <ArrowExitIcon />
                        </div>

                        <h1>{editData ? "Редактирование новости" : "Создать новость"}</h1>

                        {(editData?.status === 0) && <h5>Черновик</h5>}
                    </div>
                )}
            </div>

            <div className="contests__create__block">
                <div className="container">
                    <div className="contests__create__row row">
                        <div className="contests__create__block-left">
                            <form
                                onSubmit={handleSubmit((obj) => onSubmit(obj))}
                                className="contests__create__form"
                                onKeyDown={(e) => checkKeyDown(e)}
                            >
                                <Controller
                                    name="image"
                                    control={control}
                                    rules={{
                                        required: {
                                            value: false,
                                            message: "Прикрепите картинку"
                                        }
                                    }}
                                    render={({ field: { onChange, value } }) => (
                                        <FileField
                                            className={"form-image"}
                                            upload={value}
                                            label="Изображение"
                                            setUpload={onChange}
                                        />
                                    )}
                                />

                                <Controller
                                    name="title"
                                    control={control}
                                    rules={{
                                        required: {
                                            value: true,
                                            message: "Заполните заголовок"
                                        }
                                    }}
                                    render={({ field: { onChange, value } }) => (
                                        <TextField
                                            value={value}
                                            className=""
                                            title="Заголовок"
                                            placeholder={"Введите заголовок"}
                                            name="title"
                                            onChange={onChange}
                                            errors={errors}
                                        />
                                    )}
                                />

                                <Controller
                                    name="json"
                                    control={control}
                                    rules={{
                                        required: {
                                            value: false,
                                            message: "Напишите описание"
                                        }
                                    }}
                                    render={({ field: { onChange, value } }) => (
                                        <EditorField
                                            value={value}
                                            title="Описание"
                                            name="json"
                                            onChange={onChange}
                                            errors={errors}
                                        />
                                    )}
                                />

                                <div className={"d-flex gap-5"}>
                                    <Controller
                                        name="cat_id"
                                        control={control}
                                        rules={{
                                            required: {
                                                value: true,
                                                message: "Выберете категорию"
                                            }
                                        }}
                                        render={({ field: { onChange, value } }) => (
                                            <Dropdown
                                                className={"form-select"}
                                                placeholder={"Категория не выбрана"}
                                                label={"Категория"}
                                                options={categoryList}
                                                value={getValue(categoryList, value)}
                                                onChange={(select) => onChange(select.value)}
                                            />
                                        )}
                                    />

                                    <Controller
                                        name="groups_ids"
                                        control={control}
                                        rules={{
                                            required: {
                                                value: false,
                                                message: "Выберете группу"
                                            }
                                        }}
                                        render={({ field: { onChange, value } }) => (
                                            <Dropdown
                                                className={"form-select"}
                                                placeholder={"Группа не выбрана"}
                                                label={"Группа"}
                                                options={groupList}
                                                value={value}
                                                onChange={(select) => onChange(select)}
                                                isMulti
                                            />
                                        )}
                                    />
                                </div>

                                <div className='services-create-form__hashtags'>
                                    <Controller
                                        name="hashtags"
                                        control={control}
                                        rules={{
                                            required: {
                                                value: false,
                                                message: ""
                                            }
                                        }}
                                        render={({ field: { value, onChange } }) => (
                                            <HashtagsField
                                                value={value}
                                                title="Теги"
                                                name="hashtags"
                                                onChange={onChange}
                                            />
                                        )}
                                    />
                                </div>

                                <div className="sport__create__form-date">
                                    <h4>Время публикации</h4>

                                    <div className={"sport__create__form-date__items"}>
                                        <div className="sport__create__form-date__item">
                                            <Controller
                                                name={"date"}
                                                control={control}
                                                rules={{
                                                    required: {
                                                        value: false,
                                                        message: "Выберете дату"
                                                    }
                                                }}
                                                render={({ field: { onChange, value } }) => (
                                                    <DatePickerForm
                                                        startDate={value}
                                                        className=""
                                                        label="Дата публикации"
                                                        placeholder={"Выберите дату"}
                                                        onChange={onChange}
                                                        isIcon
                                                        isRightIcon
                                                    />
                                                )}
                                            />

                                            <Controller
                                                name={"date"}
                                                control={control}
                                                rules={{
                                                    required: {
                                                        value: false,
                                                        message: "Выберете время"
                                                    }
                                                }}
                                                render={({ field: { onChange, value } }) => (
                                                    <DatePickerTimeField
                                                        startDate={value as Date}
                                                        // timeIntervals={1}
                                                        label="Время публикации"
                                                        placeholder={"Выберите время"}
                                                        onChange={onChange}
                                                        isRightIcon
                                                    />
                                                )}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <Controller
                                    name="pinned"
                                    control={control}
                                    rules={{
                                        required: {
                                            value: false,
                                            message: ""
                                        }
                                    }}
                                    render={({ field: { onChange, value } }) => (
                                        <ToggleBlock
                                            isChecked={!!value}
                                            className="contests__create__form-toggle"
                                            desc="Закрепить новость"
                                            handleChange={(value) => onChange(value ? 1 : 0)}
                                        />
                                    )}
                                />

                                <Controller
                                    name="comments"
                                    control={control}
                                    rules={{
                                        required: {
                                            value: false,
                                            message: ""
                                        }
                                    }}
                                    render={({ field: { onChange, value } }) => (
                                        <ToggleBlock
                                            isChecked={!!value}
                                            className="contests__create__form-toggle"
                                            desc="Комментарии"
                                            handleChange={(value) => onChange(value ? 1 : 0)}
                                        />
                                    )}
                                />

                                <Controller
                                    name="like_type"
                                    control={control}
                                    rules={{
                                        required: {
                                            value: false,
                                            message: ""
                                        }
                                    }}
                                    render={({ field: { onChange, value } }) => (
                                        <ToggleBlock
                                            isChecked={!!value}
                                            className="contests__create__form-toggle"
                                            desc="Производный лайк"
                                            handleChange={(value) => onChange(value ? 1 : 0)}
                                        />
                                    )}
                                />

                                <div className="sport__create__form-date">
                                    <div className={"d-flex align-items-center gap-2"}>
                                        <h4>Иконка лайка</h4>

                                        <LikeIcon color={"#00A7B5"}/>
                                    </div>

                                    <div className={"sport__create__form-date__items"}>
                                        <div className="sport__create__form-date__item">
                                            <Controller
                                                name={"like_name"}
                                                control={control}
                                                rules={{
                                                    required: {
                                                        value: false,
                                                        message: "Выберете дату"
                                                    }
                                                }}
                                                render={({ field: { onChange, value } }) => (
                                                    <TextField
                                                        value={value || ""}
                                                        className=""
                                                        title="Название лайка"
                                                        placeholder={"Введите название"}
                                                        name="like_name"
                                                        onChange={onChange}
                                                        errors={errors}
                                                    />
                                                )}
                                            />

                                            <Controller
                                                name={"like_type"}
                                                control={control}
                                                rules={{
                                                    required: {
                                                        value: false,
                                                        message: ""
                                                    }
                                                }}
                                                render={({ field: { onChange, value } }) => (
                                                    <Dropdown
                                                        className={"form-select"}
                                                        placeholder={"Иконка"}
                                                        label={"Иконка"}
                                                        options={checkIconList}
                                                        value={getValue(checkIconList, value)}
                                                        onChange={(select) => onChange(select.value)}
                                                    />
                                                )}
                                            />
                                        </div>
                                    </div>
                                </div>

                                {watch("like_type") === 2 && (
                                    <Controller
                                        name="like_image"
                                        control={control}
                                        rules={{
                                            required: {
                                                value: false,
                                                message: "Прикрепите картинку"
                                            }
                                        }}
                                        render={({ field: { onChange, value } }) => (
                                            <Dropdown
                                                className={"form-select"}
                                                placeholder={"Выберете иконку"}
                                                label={"Выберете иконку"}
                                                options={fontListIcons}
                                                value={getValue(fontListIcons, value)}
                                                onChange={(select) => onChange(select.value)}
                                                isSearchable
                                            />
                                        )}
                                    />
                                )}

                                {watch("like_type") === 3 && (
                                    <div className={"form-image-small"}>
                                        <Controller
                                            name="like_image_file"
                                            control={control}
                                            rules={{
                                                required: {
                                                    value: false,
                                                    message: "Прикрепите картинку"
                                                }
                                            }}
                                            render={({ field: { onChange, value } }) => (
                                                <FileField
                                                    className={"form-image"}
                                                    upload={value || ""}
                                                    label={""}
                                                    setUpload={onChange}
                                                    accept={".svg"}
                                                    iconFile={<PlusIcon2/>}
                                                    id={"like_image_file"}
                                                />
                                            )}
                                        />

                                        <p>Добавьте иконку в формате .svg</p>
                                    </div>
                                )}

                                <div className={"d-flex gap-3"}>
                                    {isEdit && (
                                        <Button
                                            text={"Удалить"}
                                            className={"btn btn-red w-100"}
                                            onClick={handleDelete}
                                        />
                                    )}

                                    <Button
                                        text={"В черновик"}
                                        className={"btn btn-light w-100"}
                                        onClick={() => onSubmit(watch(), true)}
                                    />

                                    <Button
                                        type={"submit"}
                                        text={testBtnCreate}
                                        className={"btn btn-primary w-100"}
                                    />
                                </div>
                            </form>
                        </div>

                        <div className="contests__create__block-right">
                            <div className={"contests__create__preview"}>
                                <ToggleBlock
                                    isChecked={isShow}
                                    className="contests__create__form-toggle"
                                    desc="Предпросмотр"
                                    handleChange={setIsShow}
                                />

                                {isShow && (
                                    <NewsCreateEditPreview
                                        data={watch()}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NewsCreateEditPage;