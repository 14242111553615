import { FC } from "react";
import { IBaseIconProps } from "../../types";

export const InfoIcon: FC<IBaseIconProps> = (
    {
        size = 24,
        color = "#000",
        opacity = 0.3
    }
) => {
    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g opacity={opacity}>
                <path
                    d="M8.4002 7.1999H15.6002M8.4002 10.7999H15.6002M8.4002 14.3999H12.0002M6.59992 2.3999H17.4001C18.7256 2.3999 19.8002 3.47445 19.8001 4.79995L19.7999 19.2C19.7998 20.5254 18.7253 21.5999 17.3999 21.5999L6.59982 21.5998C5.27433 21.5998 4.19982 20.5253 4.19983 19.1998L4.19992 4.79989C4.19993 3.47441 5.27444 2.3999 6.59992 2.3999Z"
                    stroke={color}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
        </svg>
    );
};