import { ForwardedRef, forwardRef, useState } from 'react'
import { MaskFieldJSXProps } from './types'
import TextField from './TextField'
import { useMask } from '@react-input/mask'

export const setRefs =
	<T extends HTMLElement>(
		forwardedRef: ForwardedRef<T>,
		before?: (el: T | null) => void
	) =>
		(el: T | null) => {
			before && before(el)

			if (typeof forwardedRef == 'function') forwardedRef(el)
			else if (forwardedRef) forwardedRef!.current = el
			else forwardedRef = { current: el }
		}

const MaskTextField = forwardRef<HTMLInputElement, MaskFieldJSXProps>(
    (
        { mask, unmaskedOutput, value, onChange, errorMessage, ...props },
        forwardedRef
    ) => {
        const modify = (input: string) => {
            return {
                mask: '+_ ___ ___-__-__',
            }
        }

        const ref = useMask({
            mask: '+_ ___ ___-__-___',
            replacement: { _: /\d/ },
            modify,
        })
        const [maskedValue, setMaskedValue] = useState<string>(
            mask.getMaskedValue(value) || ''
        )
        const setFieldRefs = setRefs(forwardedRef, (el) => (ref.current = el))
        let unMaskedValue = value || ''

        const onChangeHandler = (newValue: string): void => {
            unMaskedValue = unmaskedOutput
                ? mask.getUnmaskedValue(newValue)
                : newValue

            setMaskedValue(newValue)

            onChange && onChange(unMaskedValue)
        }

        return (
            <TextField
                ref={setFieldRefs}
                {...props}
                value={maskedValue}
                onChange={onChangeHandler}
            />
        )
    }
)

MaskTextField.displayName = 'MaskTextField'

export default MaskTextField
