import React, { useEffect, useMemo, useState } from "react";
import { ArrowExitIcon, CloseIcon, SearchIcon } from "../../../img";
import ContestCreateEditPreview
    from "../../../containers/contests/ContestsCreateEdit/ContestCreateEditPreview";
import Button from "../../ui/form/Buttons/Button";
import DialogContainer from "../../ui/dialog/DialogContainer";
import ContestsViewWinnerWin from "../../../containers/contests/ContestsView/ContestsViewWinnerWin";
import ContestsViewWinnerExclude
    from "../../../containers/contests/ContestsView/ContestsViewWinnerExclude";
import TextField from "../../ui/form/Fields/TextField";
import { withDebounce } from "../../../functions";
import { $getUsers } from "../../../api/requests/user";
import { IUsers } from "../../../api/requests/user/interface";
import { BlankImage } from "../../ui/blank-image/BlankImage";
import { useSelector } from "react-redux";
import { getPersonData } from "../../../redux";
import { BlankAva } from "../../ui/avatars/BlankAva";

interface ContestsViewCompletedProps {
    isOpen: boolean;
    onClose: () => void;
    data?: any;
    setData: any;
}

export const ServicesEmployeesModal = (
    {
        data,
        isOpen,
        onClose,
        setData
    }: ContestsViewCompletedProps) => {
    const { workspace_id: wsId } = useSelector(getPersonData);

    const [usersList, setUsersList] = useState<IUsers[]>([]);
    const [filteredList, setFilteredList] = useState<IUsers[]>([]);

    const [selectedUsers, setSelectedUsers] = useState<IUsers[]>([]);
    const [selectedUsersIndexes, setSelectedUsersIndexes] = useState<any>([]);

    const [searchValue, setSearchValue] = useState<string>("");

    const handleAddData = () => {
        setSelectedUsersIndexes([]);
        setSelectedUsers([]);
        setSearchValue("");
        setUsersList([]);
        setData(selectedUsers);
        onClose();
    };

    useEffect(() => {
        if (searchValue) {
            $getUsers({ id: wsId, search: searchValue }, { formData: true }).then(res => {
                if (!res.users) return;

                setUsersList(res.users);
            });
        }
    }, [searchValue]);

    useEffect(() => {
        const updatedList: any = usersList.filter(item => !selectedUsers.some((s: any) => s.id == item.id));

        setFilteredList([...selectedUsers, ...updatedList]);
    }, [usersList, selectedUsers]);

    useEffect(() => {
        if (data) {
            setSelectedUsers(data);
            setSelectedUsersIndexes(data?.map((item: any) => item.id));
        }
    }, [data]);

    function onChangeSearch(value: string) {
        setSearchValue(value);
    }

    function onChangeCheckbox(item: IUsers) {
        if (selectedUsersIndexes.includes(item.id)) {
            setSelectedUsersIndexes((prev: any) => [...prev].filter(i => i !== item.id));
            setSelectedUsers((prev: any) => [...prev].filter(i => i.id !== item.id));
        } else {
            setSelectedUsersIndexes((prev: any) => [...prev, item.id]);
            setSelectedUsers([...selectedUsers, item]);
        }
    }

    function closeModal() {
        setSearchValue("");
        setUsersList([]);
        setFilteredList([])
        setSelectedUsersIndexes([]);
        setSelectedUsers([]);
        onClose();
    }

    return (
        <DialogContainer
            isOpen={isOpen}
            closeModal={closeModal}
            isShowCloseBtn={false}
            classNameModal={"p-0"}
            widthProps={427}
        >
            <div className={"contests__winner"}>
                <div className="services-create-modal__header" style={{marginBottom: 20}}>
                    <h3 className="services-create-modal__header__title">Пользователи</h3>

                    <div className="services-create-modal__header__close" onClick={onClose}>
                        <CloseIcon color="#000" />
                    </div>
                </div>

                <div className={"contests__winner__search"}>
                    <TextField
                        value={searchValue}
                        onChange={(value: string) => {
                            setSearchValue(value);
                            withDebounce(() => onChangeSearch(value));
                        }}
                        placeholder={"Поиск"}
                        prepend={<SearchIcon color={"black"} />}
                        className={`search__block-textField`}
                    />
                </div>

                <div className={"contests__winner__list"}>
                    {filteredList.map((item, idx) => (
                        <div key={idx} className="layout-vacation-modal__block">
                            <input
                                type="checkbox"
                                checked={selectedUsersIndexes.includes(item.id)}
                                onChange={() => onChangeCheckbox(item)}
                            />

                            <div className="layout-vacation-modal__block__right">
                                {item?.photo ? (
                                    <img src={item?.photo} alt="" />
                                ) : (
                                    <BlankAva
                                        colorBg={"#CCD9E0"}
                                        colorText={"black"}
                                        text={item?.name}
                                    />
                                )}

                                <div className="layout-vacation-modal__block__desc">
                                    <h1>{item.name}</h1>

                                    <p className="layout-vacation-modal__block__desc__position">HR
                                        менеджер</p>

                                    <p className="layout-vacation-modal__block__desc__department">Департамент
                                        испытания и сертификации</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>

                <div className={"contests__winner__btns"}>
                    <Button
                        text={"Выбрать пользователя"}
                        className={"btn btn-primary w-100"}
                        onClick={() => handleAddData()}
                        disabled={!selectedUsersIndexes.length}
                    />
                </div>
            </div>
        </DialogContainer>
    );
};