import { FC } from "react";
import { IBaseIconProps } from "./types";

export const BookIcon: FC<IBaseIconProps> = ({
  size = 24,
  color = 'white',
}) => {
  return (
      <svg width={size} height={size} viewBox="0 0 24 24" fill="none"
           xmlns="http://www.w3.org/2000/svg">
          <path
              d="M20.4924 15.6923H6.46166C4.83029 15.6923 3.50781 17.0148 3.50781 18.6462M20.4924 15.6923V20.1231C20.4924 20.9388 19.8312 21.6 19.0155 21.6H6.46166C4.83029 21.6 3.50781 20.2775 3.50781 18.6462M20.4924 15.6923V3.87695C20.4924 3.06127 19.8312 2.40002 19.0155 2.40002H8.30781M3.50781 18.6462V5.35387C3.50781 3.72251 4.83029 2.40002 6.46166 2.40002H8.30781M8.30781 15.6V2.40002"
              stroke={color}
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
          />
      </svg>
  )
}