import { FC } from "react";
import { IBaseIconProps } from "../types";

export const ServicesEmbulanceIcon: FC<IBaseIconProps> = (
    {
        size = 36,
        color = "#00A7B5"
    }) => {
    return (
        <svg width={size} height={size} viewBox="0 0 36 36" fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path
                d="M12.8568 3.59998V32.4M26.2282 15.9428H19.0282M26.2282 9.7714H19.0282M7.71389 9.7714H3.59961M7.71389 15.9428H3.59961M7.71389 22.1143H3.59961M9.77104 32.4H28.2853C30.5576 32.4 32.3996 30.5579 32.3996 28.2857V7.71426C32.3996 5.442 30.5576 3.59998 28.2853 3.59998H9.77104C7.49878 3.59998 5.65675 5.442 5.65675 7.71426V28.2857C5.65675 30.5579 7.49878 32.4 9.77104 32.4Z"
                stroke={color} strokeWidth="3" stroke-linecap="round" />
        </svg>
    );
};