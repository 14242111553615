import React from "react";

export const PlusIcon2 = ({ color = "#00A7B5", size = 24 }) => {
    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M6 12H12M12 12H18M12 12V6M12 12V18"
                  stroke={color}
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
            />
        </svg>
    );
};

export default PlusIcon2;
