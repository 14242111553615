import { Controller, useForm } from "react-hook-form";
import Dropdown from "../../ui/form/Dropdown/Dropdown";
import DatePickerForm from "../../ui/form/DatePickerForm";
import React, { FC, useState } from "react";
import { AcceptedIcon, RejectedIcon, ReturnIcon } from "../../../img";
import { PlusOneIcon } from "../../../img/icons/PlusOneIcon";
import { ServicesImprovementModal } from "./modals";
import { ServicesAddingAgreerModal } from "./modals/ServicesAddingAgreerModal";

interface DropdownItem {
  label: string;
  value: number | string;
}

interface IServicesAcceptanceList {
  isAdmin: boolean,
  isHr: boolean,
}

export const ServicesAcceptanceList: FC<IServicesAcceptanceList> = ({
  isAdmin,
  isHr
}) => {
  const userBlockWidth = isAdmin || isHr ? '20%' : '25%'

  const [isOpenDialog, setIsOpenDialog] = useState(0)

  const [ categoryList, setCategoryList ] = useState<DropdownItem[]>([
    {
      label: "Спорт",
      value: 0
    },
    {
      label: "Инновации",
      value: 1
    },
    {
      label: "Творчество",
      value: 2
    }
  ]);

  const {
    handleSubmit,
    control,
    watch,
    register,
    reset,
    formState: {isValid},
    setValue,
  } = useForm<any>();

  const [data, setData] = useState([
    {
      id: 2314,
      date_education: '21.10.2024',
      date_application: '21.07.2024',
      admin: {
        image: '/testImages/people/Image-2.png',
        name: 'Воробьёв Илья Романович'
      },
      users: [
        {
          image: '/testImages/people/Image-3.png',
          name: 'Воробьёв Илья Романович',
          isAccepted: 2,
        },
        {
          image: '/testImages/people/Image-4.png',
          name: 'Воробьёв Илья Романович',
          isAccepted: 1
        },
        {
          image: '/testImages/people/Image-5.png',
          name: 'Воробьёв Илья Романович',
          isAccepted: 0
        }
      ]
    },
    {
      id: 2314,
      date_education: '21.10.2024',
      date_application: '21.07.2024',
      admin: {
        image: '/testImages/people/Image-2.png',
        name: 'Воробьёв Илья Романович'
      },
      users: [
        {
          image: '/testImages/people/Image-3.png',
          name: 'Воробьёв Илья Романович',
          isAccepted: 1
        },
        {
          image: '/testImages/people/Image-4.png',
          name: 'Воробьёв Илья Романович',
          isAccepted: 1
        },
        {
          image: '/testImages/people/Image-5.png',
          name: 'Воробьёв Илья Романович',
          isAccepted: 0
        }
      ]
    }
  ])

  return (
    <div className='services-education-wrapper'>
      <div className='services-education-wrapper__header'>
        <div className='services-education-wrapper__buttons'>
          <div className='services-education-wrapper__buttons__block'>
            <Controller
              name="type"
              control={ control }
              rules={ {
                required: {
                  value: true,
                  message: ""
                }
              } }
              render={ ({field: {onChange, value}}) => (
                <Dropdown
                  className={ "form-select" }
                  placeholder={ "Все" }
                  label={ "Отправитель" }
                  options={ [
                    {
                      label: "Все",
                      value: "all"
                    },
                    ...categoryList
                  ] }
                  value={ value }
                  onChange={ onChange }
                />
              ) }
            />
          </div>

          <div className='services-education-wrapper__buttons__block'>
            <Controller
              name="type"
              control={ control }
              rules={ {
                required: {
                  value: true,
                  message: ""
                }
              } }
              render={ ({field: {onChange, value}}) => (
                <Dropdown
                  className={ "form-select" }
                  placeholder={ "Все" }
                  label={ "Руководитель" }
                  options={ [
                    {
                      label: "Все",
                      value: "all"
                    },
                    ...categoryList
                  ] }
                  value={ value }
                  onChange={ onChange }
                />
              ) }
            />
          </div>

          <div className='services-education-wrapper__buttons__block'>
            <Controller
              name="date_start"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: "Выберете дату"
                }
              }}
              render={({ field: { onChange, value } }) => (
                <DatePickerForm
                  value={value}
                  className=""
                  label="Дата обучение"
                  placeholder={"Не выбрано"}
                  onChange={onChange}
                  isIcon
                  isRightIcon
                />
              )}
            />
          </div>

          <div className='services-education-wrapper__buttons__block'>
            <Controller
              name="date_start"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: "Выберете дату"
                }
              }}
              render={({ field: { onChange, value } }) => (
                <DatePickerForm
                  value={value}
                  className=""
                  label="Дата заявки"
                  placeholder={"Не выбрано"}
                  onChange={onChange}
                  isIcon
                  isRightIcon
                />
              )}
            />
          </div>
        </div>

        {/*<div className='services-education-wrapper__header__button'>*/}
        {/*  Подать заявку*/}
        {/*</div>*/}
      </div>

      <div className='services-acceptance'>
        <div className='services-acceptance__header'>
          <div className='column colId'>ID заявки</div>
          <div className='column colDate'>Дата обучение</div>
          <div className='column colDate'>Дата заявки</div>
          <div className='column colAdmin' style={{width: userBlockWidth}}>Руководитель</div>
          <div className='column colUser' style={{width: userBlockWidth}}>Согласующие</div>
          {isAdmin || isHr ? (
            <div className='column colFile'>Файл</div>
          ) : ''}
          <div className='column colStatus'></div>
        </div>
      </div>

      <div className='services-acceptance__content'>
        {data.map((item, idx) => (
          <div className='services-acceptance__block' key={idx}>
            <p className='services-acceptance__block__id'>{item.id}</p>

            <p className='services-acceptance__block__date'>{item.date_education}</p>

            <p className='services-acceptance__block__date'>{item.date_application}</p>

            <div className='services-acceptance__block__admin' style={{width: userBlockWidth}}>
              <img className='services-acceptance__block__admin__image' src={item.admin.image} alt="" />

              <h5 className='services-acceptance__block__admin__name'>{item.admin.name}</h5>
            </div>

            <div className='services-acceptance__block__users' style={{width: userBlockWidth}}>
              {item.users.map((user, idx) => (
                <div className='services-acceptance__block__user' key={idx}>
                  <div className='services-acceptance__block__user__left'>
                    <img src={user.image} alt="" />

                    <h5>{user.name}</h5>
                  </div>

                  {user.isAccepted === 1 && (
                    <AcceptedIcon/>
                  )}

                  {user.isAccepted === 0 && (
                    <RejectedIcon/>
                  )}
                </div>
              ))}
            </div>

            {isAdmin || isHr ? (
              <div className='services-acceptance__block__file'>
                Скачать
              </div>
            ) : ''}

            {isAdmin || isHr ? (
              <div className='services-acceptance__block__status'>
                <div className='services-acceptance__block__status__item'><AcceptedIcon/></div>
                <div className='services-acceptance__block__status__item'><RejectedIcon/></div>

                {isHr && (
                  <div className='services-acceptance__block__status__item' onClick={() => setIsOpenDialog(2)}><PlusOneIcon/></div>
                )}
                <div className='services-acceptance__block__status__item' onClick={() => setIsOpenDialog(1)}><ReturnIcon/></div>
              </div>
            ) : (
              <div className='services-acceptance__block__status'>

              </div>
            )}

          </div>
        ))}
      </div>

      {isOpenDialog === 1 && (
        <ServicesImprovementModal isOpen={isOpenDialog === 1} onClose={() => setIsOpenDialog(0)}/>
      )}

      {isOpenDialog === 2 && (
        <ServicesAddingAgreerModal isOpen={isOpenDialog === 2} onClose={() => setIsOpenDialog(0)}/>
      )}
    </div>
  )
}