import { useState, FC } from "react";
import { SliderBanners } from "./SliderBanners";
import HorizonNavigate from "../navigation/HorizonNavigate";
import { IBanners, isMd } from "../../utils";
import GroupMainSlide from "../../containers/groups/GroupMainSlide";
import { useSelector } from "react-redux";
import { getMainPageData } from "../../redux";

interface ISlider {
    isScrolled: boolean
}

export const Slider: FC<ISlider> = ({isScrolled}) => {
    const isMobile = isMd();
    const mainData = useSelector(getMainPageData);

    const getBanners = () => {
        return mainData?.banners?.slice(1).filter((item: IBanners) => !item.layout || item.layout === 'right')
    }

    return (
        <div id='content_id' className={ "main__home" }>
            <div className='slider'>
                <GroupMainSlide/>

                <div className='slider-bannersContent'>
                    <SliderBanners slidersNumber={ getBanners().length } slides={ getBanners() } />
                </div>

                { !isMobile && (
                    <div id='sliderRouters'>
                        <HorizonNavigate isScroll={isScrolled}/>
                    </div>
                ) }
            </div>
        </div>
    )
}