import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import {
  getDepartment,
  getIsAdmin,
  getIsOwner,
  getPopupData, getProfileVarsData,
  setBookingData,
  setBookingList,
  setCurrentUser,
  setIsOwner,
  setMonth,
  setOpenCalendarPopup,
  setUserList
} from "../../../../redux";

import { DateRangeBlock } from './DateRangeBlock';
import { ReasonBlock } from './ReasonBlock';
import { MembersBlock } from './MembersBlock';
import { ConciliatorsBlock } from './ConciliatorsBlock';
import { failureNotify, isMd, successNotify } from "../../../../utils";
import {
  $additionalGet,
  $bookingCancel, $bookingConciliate,
  $bookingCreate,
  $bookingDelete,
  $bookingEdit, $bookingGet, $staffGet, $usersGet
} from "../../../../api/requests/vacation";
import Button from "../../../ui/form/Buttons/Button";
import { vacationColors } from "../../../../containers/calendar/vacation/constans";
import { CloseIcon } from "../../../../img";
import DialogContainer from "../../../ui/dialog/DialogContainer";
import styles from "./CalendarBookingPopup.module.scss";
import { UserSingle } from "./MembersBlock/UserSingle";

interface BookingPopupData {
  isOpen: boolean;
  onClose: () => void
}

export const BookingPopup = ({ isOpen, onClose }: BookingPopupData) => {
  const isMobile = isMd();

  const dispatch = useDispatch();
  const department = useSelector(getDepartment);
  const user = useSelector(getPopupData);
  const isAdmin = useSelector(getIsAdmin);
  const isOwner = useSelector(getIsOwner);
  const vars = useSelector(getProfileVarsData);

  const {
    id,
    indicator,
    for_user,
    created_at,
    start_date,
    end_date,
    days_count,
    reason,
    files,
    conciliated_by_id,
    is_special: isSpecial,
    isEdit,
    isConciliate,
    isAccept,
    isRefuse,
    isCancel,
    isProcess,
    isShow,
  } = user || {};

  const initialBooking = {
    isDirty: false,
    isAdmin: false,
    isEdit: false,
    isConciliate: false,
    isShow: false,
    isAccept: false,
    isRefuse: false,
    isCancel: false,
    isProcess: false,
    request: {
      start_date: moment(new Date()).format('yyyy-MM-DD'),
      end_date: moment(new Date()).add(1, 'days').format('yyyy-MM-DD'),
      // end_date: '',
      for_user_id: null,
      reason: '',
      files: null,
    },
    id: null,
    member: null,
    conciliator: null,
    created: null,
    daysCount: null,
    additionalDays: null,
  };

  const [booking, setBooking] = useState(initialBooking);
  const [conciliate, setConciliate] = useState<boolean | null>(null);
  const [openCancelPopup, setOpenCancelPopup] = useState(false);
  const [isLoadingSave, setIsLoadingSave] = useState(false);

  const isValid = booking?.request?.start_date && booking?.request?.end_date;

  const handleOpenCancelPopup = () => {
    setOpenCancelPopup(true);
  };

  const handleCloseCancelPopup = () => {
    setOpenCancelPopup(false);
  };

  const handleMonth = (date: string) => {
    dispatch(setMonth(moment(date).format('YYYY-MM-DD')));
  };

  const handleCreate = () => {
    $bookingCreate(isAdmin, isSpecial, booking?.request).then(res => {
      if (!res.data) {
        failureNotify(res.message);

        handleCloseCancelPopup();
      }

      if (res?.message) {
        isMobile && dispatch(setBookingData(res?.data));
        isMobile && dispatch(setOpenCalendarPopup());

        successNotify('Бронирование создано' || res?.message);

        getRepeatData();
      }
    });

    handleMonth(booking?.request?.start_date);
  };

  const handleEdit = () => {
    $bookingEdit(id, booking?.request).then(res => {
      if (!res.data) {
        failureNotify(res.message);

        handleCloseCancelPopup();
      }

      if (res?.message) {
        isMobile && dispatch(setBookingData(res?.data));
        isMobile && dispatch(setOpenCalendarPopup());

        successNotify('Бронирование отредактировано' || res?.message);

        getRepeatData();
      }
    });

    handleMonth(booking?.request?.start_date);
  };

  const handleCancel = () => {
    $bookingCancel(id).then(res => {
      if (!res.data) {
        failureNotify(res.message);

        handleCloseCancelPopup();
      }
      if (res?.message) {
        successNotify('Бронирование отменено');

        getRepeatData();
      }
    });
  };

  const handleDelete = () => {
    $bookingDelete(id).then(res => {
      if (!res.data) {
        failureNotify(res.message);

        handleCloseCancelPopup();
      }
      if (res?.message) {
        successNotify('Бронирование удалено' || res?.message);

        getRepeatData();
      }
    });
  };

  const handleAccept = () => {
    setConciliate(true);

    $bookingConciliate(isAdmin, id,{
      conciliate: true,
    }).then(res => {
      if (!res) {
        failureNotify(res.message);

        handleCloseCancelPopup();
      }

      if (res?.message && conciliate) {
        successNotify('Успешно согласовано' || res?.message);

        setBooking((prev) => {
          return {
            ...prev,
            isAccept: true,
            isRefuse: false,
          };
        });

        getRepeatData();
      }

      if (res?.message && !conciliate) {
        successNotify('Успешно отклонено' || res?.message);

        setBooking((prev) => {
          return {
            ...prev,
            isAccept: false,
            isRefuse: true,
          };
        });

        getRepeatData();
      }
    });
  };

  const handleRefuse = () => {
    setConciliate(false);

    $bookingConciliate(isAdmin, id, {
      conciliate: false,
    }).then(res => {
      if (res?.message && conciliate) {
        successNotify('Успешно согласовано' || res?.message);

        setBooking((prev) => {
          return {
            ...prev,
            isAccept: true,
            isRefuse: false,
          };
        });
      }

      if (res?.message && !conciliate) {
        successNotify('Успешно отклонено' || res?.message);

        setBooking((prev) => {
          return {
            ...prev,
            isAccept: false,
            isRefuse: true,
          };
        });
      }
    });
  };

  const style: any = {
    "--lightBlue": vars.colors.secondaryColor,
    "--whiteColor": vars.colors.fourthColor
  };

  useEffect(() => {
    let timeout: any;

    if (!isOpen) {
      timeout = setTimeout(() => setBooking(initialBooking), 500);
    }

    if (isOpen) {
      setConciliate(null);
    }

    return () => clearTimeout(timeout);
  }, [isOpen]);

  useEffect(() => {
    if (user) {
      setBooking((prev) => {
        return {
          ...prev,
          isAdmin,
          isEdit,
          isConciliate,
          isShow,
          isAccept,
          isRefuse,
          isCancel,
          isProcess,
          request: {
            ...prev?.request,
            start_date: start_date || moment(new Date()).format('yyyy-MM-DD'),
            end_date: end_date || moment(new Date()).add(1, 'days').format('yyyy-MM-DD'),
            // end_date: end_date || '',
            for_user_id: for_user?.id,
            reason: reason || '',
            files,
          },
          id,
          member: for_user,
          conciliator: conciliated_by_id,
          created: created_at,
          daysCount: days_count,
        };
      });
    }
  }, [user]);

  useEffect(() => {
    if (
      isOpen &&
      !isSpecial &&
      !isConciliate &&
      !isCancel &&
      !isShow &&
      booking?.request?.start_date &&
      booking?.request?.end_date &&
      booking?.request?.for_user_id
    ) {
      const requestData = `for_user_id=${booking?.request?.for_user_id}&start_date=${booking?.request?.start_date}&end_date=${booking?.request?.end_date}${isEdit ? '&update=1' : ''}`

      $additionalGet(requestData).then(res => {
        if (booking?.isDirty && !res.data) {
          failureNotify(res.message);

          return;
        }

        setBooking((prev) => {
          return {
            ...prev,
            additionalDays: res?.data?.available_days,
          };
        });
      });
    }
  }, [isEdit, booking?.request?.start_date, booking?.request?.end_date, booking?.request?.for_user_id]);

  function getRepeatData() {
    $staffGet().then(res => {
      dispatch(setCurrentUser(res?.data));
      dispatch(
          setIsOwner(
              res?.data?.staff?.department_head === 2 || res?.data?.staff?.department_head === 1,
          ),
      );
    });

    const bookingUrl = isAdmin ? 'backend/v1/vacations/booking' : 'api/v1/booking/vacations'
    const bookingRequest = isAdmin && department ? `department_id=${department}` : booking?.request?.start_date
    && department ? `date=${booking?.request?.start_date}&department_id=${department}`
        : booking?.request?.start_date && `date=${booking?.request?.start_date}`

    $bookingGet(bookingUrl, bookingRequest).then(res => {
      dispatch(setMonth(booking?.request?.start_date));
      dispatch(setBookingList(res?.data?.data));
    });

    const usersUrl = isAdmin ? 'backend/v1/vacations/booking/users' : 'api/v1/booking/vacations/users';
    const usersRequest = (isAdmin && department && `page=1&department_id=${department}`) || `page=1`

    $usersGet(usersUrl, usersRequest).then(res => {
      dispatch(setUserList(res?.data));
    });

    handleCloseCancelPopup();
    !isConciliate && onClose();
  }

  return (
    <>
      <DialogContainer
        isOpen={isOpen}
        setIsOpen={onClose}
        widthProps={420}
        label={(
            <div className={"calendar__booking-popup__head"}>
              <div className={"calendar__booking-popup__title"}>
                {isEdit && "Редактирование отпуска"}

                {isConciliate && "Согласование отпуска"}

                {!isEdit && !isConciliate && "Бронирование отпуска"}
              </div>

              {for_user?.structure?.title && <div className={"calendar__booking-popup__address"}>{for_user?.structure?.title}</div>}
            </div>
        )}
      >
        <div className={"calendar__booking-popup__wrapper"} style={style}>
          {/*<div*/}
          {/*    className={"calendar__booking-popup__indicator"}*/}
          {/*    style={{*/}
          {/*      backgroundColor: !isShow && indicator >= 0 && vacationColors[indicator % vacationColors.length] || ""*/}
          {/*  }}*/}
          {/*/>*/}

          {(isEdit || isConciliate || isShow) && <UserSingle isShort isFor {...user} />}

          <div className={"calendar__booking-popup__form"}>
            <div className={"calendar__booking-popup__block"}>
              <div className={"calendar__booking-popup__label"}>
                {(isEdit || (!isEdit && !isConciliate && !isShow)) && 'Укажите даты бронирования'}

                {(isConciliate || isShow) && 'Даты бронирования'}
              </div>

              <DateRangeBlock
                user={user}
                booking={booking}
                setBooking={setBooking}
                isError={booking?.isDirty && "additionalError"}
                errorMessage={"additionalError"}
              />
            </div>

            {reason && (
              <div className={"calendar__booking-popup__block"}>
                <div className={"calendar__booking-popup__label"}>Обстоятельство</div>

                <ReasonBlock booking={booking} setBooking={setBooking} />
              </div>
            )}

            {isAdmin && !isEdit && !isConciliate && !isShow && (
              <div className={"calendar__booking-popup__block"}>
                <MembersBlock booking={booking} setBooking={setBooking} />
              </div>
            )}

            {(isConciliate || isShow) && (
              <div className={"calendar__booking-popup__block"}>
                <div className={"calendar__booking-popup__label"}>
                  {'Согласующие'}

                  <span>Для подтверждения Вашего бронирования необходимо согласование</span>
                </div>

                <ConciliatorsBlock booking={booking} />
              </div>
            )}
          </div>

          <div className={"calendar__booking-popup__buttons"}>
            {isCancel && isShow && <div className={"calendar__booking-popup__status, calendar__booking-popup__stop"}>Отменено</div>}

            {booking?.isRefuse && (
              <div className={"calendar__booking-popup__status calendar__booking-popup__refuse"}>
                <CloseIcon
                    width={10}
                    height={10}
                    color={"#FF0000"}
                />

                {'Отклонено'}
              </div>
            )}

            {(((isEdit || isConciliate) && !booking?.isRefuse) || (isProcess && isShow)) && (
              <Button
                className={`btn btn-red calendar__booking-popup__button calendar__booking-popup__remove`}
                disabled={false}
                onClick={() => {
                  if (isConciliate) {
                    handleRefuse();

                    return;
                  }

                  handleOpenCancelPopup();
                }}
                text={
                  <>
                    {((isEdit && !isCancel) || (isProcess && isShow)) && 'Отменить'}

                    {isConciliate && "conciliateLoading" && 'Загрузка...'}

                    {isConciliate && !isCancel && !"conciliateLoading" && 'Отклонить'}

                    {isCancel && 'Удалить'}
                  </>
                }
              />
            )}

            {!isEdit && !isConciliate && !isShow && (
              <Button
                className={`btn btn-red calendar__booking-popup__button calendar__booking-popup__cancel`}
                disabled={false}
                onClick={onClose}
                text={"Отменить"}
              />
            )}

            {isProcess && isShow && <div className={`calendar__booking-popup__status, calendar__booking-popup__process ${styles.process}`}>На согласовании</div>}

            {booking?.isAccept && (
              <div className={"calendar__booking-popup__status, calendar__booking-popup__accept"}>
                IconDone

                {'Согласовано'}
              </div>
            )}

            {(isAdmin || isOwner || !isShow) && !isCancel && !booking?.isAccept && (
              <Button
                className={`btn btn-primary calendar__booking-popup__button calendar__booking-popup__action ${styles.action} ${isLoadingSave && 'calendar__booking-popup__disabled'}`}
                disabled={!isValid || isLoadingSave}
                onClick={() => {
                  setIsLoadingSave(true);

                  if (isEdit) return handleEdit();

                  if (isConciliate) return handleAccept();

                  handleCreate();
                }}
                text={
                <>
                  {isLoadingSave && 'Загрузка...'}

                  {!isLoadingSave && (
                      <>{isEdit ? 'Сохранить' : isConciliate ? 'Согласовать' : 'Забронировать'}</>
                  )}
                </>
                }
              />
            )}
          </div>
        </div>

        {/*<BookingCancelPopup*/}
        {/*  booking={booking}*/}
        {/*  isLoading={false}*/}
        {/*  isOpen={openCancelPopup}*/}
        {/*  onClose={handleCloseCancelPopup}*/}
        {/*  onCancel={(isEdit && !isCancel) || (isProcess && isShow) ? handleCancel : handleDelete}*/}
        {/*/>*/}
      </DialogContainer>
    </>
  );
};
