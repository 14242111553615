import React, { FC } from 'react'
import { IBaseIconProps } from "../types";

export const PenIcon: FC<IBaseIconProps>= ({size= 24, color= '#00A7B5'}) => {
    return (
        <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M14.3636 5.65153L15.8435 4.17154C16.6246 3.39049 17.8909 3.39049 18.672 4.17154L20.0862 5.58576C20.8672 6.3668 20.8672 7.63313 20.0862 8.41418L18.6062 9.89417M14.3636 5.65153L4.74791 15.2672C4.41584 15.5993 4.21121 16.0376 4.16989 16.5054L3.9278 19.2459C3.87303 19.8659 4.3919 20.3848 5.01192 20.33L7.75233 20.0879C8.22014 20.0466 8.65848 19.8419 8.99055 19.5099L18.6062 9.89417M14.3636 5.65153L18.6062 9.89417"
                stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}
