import { FC } from "react";
import { IBaseIconProps } from "../types";

export const ServicesTripIcon: FC<IBaseIconProps> = (
    {
        size = 36,
        color = "#00A7B5"
    }) => {
    return (
        <svg width={size} height={size} viewBox="0 0 36 36" fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path
                d="M15.75 6.75V13.7512C15.75 14.0615 15.5901 14.3499 15.327 14.5144L4.173 21.4855C3.90986 21.6501 3.75 21.9386 3.75 22.2488V23.5972C3.75 24.1828 4.30025 24.6125 4.86828 24.4704L14.6317 22.0296C15.1998 21.8876 15.75 22.3172 15.75 22.9028V27.3415C15.75 27.6012 15.638 27.8481 15.4427 28.019L12.2022 30.8542C11.5148 31.4559 12.0571 32.5806 12.9559 32.4171L17.8391 31.5293C17.9454 31.5099 18.0546 31.5099 18.1609 31.5293L23.0442 32.4171C23.9428 32.5806 24.4853 31.4559 23.7978 30.8542L20.5573 28.019C20.362 27.8481 20.25 27.6012 20.25 27.3415V22.9028C20.25 22.3172 20.8002 21.8876 21.3682 22.0296L31.1317 24.4704C31.6998 24.6125 32.25 24.1828 32.25 23.5972V22.2488C32.25 21.9386 32.0901 21.6501 31.827 21.4855L20.673 14.5144C20.4099 14.3499 20.25 14.0615 20.25 13.7512V6.75C20.25 5.50736 19.2426 4.5 18 4.5C16.7574 4.5 15.75 5.50736 15.75 6.75Z"
                stroke={color} strokeWidth="3" stroke-linecap="round" strokeLinejoin="round" />
        </svg>
    );
};