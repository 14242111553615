import { FC } from "react";
import { IBaseIconProps } from "../types";

export const ServicesVacationIcon: FC<IBaseIconProps> = (
    {
        size= 36,
        color = '#00A7B5'
    }) => {
    return (
        <svg width={size} height={size} viewBox="0 0 36 36" fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path
                d="M30 15C30 21.6275 18 33 18 33C18 33 6 21.6275 6 15C6 8.37258 11.3726 3 18 3C24.6275 3 30 8.37258 30 15Z"
                stroke="#00A7B5" strokeWidth="4" />
            <path
                d="M18 16.5C18.8284 16.5 19.5 15.8284 19.5 15C19.5 14.1716 18.8284 13.5 18 13.5C17.1716 13.5 16.5 14.1716 16.5 15C16.5 15.8284 17.1716 16.5 18 16.5Z"
                fill={color} stroke="#00A7B5" strokeWidth="4" stroke-linecap="round"
                strokeLinejoin="round" />
        </svg>
    );
};