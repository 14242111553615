import { IApiHandlerParams } from "../../interface";
import api from "../../index";
import { IChangeStatuses, IResponseStatuses } from "./interface";

export const $getStatuses = (
    handler?: IApiHandlerParams
): Promise<IResponseStatuses> =>
    api.get("api/workspaces/get_statuses", {
        mock: require('./mocks/status.list.json'),
        handler
    });

export const $setStatus = (
    body: IChangeStatuses,
    handler?: IApiHandlerParams
): Promise<IResponseStatuses> =>
    api.post("api/account/set_status", body, {
        handler
    });