import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as brandIcons from '@fortawesome/free-brands-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { SizeProp } from "@fortawesome/fontawesome-svg-core";

function toPascalCase(text: string) {
  return text && text.replace(/(^\w|-\w)/g, clearAndUpper);
}

function clearAndUpper(text: string) {
  return text.replace(/-/, '').toUpperCase();
}

interface FontAwesomeProps {
  iconName: string;
  size?: SizeProp;
  style?: any;
  id?: string;
}

export default function FontAwesome(
    {
      iconName,
      size = '1x',
      style = {},
      id
    }: FontAwesomeProps
) {
    const name: string = toPascalCase(iconName);

    return (
        <FontAwesomeIcon
            // @ts-ignore
            icon={fas[`fa${name}`] || brandIcons[`fa${name}`]}
            size={size}
            style={style}
            id={id}
        />
    );
}
