import FontAwesome from "../font-awesome/FontAwesome";
import { useSelector } from "react-redux";
import { getProfileVarsData } from "../../../redux";

interface LikeNewsIconProps {
    fill?: string;
    opacity?: string;
    fontAwesome?: boolean;
    style?: any;
    size?: number;
    itemContent: any;
}

export const LikeNewsIcon = (
    {
        fill,
        opacity = "1",
        fontAwesome = true,
        style = {},
        size = 16,
        itemContent
    }: LikeNewsIconProps
) => {
    const vars = useSelector(getProfileVarsData);

    if (fill === undefined) fill = vars.colors.fourthColor;

    if (fontAwesome && itemContent && itemContent.like_image && +itemContent.like_type === 2)
        return (
            <span style={{ color: fill, opacity, fontSize: size, lineHeight: 0, ...style }}>
                <FontAwesome iconName={itemContent.like_image} style={style} />
            </span>
        );

    const height = (20 / 21) * size;
    const width = size;

    if (itemContent && itemContent.like_image_file && +itemContent.like_type === 3)
        return (
            <span style={{ opacity, display: "flex", alignItems: "center" }}>
                <img
                    src={itemContent.like_image_file}
                    alt={""}
                    style={{
                        maxWidth: width * 2,
                        maxHeight: height * 2
                    }}
                />
            </span>
        );

    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ ...style }}
        >
            <path
                d="M7.90417 0.305176C6.6091 0.305176 0.0488281 0.432544 0.0488281 2.91653V13.1285C0.0488281 15.6125 6.6091 15.7399 7.90417 15.7399C9.19924 15.7399 15.7595 15.6125 15.7595 13.1285V2.91653C15.7595 0.432544 9.19924 0.305176 7.90417 0.305176ZM14.0611 9.85897C13.6364 10.2411 11.4709 10.9205 7.90417 10.9205C4.33742 10.9205 2.17189 10.2411 1.74728 9.85897V8.60636C3.87035 9.39189 7.03371 9.45558 7.90417 9.45558C8.77463 9.45558 11.938 9.39189 14.0611 8.60636V9.85897ZM14.0611 6.6956C13.6364 7.07775 11.4709 7.75713 7.90417 7.75713C4.33742 7.75713 2.17189 7.07775 1.74728 6.6956V4.67869C3.87035 5.46422 7.03371 5.52791 7.90417 5.52791C8.77463 5.52791 11.938 5.46422 14.0611 4.67869V6.6956ZM7.79802 1.9824C11.0038 1.9824 13.042 2.51315 13.7426 2.91653C13.0632 3.29868 11.0038 3.85068 7.79802 3.85068C4.59219 3.85068 2.55405 3.31991 1.85343 2.91653C2.53281 2.53438 4.59219 1.9824 7.79802 1.9824ZM7.90417 14.0626C4.33742 14.0626 2.17189 13.3832 1.74728 13.0011V11.7485C3.87035 12.534 7.03371 12.5977 7.90417 12.5977C8.77463 12.5977 11.938 12.534 14.0611 11.7485V13.0011C13.6364 13.3832 11.4709 14.0626 7.90417 14.0626Z"
                fill={fill}
                fillOpacity={opacity}
            />
        </svg>
    );
}
