import { FC } from "react";
import { IBaseIconProps } from "../types";

export const HamburgerIcon: FC<IBaseIconProps> = ({
    color = '#000',
    size = 24,
    opacity = '0.5',
}) => {
    return (
        <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g opacity={opacity}>
                <path d="M6 7L18 7M6 12L18 12M6 17L18 17" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            </g>
        </svg>
    )
}