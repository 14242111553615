import React, { FC, useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { changeMainPage, changePersonData, changeProfileVarsData, getPersonData } from "../redux";
import AuthSwiper from '../components/auth/AuthSwiper'
import Cookies from "js-cookie";
import { $mainPageNewData } from "../api/requests/main";
import Preloader from "../components/ui/preloader/Preloader";
import { $getPersonalPage } from "../api/requests/user";
import { useVskAccess } from "../hooks";

const AuthLayout: FC = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const userData = useSelector(getPersonData);
    const token = Cookies.get('token')

    const [personalData, setPersonalData] = useState(userData || []);

    const { jsonData } = useVskAccess({ data: personalData });

    // useEffect(() => {
    //     console.log(userData?.id || token, 'userData?.id || token');
    //     if (userData?.id || token) navigate('/')
    // }, [userData, token])

    useEffect(() => {
        if (!userData) return getFuncPersonData();
    }, [location]);

    useEffect(() => {
        const defaultColorScheme = {
            primaryColor: "#00A7B5", //blueColor
            secondaryColor: "#00A7B5", //lightBlue
            thirdColor: "#E9F0F9", //grayColor
            fourthColor: "#FFF" //whiteColor
        };

        if (jsonData) {
            dispatch(changePersonData(jsonData));
            dispatch(changeProfileVarsData({
                colors: jsonData.workspace_design
                    ? JSON.parse(jsonData.workspace_design)
                    : defaultColorScheme,
            }));
        }
    }, [jsonData]);

    function getFuncPersonData() {
        $getPersonalPage(null, { newApi: true, dontShowErrorMessage: true }).then(res => {
            if (res.error || res.response) return;

            setPersonalData(res.data);
        });
    }

    if (!userData)
        return null

    return (
        <>
            <Outlet />
        </>
    )
}
export default AuthLayout
