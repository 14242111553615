import React, { ReactNode } from "react";
import { ArrowExitIcon, CloseIcon } from "../../../img";
import Button from "../../../components/ui/form/Buttons/Button";
import DialogContainer from "./DialogContainer";

export interface BtnDataItem {
    text: string | ReactNode;
    className?: string;
    disabled?: boolean;
    key: number;
}

interface CompleteModalProps {
    btnList?: BtnDataItem[];
    title?: string;
    text?: string;
    icon?: string;
    isOpen: boolean;
    isSearch?: boolean;
    isClose?: boolean;
    onBack?: () => void;
    onClose?: () => void;
    setIsOpen: () => void;
    onClick?: (val: BtnDataItem) => void;
    isFilter?: boolean;
    content?: ReactNode;
}

const CompleteModal = (
    {
        btnList,
        title,
        icon,
        onBack,
        onClose,
        onClick,
        isOpen,
        setIsOpen,
        text,
        isFilter,
        content
    }: CompleteModalProps
) => {
    return (
        <DialogContainer
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            isShowCloseBtn={false}
            classNameModal={"p-0"}
            widthProps={428}
            isFilter={isFilter}
        >
            <div className="sport__view__modal contests__winner">
                {(onBack || onClose) && (
                    <div className={"contests__winner__header"}>
                        {onBack && (
                            <div className={"back"} onClick={onBack}>
                                <ArrowExitIcon color={"#00A7B5"} size={14} strokeWidth={4} />
                            </div>
                        )}

                        {onClose && (
                            <div
                                className={"d-flex align-items-center ml-auto"}
                                onClick={onClose}
                            >
                                <CloseIcon color={"black"} opacity={"1"} />
                            </div>
                        )}
                    </div>
                )}

                <div className={"sport__view__modal__content-complete"}>
                    {icon && <span>{icon}</span>}

                    {title && <h3 className={"text-center"}>{title}</h3>}
                    {text && <small className={"text-center"}>{text}</small>}

                    {content && content}
                </div>

                <div className={"contests__win__btns contests__winner__btns"}>
                    {btnList && btnList.map((item, idx) => (
                        <Button
                            key={`btn-win-item-${idx}`}
                            text={item.text}
                            className={`btn ${item.className}`}
                            onClick={() => onClick && onClick(item)}
                        />
                    ))}
                </div>
            </div>
        </DialogContainer>
    );
};

export default CompleteModal;