import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from "../../store"

export const initialStateNewsReducer: any = {
    loading: false,
    data: null,
    error: null,
    success: false,
    supply: {},
    redactorNewsList: "",
    catList: []
}

const newsReducer = createSlice({
    name: 'news',
    initialState: initialStateNewsReducer,
    reducers: {
        changeNews: (state, action: PayloadAction<any>) => {
            state.data = action.payload
        },
        setRedactorNewsListData: (state, action: PayloadAction<any>) => {
            state.redactorNewsList = action.payload;
        },
        setNewsCatList: (state, action: PayloadAction<any>) => {
            state.catList = action.payload;
        },
    },
})

export const { changeNews, setRedactorNewsListData, setNewsCatList } = newsReducer.actions

export const getNewsData = (state: RootState): any => state.news.data
export const getRedactorNewsListData = (state: RootState) => state.news.redactorNewsList;
export const getNewsCatListData = (state: RootState) => state.news.catList;

export default newsReducer.reducer
