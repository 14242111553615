import { FC, useEffect, useRef, useState } from "react";
import {
    BadgesCard,
    ProfileCard,
    StatsCard,
    VacationCard,
    CongratulatedCard,
    FavoritesCard, StatusCard, MobileVacationCard,
} from "../../components/layout-components";
import {
    DepartmentBlock,
    ServicesBlock,
    PersonalWorkBlock,
    SportBlock,
} from "../../components/profile";
import { ArrowExitIcon, ArrowRightIcon, HeartIcon, PlusIcon2, ThreePeopleIcon } from "../../img";
import MainLayoutContent from "../../layouts/MainLayoutContent";
import { useNavigate } from "react-router-dom";
import { isMd } from "../../utils";
import { useSelector } from "react-redux";
import { getPersonData } from "../../redux";
import { BookIcon } from "../../img/icons/BookIcon";

export const ProfilePage: FC = () => {
    const isAdmin = true;
    const isMobile = isMd()
    const navigate = useNavigate();

    const personalData = useSelector(getPersonData);

    const [ leaderItem, setLeaderItem ] = useState<any>();

    const [ departmentItems, setDepartmentItems ] = useState<any[]>([]);

    const [sportData, setSportData] = useState([
        {
            id: 1,
            weekDay: 'Вторник',
            month: 'Июля',
            day: 13,
            type: 'Тренировка',
            text: 'Бег на дорожке',
            startTime: '17:30',
            endTime: '19:00',
            isMuted: true,
        },
        {
            id: 2,
            weekDay: 'Вторник',
            month: 'Июля',
            day: 13,
            type: 'Тренировка',
            text: 'Бег на дорожке',
            startTime: '17:30',
            endTime: '19:00',
            isMuted: false,
        },
        {
            id: 3,
            weekDay: 'Вторник',
            month: 'Июля',
            day: 13,
            type: 'Тренировка',
            text: 'Бег на дорожке',
            startTime: '17:30',
            endTime: '19:00',
            isMuted: true,
        },
    ])

    const changeDepartmentData = (item: any) => {
        return {
            ...item,
            image: item?.user?.photo,
            name: item?.user?.name,
            position: item?.title,
            icon: "",
            department: ""
        }
    }

    useEffect(() => {
        if (personalData) {
            const team = personalData?.structure?.team;

            team?.leader && setLeaderItem(changeDepartmentData(team.leader))
            team?.staff && setDepartmentItems(team.staff.map((item: any) => changeDepartmentData(item)))
        }
    }, [personalData]);

    const handleNavigation = () => {
        navigate(-1);
    };

    return (
        <div className='profile'>
            <div className='layout-inner__header profile-header'>
                { !isMobile && (
                    <div className='layout-inner__header__title'>
                        <div className='header__exitIcon' onClick={ handleNavigation }>
                            <ArrowExitIcon />
                        </div>
                        <h1>Моя страница</h1>
                    </div>
                ) }
            </div>

            <MainLayoutContent
                contentGap={ 40 }
                leftComponents={
                    <>
                        <ProfileCard />
                        <StatsCard />
                        <StatusCard />

                        <div onClick={() => navigate("/profile")}>
                            <div className="layout-adaptation">
                                <div className="layout-adaptation__left">
                                    <BookIcon />

                                    <h3>Адаптация</h3>
                                </div>
                            </div>
                        </div>

                        <BadgesCard />
                        <CongratulatedCard />

                        <div onClick={() => navigate("/favorites")}>
                            <FavoritesCard />
                        </div>

                        {isMobile ? <MobileVacationCard data={departmentItems} /> :
                            <VacationCard data={departmentItems} />}
                    </>
                }
            >
                <div className="layout-right-block">
                    <div className="layout-right-block__header">
                        <h1 className="layout-right-block__header__title">Моё подразделение</h1>

                        <div className='layout-right-block__header__link' onClick={() => navigate("colleagues")}>
                            <h4>Показать все</h4>
                            <ArrowRightIcon color={ "#000" } />
                        </div>
                    </div>

                    <div className="profile-departments__content">
                        {leaderItem && <DepartmentBlock { ...leaderItem } />}

                        { departmentItems.slice(0, 4).map((
                            item,
                            idx
                        ) => (
                            <DepartmentBlock key={ idx } { ...item } />
                        )) }
                    </div>
                </div>

                <div className="layout-right-block">
                    <div className="layout-right-block__header">
                        <h1 className="layout-right-block__header__title">Моя работа</h1>
                    </div>

                    <PersonalWorkBlock />
                </div>

                <div className='layout-right-block'>
                    <div className='layout-right-block__header'>
                        <h1 className='layout-right-block__header__title '>Мои сервисы</h1>

                        <div className='layout-right-block__header__right'>
                            {/*{isAdmin && !isMobile && (*/}
                            {/*    <button onClick={() => navigate('/profile/colleagues')} className='layout-right-block__header__addButton profile-services-addButton'>*/}
                            {/*        <ThreePeopleIcon />*/}

                            {/*        <p>Мои сотрудники</p>*/}

                            {/*        <span>*/}
                            {/*            3*/}
                            {/*        </span>*/}
                            {/*    </button>*/}
                            {/*)}*/}

                            <button className='layout-right-block__header__addButton profile-services-addButton'>
                                <PlusIcon2 />

                                <p>Подать новую</p>
                            </button>
                        </div>
                    </div>

                    <ServicesBlock />
                </div>

                <div className='layout-right-block'>
                    <div className='layout-right-block__header'>
                        <h1 className='layout-right-block__header__title '> Спорт </h1>

                        <button className='layout-right-block__header__addButton' onClick={() => navigate("/sport")}>
                            <PlusIcon2 />

                            <p>Записаться</p>
                        </button>
                    </div>

                    <div className='profile-sport'>
                        {sportData.map((item, idx) => (
                            <SportBlock key={idx} {...item}/>
                        ))}
                    </div>
                </div>
            </MainLayoutContent>
        </div>

    );
};