import { IApiHandlerParams, IRequestConfig, TApiResponse } from './interface'
import { API_DEF_CONFIG, API_URL } from './config'
import {
    autoLogoutApiStatusCodes,
    refreshTokenApiStatusCodes,
    skipApiStatusCodes,
    successApiStatusCodes,
    successRequestStatusCodes,
} from './status-code'
import {
    changeProfileVarsData,
    decreaseLoadingCountAction,
    increaseLoadingCountAction, resetProfile,
    selectLoading,
    store
} from "../redux";
import Cookies from 'js-cookie'
// import { signOut } from 'next-auth/react'
import process from 'process'
import { failureNotify } from '../utils'

export const loadingIsActive = (): boolean =>
    selectLoading(store.getState() as any)

export const setLoading = (isActive: boolean): void => {
    store.dispatch(
        isActive
            ? store.dispatch(increaseLoadingCountAction())
            : store.dispatch(decreaseLoadingCountAction())
    )
}

export const activateLoading = (_config?: IApiHandlerParams): false | void =>
    (!_config || _config.disableLoader !== true) && setLoading(true)

export const hideLoading = (_config?: IApiHandlerParams): false | void =>
    (_config ? _config.disableLoader !== true : true) &&
    loadingIsActive() &&
    setLoading(false)

export const addToast = (msg: string, type: string, timeout?: number): void => {
    console.log(msg, type, 'toast')
    failureNotify(msg, timeout)
    // (<any>useAlertMixin())[type](msg, false);
}

export const isSuccessApiStatusCode = (statusCode: number): boolean =>
    successApiStatusCodes.includes(statusCode)

export const isSkipApiStatusCode = (statusCode: number): boolean =>
    skipApiStatusCodes.includes(statusCode)

export const isSuccessResponseStatusCode = (statusCode: number): boolean =>
    isSuccessApiStatusCode(statusCode) || isSkipApiStatusCode(statusCode)

export function getFullConfig(config?: IApiHandlerParams): IApiHandlerParams {
    return Object.assign({}, API_DEF_CONFIG, config || {})
}

export function isSuccessResponse(r: TApiResponse): boolean {
    try {
        return successRequestStatusCodes.includes(r.status)
    } catch (err) {
        console.warn('isSuccessResponse()', err)

        return false
    }
}

export const getRequestUrl = (config?: IRequestConfig): string => {
    if (config?.handler?.newApi)
        return process.env.REACT_APP_NEW_API_URL || ''

    return config?.handler?.dontUseApiUrl ? '' : API_URL
}

export const handleErrorResponse = (
    _config?: IApiHandlerParams,
    r: TApiResponse = null
): TApiResponse => {
    if (!r || (r.data && r.data.statusCode === 200)) return

    try {
        if (autoLogoutApiStatusCodes.includes(r?.response?.status as number)) {
            // store.commit('auth/setAuthState');
        } else if (
            refreshTokenApiStatusCodes.includes(r?.response?.status as number)
        ) {
            // $authRefresh();
        } else if (!_config || !_config.dontShowErrorMessage) {
            addToast(r?.data?.error
                || r?.data?.message
                || r?.response?.data?.error
                || r?.response?.data?.message
                || r?.message,
                'error'
            )
        }
    } catch (err) {
        console.warn('handleErrorResponse()', err)
    }

    hideLoading(_config)

    // const mainPage = window.location.origin + '/'

    // if (r?.code === "ERR_NETWORK") {
    //     window.location.href = mainPage
    // }

    if (r?.response?.status === 401 || ["TOKEN_NOT_FOUND", "WS_NOT_FOUND"].includes(r?.data?.error)) {
        console.log(r, 401)
        // TODO: auto-logout

        Cookies.remove('token')
        localStorage.removeItem("token");
        store.dispatch(
            changeProfileVarsData({
                token: ""
            })
        );
        store.dispatch(resetProfile())
        setTimeout(() => window.location.href = window.location.origin + '/auth', 300)
        // store.dispatch(resetProfile())
        // signOut().then()

        // window.location.href = mainPage
    }

    return r
}

export async function requestHandler(
    r: TApiResponse,
    _config?: IApiHandlerParams
): Promise<TApiResponse> {
    hideLoading(_config)

    if (r.data.error || !isSuccessResponse(r)) handleErrorResponse(_config, r)

    try {
        return (_config ? _config.returnData : true) ? r.data : r
    } catch (err) {
        console.warn('requestHandler()', err)

        return null
    }
}
