import { fabric } from 'fabric';

function debounce(func: (...args: any[]) => any, wait: number, immediate?: boolean) {
    let timeout: NodeJS.Timeout | null;

    return function (this: any, ...args: any[]) {
        const context = this;

        const later = function () {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };

        const callNow = immediate && !timeout;
        if (timeout) clearTimeout(timeout);
        timeout = setTimeout(later, wait);

        if (callNow) func.apply(context, args);
    };
}


function generateUUID() {
    let d = new Date().getTime();
    let d2 = (typeof performance !== 'undefined' && performance.now && performance.now() * 1000) || 0;

    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
        let r = Math.random() * 16;

        if (d > 0) {
            r = (d + r) % 16 | 0;
            d = Math.floor(d / 16);
        } else {
            r = (d2 + r) % 16 | 0;
            d2 = Math.floor(d2 / 16);
        }

        return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
    });
}

function handleShapeCursor(shape: any) {
    shape.hoverCursor = 'pointer';
}

function setDrawnShapeSettings(shape: any, lock = false) {
    shape.lockMovementX = lock;
    shape.lockMovementY = lock;
    shape.editable = false;
    shape.hasControls = false;
    shape.hasBorders = !lock;

    handleShapeCursor(shape);

    shape?.setControlsVisibility && shape.setControlsVisibility({
        mt: false,
        mb: false,
        ml: false,
        mr: false,
        tl: false,
        tr: false,
        br: false,
        bl: false,
    });
}

function getDrawnShapePosition(canvas: any) {
    const zoom = canvas.getZoom();
    return {
        x: fabric.util.invertTransform(canvas.viewportTransform)[4] + canvas.width / zoom / 2,
        y: fabric.util.invertTransform(canvas.viewportTransform)[5] + canvas.height / zoom / 2,
    };
}

export { generateUUID, setDrawnShapeSettings, getDrawnShapePosition, debounce };