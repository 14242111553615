import { Controller, useForm } from "react-hook-form";
import FileField from "../../../ui/form/FileField/FileField";
import TextField from "../../../ui/form/Fields/TextField";
import DatePickerForm from "../../../ui/form/DatePickerForm";
import Dropdown from "../../../ui/form/Dropdown/Dropdown";
import TextArea from "../../../ui/form/Fields/TextArea";
import { CloseIcon } from "../../../../img";
import Button from "../../../ui/form/Buttons/Button";
import React, { useEffect, useState } from "react";
import { $getPersonalPage } from "../../../../api/requests/user";
import moment from "moment/moment";
import { $setProfileData } from "../../../../api/requests/auth";

export interface EditProfileFormData {
    image: File | string | null;
    firstname: string;
    lastname: string;
    familyName?: string | null;
    username: string;
    password: string;
    mail: string;
    birth: Date | undefined;
    gender: number;
    position?: string | null;
    company: string;
    city: string;
    about: string | null;
    skills?: String | null;
}

export const ProfilePersonalEditBlock = () => {

    const genderList = [{ label: "Мужской", value: 1 }, { label: "Женский", value: 2 }];

    const [editData, setEditData] = useState<EditProfileFormData | null>(null);

    const [skills, setSkills] = useState([""]);
    const [currentSkill, setCurrentSkill] = useState("");

    useEffect(() => {
        init();
    }, []);

    function init() {
        getProfile();
    }

    function getProfile() {
        $getPersonalPage(null, { newApi: true }).then(res => {
            if (!res) return;

            const user = res.data;

            console.log("User", user);

            setEditData({
                image: user.photo,
                firstname: user.firstname,
                lastname: user.lastname,
                familyName: user.middle_name,
                username: user.username,
                password: user.password,
                mail: user.mail,
                birth: new Date(user.birth),
                gender: user.sex,
                position: user.my_job,
                company: user.company,
                city: user.city,
                about: user.about
            });
        });
    }

    const {
        handleSubmit,
        control,
        watch,
        reset,
        formState: { errors, isValid },
        trigger
    } = useForm<EditProfileFormData>(
        (editData) ? {
            values: {
                image: editData.image,
                firstname: editData.firstname,
                lastname: editData.lastname,
                familyName: editData.familyName,
                username: editData.username,
                password: editData.password,
                mail: editData.mail,
                birth: editData.birth,
                gender: editData.gender,
                position: editData.position,
                company: editData.company,
                city: editData.city,
                about: editData.city
            }
        } : {
            values: {
                image: "",
                firstname: "",
                familyName: "",
                lastname: "",
                username: "",
                password: "",
                mail: "",
                birth: undefined,
                gender: 0,
                position: null,
                company: "",
                city: "",
                about: ""
            }
        }
    );

    const checkKeyDown = (e: any) => {
        if (e.key === "Enter") e.preventDefault();
    };

    function onSubmit(data?: EditProfileFormData, isDraft?: boolean) {
        const profileData = {
            photo: data?.image,
            name: data?.firstname,
            lastname: data?.lastname,
            username: data?.firstname && data?.lastname ? `@${data?.firstname}${data?.lastname}` : undefined,
            password: data?.password,
            mail: data?.mail,
            birth: data?.birth ? moment(data?.birth).valueOf() / 1000 : undefined,
            sex: data?.gender,
            city: data?.city,
            about: data?.about
        };

        // Filter out undefined or empty values
        const filteredProfileData = Object.fromEntries(
            Object.entries(profileData).filter(([key, value]) => value !== undefined && value !== "")
        );

        $setProfileData(filteredProfileData, { formData: true }).then();
    }

    const pressAddSkill = (event: any) => {
        const value: string = event.target.value;

        if (event.key === "Enter" && value) {
            setSkills([...skills, value]);
            setCurrentSkill("");
        }
    };

    const getValue = (
        opts: { label: string; value: string | number }[],
        val: number | string | undefined
    ) => {
        if (val) {
            return opts.filter((o) => o.value == val);
        }
        return null;
    };

    const handleDeleteInput = (
        idx: number,
        data: any,
        setData: any
    ) => {
        const updatedList = data && data?.filter((
            item: any,
            index: number
        ) => index !== idx);

        setData(updatedList);
    };

    return (
        <div className="profile-edit__mainbar">
            <form
                onSubmit={handleSubmit((obj) => onSubmit(obj))}
                onKeyDown={(e) => checkKeyDown(e)}
            >
                <div className="profile-edit__header">
                    <div className="profile-edit__photo">
                        <Controller
                            name="image"
                            control={control}
                            rules={{
                                required: {
                                    value: false,
                                    message: "Прикрепите картинку"
                                }
                            }}
                            render={({ field: { onChange, value } }) => (
                                <FileField
                                    className={"form-image"}
                                    upload={value}
                                    label=""
                                    setUpload={onChange}
                                />
                            )}
                        />
                    </div>

                    <p className="profile-edit__fulname">
                        {`${editData?.firstname ?? ""} ${editData?.lastname ?? ""}\n${editData?.familyName ?? ""}`}
                    </p>
                </div>

                <div className="profile-edit__block">
                    <Controller
                        name="firstname"
                        control={control}
                        rules={{
                            required: {
                                value: true,
                                message: "Заполните имя"
                            }
                        }}
                        render={({ field: { onChange, value } }) => (
                            <TextField
                                id="firstname"
                                value={value}
                                className=""
                                title="Имя"
                                placeholder={"Введите имя"}
                                name="firstname"
                                onChange={onChange}
                                errors={errors}
                            />
                        )}
                    />

                    <Controller
                        name="lastname"
                        control={control}
                        rules={{
                            required: {
                                value: true,
                                message: "Заполните фамилия"
                            }
                        }}
                        render={({ field: { onChange, value } }) => (
                            <TextField
                                id="lastname"
                                value={value}
                                className=""
                                title="Фамилия"
                                placeholder={"Введите фамилия"}
                                name="lastname"
                                onChange={onChange}
                                errors={errors}
                            />
                        )}
                    />

                    <Controller
                        name="familyName"
                        control={control}
                        rules={{
                            required: {
                                value: false,
                                message: "Заполните отчество"
                            }
                        }}
                        render={({ field: { onChange, value } }) => (
                            <TextField
                                id="familyName"
                                value={value ?? ""}
                                className=""
                                title="Отчество"
                                placeholder={"Введите отчество"}
                                name="familyName"
                                onChange={onChange}
                                errors={errors}
                            />
                        )}
                    />
                </div>

                <div className="profile-edit__block">
                    <Controller
                        name="username"
                        control={control}
                        rules={{
                            required: {
                                value: true,
                                message: "Заполните никнейм"
                            }
                        }}
                        render={({ field: { onChange, value } }) => (
                            <TextField
                                id="username"
                                value={value ?? ""}
                                className=""
                                title="Никнейм"
                                placeholder={"Введите никнейм"}
                                name="username"
                                onChange={onChange}
                                errors={errors}
                            />
                        )}
                    />

                    <Controller
                        name="password"
                        control={control}
                        rules={{
                            required: {
                                value: true,
                                message: "Заполните пароль"
                            }
                        }}
                        render={({ field: { onChange, value } }) => (
                            <TextField
                                id="password"
                                value={value ?? ""}
                                className=""
                                type="password"
                                title="Пароль"
                                placeholder={"Введите пароль"}
                                name="password"
                                onChange={onChange}
                                errors={errors}
                            />
                        )}
                    />
                </div>

                <div className="profile-edit__block">
                    <Controller
                        name="mail"
                        control={control}
                        rules={{
                            required: {
                                value: true,
                                message: "Заполните Email"
                            }
                        }}
                        render={({ field: { onChange, value } }) => (
                            <TextField
                                id="mail"
                                value={value ?? ""}
                                className=""
                                title="Email"
                                placeholder={"Введите Email"}
                                name="mail"
                                onChange={onChange}
                                errors={errors}
                            />
                        )}
                    />
                </div>

                <div className="profile-edit__block">
                    <Controller
                        name={"birth"}
                        control={control}
                        rules={{
                            required: {
                                value: false,
                                message: "Выберете дату"
                            }
                        }}
                        render={({ field: { onChange, value } }) => (
                            <DatePickerForm
                                startDate={value}
                                className=""
                                label="Дата рождения"
                                placeholder={"Выберите дату"}
                                onChange={onChange}
                                isIcon
                                isRightIcon
                            />
                        )}
                    />

                    <Controller
                        name="gender"
                        control={control}
                        rules={{
                            required: {
                                value: true,
                                message: "Выберете пол"
                            }
                        }}
                        render={({ field: { onChange, value } }) => (
                            <Dropdown
                                className={"form-select"}
                                placeholder={"Пол не выбран"}
                                label={"Пол"}
                                options={genderList}
                                value={getValue(genderList, value)}
                                onChange={(select) => onChange(select.value)}
                            />
                        )}
                    />
                </div>

                <div className="profile-edit__block">
                    <Controller
                        name="position"
                        control={control}
                        rules={{
                            required: {
                                value: false,
                                message: "Заполните должность"
                            }
                        }}
                        render={({ field: { onChange, value } }) => (
                            <TextField
                                id="position"
                                value={value ?? ""}
                                className=""
                                title="Должность"
                                placeholder={"Введите должность"}
                                name="position"
                                onChange={onChange}
                                errors={errors}
                            />
                        )}
                    />

                    <Controller
                        name="city"
                        control={control}
                        rules={{
                            required: {
                                value: true,
                                message: "Заполните город"
                            }
                        }}
                        render={({ field: { onChange, value } }) => (
                            <TextField
                                id="city"
                                value={value ?? ""}
                                className=""
                                title="Город"
                                placeholder={"Введите город"}
                                name="city"
                                onChange={onChange}
                                errors={errors}
                            />
                        )}
                    />
                </div>

                <div className="profile-edit__block">
                    <Controller
                        name="about"
                        control={control}
                        rules={{
                            required: {
                                value: false,
                                message: "Заполните описание"
                            }
                        }}
                        render={({ field: { onChange, value } }) => (
                            <TextArea
                                id="about"
                                value={value ?? ""}
                                className=""
                                title="Обо мне"
                                placeholder={"Введите описание"}
                                name="about"
                                onChange={onChange}
                                errors={errors}
                            />
                        )}
                    />
                </div>

                <div className="profile-edit__block">
                    <Controller
                        name="skills"
                        control={control}
                        rules={{
                            required: {
                                value: true,
                                message: ""
                            }
                        }}
                        render={({ field: { value } }) => (
                            <>
                                <TextField
                                    id="skills"
                                    value={currentSkill}
                                    className=""
                                    title="Введите навык"
                                    name="skills"
                                    onChange={(val) => setCurrentSkill(val)}
                                    onKeyDown={(e: any) => pressAddSkill(e)}
                                />
                            </>
                        )}
                    />
                </div>

                <div className="profile-edit__block">
                    {skills?.map((
                        value,
                        idx
                    ) => (
                        <div key={idx}>
                            {value && (
                                <div className="services-create-form__hashtags__values__item">
                                    <p>{value}</p>

                                    <div
                                        onClick={() => handleDeleteInput(idx, skills, setSkills)}
                                        className="services-create-form__hashtags__values__delete">
                                        <CloseIcon color="#00A7B5" />
                                    </div>
                                </div>
                            )}
                        </div>
                    ))}
                </div>

                <div className="profile-edit__bottom">
                    <Button
                        text={"Отмена"}
                        className={"btn btn-light w-100"}
                        onClick={() => ""}
                    />

                    <Button
                        type={"submit"}
                        text={"Сохранить"}
                        className={"btn btn-primary w-100"}
                        onClick={() => onSubmit(watch(), true)}
                    />
                </div>

                <div className="profile-edit__links">
                    <a href="#">Политика конфиденциальности персональных данных</a>
                    <a href="#">Информация о специальных предложениях партнёров</a>
                </div>
            </form>
        </div>
    )
}