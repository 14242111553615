import { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";

import TextField from "../../../components/ui/form/Fields/TextField";
import Button from "../../../components/ui/form/Buttons/Button";

import FileField from "../../../components/ui/form/FileField/FileField";
import TextArea from "../../../components/ui/form/Fields/TextArea";
import { $editGroup } from "../../../api/requests/groups";
import {
    getCurrentGroupData,
} from "../../../redux/reducers/workplace/workplaceSlice";
import { useSelector } from "react-redux";
import Dropdown from "../../../components/ui/form/Dropdown/Dropdown";
import { successNotify } from "../../../utils";

export interface CreateGroupFormData {
    image: File | string | null;
    title: string;
    text: string;
    status: number;
    password?: string;
}

const WorkspaceSettingGroupEdit = () => {
    const navigate = useNavigate();

    const currentGroup = useSelector(getCurrentGroupData);

    const [isLoading, setIsLoading] = useState(false);
    const [isEye, setIsEye] = useState<boolean>(false);

    const accessList = [
        { label: 'Не активна', value: 0 },
        { label: 'Открытая', value: 1 },
        { label: 'По коду', value: 2 },
        { label: 'Вручную', value: 3 },
        { label: 'Закрытая', value: 4 },
    ];

    const {
        handleSubmit,
        control,
        formState: { errors },
        watch
    } = useForm<CreateGroupFormData>(
        currentGroup && {
            values: {
                image: currentGroup.image || null,
                title: currentGroup.title || "",
                text: currentGroup.text || "",
                status: currentGroup.status || "",
            }
        } || undefined
    );

    const getValue = (
        opts: { label: string; value: string | number }[],
        val: number | string | undefined
    ) => {
        if (val || val === 0) {
            return opts.filter((o) => o.value == val);
        }
        return null;
    };

    function onSubmit(data: CreateGroupFormData) {
        setIsLoading(true);
        const requestData: any = {
            ...data,
            id: currentGroup.id,
            password: data.password || ""
        };

        $editGroup(requestData, { formData: true }).then(res => {
            setIsLoading(false);

            if (res.error || res.response) return;

            successNotify('Успешно сохранено')
        });
    }

    function handleShare() {
        const url = `${window.location.origin}/join-group?id=${currentGroup.id}`;
        navigator.clipboard.writeText(url);

        successNotify("Ссылка успешно скопирована").then();
    }

    return (
        <form
            onSubmit={handleSubmit(onSubmit)}
            className="contests__create__form workspace-settings-group__main-block"
        >
            <Controller
                name="image"
                control={control}
                rules={{
                    required: {
                        value: false,
                        message: "Прикрепите аватарку"
                    }
                }}
                render={({ field: { onChange, value } }) => (
                    <FileField
                        className={"form-image"}
                        upload={value}
                        label="Аватарка"
                        setUpload={onChange}
                    />
                )}
            />

            <Controller
                name="title"
                control={control}
                rules={{
                    required: {
                        value: true,
                        message: "Заполните заголовок"
                    }
                }}
                render={({ field: { onChange, value } }) => (
                    <div className={"w-50 pr-3"}>
                        <TextField
                            value={value}
                            className=""
                            title="Название группы"
                            placeholder={"Введите название"}
                            name="title"
                            onChange={onChange}
                            errors={errors}
                        />
                    </div>
                )}
            />

            <div className={"d-flex gap-5"}>
                <Controller
                    name="status"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                        <Dropdown
                            value={getValue(accessList, value)}
                            className={`form-select ${+watch().status === 2 ? "" : "w-50"}`}
                            label="Доступ"
                            placeholder={"Доступ"}
                            onChange={(select) => onChange(select.value)}
                            options={accessList}
                        />
                    )}
                />

                {(+watch().status === 2) && (
                    <Controller
                        name="password"
                        control={control}
                        rules={{
                            required: {
                                value: false,
                                message: ""
                            }
                        }}
                        render={({ field: { onChange, value } }) => (
                            <TextField
                                value={value || ""}
                                title="Код доступа"
                                placeholder={"Код доступа"}
                                name="password"
                                type={isEye ? "text" : "password"}
                                append={
                                    <Button
                                        className="btn-link cursor-pointer"
                                        text={
                                            <img
                                                src={
                                                    isEye
                                                        ? '/images/icons/eye.svg'
                                                        : '/images/icons/hide-eye.svg'
                                                }
                                                width="24"
                                            />
                                        }
                                        onClick={() => setIsEye(!isEye)}
                                    />
                                }
                                onChange={onChange}
                                errors={errors}
                            />
                        )}
                    />
                )}
            </div>

            <div className={"workspace-settings-group__main-block-text"}>
                <p>Определите формат доступа в группу.</p>
                <p><strong>Не активна</strong> - группа не активна.</p>
                <p><strong>Открытая</strong> - любой пользователь может вступить в группу.</p>
                <p><strong>По коду</strong> - любой пользователь, который знает код, может вступить в группу.</p>
                <p><strong>Вручную</strong> - любой пользователь может отправить заявку на вступление группу, а
                    администратор вручную может разрешить или отклонить заявку.</p>
                <p><strong>Закрытая</strong> - только администратор может вручную добавлять пользователей в
                    группу.</p>
            </div>

            {/*<Controller*/}
            {/*    name="text"*/}
            {/*    control={control}*/}
            {/*    rules={{*/}
            {/*        required: {*/}
            {/*            value: false,*/}
            {/*            message: "Напишите описание"*/}
            {/*        }*/}
            {/*    }}*/}
            {/*    render={({ field: { onChange, value } }) => (*/}
            {/*        <TextArea*/}
            {/*            value={value}*/}
            {/*            title="Описание"*/}
            {/*            name="text"*/}
            {/*            onChange={onChange}*/}
            {/*            errors={errors}*/}
            {/*            rows={2}*/}
            {/*        />*/}
            {/*    )}*/}
            {/*/>*/}

            <div className={"d-flex gap-3"}>
                <Button
                    text={"Отмена"}
                    className={"btn btn-light w-100"}
                    onClick={() => navigate(-1)}
                />

                <Button
                    text={"Пригласительная ссылка"}
                    className={"btn btn-light w-100"}
                    onClick={handleShare}
                />

                <Button
                    type={"submit"}
                    text={"Сохранить"}
                    className={"btn btn-primary w-100"}
                    loading={isLoading}
                />
            </div>
        </form>
    );
};

export default WorkspaceSettingGroupEdit;