import {
    ArrowExitIcon,
    SearchIcon,
    SettingIcon
} from "../../img";
import { useLocation, useNavigate } from "react-router-dom";
import { NewsCard, NewsSlider } from "../../components/news";
import React, { useEffect, useState } from "react";
import { $getListCategories, $getNewsList } from "../../api/requests/news";
import Dropdown, { DropdownItem } from "../../components/ui/form/Dropdown/Dropdown";
import { isMd } from "../../utils";
import { useSelector } from "react-redux";
import { getCurrentRole, getPersonData } from "../../redux";
import Button from "../../components/ui/form/Buttons/Button";
import ModalCatNews from "../../components/categories/ModalCatNews";
import TextField from "../../components/ui/form/Fields/TextField";

export const NewsPage = () => {
    const navigate = useNavigate();
    const isMobile = isMd();
    const location = useLocation();

    const profileData = useSelector(getPersonData);
    const currentRole = useSelector(getCurrentRole);

    const searchParams = new URLSearchParams(location.search);
    const catId = searchParams.get('catId') || undefined;

    const [newsData, setNewsData] = useState<any[]>([]);
    const [filterList, setFilterList] = useState<any[]>([]);

    const [categoryList, setCategoryList] = useState<any[]>([]);
    const [categorySelect, setCategorySelect] = useState<any>(null);
    const [isOpenCatModal, setIsOpenCatModal] = useState(false);

    const [searchValue, setSearchValue] = useState<string>('');

    useEffect(() => {
        init();
    }, [catId])

    function init() {
        getCatList();

        getNewsList();
    }

    function getCatList() {
        $getListCategories().then(res => {
            if (!res || !res.length) return;

            const updateList = [...res].map(i => ({
                ...i,
                label: i.title,
                value: i.id,
            }))

            setCategoryList([{ value: "", label: "Все" }, ...updateList])

            if (catId) {
                setCategorySelect(updateList.find(i => i.value === +catId))
            }
        })
    }

    function getNewsList() {
        $getNewsList(
            {
                workspace_id: profileData?.workspace_id || undefined,
                offset: 0,
                limit: 30,
                cat_id: catId || ""
            }, { formData: true }).then(res => {
            if (!res) return;

            setNewsData(res);
            setFilterList(res);
        })
    }

    function handleCategory(select: DropdownItem) {
        setCategorySelect(select);

        navigate(`?catId=${select.value}`)
    }

    function handleSetting() {
        setIsOpenCatModal(true);
    }

    function onChangeSearch(value: string) {
        setSearchValue(value);

        const filterList = [...newsData].filter(item => {
            return (item.title.toLowerCase().includes(value.toLowerCase()));
        });

        setFilterList(filterList);
    }

    return (
        <div className='news'>
            <div className='layout-inner__header profile-header'>
                { !isMobile && (
                    <div className='layout-inner__header__title'>
                        <div className='header__exitIcon' onClick={ () => navigate(-1)}>
                            <ArrowExitIcon />
                        </div>
                        <h1>Новости</h1>
                    </div>
                ) }

                <div className='news-search'>
                    <div className="search__block-field">
                        <TextField
                            value={searchValue}
                            onChange={onChangeSearch}
                            placeholder={"Поиск"}
                            prepend={<SearchIcon color={"black"} />}
                            className={`search__block-textField`}
                        />
                    </div>
                </div>
            </div>

            {filterList.length ? <NewsSlider data={filterList?.slice(0, 5)} /> : (
                <div className={"d-flex align-items-center justify-content-center p-5"}>Новостей нет</div>
            )}

            <div className="news-content">
                <div className="news-content__header">
                    <Dropdown
                        value={categorySelect}
                        className="workspace-select"
                        placeholder={"Категория"}
                        options={categoryList}
                        onChange={handleCategory}
                        labelBottom={currentRole === 1 ? (
                            <div className={"dropdown__list__setting"}>
                                <Button
                                    leftIcon={<SettingIcon color={"currentColor"} />}
                                    text={"Настройки"}
                                    className={"btn btn-primary-revert w-100"}
                                    onClick={handleSetting}
                                />
                            </div>
                        ) : null}
                    />
                </div>

                <div className='news-content__items'>
                    {filterList.slice(6, newsData.length).map((item, idx) => (
                        <div key={idx} onClick={() => navigate(`/news/show/${item?.id}`)}>
                            <NewsCard data={item}/>
                        </div>
                    ))}
                </div>
            </div>

            <ModalCatNews
                isOpen={isOpenCatModal}
                setIsOpen={() => setIsOpenCatModal(false)}
                catList={categoryList}
                setCatList={setCategoryList}
                route={'news'}
            />
        </div>
    )
}