import React, { ReactNode, useEffect, useState } from "react";
import DialogContainer from "../../../components/ui/dialog/DialogContainer";
import { $getBadges, $sendBadge } from "../../../api/requests/user";
import { ArrowExitIcon, CloseIcon } from "../../../img";
import { BlankImage } from "../../../components/ui/blank-image/BlankImage";

interface UserProfileGratitudeProps {
    userId: number;
    isOpen: boolean;
    onClose: () => void;
}

export interface UserBadgeItem {
    id: number;
    title: string;
    description: string;
    image: string;
    causes: [string];
}

const UserProfileGratitudeModal = (
    {
        userId,
        isOpen,
        onClose
    }: UserProfileGratitudeProps
) => {

    const [badges, setBadges] = useState<UserBadgeItem[]>();
    const [isCongratulated, setIsCongratulated] = useState<boolean>(false);

    useEffect(() => {
        init();
    }, [isOpen]);

    function init() {
        setIsCongratulated(false);

        $getBadges().then(res => {
            if (!res) return;

            setBadges(res);
            console.log(res);
        });
    }

    function congratulate(badge: UserBadgeItem) {
        $sendBadge({
            id: userId,
            badge_id: badge.id,
            as_ws: 1,
            cause: badge.causes[0]
        }, { formData: true }).then();
        setIsCongratulated(true);
    }

    return (
        <DialogContainer
            isOpen={isOpen}
            setIsOpen={onClose}
            isShowCloseBtn={false}
            classNameModal={"p-0"}
            widthProps={427}
        >
            {!isCongratulated && (
                <div className={"profile-gratitude"}>
                    <div className={"profile-gratitude__header"}>
                        <h3>Поблагодарить</h3>

                        <div
                            className={"d-flex align-items-center ml-auto cursor-pointer"}
                            onClick={() => onClose()}
                        >
                            <CloseIcon color={"black"} opacity={"1"} />
                        </div>
                    </div>

                    <div className={"profile-gratitude__content"}>
                        {badges?.map((badge) => (
                                <div className={"profile-gratitude__item"}
                                     onClick={() => congratulate(badge)}>

                                    <BlankImage classname="notification-card__image"
                                                image={badge.image ?? ""} />

                                    <p className={"profile-gratitude__item__name"}>{badge.title}</p>
                                </div>
                            )
                        )}
                    </div>
                </div>
            )}

            {isCongratulated && (
                <div
                    className={`profile-gratitude__congrats ${isCongratulated ? "fade-in" : "hidden"}}`}>
                    <div className={"profile-gratitude__congrats__content"}>
                        <div className={"profile-gratitude__congrats__sticker"}>👍🏻</div>
                        <div className={"profile-gratitude__congrats__title"}>Отлично!</div>
                        <div className={"profile-gratitude__congrats__desc"}>
                            Вы поблагодарили сотрудника
                        </div>
                    </div>

                    <button onClick={() => onClose()}>Спасибо</button>
                </div>
            )}

        </DialogContainer>
    );
};

export default UserProfileGratitudeModal;