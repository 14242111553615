import { ArrowRightIcon } from "../../img";
import { daysOfWeek } from "../../utils";
import { useEffect, useState } from "react";
import moment from "moment/moment";

interface CalendarDaysProps {
    days?: number;
    currentDay: string;
    setCurrentDay: (date: string) => void;
}

type WeekDay = {
    number: number;
    isToday: boolean;
    date: string;
    week: string;
};

const CalendarDays = (
    {
        days = 7,
        currentDay,
        setCurrentDay
    }: CalendarDaysProps
) => {
    const [daysList, setDaysList] = useState<WeekDay[]>([]);

    const getCurrentWeek = (today: Date): WeekDay[] => {
        const currentDay = today.getDay();

        const monday = new Date(today);
        const diff = (currentDay === 0 ? -6 : 1) - currentDay;
        monday.setDate(today.getDate() + diff);

        return Array.from({ length: days }, (_, i) => {
            const day = new Date(monday);
            day.setDate(monday.getDate() + i);

            return {
                number: day.getDate(),
                isToday: day.toDateString() === today.toDateString(),
                week: daysOfWeek[i === 6 ? 0 : i + 1],
                date: moment(day).format("yyyy-MM-DD")
            };
        });
    };

    useEffect(() => {
        setDaysList(getCurrentWeek(new Date(currentDay)))
    }, [currentDay]);

    function handleClickDay(select: any) {
        setCurrentDay(select);
    }

    function handleChangeWeek(next?: boolean) {
        const newDate = new Date(currentDay);
        newDate.setDate(newDate.getDate() + (next ? 7 : -7));

        setCurrentDay(moment(newDate).format("yyyy-MM-DD"));
    }

    return (
        <div className={"calendar__days__block"}>
            <div className={"calendar__days__arrow left"} onClick={() => handleChangeWeek()}>
                <ArrowRightIcon />
            </div>

            <div className={"calendar__days__items"}>
                {daysList.map(({ isToday, number, week, date }, idx) => (
                    <div
                        key={`day-item-${idx}`}
                        className={`calendar__days__item cursor-pointer ${isToday ? "active" : ""}`}
                        onClick={() => handleClickDay(date)}
                    >
                        <div className={"calendar__days__item-number"}>{number}</div>
                        <p className={"calendar__days__item-text"}>{week}</p>
                    </div>
                ))}
            </div>

            <div className={"calendar__days__arrow"} onClick={() => handleChangeWeek(true)}>
                <ArrowRightIcon />
            </div>
        </div>
    )
}

export default CalendarDays;