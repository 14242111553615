import { AirplaneIcon, ArrowExitIcon, PaperClipSmallIcon, StarIcon } from "../../../img";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getYearMonthDay, getYearMonthDayAndTime, isMd } from "../../../utils";
import { updateRouteTitle } from "../../../redux";
import { useDispatch } from "react-redux";
import { IServices } from "../../../api/requests/services/interface";
import {
    $editServiceApplicationsItem,
    $getServiceApplicationsItem, $uploadServiceApplicationFile
} from "../../../api/requests/services";
import { BlankImage } from "../../../components/ui/blank-image/BlankImage";
import { Controller, useForm } from "react-hook-form";
import TextArea from "../../../components/ui/form/Fields/TextArea";
import PlusIcon2 from "../../../img/icons/vacation/PlusIcon2";
import TextField from "../../../components/ui/form/Fields/TextField";
import DatePickerForm from "../../../components/ui/form/DatePickerForm";
import Dropdown from "../../../components/ui/form/Dropdown/Dropdown";
import FileField from "../../../components/ui/form/FileField/FileField";
import Button from "../../../components/ui/form/Buttons/Button";
import { IUsers } from "../../../api/requests/user/interface";
import ToggleBlock from "../../../components/ui/form/Toggle/ToggleBlock";
import { isArray } from "util";
import FileClipField from "../../../components/ui/form/FileField/FileClipField";

export const ServicesViewPage = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const isMobile = isMd();
    const { id: serviceId } = useParams();

    const [serviceData, setServiceData] = useState<IServices>();

    const [activeStarId, setActiveStarId] = useState(0);

    const [uploadFileList, setUploadFileList] = useState<any>(undefined);

    const [statusesData, setStatusesData] = useState([
        // {
        //     value: 8691,
        //     label: 'В работе'
        // },
        // {
        //     value: 8692,
        //     label: 'Выполнена'
        // },
        // {
        //     value: 8693,
        //     label: 'Отменена'
        // },{
        //     value: 8695,
        //     label: 'Отклонена'
        // }

    ]);

    const {
        handleSubmit,
        control,
        watch,
        setValue
    } = useForm<any>();

    const getDropdownItems = (data: any, title: string) => {
        return data?.map((item: any, idx: number) => {
            return {
                value: idx,
                label: item[title],
                ...item
            };
        });
    };

    const handleMouseOver = (id: number) => {
        setActiveStarId(id);
    };
    const handleMouseOut = () => {
        setActiveStarId(serviceData?.rating || 0);
    };

    function handleUploadList(list: any) {
        list().forEach((item: any) => {
            if (item.file) {
                const reader = new FileReader();

                reader.onloadend = () => {
                    if (reader.result && typeof reader.result === "string") {
                        $uploadServiceApplicationFile({ file: item.file }, {
                            newApi: true,
                            formData: true
                        }).then(res => {
                            setUploadFileList({ file: reader.result });
                            setValue("file", res.data.url);
                        });
                    }
                };

                reader.readAsDataURL(item.file);
            }
        });
    }


    useEffect(() => {
        dispatch(updateRouteTitle({ key: "services_view", title: serviceData?.name }));
        setActiveStarId(serviceData?.rating || 0);
    }, [serviceData]);

    useEffect(() => {
        init();
    }, [serviceId]);

    function init() {
        if (serviceId) {
            $getServiceApplicationsItem(+serviceId, { formData: true, newApi: true }).then(res => {
                const getStatuses = res.data.sample_application.system_statuses.filter((item: any) =>
                    item.title !== "Создана"
                    &&
                    item.title !== "Закрыта"
                    &&
                    item.title !== "Ожидает проверки"
                );
                setStatusesData(getDropdownItems(getStatuses, "name"));
                setServiceData(res.data.sample_application);
            });
        }
    }

    function onSubmit(rating?: number) {
        const updatedList = {
            sample_id: serviceId && +serviceId,
            executors: serviceData?.executors?.map((item: any) => item.id),
            status: watch()?.status?.id || serviceData?.status,
            fields: serviceData?.fields,
            ...!rating && { public_comment: watch().public_comment },
            ...!rating && { status_comment: watch().status_comment || "" },
            ...!rating && { file: watch().file || "" },
            ...rating && { rating }
        };


        if ((watch().status || watch()?.status?.label === serviceData?.status_name)) {
            $editServiceApplicationsItem(updatedList, { newApi: true }).then(res => {
                init();

                if (!rating) {
                    window.scrollTo({
                        top: 100,
                        left: 100,
                        behavior: "smooth"
                    });
                }

                setValue('status', 0)
                setValue("public_comment", undefined);
                setValue("status_comment", "");
            });
        }
    }

    return (
        <div className="services-view">
            <div className="layout-inner__header profile-header">
                {!isMobile && (
                    <div className="layout-inner__header__title">
                        <div className="header__exitIcon" onClick={() => navigate(-1)}>
                            <ArrowExitIcon />
                        </div>

                        <h1>{serviceData?.name}</h1>
                    </div>
                )}
            </div>

            <div className="services-show-content">
                <div className="services-show-block">
                    <BlankImage image={serviceData?.sample?.image_file || ""}
                                classname="services-show__image" />

                    <div className="services-view__header">
                        <h5 className="services-show__header__title">{serviceData?.name}</h5>

                        <div className="services-show__header__bottom">
                            <div className="services-show__header__category">
                                {serviceData?.status_name}
                            </div>

                            <div className="services-show__header__category">
                                {getYearMonthDayAndTime(serviceData?.created_at)}
                            </div>
                        </div>
                    </div>

                    <div className="services-show__form">
                        <form
                            onSubmit={handleSubmit(() => "")}
                            className="services-create-form"
                        >
                            <Controller
                                name="description"
                                control={control}
                                rules={{
                                    required: {
                                        value: true,
                                        message: "Напишите описание"
                                    }
                                }}
                                render={({ field: { onChange, value } }) => (
                                    <TextArea
                                        value={serviceData?.description || ""}
                                        className=""
                                        title="Описание"
                                        placeholder={"Введите описание"}
                                        onChange={onChange}
                                        disabled={true}
                                    />
                                )}
                            />
                            {serviceData?.fields?.map((item, idx) => (
                                <>
                                    {item.value.type === "text" && (
                                        <Controller
                                            name={item?.value?.type}
                                            control={control}
                                            rules={{
                                                required: {
                                                    value: true,
                                                    message: "Напишите название"
                                                }
                                            }}
                                            render={({ field: { onChange, value } }) => (
                                                <TextField
                                                    value={item?.value?.value}
                                                    className=""
                                                    title={item?.name}
                                                    placeholder={"Введите "}
                                                    name="name"
                                                    disabled
                                                    onChange={(e) => ""}
                                                />
                                            )}
                                        />
                                    )}

                                    {item.value.type === "date_from" && (
                                        <Controller
                                            name="date_from"
                                            control={control}
                                            rules={{
                                                required: {
                                                    value: true,
                                                    message: "Выберете дату"
                                                }
                                            }}
                                            render={({ field: { onChange, value } }) => (
                                                <DatePickerForm
                                                    value={getYearMonthDay(item?.value?.value)}
                                                    className=""
                                                    label={`${item.name}${item.required ? "*" : ""}`}
                                                    placeholder={"Не выбрано"}
                                                    onChange={(e) => ""}
                                                    isIcon
                                                    isRightIcon
                                                    disabled
                                                />
                                            )}
                                        />
                                    )}

                                    {item.value.type === "radiolist" && (
                                        <Controller
                                            name="type"
                                            control={control}
                                            rules={{
                                                required: {
                                                    value: true,
                                                    message: ""
                                                }
                                            }}
                                            render={({ field: { onChange, value } }) => (
                                                <Dropdown
                                                    className={"form-select"}
                                                    placeholder={"Тип сервиса"}
                                                    label={`${item.value.name}`}
                                                    options={[]}
                                                    value={{
                                                        value: 1,
                                                        label: item?.value?.value[0]
                                                    }}
                                                    onChange={(e) => ""}
                                                    disabled
                                                />
                                            )}
                                        />
                                    )}

                                    {item.value.type === "file" && (
                                        <div className="services-create-form__icon">
                                            <Controller
                                                name="icon"
                                                control={control}
                                                rules={{
                                                    required: {
                                                        value: true,
                                                        message: ""
                                                    }
                                                }}
                                                render={({ field: { onChange, value } }) => (
                                                    <FileField
                                                        iconFile={item.value ? " " :
                                                            <AirplaneIcon />}
                                                        className={"form-image"}
                                                        upload={item?.value?.value}
                                                        label={`${item.value.name}`}
                                                        accept="image/svg+xml"
                                                        setUpload={(e) => ""}
                                                        disabled
                                                    />
                                                )}
                                            />
                                        </div>
                                    )}

                                    {item.value.type === "radio" && (
                                        <div className="services-show__form__checkboxes">
                                            <p className="services-show__form__label">{item.value.name}</p>

                                            <div className="layout-contest__content">
                                                {(Array.isArray(item?.value?.values) ? item?.value?.values : JSON.parse(item?.value?.values)).map((check: any, idx: number) => (
                                                    <div className="layout-contest__block"
                                                         key={idx}>
                                                        <input
                                                            type="checkbox"
                                                            checked={check === item.value.value}
                                                            onChange={() => ""}
                                                        />

                                                        <h3>{check}</h3>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    )}

                                    {item.value.type === "checkbox" && (
                                        <div className="services-show__form__checkboxes">
                                            <p className="services-show__form__label">{item.value.name}</p>

                                            <div className="layout-contest__content">
                                                {(Array.isArray(item?.value?.values) ? item?.value?.values : JSON.parse(item?.value?.values)).map((check: any, idx: number) => (
                                                    <div className="layout-contest__block"
                                                         key={idx}>
                                                        <input
                                                            type="checkbox"
                                                            checked={item.value.value.includes(check)}
                                                            onChange={(e) => ""}
                                                        />

                                                        <h3>{check}</h3>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    )}
                                </>
                            ))}

                            <div className="services-view__line"></div>

                            <div className="services-view-people">
                                <p className="services-view-people__label">Отправитель</p>

                                <div className="services-view-people__block">
                                    <BlankImage image={serviceData?.sender?.photo || ""}
                                                classname="services-view-people__image" />

                                    <div className="services-view-people__block__right">
                                        <h5 className="services-view-people__name">{serviceData?.sender?.name}</h5>

                                        <p className="services-view-people__position">{serviceData?.sender?.position}</p>
                                    </div>
                                </div>
                            </div>

                            <div className="services-view-people">
                                <p className="services-view-people__label">Руководитель</p>

                                <div className="services-view-people__block">
                                    <BlankImage image={serviceData?.supervisor?.photo || ""}
                                                classname="services-view-people__image" />

                                    <div className="services-view-people__block__right">
                                        <h5 className="services-view-people__name">{serviceData?.supervisor?.name}</h5>

                                        <p className="services-view-people__position">{serviceData?.supervisor?.position}</p>
                                    </div>
                                </div>
                            </div>

                            <div className="services-view-people">
                                <p className="services-view-people__label">Исполнитель</p>

                                <div className="services-view-people__list">
                                    {serviceData?.executors.map((executor: IUsers) => (
                                        <div className="services-view-people__block">
                                            <BlankImage image={executor?.photo || ""}
                                                        classname="services-view-people__image" />

                                            <div className="services-view-people__block__right">
                                                <h5 className="services-view-people__name">{executor?.name}</h5>

                                                <p className="services-view-people__position">{executor?.position}</p>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>

                            <div className="services-view-people">
                                <p className="services-view-people__label">Согласующий</p>

                                <div className="services-view-people__list">
                                    {serviceData?.conciliators.map((conciliator: IUsers) => (
                                        <div className="services-view-people__block">
                                            <BlankImage image={conciliator?.photo || ""}
                                                        classname="services-view-people__image" />

                                            <div className="services-view-people__block__right">
                                                <h5 className="services-view-people__name">{conciliator?.name}</h5>

                                                <p className="services-view-people__position">{conciliator?.position}</p>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>

                            <div className="services-view__line"></div>

                            <form
                                onSubmit={handleSubmit(() => "")}
                                className="services-create-form"
                            >
                                <Controller
                                    name="status"
                                    control={control}
                                    rules={{
                                        required: {
                                            value: true,
                                            message: ""
                                        }
                                    }}
                                    render={({ field: { onChange, value } }) => (
                                        <Dropdown
                                            className={"form-select"}
                                            placeholder={serviceData?.status_name || "Тип сервиса"}
                                            label={"Статус заявки"}
                                            options={statusesData}
                                            value={value}
                                            onChange={onChange}
                                            disabled={serviceData?.status_name !== "В работе" && serviceData?.status_name !== "Ожидает проверки"}
                                        />
                                    )}
                                />

                                {(serviceData?.status_name === "В работе" || serviceData?.status_name === "Ожидает проверки") && (
                                    <>
                                        <Controller
                                            name="status_comment"
                                            control={control}
                                            rules={{
                                                required: {
                                                    value: true,
                                                    message: "Напишите описание"
                                                }
                                            }}
                                            render={({ field: { onChange, value } }) => (
                                                <TextArea
                                                    value={value}
                                                    className=""
                                                    title="Комментарий"
                                                    placeholder={"Впишите комментарий"}
                                                    onChange={onChange}
                                                />
                                            )}
                                        />

                                        <Controller
                                            name="public_comment"
                                            control={control}
                                            rules={{
                                                required: {
                                                    value: false,
                                                    message: ""
                                                }
                                            }}
                                            render={({ field: { onChange, value } }) => (
                                                <ToggleBlock
                                                    isChecked={!!value}
                                                    className="contests__create__form-toggle"
                                                    desc="Публичный комментарий"
                                                    handleChange={(value) => onChange(value ? 1 : 0)}
                                                />
                                            )}
                                        />
                                    </>
                                )}

                                <div className="services-view-status__list">
                                    {serviceData?.logs?.map((item: any, idx: number) => (
                                        <div className="services-view-status" key={idx}>
                                            <div className="services-view-status__dots">
                                                <div
                                                    className="services-view-status__dots__circle"></div>

                                                {idx !== serviceData.logs.length - 1 && (
                                                    <div
                                                        className="services-view-status__dots__line"></div>
                                                )}
                                            </div>

                                            <div className="services-view-status__info">
                                                <h5 className="services-view-status__info__title">{item.status}</h5>

                                                <div className="services-view-status__info__sender">
                                                    <p>Отправитель:</p>

                                                    <h5>{item?.user_name}</h5>
                                                </div>

                                                {item.comment && (
                                                    <div
                                                        className="services-view-status__info__sender">
                                                        <p>Комментарий:</p>

                                                        <h5>{item?.comment}</h5>
                                                    </div>
                                                )}

                                                <p className="services-view-status__info__date">
                                                    {getYearMonthDayAndTime(item?.updated_at)}
                                                </p>
                                            </div>
                                        </div>
                                    ))}
                                </div>

                                {(serviceData?.status_name === "В работе" || serviceData?.status_name === "Ожидает проверки") && (
                                    <Controller
                                        name="file"
                                        control={control}
                                        rules={{
                                            required: {
                                                value: true,
                                                message: ""
                                            }
                                        }}
                                        render={({ field: { onChange, value } }) => (
                                            <FileClipField
                                                accept={".docx"}
                                                label={"Загрузить документ"}
                                                upload={uploadFileList}
                                                setUpload={handleUploadList}
                                                isLockFile={uploadFileList}
                                                handleRemove={() => setUploadFileList(undefined)}
                                                isSingleDoc={true}
                                            />
                                        )}
                                    />
                                )}
                            </form>

                            {(serviceData?.status_name === "В работе" || serviceData?.status_name === "Ожидает проверки") && (
                                <div className="services-create-buttons" style={{ marginTop: 20 }}>
                                    <div className={"d-flex gap-3"}>
                                        <Button
                                            text={"Вернуться"}
                                            className={"btn btn-light w-100"}
                                            onClick={() => navigate(-1)}
                                        />


                                        <Button
                                            onClick={() => onSubmit()}
                                            type={"submit"}
                                            text={"Сохранить"}
                                            className={"btn btn-primary w-100"}
                                        />
                                    </div>
                                </div>
                            )}

                            {(serviceData?.status_name === 'Отменена' || serviceData?.status_name === 'Отклонена' ) && (
                                <Button
                                    text={"Повторить заявку"}
                                    className={"btn btn-light w-100"}
                                    onClick={() => navigate('/services')}
                                />
                            )}

                            {serviceData?.status_name === "Выполнена" && (
                                <div className="services-view-stars">
                                    <h5 className="services-view-stars__title">Оценить заявку</h5>

                                    <div className="services-view-stars__content">
                                        {[1, 2, 3, 4, 5].map((item, idx) => (
                                            <div onMouseOver={() => handleMouseOver(idx + 1)}
                                                 onMouseOut={handleMouseOut}
                                                 onClick={() => onSubmit(idx + 1)}
                                                 key={idx}
                                                 className={`services-view-stars__icon ${idx + 1 <= activeStarId ? "active" : ""}`}
                                            >
                                                <StarIcon />
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            )}
                        </form>
                    </div>
                </div>
            </div>

        </div>
    );
};