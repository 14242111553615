import { IApiHandlerParams } from "../../interface";
import api from "../../index";
import { IGetNewsItem } from "../news/interface";
import {
  ICreateRequestIdea,
  IEditRequestIdea,
  IIdeasItem,
  IIdeasRequestCategories,
  IIdeasResponseCategories, IVoteIdea
} from "./interface";

export const $getIdeasList = (
  data: any,
  handler?: IApiHandlerParams
): Promise<any> =>
  api.post('api/ideas/listideas', data, {
    handler,
  })

export const $getIdeasItem = (
  id: {id: number | string},
  handler?: IApiHandlerParams
): Promise<IIdeasItem> =>
  api.post('api/ideas/viewidea', id, {
    handler,
  })

export const $createIdea = (
  data: ICreateRequestIdea,
  handler?: IApiHandlerParams
): Promise<IIdeasItem> =>
  api.post('api/ideas/createidea', data, {
    handler,
  })

export const $editIdea = (
  data: IEditRequestIdea,
  handler?: IApiHandlerParams
): Promise<IIdeasItem> =>
  api.post('api/ideas/editidea', data, {
    handler,
  })

export const $deleteIdea = (
  id: any,
  handler?: IApiHandlerParams
): Promise<IIdeasItem> =>
  api.post('api/ideas/deleteidea', id, {
    handler,
  })

export const $voteIdea = (
  data: IVoteIdea,
  handler?: IApiHandlerParams
): Promise<IIdeasItem> =>
  api.post('api/ideas/vote', data, {
    handler,
  })

export const $sendNotificationIdeas = (
    id: {id: number | string},
    handler?: IApiHandlerParams
): Promise<IIdeasItem> =>
    api.post('api/ideas/sendpush', id, {
        handler,
    })



export const $getIdeasCategories = (
  data: IIdeasRequestCategories,
  handler?: IApiHandlerParams
): Promise<IIdeasResponseCategories[]> =>
  api.post('api//ideas/listcategory', data, {
    handler,
  })

export const $editIdeasNotification = (
  data: any,
  handler?: IApiHandlerParams
): Promise<IIdeasResponseCategories[]> =>
  api.post('api/ideas/notification', data, {
    handler,
  })