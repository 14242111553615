import React from 'react'

interface Props {
    text?: string
    colorBg: string
    colorText: string
    style?: React.CSSProperties
    onClick?: () => void
    opacity?: number
    size?: number
}

export const BlankAva = ({
    text = 'Пользователь',
    colorText,
    colorBg,
    style,
    onClick = () => {},
    opacity = 20,
    size = 60,
}: Props) => {
    const takeFirstLetter = text && text.slice(0, 1)

    return (
        <div
            className={`blank-ava-container`}
            style={{
                backgroundColor: `${colorBg}${opacity}`,
                height: `${size}px`,
                minHeight: `${size}px`,
                minWidth: `${size}px`,
                width: `${size}px`,
                ...style,
            }}
            onClick={onClick}
        >
            <p style={{ color: colorText }}>{takeFirstLetter}</p>
        </div>
    )
}
