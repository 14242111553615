import { FC, useState } from "react";
import { ArrowRightIcon } from "../../img";
import { ILinks, isMd } from "../../utils";

interface ILinksCard {
    data: ILinks[]
}
export const LinksCard: FC<ILinksCard> = ({
  data
}) => {
    const isMobile = isMd();

    if (isMobile) return (
        <div className='layout-links-mobile'>
            <div className='feed-controls feed-news__controls' >
                <h3>Полезные ссылки</h3>
            </div>

            <div className='layout-links__content'>
                {data.slice(0, 4).map((item, idx) => (
                    <div
                        key={idx}
                        className='layout-links__block cursor-pointer'
                        onClick={() => window.open(item.src)}
                    >
                        <div className='layout-links__block__image'>
                            {item.image ? (
                                <img src={item?.image} alt="" />
                            ) : (
                                <div className='layout-links__block__noneImage'>
                                    {item?.title[0]}
                                </div>
                            )}
                        </div>

                        <h1>{item.title}</h1>
                    </div>
                ))}
            </div>

            <div className='layout-links-mobile__button'>
                Показать все
            </div>
        </div>
    )

    return (
        <div className='layout-links'>
            <div className='layout-links__header'>
                <h1> Полезные ссылки</h1>

                <div className='layout-links__header__more'>
                    Показать все
                </div>
            </div>

            <div className='layout-links__content'>
                {data.slice(0, 6).map((item, idx) => (
                    <div
                        key={idx}
                        className='layout-links__block cursor-pointer'
                        onClick={() => window.open(item.src)}
                    >
                        <div className='layout-links__block__image'>
                            {item.image ? (
                                <img src={item?.image} alt="" />
                            ) : (
                                <div className='layout-links__block__noneImage'>
                                    {item?.title[0]}
                                </div>
                            )}
                        </div>

                        <h1>{item.title}</h1>
                    </div>
                ))}
            </div>
        </div>
    )
}