import { useEffect, useState } from "react";
import { ArrowExitIcon, StarIcon } from "../../img";
import { useNavigate } from "react-router-dom";
import { isMd } from "../../utils";
import { BirthdayData } from "../../api/requests/main/interface";
import { $getBirthdays } from "../../api/requests/main";
import { BlankAva } from "../../components/ui/avatars/BlankAva";
import Button from "../../components/ui/form/Buttons/Button";


interface IDays {
    id: number,
    key: string,
    title: string
}

export const BirthdaysPage = () => {
    const isMobile = isMd();
    const navigate = useNavigate();

    const [ activeDay, setActiveDay ] = useState(1)
    const [ days, setDays ] = useState<IDays[]>([
        {
            id: 1,
            key: 'today',
            title: 'Сегодня',
        },
        {
            id: 2,
            key: 'tomorrow',
            title: 'Завтра',
        },
        {
            id: 3,
            key: 'week',
            title: 'Неделя',
        },
        {
            id: 4,
            key: 'month',
            title: 'Месяц',
        },
    ])

    const [ birthdaysContent, setBirthdaysContent ] = useState<BirthdayData[]>([]);

    const handleLike = (id: number) => {
        const updatedList: any = birthdaysContent.map((item) => {
            if (item.id === id) {
                return {
                    ...item,
                    isFavorite: item.isFavorite ? 0 : 1
                }
            } else {
                return item
            }
        })

        setBirthdaysContent(updatedList)
    };

    useEffect(() => {
        init();
    }, [activeDay]);

    function init() {
        $getBirthdays(activeDay, { newApi: true }).then(res => {
            if (res.error || res.response) return;

            setBirthdaysContent(res.data.data);
        });
    }

    function handleHappy(select: any) {
        console.log(select);
    }

    return (
        <div className='birthdays'>
            {!isMobile && (
                <div className='layout-inner__header birthdays-header'>
                    <div className='layout-inner__header__title'>
                        <div className='header__exitIcon' onClick={ () => navigate(-1) }>
                            <ArrowExitIcon />
                        </div>
                        <h1>Дни рождения</h1>
                    </div>

                    <div className='birthdays-calendar'>
                        { days.map((
                            day,
                            idx
                        ) => (
                            <div
                                key={ idx }
                                className={ `birthdays-calendar__block ${ day.id === activeDay ? 'active' : '' }` }
                                onClick={ () => setActiveDay(day.id) }
                            >
                                { day.title }
                            </div>
                        )) }
                    </div>
                </div>
            )}

            { isMobile && (
                <div className='birthdays-calendar'>
                    { days.map((
                        day,
                        idx
                    ) => (
                        <div
                            key={ idx }
                            className={ `birthdays-calendar__block ${ day.id === activeDay ? 'active' : '' }` }
                            onClick={ () => setActiveDay(day.id) }
                        >
                            { day.title }
                        </div>
                    )) }
                </div>
            ) }

            { birthdaysContent.length ? (
                <div className='birthdays-content'>
                    {birthdaysContent.map((
                        item,
                        idx
                    ) => (
                        <div className='birthdays-block' key={idx}>
                            <div className='employees-block__top'>
                                {item.photo ? (
                                    <img src={item.photo} alt="" className="employees-block__image" />
                                ) : (
                                    <BlankAva
                                        colorBg={"#CCD9E0"}
                                        colorText={"black"}
                                        text={item.name}
                                    />
                                )}

                                <div
                                    onClick={() => {
                                        handleLike(item.id)
                                    }}
                                    className={`employees-block__likeIcon ${item.isFavorite ? 'active' : ''}`}
                                >
                                    <StarIcon opacity={0.2}/>
                                </div>
                            </div>

                            <div className='birthdays-block__content'>
                                <h1 className='birthdays-block__title'>{item?.name}</h1>

                                <p className='birthdays-block__text'>{item?.staff?.title}</p>

                                <p className='birthdays-block__position'>{item?.position}</p>
                            </div>

                            <Button
                                className='btn birthdays-block__button'
                                text={"Поздравить"}
                                onClick={() => handleHappy(item)}
                           />
                        </div>
                    ))}
                </div>
            ) : <div className={"empty__list"}>Список пуст</div>}
        </div>
    )
}