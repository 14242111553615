import { Link, useNavigate } from "react-router-dom";
import { isMd } from "../../../utils";
import { ArrowExitIcon, ProfileChatIcon } from "../../../img";
import React, { useState } from "react";
import { ProfilePersonalEditBlock } from "../../../components/profile/edit/personal";
import { ProfilePersonalIcon } from "../../../img/icons/profile/ProfilePersonalIcon";
import { ProfileConfidentialIcon } from "../../../img/icons/profile/ProfileConfidentialIcon";
import { ProfileConfidentialEditBlock } from "../../../components/profile/edit/confidential";
import Cookies from "js-cookie";
import { changeToken, resetProfile, store } from "../../../redux";
import { useDispatch } from "react-redux";

enum ProfileEditType {
    personal = 1,
    confidential
}

export const ProfileEditPage = () => {
    const navigate = useNavigate();
    const isMobile = isMd();
    const dispatch = useDispatch();

    const [activeTab, setActiveTab] = useState<ProfileEditType>(ProfileEditType.personal);

    const isPersonal = activeTab === ProfileEditType.personal;
    const isConfidential = activeTab === ProfileEditType.confidential;

    function exit() {
        Cookies.remove("token");
        localStorage.removeItem("token");
        dispatch(changeToken(""))
        dispatch(resetProfile());
        setTimeout(() => navigate("/auth"), 300);
    }

    return (
        <div className="profile-edit">
            <div className="layout-inner__header profile-header">
                {!isMobile && (
                    <div className="layout-inner__header__title">
                        <div className="header__exitIcon" onClick={() => navigate(-1)}>
                            <ArrowExitIcon />
                        </div>
                        <h1>Настройки профиля</h1>
                    </div>
                )}
            </div>

            <div className="profile-edit__container">
                <div className="profile-edit__topbar">
                    <div className="profile-edit__options">
                        <div
                            className={`profile-edit__option ${isPersonal && "profile-edit__option__active"}`}
                            onClick={() => setActiveTab(ProfileEditType.personal)}>
                            <p className="profile-edit__option__title">Личные данные</p>
                        </div>

                        <div
                            className={`profile-edit__option ${isConfidential && "profile-edit__option__active"}`}
                            onClick={() => setActiveTab(ProfileEditType.confidential)}>
                            <p className="profile-edit__option__title">Настройки приватности</p>
                        </div>
                    </div>
                </div>

                <div className="profile-edit__sidebar">
                    <div className="profile-edit__options">
                        <div
                            className={`profile-edit__option ${isPersonal && "profile-edit__option__active"}`}
                            onClick={() => setActiveTab(ProfileEditType.personal)}>

                            <ProfilePersonalIcon
                                color={isPersonal ? "#00A7B5" : "rgba(0,0,0,0.3)"} />

                            <p className="profile-edit__option__title">Личные данные</p>
                        </div>

                        <div
                            className={`profile-edit__option ${isConfidential && "profile-edit__option__active"}`}
                            onClick={() => setActiveTab(ProfileEditType.confidential)}>

                            <ProfileConfidentialIcon
                                color={isConfidential ? "#00A7B5" : "rgba(0,0,0,0.3)"} />

                            <p className="profile-edit__option__title">Настройки приватности</p>
                        </div>
                    </div>

                    <div className="profile-edit__exit" onClick={exit}>
                        Выход из аккаунта
                    </div>
                </div>

                {isPersonal && <ProfilePersonalEditBlock />}
                {isConfidential && <ProfileConfidentialEditBlock />}
            </div>
        </div>
    );
};