import {
    ArrowExitIcon,
    ArrowRightIcon, PlusIcon2,
    WorkspaceAccrualIcon, WorkspaceBannerIcon, WorkspaceNavigationIcon,
    WorkspaceStoreIcon,
    WorkspaceUserFieldsIcon, WorkspaceUsersIcon
} from "../../img";
import { isMd } from "../../utils";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { SearchItem } from "../../components/ui/form/SearchItem";
import Dropdown from "../../components/ui/form/Dropdown/Dropdown";
import React, { ReactNode, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getPersonData, store } from "../../redux";
import {
    getCurrentGroupData,
    setIsOpenCreateBanner
} from "../../redux/reducers/workplace/workplaceSlice";
import Button from "../../components/ui/form/Buttons/Button";

interface ISettingsPageChild {
    key: string,
    title: string,
    parent: string,
}

interface ISettingsPage {
    key: string;
    title: string;
    isOpen?: boolean;
    icon?: ReactNode;
    child?: ISettingsPageChild[];
    fill?: boolean;
}

export const WorkspaceSettingsPage = () => {
    const navigate = useNavigate();
    const isMobile = isMd();
    const location = useLocation();

    const personalData = useSelector(getPersonData);
    const currentGroup = useSelector(getCurrentGroupData);

    const [activePage, setActivePage] = useState({ key: "navigation" });
    const [activeChildPage, setActiveChildPage] = useState('');

    const [settingsPage, setSettingsPage] = useState<ISettingsPage[]>([
        {
            key: "navigation",
            title: "Навигация",
            icon: <WorkspaceNavigationIcon />
        },
        {
            key: "banners",
            title: "Банеры",
            icon: <WorkspaceBannerIcon />,
            fill: true
        },
        {
            key: "users",
            title: "Пользователи",
            icon: <WorkspaceUsersIcon />,
        },
        {
            key: "user_fields",
            title: "Поля пользователя",
            icon: <WorkspaceUserFieldsIcon />
        },
        {
            key: "market",
            title: "Копр. Магазин",
            icon: <WorkspaceStoreIcon />
        },
        // {
        //     key: "vacation",
        //     title: "Отпуска",
        //     icon: <WorkspaceVacationIcon />
        // },
        {
            key: "accrual",
            title: "Начисления руководителям",
            icon: <WorkspaceAccrualIcon />,
            fill: true
        },
        {
            key: "all_settings",
            title: "Общие настройки",
            isOpen: false,
            icon: <WorkspaceUsersIcon />,
            child: [
                {
                    key: "setting",
                    title: "Общие",
                    parent: "all_settings"
                },
                {
                    key: "badges",
                    title: "Бейджи",
                    parent: "all_settings"
                },
                {
                    key: "booking",
                    title: "Бронирование",
                    parent: "all_settings"
                },
                {
                    key: "booking",
                    title: "Отпуска",
                    parent: "all_settings"
                },
                {
                    key: "welcome",
                    title: "Велком баллы",
                    parent: "all_settings"
                },
                {
                    key: "booking",
                    title: "Опросы",
                    parent: "all_settings"
                },
                {
                    key: "sport",
                    title: "Спорт",
                    parent: "all_settings"
                }
            ]
        },
    ]);

    const pages = location.pathname.split("/");

    const currentPage = pages[pages.length - 1];

    useEffect(() => {
        // if (currentPage !== activePage) {
        //     // setActivePage(currentPage)
        // }
    }, [currentPage]);

    useEffect(() => {
        if (personalData.group_id && !currentGroup) {
            // $getGroupList().then(res => {
            //     const findCurrentGroup = res.find(item => +item.id === personalData.group_id);
            //
            //     findCurrentGroup && dispatch(setCurrentGroupData(findCurrentGroup));
            // });
        }
    }, [personalData]);

    function handleOpenPage(select: any) {
        setActivePage(select);
        if (select.child) {
            const updateList = [...settingsPage].map(item => {
                if (item.key === select.key) return {
                    ...item,
                    isOpen: !select.isOpen
                }

                return item
            })

            setSettingsPage(updateList);

            return;
        } else {
            const updateList = [...settingsPage].map(item => ({
                ...item,
                isOpen: false
            }))

            setSettingsPage(updateList);
        }

        navigate(select.key);
    }

    function handleDeleteGroup() {
        if (!currentGroup) return;

        // $deleteGroupById({ id: currentGroup.id }, { formData: true }).then(res => {
        //     navigate("/?group_id=0");
        // });
    }

    const handleOpenChildPage = (key: string, parent: string) => {
        setActiveChildPage(key);
        navigate(key);
    }

    return (
        <div className="workspace-settings">
            <div className="layout-inner__header user-header">
                {!isMobile && (
                    <div className="layout-inner__header__title">
                        <div className="header__exitIcon" onClick={() => navigate(-1)}>
                            <ArrowExitIcon />
                        </div>
                        <h1>Настройки организации</h1>
                    </div>
                )}

                {activePage.key === "users" && (
                    <div className="workspace-settings-users__header">
                        <div className="workspace-settings-users__header__dropdown">
                            <Dropdown
                                options={[{ value: 1, label: "Роль" }]}
                                placeholder={"Роль"}
                                onChange={() => ""}
                            />
                        </div>

                        <div className="workspace-settings-users__header__search">
                            <SearchItem
                                params={[]}
                                onChange={() => ""}
                            />
                        </div>
                    </div>
                )}

                {activePage.key === "banners" && (
                    <div className="workspace-settings-users__header">
                        <div className="workspace-settings-users__header__btn">
                            <Button
                                leftIcon={<PlusIcon2 color={"black"}/>}
                                className="btn btn-secondary"
                                text={"Добавить банер"}
                                onClick={() => store.dispatch(setIsOpenCreateBanner(true))}
                            />
                        </div>
                    </div>
                )}

                {activePage.key === "user_fields" && (
                    <div className="workspace-settings-users__header">
                        <div className="workspace-settings-users__header__btn">
                            <Button
                                leftIcon={<PlusIcon2 color={"black"}/>}
                                className="btn btn-secondary"
                                text={"Добавить блок"}
                                onClick={() => store.dispatch(setIsOpenCreateBanner(true))}
                            />
                        </div>
                    </div>
                )}

                {/*<div className="workspace-settings-users__header">*/}
                {/*    <div className="workspace-settings-users__header__dropdown">*/}
                {/*        <Dropdown options={[{ value: 1, label: "Роль" }]} onChange={() => ""} />*/}
                {/*    </div>*/}

                {/*    <div className="workspace-settings-users__header__search">*/}
                {/*        <SearchItem params={[]} onChange={() => ""} />*/}
                {/*    </div>*/}
                {/*</div>*/}
            </div>

            <div className="workspace-settings-content">
                <div className="workspace-settings-left">
                    {settingsPage.map((item, idx) => (
                        <div className={`workspace-settings-page`}
                             key={`workspace-settings-left-${idx}`}
                        >
                            <div
                                className={`workspace-settings-page__main ${activePage.key === item.key ? "active" : ""}`}
                                onClick={() => handleOpenPage(item)}
                            >
                                <div className="workspace-settings-page__left">
                                    <div
                                        className={`workspace-settings-page__main__icon ${item.fill ? "fill" : "stroke"}`}>
                                        {item?.icon}
                                    </div>

                                    <p className="workspace-settings-page__title">{item.title}</p>
                                </div>

                                {item.child && (
                                    <div
                                        className={`profile-services-content-mobile__header__icon`}>
                                        <ArrowRightIcon />
                                    </div>
                                )}
                            </div>

                            {item.isOpen && (
                                <div className="workspace-settings-page__content">
                                    {item.child && item?.child.map((childItem, i) => (
                                        <div
                                            className={`workspace-settings-page__childPage ${childItem.key === activeChildPage ? "active" : ""}`}
                                            key={i}
                                            onClick={() => handleOpenChildPage(childItem.key, childItem.parent)}>
                                            {childItem.title}
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                    ))}

                    <Button
                        className="btn btn-light mt-auto"
                        text={"Начислить баллы"}
                        onClick={handleDeleteGroup}
                    />
                </div>

                <div className="workspace-settings-right workspace-settings-group__right">
                    {settingsPage.map((item, idx) => {
                        if (item.key === activePage.key) return (
                            <React.Fragment key={`workspace-settings-right-${idx}`}>
                                <Outlet />
                            </React.Fragment>
                        );

                        return null;
                    })}
                </div>
            </div>
        </div>
    );
};