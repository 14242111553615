import { ArrowExitIcon, CommentIcon, EyeIcon, HeartIcon, LikeIcon } from "../../../img";
import { useLocation, useNavigate } from "react-router-dom";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import ImageField from "../../../components/ui/form/ImageField/ImageField";
import TextField from "../../../components/ui/form/Fields/TextField";
import Dropdown from "../../../components/ui/form/Dropdown/Dropdown";
import { useEffect, useState } from "react";
import Button from "../../../components/ui/form/Buttons/Button";
import ToggleBlock from "../../../components/ui/form/Toggle/ToggleBlock";
import { $getBlogs } from "../../../api/requests/blogs";
import { IBlogsRes } from "../../../api/requests/blogs/interface";
// import { find } from "@reduxjs/toolkit/dist/utils";
import TextOverflow from "../../../utils/text-overflow/TextOverflow";
import { ClockIcon } from "@mui/x-date-pickers";
import { isMd } from "../../../utils";

interface DropdownItem {
    label: string;
    value: number | string;
}

interface CreateBlogFormSubmit {
    image: File | string | null;
    title: string;
    text: string;
    category: any;
}

export const BlogsCreatePage = () => {
    const location = useLocation();
    const pathname = location.pathname;
    const searchParams = new URLSearchParams(location.search);

    const isMobile = isMd();
    const navigate = useNavigate();

    const purpose: string = pathname.includes('edit') ? 'edit' : 'create';
    const blogs_id = searchParams.get('blogs_id');

    const [ blogData, setBlogData ] = useState<IBlogsRes>()

    const [categoryList, setCategoryList] = useState<DropdownItem[]>([
        {
            label: "Спорт",
            value: 0
        },
        {
            label: "Инновации",
            value: 1
        },
        {
            label: "Творчество",
            value: 2
        }
    ]);

    const {
        handleSubmit,
        control,
        watch,
        register,
        reset,
        formState: { isValid },
        setValue,
    } = useForm<CreateBlogFormSubmit>();


    const handleNavigation = () => {
        navigate(-1);
    };

    useEffect(() => {
        $getBlogs().then(res => {
            if (!res.data) return;

            const getBlogItem = res.data.find((item: any) => item.id == blogs_id)

            setBlogData(getBlogItem)
        })
    }, [])

    useEffect(() => {
        if (blogData) {
            (Object.keys(blogData) as (keyof CreateBlogFormSubmit)[]).forEach((key) => {
                if (key in blogData) {
                    if (key === 'category') {
                        const findCategory = categoryList.find((item) => item.label === blogData[key])

                        setValue(key, findCategory)
                    }
                    else {
                        setValue(key, blogData[key]);
                    }
                }
            });
        }
    }, [blogData])

    function onSubmit(data: CreateBlogFormSubmit) {
        console.log(data);
    }

    return (
        <div className='blogs-create'>
            <div className='layout-inner__header profile-header'>
                { !isMobile && (
                    <div className='layout-inner__header__title'>
                        <div className='header__exitIcon' onClick={ handleNavigation }>
                            <ArrowExitIcon />
                        </div>
                        <h1> {purpose === 'edit' ? 'Редактирование' : 'Создать пост'}</h1>
                    </div>
                ) }
            </div>

            <div className='blogs-create-content'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-7'>
                            <form
                                onSubmit={handleSubmit(onSubmit)}
                                className="contests__create__form"
                            >
                                <Controller
                                    name="image"
                                    control={control}
                                    rules={{
                                        required: {
                                            value: true,
                                            message: ""
                                        }
                                    }}
                                    render={({ field: { onChange, value } }) => (
                                        <ImageField
                                            upload={value}
                                            label="Изображение"
                                            setUpload={onChange}
                                        />
                                    )}
                                />

                                <Controller
                                    name="title"
                                    control={control}
                                    rules={{
                                        required: {
                                            value: true,
                                            message: ""
                                        }
                                    }}
                                    render={({ field: { onChange, value } }) => (
                                        <TextField
                                            value={value}
                                            className=""
                                            title="Заголовок"
                                            name="title"
                                            onChange={onChange}
                                        />
                                    )}
                                />

                                <Controller
                                    name="text"
                                    control={control}
                                    rules={{
                                        required: {
                                            value: true,
                                            message: ""
                                        }
                                    }}
                                    render={({ field: { onChange, value } }) => (
                                        <TextField
                                            value={value}
                                            className=""
                                            title="Описание"
                                            name="text"
                                            onChange={onChange}
                                        />
                                    )}
                                />

                                <Controller
                                    name="category"
                                    control={control}
                                    rules={{
                                        required: {
                                            value: true,
                                            message: ""
                                        }
                                    }}
                                    render={({ field: { onChange, value } }) => (
                                        <Dropdown
                                            className={"form-select"}
                                            placeholder={"Категория"}
                                            label={"Категория"}
                                            options={[
                                                {
                                                    label: "Все",
                                                    value: "all"
                                                },
                                                ...categoryList
                                            ]}
                                            value={value}
                                            onChange={onChange}
                                        />
                                    )}
                                />

                                <div className={"d-flex gap-3 services-create-content__buttons"}>
                                    <Button
                                        text={"В черновик"}
                                        className={"btn btn-light w-100"}
                                    />

                                    <Button
                                        type={"submit"}
                                        text={"Опубликовать"}
                                        className={"btn btn-primary w-100"}
                                    />
                                </div>
                            </form>
                        </div>

                        {purpose === 'edit' ? (
                            <div className='col-5'>
                                <div className={"blogs-create__preview"}>
                                    <ToggleBlock
                                        isChecked={true}
                                        className="blogs-create__form-toggle"
                                        desc="Предпросмотр"
                                        handleChange={() => {}}
                                    />

                                    <div className='blogs-card-user'>
                                        <img src={ blogData?.user?.image } alt="" className='blogs-card-user__image' />

                                        <div className='blogs-card-user__content'>
                                            <h1 className='blogs-card-user__content__name'>{ blogData?.user?.name }</h1>
                                            <p className='blogs-card-user__content__position'>{ blogData?.user?.position }</p>
                                        </div>
                                    </div>

                                    <div className="blogs-create__preview-items">

                                        {(watch().image && typeof watch().image === "string") && (
                                            <div className="blogs-create__preview__image" style={{backgroundImage: `url(${watch().image})`}}></div>
                                        )}

                                        <div className="blogs-create__preview-item">
                                            <div className="blogs-create__preview-item__header">
                                                <div className='blogs-card-block-content__top'>
                                                    <h1 className='blogs-card-block-content__title'>
                                                        { blogData?.title }
                                                    </h1>

                                                    <div
                                                        // onClick={ () => {
                                                        //     handleLike(item.id)
                                                        // } }
                                                        className={ `blogs-card-block-content__likeIcon ${ blogData?.is_liked ? 'active' : '' }` }
                                                    >
                                                        <HeartIcon />
                                                    </div>
                                                </div>

                                                <div className="blogs-create__preview__category">{watch()?.category?.label}</div>
                                            </div>

                                            <div className="blogs-create__preview__text">
                                                {TextOverflow({title: watch()?.text, lines: 6})}
                                            </div>

                                            <div className='blogs-card-block-content__bottom'>
                                                <div className='blogs-card-block-content__bottom__block '>
                                                    <ClockIcon />

                                                    <p>{ blogData?.date }</p>
                                                </div>

                                                <div className='blogs-card-block-content__bottom__right'>
                                                    <div
                                                        className='blogs-card-block-content__bottom__block blogs-card-block-content__bottom__likeContent'>
                                                        <LikeIcon color='#000' />

                                                        <p>{ blogData?.likes_count }</p>

                                                    </div>

                                                    <div className='blogs-card-block-content__bottom__block '>
                                                        <CommentIcon color='#000' />

                                                        <p>{ blogData?.comments_count }</p>
                                                    </div>

                                                    <div className='blogs-card-block-content__bottom__block '>
                                                        <EyeIcon color='#000' />

                                                        <p>{ blogData?.views_count }</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ): null}
                    </div>
                </div>
            </div>
        </div>
    )
}