import { ArrowExitIcon, CloseIcon, CoinCompetitionIcon, HamburgerIcon, PlusIcon } from "../../../img";
import { useLocation, useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import ImageField from "../../../components/ui/form/ImageField/ImageField";
import TextField from "../../../components/ui/form/Fields/TextField";
import Dropdown from "../../../components/ui/form/Dropdown/Dropdown";
import { useEffect, useRef, useState } from "react";
import ToggleBlock from "../../../components/ui/form/Toggle/ToggleBlock";
import DatePickerForm from "../../../components/ui/form/DatePickerForm";
import Button from "../../../components/ui/form/Buttons/Button";
import PlusIcon2 from "../../../img/icons/vacation/PlusIcon2";
import { DragDropContext, Draggable, Droppable } from "@hello-pangea/dnd";
import { PollsPageView } from "../../../components/polls";
import { IPolls2, IPollsAnswers } from "../../../api/requests/polls/interface";
import { $getPollsList } from "../../../api/requests/polls";
import { useDispatch } from "react-redux";
import { updateRouteTitle } from "../../../redux";
import { isMd } from "../../../utils";

interface DropdownItem {
    label: string;
    value: number | string;
}

interface CreatePollFormSubmit {
    image: File | string | null;
    title: string;
    text: string;
    category: any;
    clip: boolean;
    date_start: string;
    date_end: string;
    desc: string;
    answers: any;
}

export const PollsCreatePage = () => {
    const location = useLocation();
    const pathname = location.pathname;
    const searchParams = new URLSearchParams(location.search);

    const dispatch = useDispatch();

    const isMobile = isMd();
    const navigate = useNavigate();

    const purpose: string = pathname.includes('edit') ? 'edit' : 'create';
    const poll_id = searchParams.get('poll_id');

    const dragItem = useRef<any>(null)
    const dragOverTime = useRef<any>(null)

    const [isShow, setIsShow] = useState(true);
    const [pollData, setPollData] = useState<IPolls2>()

    const [categoryList, setCategoryList] = useState<DropdownItem[]>([
        {
            label: "Спорт",
            value: 0
        },
        {
            label: "Инновации",
            value: 1
        },
        {
            label: "Творчество",
            value: 2
        }
    ]);

    const [answersData, setAnswersData] = useState<IPollsAnswers[]>([])

    const {
        handleSubmit,
        control,
        watch,
        register,
        reset,
        formState: { isValid },
        setValue,
    } = useForm<IPolls2>();


    const handlePollFieldChange = (index: number, value: string) => {
        const updatedList = [...answersData].map((item, idx) =>
            idx === index ? {...item, title: value, value: false} : item
        )

        setAnswersData(updatedList)
    }

    const handleAddPoll = () => {
        const lastId = answersData.length > 0 ? answersData[answersData.length - 1].id : 0;
        const newItem = { id: lastId + 1, title: '', value: false };

        setAnswersData([...answersData, newItem]);
    }

    const handleDeletePoll = (id: number) => {
        const updatedList = answersData.filter((item) => item.id !== id )

        setAnswersData(updatedList)
    }

    const handleSort = () => {
        let _answersData = [...answersData];

        const draggedItemContent = _answersData.splice(dragItem.current, 1)[0];

        _answersData.splice(dragOverTime.current, 0, draggedItemContent)

        dragItem.current = null;
        dragOverTime.current= null;

        setAnswersData(_answersData)
    }

    const handleNavigation = () => {
        const pathArray = location.pathname.split('/');
        if (pathArray.length > 1) {
            pathArray.pop();
            const newPath = pathArray.join('/') || '/';
            navigate(newPath);
        } else {
            navigate('/');
        }
    }

    const onDragEnd = (result: any) => {
        const { destination, draggableId, source, type } = result;

        if (!destination) {
            return;
        }

        if (destination.droppableId === source.droppableId && destination.index === source.index) {
            return;
        }

        if (type === 'group') {
            const sourceIndex = source.index;
            const targetIndex = destination.index;

            const workValue = [...answersData];
            const [deletedItem] = workValue.splice(sourceIndex, 1);
            workValue.splice(targetIndex, 0, deletedItem);

            setAnswersData(workValue);

            return;
        }
    };

    useEffect(() => {
        setValue('answers', answersData )
    }, [answersData])

    useEffect(() => {
        $getPollsList().then(res => {
            if (!res.data) return;

            const getBlogItem = res.data.find((item: any) => item.id == poll_id)

            setPollData(getBlogItem)
        })
    }, [])

    useEffect(() => {
        if (pollData) {
            (Object.keys(pollData) as (keyof IPolls2)[]).forEach((key) => {
                if (key in pollData) {
                    if (key === 'category' || key === 'type_answer' || key === 'type' || key === 'logic') {
                        const findCategory: any = categoryList.find((item) => item.label === pollData[key])

                        setValue(key, findCategory)
                    }
                    else {
                        setValue(key, pollData[key]);
                    }
                }
            });

            setAnswersData(pollData.answers)
            dispatch(updateRouteTitle({key: 'polls_edit', title: pollData?.title}))
        }
    }, [pollData])


    function onSubmit(data: IPolls2) {
        console.log(data);
    }

    return (
        <div className='polls-create'>
            <div className='layout-inner__header profile-header'>
                { !isMobile && (
                    <div className='layout-inner__header__title'>
                        <div className='header__exitIcon' onClick={ handleNavigation }>
                            <ArrowExitIcon />
                        </div>
                        <h1> {purpose === 'edit' ? pollData?.title : 'Создать опрос'}</h1>
                    </div>
                ) }
            </div>

            <div className='polls-create-content'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-7'>
                            <form
                                action=""
                                onSubmit={handleSubmit(onSubmit)}
                                className="polls-create__form"
                            >
                                <Controller
                                    name="image"
                                    control={control}
                                    rules={{
                                        required: {
                                            value: true,
                                            message: ""
                                        }
                                    }}
                                    render={({ field: { onChange, value } }) => (
                                        <ImageField
                                            upload={value}
                                            label="Изображение"
                                            setUpload={onChange}
                                        />
                                    )}
                                />

                                <Controller
                                    name="title"
                                    control={control}
                                    rules={{
                                        required: {
                                            value: true,
                                            message: ""
                                        }
                                    }}
                                    render={({ field: { onChange, value } }) => (
                                        <TextField
                                            value={value}
                                            className=""
                                            title="Название опроса"
                                            name="title"
                                            onChange={onChange}
                                        />
                                    )}
                                />

                                <Controller
                                    name="category"
                                    control={control}
                                    rules={{
                                        required: {
                                            value: true,
                                            message: ""
                                        }
                                    }}
                                    render={({ field: { onChange, value } }) => (
                                        <Dropdown
                                            className={"form-select"}
                                            placeholder={"Категория"}
                                            label={"Тип опроса"}
                                            options={[
                                                {
                                                    label: "Все",
                                                    value: "all"
                                                },
                                                ...categoryList
                                            ]}
                                            value={value}
                                            onChange={onChange}
                                        />
                                    )}
                                />

                                <div className={"polls-create__form-date"}>
                                    <Controller
                                        name="date_start"
                                        control={control}
                                        rules={{
                                            required: {
                                                value: true,
                                                message: ""
                                            }
                                        }}
                                        render={({ field: { onChange, value } }) => (
                                            <DatePickerForm
                                                value={value}
                                                className=""
                                                label="Дата начала"
                                                placeholder={"Выберите дату"}
                                                // name="name"
                                                onChange={onChange}
                                                isIcon
                                                isRightIcon
                                            />
                                        )}
                                    />

                                    <Controller
                                        name="date_end"
                                        control={control}
                                        rules={{
                                            required: {
                                                value: true,
                                                message: ""
                                            }
                                        }}
                                        render={({ field: { onChange, value } }) => (
                                            <DatePickerForm
                                                value={value}
                                                className=""
                                                label="Дата завершения"
                                                placeholder={"Выберите дату"}
                                                // name="name"
                                                onChange={onChange}
                                                isIcon
                                                isRightIcon
                                            />
                                        )}
                                    />
                                </div>

                                <Controller
                                    name="type"
                                    control={control}
                                    rules={{
                                        required: {
                                            value: true,
                                            message: ""
                                        }
                                    }}
                                    render={({ field: { onChange, value } }) => (
                                        <Dropdown
                                            className={"form-select"}
                                            placeholder={"Категория"}
                                            label={"Тип опроса"}
                                            options={[
                                                {
                                                    label: "Все",
                                                    value: "all"
                                                },
                                                ...categoryList
                                            ]}
                                            value={value}
                                            onChange={onChange}
                                        />
                                    )}
                                />

                                <Controller
                                    name="logic"
                                    control={control}
                                    rules={{
                                        required: {
                                            value: true,
                                            message: ""
                                        }
                                    }}
                                    render={({ field: { onChange, value } }) => (
                                        <Dropdown
                                            className={"form-select"}
                                            placeholder={"Категория"}
                                            label={"Логика опроса"}
                                            options={[
                                                {
                                                    label: "Все",
                                                    value: "all"
                                                },
                                                ...categoryList
                                            ]}
                                            value={value}
                                            onChange={onChange}
                                        />
                                    )}
                                />

                                <span className='polls-create__form__line'></span>

                                <div className='polls-create-answers'>
                                    <p className='polls-create-answers__label'>Варианты ответа</p>

                                    <DragDropContext onDragEnd={onDragEnd}>
                                        <Droppable droppableId='ROOT' type="group">
                                            {(provided) => (
                                                <div
                                                    {...provided.droppableProps}
                                                    ref={provided.innerRef}
                                                >
                                                    {answersData.map((item: IPollsAnswers, idx) => (
                                                        <Draggable
                                                            draggableId={`items-${idx}-block-${item?.id}`}
                                                            key={`items-${idx}-block-${item?.id}`}
                                                            index={idx}
                                                        >
                                                            {(_provided) => (
                                                                <div
                                                                    {..._provided.draggableProps}
                                                                    {..._provided.dragHandleProps}
                                                                    ref={_provided.innerRef}
                                                                    className='polls-create-answers__block'
                                                                >
                                                                    <div className='polls-create-answers__block__left'>
                                                                        <div

                                                                        >
                                                                            <HamburgerIcon/>
                                                                        </div>


                                                                        <p>{idx + 1}</p>
                                                                    </div>

                                                                    <div className='polls-create-answers__block__input'>
                                                                        <input
                                                                            type="text"
                                                                            onChange={(e) => handlePollFieldChange(idx, e.target.value)}
                                                                            value={item.title}
                                                                        />

                                                                        <div
                                                                            className='polls-create-answers__block__input__delete'
                                                                            onClick={() => handleDeletePoll(item.id)}>
                                                                            <CloseIcon color='#FF0000' width={12} height={12}/>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </Draggable>
                                                    ))}

                                                    {provided.placeholder}

                                                    <div className='polls-create-answers__button' onClick={handleAddPoll}>
                                                        <PlusIcon2 color={'#007BFF'} size={32}/>
                                                    </div>
                                                </div>
                                            )}
                                        </Droppable>
                                    </DragDropContext>
                                </div>

                                <Controller
                                    name="type_answer"
                                    control={control}
                                    rules={{
                                        required: {
                                            value: true,
                                            message: ""
                                        }
                                    }}
                                    render={({ field: { onChange, value } }) => (
                                        <Dropdown
                                            className={"form-select"}
                                            placeholder={"Категория"}
                                            label={"Тип ответов"}
                                            options={[
                                                {
                                                    label: "Все",
                                                    value: "all"
                                                },
                                                ...categoryList
                                            ]}
                                            value={value}
                                            onChange={onChange}
                                        />
                                    )}
                                />

                                <Controller
                                    name="extra"
                                    control={control}
                                    rules={{
                                        required: {
                                            value: true,
                                            message: ""
                                        }
                                    }}
                                    render={({ field: { onChange, value } }) => (
                                        <ToggleBlock
                                            isChecked={value}
                                            className="polls-create__form-toggle"
                                            desc="Поле “Другое”"
                                            handleChange={onChange}
                                        />
                                    )}
                                />

                                <span className='polls-create__form__line'></span>

                                <div className='polls-create__form__textWithToggle'>
                                    <Controller
                                        name="reward"
                                        control={control}
                                        rules={{
                                            required: {
                                                value: true,
                                                message: ""
                                            }
                                        }}
                                        render={({ field: { onChange, value } }) => (
                                            <ToggleBlock
                                                isChecked={value}
                                                className="polls-create__form__textWithToggle-toggle"
                                                desc="Награда за участие"
                                                handleChange={onChange}
                                            />
                                        )}

                                    />

                                    <Controller
                                        name="reward_point"
                                        control={control}
                                        rules={{
                                            required: {
                                                value: true,
                                                message: ""
                                            }
                                        }}
                                        render={({ field: { onChange, value } }) => (
                                            <TextField
                                                value={value}
                                                className=""
                                                type='number'
                                                name="name"
                                                onChange={onChange}
                                                prepend={<CoinCompetitionIcon />}
                                            />
                                        )}
                                    />

                                </div>

                                <Controller
                                    name="is_consolidated"
                                    control={control}
                                    rules={{
                                        required: {
                                            value: true,
                                            message: ""
                                        }
                                    }}
                                    render={({ field: { onChange, value } }) => (
                                        <ToggleBlock
                                            isChecked={value}
                                            className="polls-create__form-toggle"
                                            desc="Закрепить опрос"
                                            handleChange={onChange}
                                        />
                                    )}
                                />

                                <Controller
                                    name="is_anonymous"
                                    control={control}
                                    rules={{
                                        required: {
                                            value: true,
                                            message: ""
                                        }
                                    }}
                                    render={({ field: { onChange, value } }) => (
                                        <ToggleBlock
                                            isChecked={value}
                                            className="polls-create__form-toggle"
                                            desc="Ананомный опрос"
                                            handleChange={onChange}
                                        />
                                    )}
                                />

                                <Controller
                                    name="is_adaptation"
                                    control={control}
                                    rules={{
                                        required: {
                                            value: true,
                                            message: ""
                                        }
                                    }}
                                    render={({ field: { onChange, value } }) => (
                                        <ToggleBlock
                                            isChecked={value}
                                            className="polls-create__form-toggle"
                                            desc="Адаптация"
                                            handleChange={onChange}
                                        />
                                    )}
                                />

                                <div className={"d-flex gap-3"}>
                                    {purpose === 'edit' && (
                                        <Button
                                            type={"reset"}
                                            text={"Удалить"}
                                            className={"btn btn-de w-100 polls-create__form__deleteButton"}
                                        />
                                    )}

                                    <Button
                                        text={"В черновик"}
                                        className={"btn btn-light w-100"}
                                    />

                                    <Button
                                        type={"submit"}
                                        text={"Опубликовать"}
                                        className={"btn btn-primary w-100"}
                                    />
                                </div>
                            </form>
                        </div>

                        <div className='col-5'>
                                <div className={"polls-create-preview"}>
                                    <ToggleBlock
                                        isChecked={isShow}
                                        className="contests__create__form-toggle"
                                        desc="Предпросмотр"
                                        handleChange={setIsShow}
                                    />

                                    {isShow && watch() && (
                                        <PollsPageView data={watch()} setValue={setValue}/>
                                    )}
                                </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}