import React, { FC, ReactNode, useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { ru } from "date-fns/locale/ru";

import "react-datepicker/dist/react-datepicker.css";
import { FieldErrors } from "react-hook-form";
import { ClockIcon } from "../../../../img/icons/ClockIcon";
import { dataPickerType } from "./index";

type DateInterface = Date | null;

interface DatePickerProps {
    startDate?: DateInterface;
    onChange: (date: Date | null) => void;
    disabled?: boolean;
    className?: string;
    minTime?: any;
    maxTime?: any;
    placeholder?: string;
    isRightIcon?: boolean;
    isIcon?: ReactNode | string;
    name?: string;
    errors?: FieldErrors<dataPickerType>;
    value?: string;
    label?: string;
    timeIntervals?: number;
}

const DatePickerTimeField: FC<DatePickerProps> = (
    {
        startDate,
        onChange,
        disabled,
        className = "",
        minTime,
        maxTime,
        placeholder,
        isRightIcon,
        isIcon = <ClockIcon size={24} color={"#00A7B5"} opacity={"1"}/>,
        name,
        errors,
        value,
        label,
        timeIntervals = 30
    }
) => {
    const dateStart = (date: any): DateInterface => {
        if (typeof date === "string" && date.length) return new Date(date);

        return startDate || null;
    };

    const [startDateLocal, setStartDate] = useState<DateInterface>(
        dateStart(startDate)
    );

    const onDateRange = (value: Date | null) => {
        onChange(value || new Date());
    };

    useEffect(() => {
        if (dateStart(startDate) !== startDateLocal)
            setStartDate(dateStart(startDate));
    }, [startDate]);

    let handleDateChangeRaw = (e: { preventDefault: () => void } | undefined) => {
        e && e.preventDefault();
    };

    useEffect(() => {
        if (startDate) setStartDate(dateStart(startDate));
    }, [startDate]);

    return (
        <div className={"form-group calendar-block calendar-block-time"}>
            {label && (
                <label className={"form-label"}>
                    {label}
                </label>
            )}

            <div className={"calendar-block__date-picker"}>
                {isIcon && (
                    <div
                        className={`calendar-block__icon ${isRightIcon ? "right" : "left"}`}
                    >
                        {isIcon}
                    </div>
                )}

                <DatePicker
                    locale={ru}
                    selected={startDateLocal}
                    value={value}
                    wrapperClassName="w-100"
                    onChange={(date: Date | null) => {
                        onDateRange(date || null);

                        setStartDate(date || null);
                    }}
                    placeholderText={placeholder}
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={timeIntervals}
                    timeCaption="Время"
                    timeFormat="HH:mm"
                    dateFormat="HH:mm"
                    disabled={disabled}
                    onChangeRaw={handleDateChangeRaw}
                    minTime={minTime}
                    maxTime={maxTime}
                    className={`${className} form-control form-datepicker`}
                />
            </div>

            {errors?.[name as keyof dataPickerType] && (
                <div className="text-[12px] leading-[14.06px] flex flex-col">
                    <p className="text-red-default mb-[5px]">
                        {errors?.[name as keyof dataPickerType]?.message}
                    </p>
                </div>
            )}
        </div>
    );
};

export default DatePickerTimeField;
