import { FC } from "react";
import { IBaseIconProps } from "../types";

export const MessengerSendIcon: FC<IBaseIconProps> = ({
  size = 24,
  color = '#00A7B5'
}) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.2617 14.002L12.4954 20.4628C12.9141 21.6739 14.6119 21.7143 15.0879 20.5246L21.496 4.5065C21.9418 3.39217 20.8546 2.27752 19.7295 2.69539L3.30039 8.79726C2.09981 9.24316 2.09713 10.9403 3.2963 11.39L10.2617 14.002Z"
        stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}