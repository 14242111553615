import Button from "../../../components/ui/form/Buttons/Button";

import noImageBanner from "../../../img/noimage.png";
import { CreateSportFormData } from "./index";
import { getDateByFormat, getWeekDay } from "../../../utils";
import { LocationIcon } from "../../../img/icons/LocationIcon";
import { useEffect, useState } from "react";

interface SportCreateEditPreviewProps {
    data: CreateSportFormData;
    handleSubmit?: () => void;
    isHideBtn?: boolean;
}
const SportCreateEditPreview = (
    {
        data,
        handleSubmit,
        isHideBtn
    }: SportCreateEditPreviewProps
) => {
    const [upload, setUpload] = useState("");

    const handleImageUpload = (file: File): void => {
        if (file) {
            const reader = new FileReader()

            reader.onloadend = () => {
                if (reader.result && typeof reader.result === 'string') {
                    setUpload(reader.result)
                }
            }

            reader.readAsDataURL(file)
        }
    }

    useEffect(() => {
        if (typeof data.image === "string") {
            setUpload(data.image)
        } else {
            handleImageUpload(data.image as File)
        }
    }, [data.image]);

    return (
        <div className="contests__create__preview-items">
            <div className="contests__create__preview-img">
                <img src={upload || noImageBanner} />
            </div>

            <div className="contests__create__preview-item">
                <div className="contests__create__preview-item__header">
                    <div className={"d-flex align-items-center gap-3"}>
                        <h3>{data.title || "Название"}</h3>
                    </div>
                </div>

                <div className="contests__create__preview-item__content sport__create__preview">
                    <p className={"contests__create__preview-item__content__text"}>{data.description || "Введите описание"}</p>

                    <div
                        className="sport__create__preview-date"
                    >
                        <h5>Осталось мест: <span>{data.seats}</span></h5>
                    </div>

                    {data.schedules && (
                        <div
                            className="sport__create__preview-date"
                        >
                            <h5>Время тренировки</h5>

                            <div
                                className="sport__create__preview-date__items"
                            >
                                {data.schedules.map((item, idx) => (
                                    <div
                                        key={idx}
                                        className={"contests__create__preview-item__header-category-item"}
                                    >
                                        {getWeekDay(item.date) || "..."} | {""}
                                        {getDateByFormat(item.date, 'DD.MM')} | {""}
                                        {getDateByFormat(item.time_start, 'HH:mm')} - {""}
                                        {getDateByFormat(item.time_end, 'HH:mm')}
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}

                    {data.trainer && (
                        <div className="sport__create__preview-trainer">
                            <h5>Тренер</h5>

                            {(data.trainer.photo || data.trainer.name || data.trainer.position) && (
                                <div className="sport__content-card-user">
                                    {data.trainer.photo && (
                                        <img
                                            src={data.trainer.photo}
                                            alt=""
                                            className="sport__content-card-user__image"
                                        />
                                    )}

                                    <div className="sport__content-card-user__content">
                                        <h1 className="sport__content-card-user__content__name">{data.trainer.name}</h1>
                                        <p className="sport__content-card-user__content__position">{data.trainer.position}</p>
                                    </div>
                                </div>
                            )}
                        </div>
                    )}

                    <div className="sport__create__preview-location">
                        <h5>Место проведения</h5>

                        {data.venue && (
                            <div className="">
                                <LocationIcon />

                                <p>{data.venue}</p>
                            </div>
                        )}
                    </div>

                    {!isHideBtn && (
                        <div className="">
                            <Button
                                text={"Записаться на тренировку"}
                                className={"btn btn-primary w-100"}
                                onClick={() => handleSubmit && handleSubmit()}
                            />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default SportCreateEditPreview;