import { IBanners } from "../../utils";
import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import noImageBanner from "../../img/noimage.png";
import { BlankImage } from "../ui/blank-image/BlankImage";

interface IBannerCard {
  data: IBanners
}

export const BannerCard: FC<IBannerCard> = ({
  data
}) => {
  return (
    <div className='layout-banner'>
      <BlankImage image={ data?.image } classname={ 'layout-banner__image' } />

      <div className='layout-banner__content'>
        <div className='layout-banner__desc'>
          <p>{ data?.src }</p>
          {/*<h1 className='layout-banner__title'>Карьерный рост</h1>*/ }
        </div>

        <a href={ data?.src }>
          <button className='layout-banner__button'>
            Подробности
          </button>
        </a>

      </div>
    </div>
  )
}