import DialogContainer from "../../../ui/dialog/DialogContainer";
import { FC } from "react";
import { CloseIcon } from "../../../../img";

interface IServicesAddingAgreerModal {
  isOpen: boolean,
  onClose: () => void,
}
export const ServicesAddingAgreerModal: FC<IServicesAddingAgreerModal> = ({
  isOpen,
  onClose
}) => {
  return (
    <DialogContainer isOpen={isOpen} isShowCloseBtn={false} closeModal={onClose}>
      <div className='services-create-modal__header'>
        <h1 className='services-create-modal__header__title'>Добавить согласанта</h1>

        <div className='services-create-modal__header__close' onClick={ onClose }>
          <CloseIcon color='#000' />
        </div>
      </div>

      <div className='services-create-modal__content'>

      </div>

      <div className='services-create-modal__buttons'>
        <div className='services-create-modal__buttons__item services-create-modal__buttons__add'
             onClick={() => ''}
        >
          Направить
        </div>
      </div>
    </DialogContainer>
  )
}