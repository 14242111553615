import TextOverflow from "../../../utils/text-overflow/TextOverflow";
import { CommentIcon, EyeIcon, HeartIcon, LikeIcon } from "../../../img";
import NewsDateIcon from "../../../img/icons/news/NewsDateIcon";
import { FC, useEffect, useState } from "react";
import { formatDate, getDateByFormat, getYearMonthDay, isTillMd } from "../../../utils";
import noImageBanner from "../../../img/noimage.png";
import { BlankImage } from "../../ui/blank-image/BlankImage";
import { useNavigate } from "react-router-dom";
import { NewsCardProps } from "../../news";
import moment from "moment";

export interface NotificationCardProps {
    id: number;
    title: string;
    content: string;
    image?: string;
    created_at: string;
    type: string;
    type_id: number;
}

interface INotificationCard {
    data: NotificationCardProps;
}

export const NotificationCard: FC<INotificationCard> = (
    {
        data
    }) => {
    const navigate = useNavigate();
    const isMobile = isTillMd();

    const [imgSrc, setImgSrc] = useState<any>(null);

    const {
        id,
        title,
        image,
        created_at
    } = data;

    useEffect(() => {
        const img = new Image();
        if (image != null) {
            img.src = image;
        }

        img.onload = () => {
            setImgSrc(image);
        };

    }, [image]);

    const pages = [
        {
            "type": "ideas",
            "path": "/ideas/show/{id}"
        },
        {
            "type": "default",
            "path": "/"
        }
    ];

    function handlePush() {
        const page = pages.find(it => it.type == data.type);

        if (page) {
            const path = page?.path?.replace("{id}", `${data.type_id}`);

            if (path)
                navigate(path);
        } else {
            const defaultPage = pages.find(it => it.type == "default");
            navigate(defaultPage!!.path);
        }
    }

    return (
        <div className="notification-card" onClick={handlePush}>
            <BlankImage classname="notification-card__image" image={image ?? ""} />

            <div className="notification-card__block">
                <div className="notification-card__title">{data.content}</div>
                <div className="notification-card__description">{data.title}</div>
            </div>

            <div className="notification-card__date">{formatDate(created_at)}</div>
        </div>
    );
};