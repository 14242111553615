import { IApiHandlerParams } from "../../interface";
import api from "../../index";

export const $uploadImage = (
    file: {
        file_content: File;
        target_type: string;
        file_type?: string;
    },
    handler?: IApiHandlerParams
): Promise<any> =>
    api.post('https://api-itelma-dev.svoi.club/backend/v1/upload/file', {
        ...file,
        file_type: "image",
    },{
        handler: {
            ...handler,
            formData: true,
            dontUseApiUrl: true
        }
    });