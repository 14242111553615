import { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    changeMainPage,
    changePersonData, changeProfileVarsData,
    getMainPageData,
    getPersonData
} from "../redux";
import Cookies from "js-cookie";
import { $mainPageNewData } from "../api/requests/main";
import Preloader from "../components/ui/preloader/Preloader";
import HeaderHome from "../components/header/HeaderHome";
import AsideHome from "../components/header/AsideHome";
import FooterHome from "../components/header/FooterHome";
import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";
import ScrollToTop from "../utils/scroll/ScrollToTop";
import { Slider } from "../components/slider/Slider";
import { useVskAccess } from "../hooks";
import { $getPersonalPage } from "../api/requests/user";
import { getGroupCreated, setGroupCreated } from "../redux/reducers/workplace/workplaceSlice";
import CompleteModal, { BtnDataItem } from "../components/ui/dialog/CompleteModal";

interface IMainLayout {
    isScrolled: boolean;
}

const MainLayout: FC<IMainLayout> = ({ isScrolled }) => {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const mainData = useSelector(getMainPageData);
    const localPersonData = useSelector(getPersonData);
    const isGroupCreated = useSelector(getGroupCreated);

    const searchParams = new URLSearchParams(location.search);

    const groupId = searchParams.get("group_id") || localPersonData?.group_id;

    const token = Cookies.get("token") || localStorage.getItem("token");

    const [scroll, setScroll] = useState<any>(false);

    const [listMenu, setListMenu] = useState([
        {
            img: "/images/icons/home.svg",
            text: "Главная страница",
            key: "home",
            count: 0,
            active: true,
            link: "/"
        },
        // {
        //     img: "/images/icons/facebook_like.svg",
        //     text: "Благодарности",
        //     key: "urls",
        //     count: 2,
        //     active: false,
        //     backColorCount: "#FFD600",
        //     colorCount: "#000000",
        //     link: "/"
        // },
        {
            img: "/images/icons/news.svg",
            text: "Новости",
            key: "news",
            count: 0,
            active: false,
            backColorCount: "#C7EF71",
            colorCount: "#000000",
            link: "/news"
        },
        {
            img: "/images/icons/megaphone.svg",
            text: "Конкурсы",
            key: "news",
            active: false,
            count: 0,
            link: "/competitions"
        },
        {
            img: "/images/icons/check_all.svg",
            text: "Опросы",
            key: "news",
            active: false,
            count: 0,
            link: "/polls"
        },
        {
            img: "/images/icons/user.svg",
            text: "Новые сотрудники",
            key: "news",
            active: false,
            count: 0,
            link: "/employees"
        },
        {
            img: "/images/icons/user.svg",
            text: "Структура компании",
            key: "news",
            active: false,
            count: 0,
            link: "/employees/structure"
        },
        {
            img: "/images/icons/user.svg",
            text: "Идеи",
            key: "ideas",
            active: false,
            count: 0,
            link: "/ideas"
        }
    ]);

    const btnList = [
        {
            key: 1,
            text: "Перейти к настройкам",
            className: "btn-primary w-100"
        },
        {
            key: 2,
            text: "Отмена",
            className: "btn-light w-100"
        }
    ]

    const [isOpenMenu, setIsOpenMenu] = useState(false);
    const [modal, setModal] = useState('')
    const [localLocation, setLocalLocation] = useState<any>(null)

    const [personalData, setPersonalData] = useState(localPersonData || []);

    const { jsonData } = useVskAccess({ data: personalData });

    useEffect(() => {
        // setIsOpenMenu(false);

        if ((location.pathname === localLocation?.pathname) && (location.search === localLocation?.search)) return;

        if (!localPersonData) return getFuncPersonData();

        if (groupId) {
            setTimeout(() => {
                getMainPage();

                getFuncPersonData();

                setLocalLocation(location);
            }, 500)
        }
    }, [location]);

    useEffect(() => {
        if (mainData) return;

        getMainPage();
    }, []);

    useEffect(() => {
        const handleScroll = () => {
            const slider = document.getElementById("sliderRouters");

            if (!slider) return;

            const sliderRect = slider.getBoundingClientRect();

            setScroll(sliderRect.top <= 80);
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    useEffect(() => {
        if (document.body) {
            const body = document.body;
            body.scrollTo(1000, 1000);
        }
    }, []);

    useEffect(() => {
        const defaultColorScheme = {
            primaryColor: "#00A7B5", //blueColor
            secondaryColor: "#00A7B5", //lightBlue
            thirdColor: "#E9F0F9", //grayColor
            fourthColor: "#FFF" //whiteColor
        };

        if (jsonData) {
            dispatch(changePersonData(jsonData));
            dispatch(changeProfileVarsData({
                colors: jsonData.workspace_design
                    ? JSON.parse(jsonData.workspace_design)
                    : defaultColorScheme,
            }));
        }
    }, [jsonData]);

    function getMainPage() {
        $mainPageNewData().then((res) => {
            if (!res || res.error) return;

            dispatch(changeMainPage(res));
        });
    }

    function getFuncPersonData() {
        $getPersonalPage(null, { newApi: true }).then(res => {
            if (!res) return console.error("error person data");

            setPersonalData(res.data);
        });
    }

    function openMenu(): void {
        setIsOpenMenu(!isOpenMenu);
    }

    function openSearchModal(): void {
        setModal("search");
    }

    function onClickMenuFooter(i: any): void {
        if (i.key === "navigation") openMenu();
        if (i.key === "search") openSearchModal();
    }

    function handleClickModalBtn(selectBtn: BtnDataItem) {
        if (selectBtn.key === 1) {
            navigate("/groups/setting/settings");
        }

        dispatch(setGroupCreated(false));
    }

    if (!token) {
        return <Navigate to={"/auth"} replace />;
    }

    if (!localPersonData || !mainData)
        return null

    return (
        <>
            <HeaderHome openMenu={openMenu} modal={modal} setModal={setModal}/>

            <Slider isScrolled={scroll} />

            <AsideHome
                isActive={isOpenMenu}
                openMenu={openMenu}
                menu={listMenu}
            />

            <div className={`main ${isScrolled ? "main-scrolled" : ""}`}><Outlet /></div>

            <FooterHome
                isOpenMenu={isOpenMenu}
                openMenu={openMenu}
                onClickMenuFooter={onClickMenuFooter}
                setSearchModalOpen={openSearchModal}
            />

            <ScrollToTop />

            <CompleteModal
                isOpen={isGroupCreated}
                setIsOpen={() => dispatch(setGroupCreated(false))}
                icon={"⚙️"}
                title={"Настройка"}
                text={"Для того чтобы ваша группа стала активной, для начала Вам необходимо ее настроить"}
                btnList={btnList}
                onClick={handleClickModalBtn}
                isFilter
            />
        </>
    );
};

export default MainLayout;
