import DialogContainer from "../../../components/ui/dialog/DialogContainer";
import { Controller, useForm } from "react-hook-form";
import FileField from "../../../components/ui/form/FileField/FileField";
import TextField from "../../../components/ui/form/Fields/TextField";
import TextArea from "../../../components/ui/form/Fields/TextArea";
import Button from "../../../components/ui/form/Buttons/Button";
import React, { useState } from "react";
import { ClipIcon } from "../../../img/icons/ClipIcon";
import { CloseIcon } from "../../../img";
import { CreateGroupsBannerRequestData } from "../../../api/requests/groups/banners/interface";

export interface CreateEditBannerFormData {
    id?: number;
    image: File | string;
    image_mobile: File | string;
    src?: string;
    sort?: number;
    type?: string;
    title?: string;
    text?: string;
}

interface WorkspaceSettingGroupModalCreateEditBannerProps {
    isOpen: boolean;
    onClose: () => void;
    editData?: CreateEditBannerFormData;
    createRequest: any;
    editRequest: any;
}

const WorkspaceSettingGroupModalCreateEditBanner = (
    {
        isOpen,
        onClose,
        editData,
        createRequest,
        editRequest
    }: WorkspaceSettingGroupModalCreateEditBannerProps
) => {
    const [isLoading, setIsLoading] = useState(false);
    const [isStatusComplete, setIsStatusComplete] = useState(false);

    const {
        handleSubmit,
        control,
        formState: { errors },
        reset
    } = useForm<CreateEditBannerFormData>(
        editData ? {
            values: {
                image: editData.image,
                image_mobile: editData.image_mobile,
                src: editData.src,
                title: editData.title,
                text: editData.text
            }
        } : {
            values: {
                image: "",
                image_mobile: "",
                src: "",
                title: "",
                text: ""
            }
        }
    );

    const title = editData ? `Редактирование банера № ${(typeof editData?.sort === "number") ? +editData.sort + 1 : ""}` : "Добавление банера";
    const textBtn = editData ? "Сохранить" : "Добавить";

    function onSubmit(data: CreateEditBannerFormData) {
        setIsLoading(true);
        const requestData: any = {
            image: data.image,
            image_mobile: data.image,
            src: data.src
            // title: data.title,
            // text: data.text,
        };

        if (editData) return handleEditData(requestData);

        createRequest(requestData, { formData: true }).then((res: any) => {
            setIsStatusComplete(true);
        });
    }

    function handleEditData(requestData: CreateGroupsBannerRequestData) {
        editRequest({
            ...requestData,
            id: editData?.id ? editData.id.toString() : ""
        }, { formData: true }).then((res: boolean) => {
            handleClose();
        });
    }

    function handleClose() {
        setIsLoading(false);
        onClose();
        reset();
    }

    return (
        <DialogContainer
            isOpen={isOpen}
            setIsOpen={onClose}
            isShowCloseBtn={false}
            classNameModal={"p-0"}
            widthProps={427}
        >
            {isStatusComplete ? (
                <div className={"sport__view__modal contests__winner"}>
                    <div className={"sport__view__modal__content-complete"}>
                        <span>👍🏻</span>

                        <h3>Успешно</h3>

                        <p>
                            Вы успешно добавили банер на главную страницу
                        </p>
                    </div>

                    <div className={"sport__view__modal__btn"}>
                        <Button
                            text={"Отлично"}
                            className={"btn btn-light w-100"}
                            onClick={() => {
                                handleClose();
                                setIsStatusComplete(false);
                            }}
                        />
                    </div>
                </div>
            ) : (
                <>
                    <div className={"contests__winner"}>
                        <div className={"contests__winner__header p-0"}>
                            <h3>{title}</h3>

                            <div
                                className={"d-flex align-items-center ml-auto cursor-pointer"}
                                onClick={onClose}
                            >
                                <CloseIcon color={"black"} opacity={"1"} />
                            </div>
                        </div>
                    </div>

                    <form
                        onSubmit={handleSubmit(onSubmit)}
                        className="contests__create__form workspace-settings-group__main-block pt-2"
                    >
                        <Controller
                            name="image"
                            control={control}
                            rules={{
                                required: {
                                    value: true,
                                    message: "Прикрепите изображение"
                                }
                            }}
                            render={({ field: { onChange, value } }) => (
                                <FileField
                                    className={"max-w-100 form-image"}
                                    upload={value}
                                    label="Изображение"
                                    setUpload={onChange}
                                />
                            )}
                        />

                        {/*<Controller*/}
                        {/*    name="title"*/}
                        {/*    control={control}*/}
                        {/*    render={({ field: { onChange, value } }) => (*/}
                        {/*        <TextField*/}
                        {/*            value={value || ""}*/}
                        {/*            className=""*/}
                        {/*            title="Заголовок"*/}
                        {/*            placeholder={"Введите заголовок"}*/}
                        {/*            name="title"*/}
                        {/*            onChange={onChange}*/}
                        {/*        />*/}
                        {/*    )}*/}
                        {/*/>*/}

                        {/*<Controller*/}
                        {/*    name="text"*/}
                        {/*    control={control}*/}
                        {/*    render={({ field: { onChange, value } }) => (*/}
                        {/*        <TextArea*/}
                        {/*            value={value || ""}*/}
                        {/*            title="Описание"*/}
                        {/*            placeholder={"Введите описание"}*/}
                        {/*            name="text"*/}
                        {/*            onChange={onChange}*/}
                        {/*            rows={2}*/}
                        {/*        />*/}
                        {/*    )}*/}
                        {/*/>*/}

                        <Controller
                            name="src"
                            control={control}
                            render={({ field: { onChange, value } }) => (
                                <TextField
                                    value={value || ""}
                                    prepend={<ClipIcon />}
                                    title="Ссылка на источник"
                                    placeholder={"Введите ссылку на источник"}
                                    name="src"
                                    onChange={onChange}
                                />
                            )}
                        />

                        <div className={"d-flex gap-3"}>
                            <Button
                                type={"submit"}
                                text={textBtn}
                                className={"btn btn-primary w-100"}
                                loading={isLoading}
                            />
                        </div>
                    </form>
                </>
            )}
        </DialogContainer>
    );
};

export default WorkspaceSettingGroupModalCreateEditBanner;