import React, { FC, useEffect, useRef, useState } from "react";
import { ArrowExitIcon, SearchIcon } from "../../img";
import TextField from "../../components/ui/form/Fields/TextField";
import { useNavigate } from "react-router-dom";
import { NotificationCard } from "../../components/notification/notification-card";
import moment from "moment";
import { NewsCard } from "../../components/news";
import { formatDate, isMd } from "../../utils";
import { $getPersonalPage } from "../../api/requests/user";

export const NotificationsPage: FC = () => {
    const navigate = useNavigate();
    const isMobile = isMd();

    const [pushesData, setPushesData] = useState<[]>([]);

    useEffect(() => {
        init();
    }, []);

    function init() {
        getProfile();
    }

    function getProfile() {
        $getPersonalPage(null, { newApi: true }).then(res => {
            if (!res) return;

            if (res.all_notifications)
                setPushesData(res.all_notifications);
        });
    }

    const groupedItems = pushesData.reduce((
        group: any,
        item: any
    ) => {
        const date = moment(item.created_at).format("YYYY-MM-DD");

        if (!group[date]) {
            group[date] = [];
        }

        group[date].push(item);
        return group;
    }, {});

    const parseDate = (dateString: any) => {
        const [year, month, day] = dateString.split("-").map(Number);
        return new Date(year, month - 1, day);
    };

    const formatDate = (date: any) => {
        const now = new Date();

        // Compare dates to see if they are the same day
        const isToday = date.toDateString() === now.toDateString();
        const yesterday = new Date(now);
        yesterday.setDate(now.getDate() - 1);
        const isYesterday = date.toDateString() === yesterday.toDateString();

        if (isToday) {
            return "Сегодня";
        } else if (isYesterday) {
            return "Вчера";
        } else {
            // Return formatted date as "DD.MM.YYYY"
            const dd = String(date.getDate()).padStart(2, "0");
            const mm = String(date.getMonth() + 1).padStart(2, "0");
            const yyyy = date.getFullYear();
            return `${dd}.${mm}.${yyyy}`;
        }
    };

    return (
        <div className="notifications">
            <div className="layout-inner__header profile-header">
                {!isMobile && (
                    <div className="layout-inner__header__title">
                        <div className="header__exitIcon" onClick={() => navigate(-1)}>
                            <ArrowExitIcon />
                        </div>
                        <h1>Уведомления</h1>
                    </div>
                )}
            </div>

            <div className="notifications-content">
                <div className="notifications-content-block">
                    {Object.keys(groupedItems).map(date => (
                        <>
                            <div
                                className="notifications-content__date">{formatDate(parseDate(date))}</div>
                            <div className="notifications-content__items">
                                {groupedItems[date].map((item: any) => (
                                    <NotificationCard data={item}></NotificationCard>
                                ))}
                            </div>
                        </>
                    ))}
                </div>
            </div>
        </div>
    );
};