import DialogContainer from "../../../ui/dialog/DialogContainer";
import { FC } from "react";
import { CloseIcon } from "../../../../img";
import { Controller, useForm } from "react-hook-form";
import DatePickerForm from "../../../ui/form/DatePickerForm";
import ImageField from "../../../ui/form/ImageField/ImageField";
import { DownloadIcon } from "../../../../img/icons/DownloadIcon";

interface IServicesFormingApplicationModal {
  isOpen: boolean,
  onClose: () => void
}
export const ServicesFormingApplicationModal: FC<IServicesFormingApplicationModal> = ({
  isOpen,
  onClose
}) => {
  const {
    handleSubmit,
    control,
    watch,
    register,
    reset,
    formState: {isValid},
    setValue,
  } = useForm<any>();

  return (
    <DialogContainer isOpen={isOpen} isShowCloseBtn={false} closeModal={onClose}>
      <div className='services-create-modal__header'>
        <h1 className='services-create-modal__header__title'>Оформление заявки</h1>

        <div className='services-create-modal__header__close' onClick={ onClose }>
          <CloseIcon color='#000' />
        </div>
      </div>

      <div className='services-create-modal__content'>
        <Controller
          name="date_end"
          control={control}
          rules={{
            required: {
              value: true,
              message: ""
            }
          }}
          render={({ field: { onChange, value } }) => (
            <DatePickerForm
              value={value}
              className=""
              label="Дата начала обучения"
              placeholder={"Выберите дату"}
              // name="name"
              onChange={onChange}
              isIcon
              isRightIcon
            />
          )}
        />

        <p className='services-create-modal__content__comment'>Если заявка оформленна после 15 числа, она будет обработана не раньше чем в след. месяце</p>

        <div className='services-create-modal__content__imageField'>
          <div className='services-create-modal__content__imageField__label'>
            <p>Заявка</p>
            <DownloadIcon size={18}/>
          </div>

          <Controller
            name="image"
            control={control}
            rules={{
              required: {
                value: true,
                message: ""
              }
            }}
            render={({ field: { onChange, value } }) => (
              <ImageField
                upload={value}
                label=""
                setUpload={onChange}
              />
            )}
          />
        </div>
      </div>

      <div className='services-create-modal__buttons'>
        <div className='services-create-modal__buttons__item services-create-modal__buttons__add'
             onClick={() => ''}
        >
          Отправить
        </div>
      </div>
    </DialogContainer>
  )
}