import React, { useEffect, useState } from "react";
import {
    ArrowExitIcon,
    SettingIcon,
    WorkspaceBannerIcon,
    WorkspaceNavigationIcon,
    WorkspaceUsersIcon
} from "../../img";
import { isMd } from "../../utils";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import { SearchItem } from "../../components/ui/form/SearchItem";
import Dropdown from "../../components/ui/form/Dropdown/Dropdown";
import Button from "../../components/ui/form/Buttons/Button";
import { $deleteGroupById, $getGroupList } from "../../api/requests/groups";
import { useDispatch, useSelector } from "react-redux";
import { getPersonData } from "../../redux";
import {
    getCurrentGroupData,
    setCurrentGroupData
} from "../../redux/reducers/workplace/workplaceSlice";
import WorkspaceSettingGroupInviteUsers from "./groups/WorkspaceSettingGroupInviteUsers";

const WorkspaceSettingGroupPage = () => {
    const navigate = useNavigate();
    const isMobile = isMd();
    const dispatch = useDispatch();
    const location = useLocation();

    const personalData = useSelector(getPersonData);
    const currentGroup = useSelector(getCurrentGroupData);

    const [activePage, setActivePage] = useState("settings");
    const [isOpenInviteUsers, setIsOpenInviteUsers] = useState(false);

    const settingsPage = [
        {
            key: "banners",
            title: "Банеры",
            icon: <WorkspaceBannerIcon />,
            fill: true
        },
        {
            key: "modules",
            title: "Модули",
            icon: <WorkspaceNavigationIcon />,
            fill: false
        },
        {
            key: "settings",
            title: "Общие настройки",
            icon: <SettingIcon color={"#000000"} />,
            fill: false
        },
        {
            key: "users",
            title: "Пользователи",
            icon: <WorkspaceUsersIcon />,
            fill: false
        }
    ];

    const pages = location.pathname.split("/");

    const currentPage = pages[pages.length - 1];

    useEffect(() => {
        if (currentPage !== activePage) {
            setActivePage(currentPage)
        }
    }, [currentPage]);

    useEffect(() => {
        if (personalData.group_id && !currentGroup) {
            $getGroupList().then(res => {
                const findCurrentGroup = res.find(item => +item.id === personalData.group_id);

                findCurrentGroup && dispatch(setCurrentGroupData(findCurrentGroup));
            });
        }
    }, [personalData]);

    useEffect(() => {
        return () => {
            dispatch(setCurrentGroupData(null));
        };
    }, []);

    function handleOpenPage(key: string) {
        setActivePage(key);
        navigate(key);
    }

    function handleDeleteGroup() {
        if (!currentGroup) return;

        $deleteGroupById({ id: currentGroup.id }, { formData: true }).then(res => {
            navigate("/?group_id=0");
        });
    }

    return (
        <div className={"py-3"}>
            <div className="container">
                <div className="layout-inner__header user-header">
                    {!isMobile && (
                        <div className="layout-inner__header__title">
                            <div className="header__exitIcon" onClick={() => navigate(-1)}>
                                <ArrowExitIcon />
                            </div>

                            <h1>Настройка</h1>
                        </div>
                    )}

                    {activePage === "users" && (
                        <div className="workspace-settings-users__header">
                            <div className="workspace-settings-users__header__dropdown">
                                <Dropdown
                                    options={[{ value: 1, label: "Роль" }]}
                                    placeholder={"Роль"}
                                    onChange={() => ""}
                                />
                            </div>

                            <div className="workspace-settings-users__header__search">
                                <SearchItem
                                    params={[]}
                                    onChange={() => ""}
                                />
                            </div>

                            <div className="workspace-settings-users__header__btn">
                                <Button
                                    className="btn btn-primary"
                                    text={"Пригласить"}
                                    onClick={() => setIsOpenInviteUsers(true)}
                                />
                            </div>
                        </div>
                    )}
                </div>

                <div className="workspace-settings-content">
                    <div className="workspace-settings-left workspace-settings-group__left">
                        {settingsPage.map((item, idx) => (
                            <div
                                className={`workspace-settings-page`}
                                key={`workspace-settings-left-${idx}`}
                            >
                                <div
                                    className={`workspace-settings-page__main ${activePage === item.key ? "active" : ""}`}
                                    onClick={() => handleOpenPage(item.key)}
                                >
                                    <div className="workspace-settings-page__left">
                                        <div
                                            className={`workspace-settings-page__main__icon ${item.fill ? "fill" : "stroke"}`}>
                                            {item?.icon}
                                        </div>

                                        <p className="workspace-settings-page__title">{item.title}</p>
                                    </div>
                                </div>
                            </div>
                        ))}

                        <Button
                            className="btn btn-red mt-auto"
                            text={"Удалить группу"}
                            onClick={handleDeleteGroup}
                        />
                    </div>

                    <div className="workspace-settings-right workspace-settings-group__right">
                        {settingsPage.map((item, idx) => {
                            if (item.key === activePage) return (
                                <React.Fragment key={`workspace-settings-right-${idx}`}>
                                    <Outlet />
                                </React.Fragment>
                            );

                            return null;
                        })}
                    </div>
                </div>
            </div>

            <WorkspaceSettingGroupInviteUsers
                isOpen={isOpenInviteUsers}
                onClose={() => setIsOpenInviteUsers(false)}
            />
        </div>
    );
};

export default WorkspaceSettingGroupPage;