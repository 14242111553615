import { Controller, useForm } from "react-hook-form";
import React, { useEffect, useState } from "react";
import { PictureIcon, PlusIcon2, SettingIcon } from "../../img";
import DialogContainer from "../ui/dialog/DialogContainer";
import Button from "../ui/form/Buttons/Button";
import { useSelector } from "react-redux";
import { getPersonData } from "../../redux";
import {
    $createStatus,
    $deleteStatus,
    $getStatuses, $updateStatus
} from "../../api/requests/workspace-settings/statuses";
import { successNotify } from "../../utils";
import { StatusData } from "../../api/requests/workspace-settings/statuses/interface";
import Dropdown, { DropdownItem } from "../ui/form/Dropdown/Dropdown";
import FileField from "../ui/form/FileField/FileField";

interface FormData {
    icon: File | string;
    title: string;
}

const WorkspaceStatuses = () => {
    const personalData = useSelector(getPersonData);

    const nameStatusesList = [
        {
            label: "Удаленно",
            value: "0"
        },
        {
            label: "В офисе",
            value: "1"
        },
        {
            label: "Больничный",
            value: "2"
        },
        {
            label: "Командировка",
            value: "3"
        },
        {
            label: "Отпуск",
            value: "4"
        },
        {
            label: "Отпуск по беременности и родам",
            value: "5"
        }
    ];

    const [filterNameList, setFilterNameList] = useState<DropdownItem[]>([]);

    const [isOpen, setIsOpen] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [changeField, setChangeField] = useState<StatusData | null>(null);
    const [statusList, setStatusList] = useState<StatusData[]>([]);

    const {
        handleSubmit,
        control,
        reset,
        formState: { isValid, errors },
        watch
    } = useForm<FormData>(
        {
            values: {
                icon: changeField?.icon || "",
                title: changeField?.title || ""
            }
        }
    );

    const getValue = (
        opts: { label: string; value: string | number }[],
        val: number | string | undefined
    ) => {
        if (val) {
            return opts.filter((o) => o.label === val);
        }
        return null;
    };

    useEffect(() => {
        init();
    }, [])

    useEffect(() => {
        const filterList = nameStatusesList.filter(item => !statusList.find(i => i.title === item.label))

        setFilterNameList(filterList)
    }, [statusList])

    function init() {
        $getStatuses({ id: personalData.workspace_id }, { formData: true }).then(res => {
            if (!res || !res.statuses) return;

            setStatusList(res.statuses);
        })
    }

    function handleCloseModal() {
        reset();
        setIsOpen(false);
        setIsEdit(false);
        setChangeField(null);
    }

    function onClickNewStatus() {
        setIsOpen(true);
    }

    function handleEditModal(select: StatusData) {
        setChangeField(select);
        setIsEdit(true);
        setIsOpen(true);
    }

    function onSubmit(request: any) {
        const requestData = {
            ...request,
            id: changeField?.id,
        };

        if (isEdit) return updateStatus(requestData);

        $createStatus(requestData, { formData: true }).then(res => {
            if (!res || !res.id) return;

            setStatusList((prev) => ([
                ...prev,
                res
            ]))

            handleCloseModal();
            successNotify("Успешно создано");
        });
    }

    function updateStatus(requestData: any) {
        $updateStatus(requestData, { formData: true }).then(res => {
            if (!res || !res.id) return;

            const updateList = statusList.map(i => {
                if (i.id === res.id) return {
                    ...res,
                    icon: (typeof changeField?.icon === 'string') ? changeField.icon : res.icon
                }

                return i
            })

            setStatusList([...updateList]);
            setChangeField(null);
            handleCloseModal();

            successNotify('Успешно обновлено');
        })
    }

    function onClickRemoveStatus() {
        if (!changeField?.id) return;

        $deleteStatus({ id: changeField.id }, { formData: true }).then(res => {
            if (res.error || res.id) return;

            handleCloseModal();
            setChangeField(null);
            successNotify("Успешно удалено");
            init();
        })
    }

    return (
        <>
            <div className={"d-flex gap-4"}>
                {statusList.map((item, idx) => (
                    <div
                        key={`status-item-${idx}`}
                        className={"workspace-settings-status__block"}
                    >
                        <img src={item.icon} />

                        <p>{item.title}</p>

                        <div
                            className={"setting"}
                            onClick={() => handleEditModal(item)}
                        >
                            <SettingIcon />
                        </div>
                    </div>
                ))}

                <div
                    className={"workspace-settings-status__block workspace-settings-status__block-add"}
                    onClick={onClickNewStatus}
                >
                    <PlusIcon2 color={"#007BFF"} />
                </div>
            </div>

            <DialogContainer
                isOpen={isOpen}
                setIsOpen={handleCloseModal}
                isShowCloseBtn={true}
                widthProps={427}
                classNameModal={"overflow-visible"}
                label={`${isEdit ? "Редактирование" : "Добавление"} статуса`}
            >
                <form className={"sport__view__modal"} onSubmit={handleSubmit(onSubmit)}>
                    <div
                        className="sport__view__modal__content sport__view__modal__content__cancel services-create-form__icon">
                        <Controller
                            name="icon"
                            control={control}
                            rules={{
                                required: {
                                    value: true,
                                    message: "Выберете изображение"
                                }
                            }}
                            render={({ field: { onChange, value } }) => (
                                <FileField
                                    iconFile={watch()?.icon ? ' ' : <PictureIcon color={"#00A7B5"}/>}
                                    className={"form-image"}
                                    upload={value}
                                    label="Изображение"
                                    setUpload={onChange}
                                />
                            )}
                        />
                    </div>

                    <div
                        className="sport__view__modal__content sport__view__modal__content__cancel">
                        <Controller
                            name="title"
                            control={control}
                            rules={{
                                required: {
                                    value: true,
                                    message: "Выберете название"
                                }
                            }}
                            render={({ field: { onChange, value } }) => (
                                <Dropdown
                                    className={"form-select mb-2"}
                                    label={"Выберите название"}
                                    placeholder={"Вручную"}
                                    options={filterNameList}
                                    value={getValue(nameStatusesList, value)}
                                    onChange={(select) => onChange(select.label)}
                                />
                            )}
                        />
                    </div>

                    <div className={"sport__view__modal__btn"}>
                        {isEdit && (
                            <Button
                                text={"Удалить"}
                                className={"btn btn-red w-100"}
                                onClick={onClickRemoveStatus}
                            />
                        )}

                        <Button
                            type={"submit"}
                            text={isEdit ? "Сохранить" : "Создать"}
                            className={"btn btn-primary w-100"}
                            disabled={!isValid}
                        />
                    </div>
                </form>
            </DialogContainer>
        </>
    );
};

export default WorkspaceStatuses;