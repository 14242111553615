import axios, { AxiosResponse } from 'axios'
import {
    IApiHandlerParams,
    IApiMethods,
    IRequestConfig,
    TApiResponse,
    TRequestMethods,
} from './interface'
import {
    activateLoading,
    getFullConfig,
    getRequestUrl,
    handleErrorResponse,
    hideLoading,
    requestHandler,
} from './handler'
import './requester-setup'

const handleRequest = async (
    promise: Promise<AxiosResponse<TApiResponse>>,
    config?: IApiHandlerParams
): Promise<TApiResponse> => {
    try {
        if (config?.delay) {
            await new Promise((resolve) => setTimeout(resolve, config.delay))
        }

        const response = await promise;

        return requestHandler(response, config)
    } catch (error: any) {
        return handleErrorResponse(config, error)
    } finally {
        hideLoading(config)
    }
}

const getAxiosResponseWithMockData = async (
    data: TApiResponse
): Promise<AxiosResponse<TApiResponse>> => {
    const response: AxiosResponse<TApiResponse> = {
        data,
        status: 200,
        statusText: 'OK',
        headers: {},
        config: {},
    } as AxiosResponse<TApiResponse> // Type assertion
    return Promise.resolve(response)
}

export const transformData = (payload: any): FormData => {
    const formData = new FormData()

    for (const prop in payload) {
        if (Array.isArray(payload[prop])) {
            payload[prop].forEach((_prop: any, index: number) => {
                formData.append(`${prop}[${index}]`, _prop);
            })
        } else {
            formData.append(prop, payload[prop]);
        }
    }

    return formData
}

const apiMethods: IApiMethods = {
    get: function (
        endpoint: string,
        config?: IRequestConfig
    ): Promise<TApiResponse> {
        return this.factory('get', endpoint, config)()
    },

    post: function (
        endpoint: string,
        body?: any,
        config?: IRequestConfig
    ): Promise<TApiResponse> {
        return this.factory('post', endpoint, config, body)()
    },

    put: function (
        endpoint: string,
        body?: any,
        config?: IRequestConfig
    ): Promise<TApiResponse> {
        return this.factory('put', endpoint, config, body)()
    },

    patch: function (
        endpoint: string,
        body?: any,
        config?: IRequestConfig
    ): Promise<TApiResponse> {
        return this.factory('patch', endpoint, config, body)()
    },

    delete: function (
        endpoint: string,
        config?: IRequestConfig
    ): Promise<TApiResponse> {
        return this.factory('delete', endpoint, config)()
    },

    factory: function (
        method: TRequestMethods,
        endpoint: string,
        config?: IRequestConfig,
        body?: any
    ): () => Promise<TApiResponse> {
        const params: any[] = [getRequestUrl(config) + endpoint]

        const bodyUpdate = config?.handler?.formData
            ? transformData(body)
            : body

        if (body) params.push(bodyUpdate)
        if (config?.request) params.push(config.request)

        return function (this: typeof apiMethods) {
            return this.request(
                method,
                params,
                config?.handler as any,
                config?.mock
            )
        }.bind(this)
    },

    request: (
        method: TRequestMethods,
        params: any[],
        config?: IApiHandlerParams,
        mock?: any
    ): Promise<TApiResponse> => {
        activateLoading(config)

        if (mock) {
            console.table({
                MOCK_REQUEST: {
                    URL: params[0],
                    REQUEST: params[1],
                    RESPONSE: mock,
                    METHOD: method,
                },
            })
        }

        const axiosMethod = axios[method] as (
            ...args: any[]
        ) => Promise<AxiosResponse<TApiResponse>>

        if (config) params.push(config)

        return handleRequest(
            mock ? getAxiosResponseWithMockData(mock) : axiosMethod(...params),
            getFullConfig(config)
        )
    },
}

export default apiMethods
