import { IApiHandlerParams } from "../../../interface";
import api from "../../../index";

export const $getUploadStatus = (
    params?: {
        task_id: string;
    },
    handler?: IApiHandlerParams
): Promise<any> =>
    api.get("backend/v1/workspaces/files/upload-status", {
        // mock: require('./mocks/automation.staff.list.json'),
        request: { params },
        handler
    });

export const $getDownloadErrorReport = (
    params?: any,
    handler?: IApiHandlerParams
): Promise<any> =>
    api.get("backend/v1/workspaces/files/download-error-report", {
        // mock: require('./mocks/automation.staff.list.json'),
        request: { params },
        handler
    });

export const $uploadFiles = (
    url: string,
    data: any,
    handler?: IApiHandlerParams
): Promise<any> =>
    api.post(`${url}`, data, {
        // mock: require('./mocks/complete.form.calculate.json'),
        handler
    });