import { ArrowExitIcon, HeartIcon } from "../../../img";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { $getEmployees } from "../../../api/requests/employees";
import { $getFavoritesNews } from "../../../api/requests/news";
import { isMd } from "../../../utils";

interface IEmployeesContent {
    id: number,
    name: string,
    text: string,
    position: string,
    image: string,
    isLiked: boolean,
    number: string,
    email: string,
    birth: string,
    skills: any,
    leader: any,
    replacer: any,
}

export const FavoritesEmployeesPage = () => {
    const navigate = useNavigate();
    const isMobile = isMd()

    const [ employeesData, setEmployeesData ] = useState<IEmployeesContent[]>([]);

    const handleNavigation = () => {
        navigate(-1);
    };

    const handleLike = (id: number) => {
        const updatedList: any = employeesData.map((item) => {
            if (item.id === id) {
                return {
                    ...item,
                    isLiked: !item.isLiked
                }
            } else {
                return item
            }
        })

        setEmployeesData(updatedList)
    };

    useEffect(() => {
        $getEmployees().then(res => {
                if (!res.data) return;

                // const updatedList = res.data.filter((item: any) => item.isLiked)
                setEmployeesData(res.data)
            }
        )
    }, [])

    return (
        <div className='favorites-employees'>
            <div className='layout-inner__header profile-header'>
                { !isMobile && (
                    <div className='layout-inner__header__title'>
                        <div className='header__exitIcon' onClick={ handleNavigation }>
                            <ArrowExitIcon />
                        </div>
                        <h1>Сотрудники</h1>
                    </div>
                ) }
            </div>

            <div className='favorites-employees-content'>
                { employeesData.map((
                    item,
                    idx
                ) => (
                    <div className='employees-block' key={ idx }>
                        <div className='employees-block__top'>
                            <img className='employees-block__image' src={ item?.image } alt="" />

                            <div
                                onClick={ () => {
                                    handleLike(item.id)
                                } }
                                className={ `employees-block__likeIcon ${ item.isLiked ? 'active' : '' }` }
                            >
                                <HeartIcon />
                            </div>
                        </div>

                        <div className='employees-block__content' onClick={ () => {
                            // setDialog(true)
                            // setActivePerson({...item})
                        } }>
                            <h1 className='employees-block__title'>{ item?.name }</h1>

                            <p className='employees-block__text'>{ item?.text }</p>

                            <p className='employees-block__position'>{ item?.position }</p>
                        </div>
                    </div>
                )) }
            </div>
        </div>
    )
}