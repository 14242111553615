import { IApiHandlerParams } from "../../interface";
import api from "../../../api";
import {
    GetGroupCreateResponse,
    GetGroupResponse,
    GroupData,
    JoinGroupData
} from "./interface";
import { BirthdayResponseData } from "../main/interface";

export const $getGroupList = (
    handler?: IApiHandlerParams
): Promise<GetGroupResponse[]> => api
.post("api/groups/list", null, {
    handler
});

export const $deleteGroupById = (
    data: { id: string },
    handler?: IApiHandlerParams
): Promise<boolean> => api
.post(`api/groups/delete`, data, {
    // mock: require("./mocks/news.item.json"),
    handler
});

export const $createGroup = (
    data: { title: string },
    handler?: IApiHandlerParams
): Promise<GetGroupCreateResponse> => api
.post(`api/groups/create`, data, {
    // mock: require("./mocks/news.item.json"),
    handler
});

export const $editGroup = (
    data: GroupData,
    handler?: IApiHandlerParams
): Promise<any> => api
.post(`api/groups/edit`, data, {
    // mock: require("./mocks/news.item.json"),
    handler
});

export const $joinGroup = (
    data: JoinGroupData,
    handler?: IApiHandlerParams
): Promise<BirthdayResponseData> => api
.post(`api/groups/join`, data, {
    // mock: require("./mocks/news.item.json"),
    handler
});

export const $leaveGroup = (
    data: { id: string },
    handler?: IApiHandlerParams
): Promise<boolean> => api
.post(`api/groups/leave`, data, {
    // mock: require("./mocks/news.item.json"),
    handler
});

export const $inviteGroup = (
    data: { id: string },
    handler?: IApiHandlerParams
): Promise<any> => api
.post(`api/groups/invite`, data, {
    // mock: require("./mocks/news.item.json"),
    handler
});

export const $showGroupById = (
    data: { id: string },
    handler?: IApiHandlerParams
): Promise<any> => api
.post(`api/groups/view`, data, {
    // mock: require("./mocks/news.item.json"),
    handler
});
