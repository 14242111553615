import TextField from "../../components/ui/form/Fields/TextField";
import {
    BlogIcon,
    CloseIcon,
    CoinCompetitionIcon, ContestIcon, InfoIcon, InterviewsIcon,
    NewsIcon,
    PenIcon,
    PlusIcon2,
    SettingIcon
} from "../../img";
import { Controller, useForm } from "react-hook-form";
import Dropdown from "../../components/ui/form/Dropdown/Dropdown";
import React, { useEffect, useState } from "react";
import ToggleBlock from "../../components/ui/form/Toggle/ToggleBlock";
import Button from "../../components/ui/form/Buttons/Button";
import {
    $createAutomation,
    $getAutomation,
    $getAutomationStaffList
} from "../../api/requests/workspace-settings/automation";
import {
    WorkspaceAutomationCreateData
} from "../../api/requests/workspace-settings/automation/interface";
import { successNotify } from "../../utils";
import WorkspaceStatuses from "../../components/workspace-users/WorkspaceStatuses";
import CheckIcon from "../../img/icons/vacation/CheckIcon";
import { StateData } from "./groups/WorkspaceSettingGroupModules";
import { $editGroup } from "../../api/requests/groups";
import CountField from "../../components/ui/form/Fields/CountField";
import { ClipIcon } from "../../img/icons/ClipIcon";
import FontAwesome from "../../components/ui/font-awesome/FontAwesome";
import { ProfileQrCodeSmallIcon } from "../../img/icons/layout-components";
import RadioBlock, { ListRadioData } from "../../components/ui/form/Toggle/RadioBlock";
import WorkspaceSettingsUserUpload
    from "../../components/workspace-users/general/WorkspaceSettingsUserUpload";
import { useSelector } from "react-redux";
import { getProfileVarsData } from "../../redux";

interface FormData {
    title: string;
    status: string;
    main_group_title: string;
    count?: number;
    no_system_news: number;
    groups_first_then_news: string;
    qr_code_enabled: number;
}

const WorkspaceSettingAll = () => {
    const vars = useSelector(getProfileVarsData);

    const dayTypes = [
        {
            value: "every_week",
            label: "Eженедельно",
        },
        {
            value: "every_month",
            label: "Eжемесячно",
        },
        {
            value: "every_quarter",
            label: "Eжеквартально",
        }
    ];

    const [whoList, setWhoList] = useState<any[]>([]);
    const [editData, setEditData] = useState<any>(null);
    const [search, setSearch] = useState("");

    const [modulesList, setModulesList] = useState<StateData[]>([
        {
            name: "Новости",
            url: "/news",
            icon: <NewsIcon />,
            key: "news",
            value: 0,
        },
        {
            name: "Информация",
            url: "/pages",
            icon: <InfoIcon />,
            key: "pages",
            value: 0,
        },
        {
            name: "Конкурсы",
            url: "/contests",
            icon: <ContestIcon />,
            key: "contests",
            value: 0,
        },
        {
            name: "Опросы",
            url: "/interviews",
            icon: <InterviewsIcon />,
            key: "interviews",
            value: 0,
        },
        {
            name: "Блог",
            url: "/posts",
            icon: <BlogIcon />,
            key: "posts",
            value: 0,
        },
    ]);

    const [socials, setSocials] = useState([
        {
            social: 'vk',
            name: 'Вконтакте',
            url: '',
            defaultUrl: 'http://vk.com/',
        },
        {
            social: 'telegram',
            name: 'Телеграмм',
            url: '',
            defaultUrl: 'https://telegram.org/',
        },
        {
            social: 'instagram',
            name: 'Инстаграмм',
            url: '',
            defaultUrl: 'https://instagram.com/',
        },
        {
            social: 'facebook',
            name: 'Фейсбук',
            url: '',
            defaultUrl: 'http://facebook.com/',
        },
    ]);

    const [isUploadingFile, setIsUploadingFile] = useState(false);

    const [radioNewsList, setRadioNewsList] = useState<ListRadioData[]>([
        {
            key: "groups",
            desc: "Группы перед блоком Новости"
        },
        {
            key: "news",
            desc: "Новости перед блоком Группы"
        },
    ])

    const {
        handleSubmit,
        control,
    } = useForm<FormData>(
        {
            values: {
                title: editData?.title,
                status: editData?.status,
                main_group_title: editData?.main_group_title,
                count: editData?.count,
                no_system_news: editData?.no_system_news,
                groups_first_then_news: editData?.groups_first_then_news,
                qr_code_enabled: editData?.qr_code_enabled,
            }
        }
    );

    const getValue = (
        opts: { label: string; value: string | number }[],
        val: number | string | undefined
    ) => {
        if (val) {
            return opts.filter((o) => o.value === val);
        }
        return null;
    };

    useEffect(() => {
        init();
    }, []);

    function init() {
        // $getAutomation({ newApi: true }).then(res => {
        //     if (res.error || res.code === "ERR_BAD_RESPONSE") return;
        //
        //     setEditData((prev: any) => ({
        //         ...prev,
        //         ...res.data
        //     }));
        // })
    }

    function onSubmit(data: FormData) {
        const requestData: any = {
            ...data,
            ws_id: editData.ws_id,
        };

        // $createAutomation(requestData, { newApi: true }).then(res => {
        //     if (res.error || res.code === "ERR_BAD_RESPONSE") return;
        //
        //     successNotify("Успешно сохранено");
        // })
    }

    function changeCheckboxes(select: any) {
        const updateList = [...modulesList].map(item => {
            if (item.key === select.key) return {
                ...item,
                value: select.value ? 0 : 1
            }

            return item
        });

        const requestData: any = {};

        setModulesList(updateList);
        // $editGroup(requestData, { formData: true }).then(res => {
        //     setState(updateList);
        // });
    }
    
    function handleEditSocModal(select: any) {
        console.log(select);
    }

    return (
        <div className={"workspace-settings-users__field"}>
            <div className={"workspace-settings-users__field-block"}>
                <form
                    className={"workspace-settings-users__accrual w-100"}
                    onSubmit={handleSubmit(onSubmit)}
                >
                    <div
                        className={"workspace-settings-users__accrual-block workspace-settings__setting"}>
                        <h3>Название и тип</h3>

                        <Controller
                            name="title"
                            control={control}
                            rules={{
                                required: {
                                    value: true,
                                    message: "Введите название"
                                }
                            }}
                            render={({ field: { onChange, value } }) => (
                                <TextField
                                    value={value}
                                    className="mb-3"
                                    title="Название организации"
                                    placeholder={"Введите название"}
                                    name="title"
                                    onChange={onChange}
                                />
                            )}
                        />

                        <Controller
                            name="status"
                            control={control}
                            rules={{
                                required: {
                                    value: true,
                                    message: "Выберете доступ"
                                }
                            }}
                            render={({ field: { onChange, value } }) => (
                                <Dropdown
                                    className={"form-select mb-3"}
                                    label={"Доступ"}
                                    placeholder={"Вручную"}
                                    options={dayTypes}
                                    value={getValue(dayTypes, value)}
                                    onChange={(select) => onChange(select.value)}
                                    isSearchable
                                />
                            )}
                        />

                        <Controller
                            name="main_group_title"
                            control={control}
                            rules={{
                                required: {
                                    value: true,
                                    message: "Введите название"
                                }
                            }}
                            render={({ field: { onChange, value } }) => (
                                <TextField
                                    value={value}
                                    className="mb-3"
                                    title="Название общей группы"
                                    placeholder={"Введите название"}
                                    name="main_group_title"
                                    onChange={onChange}
                                />
                            )}
                        />
                    </div>

                    <hr />

                    <div className={"workspace-settings-users__accrual-block"}>
                        <h3>Статусы</h3>

                        <WorkspaceStatuses />
                    </div>

                    <hr />

                    <div
                        className={"workspace-settings-users__accrual-block workspace-settings__setting"}>
                        <h3>Модули</h3>

                        <div className={"workspace-settings__modules"}>
                            {modulesList.map((item, index) => (
                                <div
                                    key={`${item.key}-item-${index}`}
                                    className={"drag-and-drop__block workspace-settings__modules-block"}
                                >
                                    <div className={"w-100 justify-content-between"}>
                                        <div>
                                            {item.icon}

                                            <p>{item.name}</p>
                                        </div>

                                        <ToggleBlock
                                            className={"gap-0"}
                                            desc={""}
                                            isChecked={!!item.value}
                                            handleChange={() => changeCheckboxes(item)}
                                        />
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>

                    <hr />

                    <div className={"workspace-settings-users__accrual-block"}>
                        <h3>Адаптация</h3>

                        <div className={"d-flex gap-5 my-3"}>
                            <Controller
                                name="count"
                                control={control}
                                rules={{
                                    required: {
                                        value: false,
                                        message: ""
                                    }
                                }}
                                render={({ field: { onChange, value } }) => (
                                    <CountField
                                        value={value}
                                        label="Отображение ссылки"
                                        onChange={onChange}
                                        disabled
                                    />
                                )}
                            />

                            <Controller
                                name="count"
                                control={control}
                                rules={{
                                    required: {
                                        value: true,
                                        message: "Выберете"
                                    }
                                }}
                                render={({ field: { onChange, value } }) => (
                                    <Dropdown
                                        className={"form-select"}
                                        label={"Периодичность"}
                                        options={whoList}
                                        value={getValue(whoList, value)}
                                        onChange={(select) => onChange(select.value)}
                                        // onInputChange={(value) => setSearch(value)}
                                        isSearchable
                                    />
                                )}
                            />
                        </div>

                        <Controller
                            name="title"
                            control={control}
                            rules={{
                                required: {
                                    value: true,
                                    message: "Введите название"
                                }
                            }}
                            render={({ field: { onChange, value } }) => (
                                <TextField
                                    value={value}
                                    className="mt-1"
                                    title="Ссылка на источник"
                                    prepend={<ClipIcon />}
                                    placeholder={"Введите ссылку"}
                                    name="points"
                                    onChange={onChange}
                                />
                            )}
                        />
                    </div>

                    <hr />

                    <div
                        className={"workspace-settings-users__accrual-block workspace-settings__setting"}
                    >
                        <h3>Отображение системных новостей</h3>

                        <div className={"workspace-settings__modules"}>
                            <Controller
                                name="no_system_news"
                                control={control}
                                rules={{
                                    required: {
                                        value: false,
                                        message: ""
                                    }
                                }}
                                render={({ field: { onChange, value } }) => (
                                    <ToggleBlock
                                        isChecked={!!value}
                                        desc="Не показывать системные новости"
                                        handleChange={(value) => onChange(value ? 1 : 0)}
                                    />
                                )}
                            />
                        </div>
                    </div>

                    <hr />

                    <div
                        className={"workspace-settings-users__accrual-block workspace-settings__setting"}
                    >
                        <h3>Отображение блоков на главной</h3>

                        <div className={"workspace-settings__modules"}>
                            <Controller
                                name="groups_first_then_news"
                                control={control}
                                rules={{
                                    required: {
                                        value: false,
                                        message: ""
                                    }
                                }}
                                render={({ field: { onChange, value } }) => (
                                    <RadioBlock
                                        list={radioNewsList}
                                        isChecked={value}
                                        handleChange={onChange}
                                    />
                                )}
                            />
                        </div>
                    </div>

                    <hr />

                    <div
                        className={"workspace-settings-users__accrual-block workspace-settings__setting"}>
                        <h3>Настройка соц. сетей в футере</h3>

                        <div className={"workspace-settings__modules"}>
                            {socials.map((item, index) => (
                                <div
                                    key={`${item.social}-item-${index}`}
                                    className={"drag-and-drop__block workspace-settings__modules-block"}
                                >
                                    <div className={"w-100 justify-content-between"}>
                                        <div>
                                            <FontAwesome iconName={item.social} />

                                            <p>{item.name}</p>
                                        </div>

                                        <div>
                                            <div
                                                className={"setting"}
                                                onClick={() => handleEditSocModal(item)}
                                            >
                                                <SettingIcon color={"#00A7B5"} />
                                            </div>

                                            <ToggleBlock
                                                className={"gap-0"}
                                                desc={""}
                                                isChecked={!!item.social}
                                                handleChange={() => changeCheckboxes(item)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>

                    <hr />

                    <div
                        className={"workspace-settings-users__accrual-block workspace-settings__setting"}
                    >
                        <h3>Настройка кнопок приложения в футере</h3>

                        <div className={"workspace-settings__modules"}>
                            <Controller
                                name="qr_code_enabled"
                                control={control}
                                rules={{
                                    required: {
                                        value: false,
                                        message: ""
                                    }
                                }}
                                render={({ field: { onChange, value } }) => (
                                    <ToggleBlock
                                        imgLeft={<ProfileQrCodeSmallIcon />}
                                        isChecked={!!value}
                                        desc="QR Code"
                                        handleChange={(value) => onChange(value ? 1 : 0)}
                                    />
                                )}
                            />
                        </div>
                    </div>

                    <hr />

                    <div className={"workspace-settings-users__accrual-block"}>
                        <h3>Загрузка пользователей</h3>

                        <WorkspaceSettingsUserUpload
                            colorScheme={vars}
                            uploadFileUrl={"backend/v1/workspaces/users/import"}
                            downloadFileUrl={"api/workspaces/download_new_ws_users"}
                            typeDoc={"users"}
                            setIsUploadingFile={setIsUploadingFile}
                            isUploadingFile={isUploadingFile}
                        />
                    </div>

                    <hr />

                    <div className={"workspace-settings-users__accrual-block"}>
                        <h3>Загрузка баллов и монет</h3>

                        <WorkspaceSettingsUserUpload
                            colorScheme={vars}
                            uploadFileUrl={"backend/v1/workspaces/users/import-balances-points"}
                            secondDownloadUrl={"backend/v1/workspaces/users/import-balances-points-add"}
                            downloadFileUrl={"api/workspaces/download_ws_users"}
                            typeDoc={"balances_points"}
                            textBtnName={"Заменить документ"}
                            setIsUploadingFile={setIsUploadingFile}
                            isUploadingFile={isUploadingFile}
                        />
                    </div>

                    <hr />

                    <div className={"workspace-settings-users__accrual-toggle d-flex gap-3 mt-4"}>
                        {/*<Button*/}
                        {/*    text={"Отмена"}*/}
                        {/*    className={"btn btn-light w-100"}*/}
                        {/*    // onClick={() => handleRemoveBlock(item, index)}*/}
                        {/*/>*/}
                        <Button
                            type={"submit"}
                            text={"Сохранить"}
                            className={"btn btn-primary w-100"}
                        />
                    </div>
                </form>
            </div>
        </div>
    );
};

export default WorkspaceSettingAll;