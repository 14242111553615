import { useState } from "react";
import { CalendarIcon, SliderBlogIcon, SliderFeedIcon, SliderServicesIcon } from "../../img";
import { SliderFlameIcon } from "../../img/icons/slider/SliderFlameIcon";
import { PercentageIcon } from "../../img/icons/PercentageIcon";
import { ShopIcon } from "../../img/icons/ShopIcon";
import { useNavigate } from "react-router-dom";
import { $storeToken } from "../../api/requests/auth";
import redirectUrl from "../../utils/redirect/redirectUrl";

interface HorizonNavigateProps {
    isScroll?: boolean;
}

const HorizonNavigate = (
    {
        isScroll = false
    }: HorizonNavigateProps
) => {
    const navigate = useNavigate();

    const [sliderRouters, setSliderRouters] = useState([
        {
            id: 0,
            title: "Лента",
            route: "/",
            icon: <SliderFeedIcon />
        },
        {
            id: 3,
            title: "Календарь",
            route: "/calendar",
            icon: <CalendarIcon color={"currentColor"} />
        },
        {
            id: 1,
            title: "Спорт",
            route: "/sport",
            icon: <SliderFlameIcon color={"currentColor"} />
        },
        {
            id: 10,
            title: "Блог",
            route: "/blogs",
            icon: <SliderBlogIcon />
        },
        // {
        //     id: 4,
        //     title: "Витрина скидок",
        //     route: "/discount",
        //     icon: <PercentageIcon color={"currentColor"} opacity={1} />
        // },
        {
            id: 5,
            title: "Корп. магазин",
            route: "cstore-itelma-dev.svoi.club",
            icon: <ShopIcon color={"currentColor"} opacity={1} />
        },
        {
            id: 6,
            title: "Все сервисы",
            route: "/services",
            icon: <SliderServicesIcon />
        }
    ]);

    function fetchTokenForStore() {
        $storeToken({ newApi: true }).then(res => {
            if (!res) return;

            let token = res?.data?.token;

            redirectUrl(`https://cstore-itelma-dev.svoi.club/auth/${token}`);
        });
    }

    return (
        <div className={`navigation ${isScroll ? "scrolled" : ""}`}>
            {sliderRouters.map((
                router,
                idx
            ) => (
                <div
                    key={idx}
                    className={`navigation__router ${location.pathname === router.route ? "active" : ""}`}
                    onClick={() => {
                        if (router.id == 5) {
                            fetchTokenForStore();
                        } else {
                            navigate(router.route);
                        }
                    }}
                >
                    {router?.icon}

                    <p>{router.title}</p>
                </div>
            ))}
        </div>
    );
};

export default HorizonNavigate;