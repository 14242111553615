import { IApiHandlerParams } from "../../../interface";
import api from "../../../index";

export const $getMapBookingBuildingData = (
    id: number,
    params?: any,
    handler?: IApiHandlerParams
): Promise<any> =>
    api.get(`api/v1/booking/available_cities/${id}/buildings`, {
        request: {
            params
        },
        handler: {
            ...handler,
            newApi: true,
        }
    });

export const $mapBookingCreate = (
    data: any,
    handler?: IApiHandlerParams
): Promise<any> =>
    api.post(`api/v1/booking/workplaces`, data, {
        // mock: require('./mocks/vacation.booking.json'),
        handler: {
            ...handler,
            newApi: true,
        }
    });

export const $mapBookingEdit = (
    id: string,
    data: any,
    handler?: IApiHandlerParams
): Promise<any> =>
    api.put(`api/v1/booking/workplaces/${id}`, data, {
        // mock: require('./mocks/vacation.booking.json'),
        handler: {
            ...handler,
            newApi: true,
        }
    });

export const $mapBookingDelete = (
    id: string,
    handler?: IApiHandlerParams
): Promise<any> =>
    api.delete(`api/v1/booking/workplaces/${id}`, {
        // mock: require('./mocks/vacation.booking.json'),
        handler: {
            ...handler,
            newApi: true,
        }
    });

