import { FC } from "react";
import { IBaseIconProps } from "./types";

export const AirplaneIcon: FC<IBaseIconProps> = (
    {
        size = 37,
        color = '#00A7B5'
    }) => {
    return (
        <svg width={size} height={size} viewBox="0 0 37 37" fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path
                d="M16 7.25V14.2512C16 14.5615 15.8401 14.8499 15.577 15.0144L4.423 21.9855C4.15986 22.1501 4 22.4386 4 22.7488V24.0972C4 24.6828 4.55025 25.1125 5.11828 24.9704L14.8817 22.5296C15.4498 22.3876 16 22.8172 16 23.4028V27.8415C16 28.1012 15.888 28.3481 15.6927 28.519L12.4522 31.3542C11.7648 31.9559 12.3071 33.0806 13.2059 32.9171L18.0891 32.0293C18.1954 32.0099 18.3046 32.0099 18.4109 32.0293L23.2942 32.9171C24.1928 33.0806 24.7353 31.9559 24.0478 31.3542L20.8073 28.519C20.612 28.3481 20.5 28.1012 20.5 27.8415V23.4028C20.5 22.8172 21.0502 22.3876 21.6182 22.5296L31.3817 24.9704C31.9498 25.1125 32.5 24.6828 32.5 24.0972V22.7488C32.5 22.4386 32.3401 22.1501 32.077 21.9855L20.923 15.0144C20.6599 14.8499 20.5 14.5615 20.5 14.2512V7.25C20.5 6.00736 19.4926 5 18.25 5C17.0074 5 16 6.00736 16 7.25Z"
                stroke={color} strokeWidth="3" stroke-linecap="round" strokeLinejoin="round" />
        </svg>
    );
};