export const CheckboxIcon = ({
    color = '#00A7B5',
    size = 24,
}) => (
    <svg
        width={size}
        height={size}
        viewBox="0 0 25 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M15.8085 9.98299L11.5415 14.25L10.087 12.7955M12.6665 3C7.69594 3 3.6665 7.02944 3.6665 12C3.6665 16.9706 7.69594 21 12.6665 21C17.6371 21 21.6665 16.9706 21.6665 12C21.6665 7.02944 17.6371 3 12.6665 3Z"
            stroke={color}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
)
