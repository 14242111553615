import { IApiHandlerParams } from "../../interface";
import api from "../../../api";
import {
    GetNewsItemResponseData,
    IGetNewsItem,
    NewsCreateRequestData,
    NewsItemResponseData
} from "./interface";

export const $getFavoritesNews = (
    handler?: IApiHandlerParams
): Promise<any> => api
.get(`api/v1/vacations/news`, {
    mock: require("./mocks/favorites.news.json"),
    handler
});

export const $getNewsList = (
    data: any,
    handler?: IApiHandlerParams
): Promise<any> => api
.post("api/news/listnews", data, {
    handler
});

export const $getNewsItem = (
    id: {id: number | string},
    handler?: IApiHandlerParams
): Promise<IGetNewsItem> => api
.post("api/news/viewnews", id, {
    handler
});

export const $getNewsById = (
    data: { id: string },
    handler?: IApiHandlerParams
): Promise<NewsItemResponseData> => api
.post(`api/news/viewnews`, data, {
    // mock: require("./mocks/news.item.json"),
    handler
});

export const $createNews = (
    data: NewsCreateRequestData,
    handler?: IApiHandlerParams
): Promise<GetNewsItemResponseData> => api
.post("api/news/createnews", data, {
    handler
});

export const $editNews = (
    data: NewsCreateRequestData,
    handler?: IApiHandlerParams
): Promise<GetNewsItemResponseData> => api
.post("api/news/editnews", data, {
    handler
});

export const $deleteNews = (
    data: { id: string },
    handler?: IApiHandlerParams
): Promise<GetNewsItemResponseData> => api
.post("api/news/deletenews", data, {
    handler
});

export const $createNewsCategories = (
    data: any,
    handler?: IApiHandlerParams
): Promise<any> => api
.post("api/news/createcategory", data, {
    handler
});

export const $editNewsCategories = (
    data: any,
    handler?: IApiHandlerParams
): Promise<any> => api
.post("api/news/editcategory", data, {
    handler
});

export const $deleteNewsCategory = (
    data: { id: string },
    handler?: IApiHandlerParams
): Promise<any> => api
.post("api/news/deletecategory", data, {
    handler
});

export const $getListCategories = (
    handler?: IApiHandlerParams
): Promise<any> => api
.post("api/news/listcategory", null, {
    handler
});

export const $writeNewsLogs = (
    data: any,
    handler?: IApiHandlerParams
): Promise<any> => api
.post('api/v1/news/log', data,{
    // mock: require('./mocks/complete.form.calculate.json'),
    handler
})

export const $newsCustomLike = (
    data: any,
    handler?: IApiHandlerParams
): Promise<any> => api
.post('api/news/newsCustomLike', data,{
    // mock: require('./mocks/complete.form.calculate.json'),
    handler
})

export const $newsLike = (
    data: any,
    handler?: IApiHandlerParams
): Promise<any> => api
.post('api/news/newsLike', data,{
    // mock: require('./mocks/complete.form.calculate.json'),
    handler
})