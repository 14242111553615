export const UsersProfilesIcon = () => {
    return (
        <svg width="41" height="40" viewBox="0 0 41 40" fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path
                d="M4.49976 30.3614C6.33083 27.3072 10.3439 25.2169 16.6049 25.2169C22.866 25.2169 26.8791 27.3072 28.7101 30.3614M29.8685 22.9009C33.1841 24.5587 34.8419 26.2166 36.4998 29.5322M26.4201 10.2293C27.9802 11.0686 29.0408 12.7165 29.0408 14.6122C29.0408 16.4531 28.0406 18.0603 26.5541 18.9203M21.5784 14.6121C21.5784 17.3589 19.3517 19.5856 16.6049 19.5856C13.8582 19.5856 11.6315 17.3589 11.6315 14.6121C11.6315 11.8654 13.8582 9.63867 16.6049 9.63867C19.3517 9.63867 21.5784 11.8654 21.5784 14.6121Z"
                stroke="#007BFF" strokeWidth="3.33333" strokeLinecap="round" />
        </svg>
    );
};
