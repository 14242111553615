import { FC } from "react";
import { IBaseIconProps } from "../types";

export const ServicesBookingIcon: FC<IBaseIconProps> = (
    {
        size = 36,
        color = "#00A7B5"
    }) => {
    return (
        <svg width={size} height={size} viewBox="0 0 36 36" fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path
                d="M10.8016 17.1V15.3M18.0016 17.1V13.5M25.2016 17.1V9.9M14.4016 24.3L11.7016 31.5M24.892 31.4L21.71 24.3997M7.20156 24.3C5.21333 24.3 3.60156 22.6882 3.60156 20.7V8.1C3.60156 6.11177 5.21334 4.5 7.20156 4.5H28.8016C30.7898 4.5 32.4016 6.11177 32.4016 8.1V20.7C32.4016 22.6882 30.7898 24.3 28.8016 24.3H7.20156Z"
                stroke={color} strokeWidth="3" stroke-linecap="round" strokeLinejoin="round" />
        </svg>
    );
};