export default function downloadExel(file: string, fileName: string) {
  const blob = new Blob([file], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8',
  });
  const aEle = document.createElement('a'); // Create a label
  const href = window.URL.createObjectURL(blob); // Create downloaded link
  aEle.href = href;
  aEle.download = `${fileName}.xlsx`; // File name after download
  document.body.appendChild(aEle);
  aEle.click(); // Click to download
  document.body.removeChild(aEle); // Download complete remove element
  window.URL.revokeObjectURL(href); // Release blob object
}
