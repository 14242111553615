import React, { useMemo, useState } from "react";
import { ArrowExitIcon, SearchIcon } from "../../../img";
import { withDebounce } from "../../../functions";
import TextField from "../../../components/ui/form/Fields/TextField";
import Button from "../../../components/ui/form/Buttons/Button";
import ContestsViewWinnerWin from "./ContestsViewWinnerWin";
import ContestsViewWinnerExclude from "./ContestsViewWinnerExclude";
import UserSelectListModal, {
    BtnDataItem, UserData
} from "../../../components/ui/dialog/UserSelectListModal";

interface ContestsViewWinnerProps {
    data: any;
    onBack: () => void;
    onClose: () => void;
}

const ContestsViewWinner = (
    {
        data,
        onBack,
        onClose,
    }: ContestsViewWinnerProps
) => {

    const [departmentItems, setDepartmentItems] = useState<UserData[]>([
        {
            id: 1,
            image: "/testImages/people/Image-1.png",
            name: "Краюшкин Александр Витальевич",
            position: "Руководитель",
            icon: "/testImages/statuses/statuses-1.png",
            department: "Департамент испытания и сертификации"
        },
        {
            id: 2,
            image: "/testImages/people/Image-2.png",
            name: "Смирнова Виалета Михайловна",
            position: "HR менеджер",
            icon: "/testImages/statuses/statuses-2.png",
            department: "Департамент испытания и сертификации"
        },
        {
            id: 3,
            image: "/testImages/people/Image-3.png",
            name: "Смирнова Виалета Михайловна",
            position: "HR менеджер",
            icon: "/testImages/statuses/statuses-3.png",
            department: "Департамент испытания и сертификации"
        },
        {
            id: 4,
            image: "/testImages/people/Image-4.png",
            name: "Смирнова Виалета Михайловна",
            position: "HR менеджер",
            icon: "/testImages/statuses/statuses-1.png",
            department: "Департамент испытания и сертификации"
        },
        {
            id: 5,
            image: "/testImages/people/Image-5.png",
            name: "Смирнова Виалета Михайловна",
            position: "HR менеджер",
            icon: "/testImages/statuses/statuses-2.png",
            department: "Департамент испытания и сертификации"
        }
    ]);
    const [filterUserList, setFilterUserList] = useState(departmentItems);

    const [selectedReplacer, setSelectedReplacer] = useState<number[]>([]);
    const [searchValue, setSearchValue] = useState<string>("");

    const [statusShow, setStatusShow] = useState("start");

    const [btnList, setBtnList] = useState([
        {
            key: 1,
            text: "Назначить победителя",
            className: "btn-primary w-100"
        },
        {
            key: 2,
            text: "Исключить",
            className: "btn-red w-100"
        }
    ])

    const updateData = useMemo(() => {
        const selectUsers = filterUserList.filter((item, idx) => selectedReplacer.includes(idx))

        return {
            ...data,
            selectUsersList: selectUsers
        }
    }, [selectedReplacer])

    function handleBack() {
        onBack();
    }

    function onChangeSearch(value: string) {
        setSearchValue(value);

        const updateList = [...departmentItems].filter(item => item.name.toLowerCase().includes(value.toLowerCase()));

        setFilterUserList(updateList);
    }

    function handleWinner() {
        setStatusShow("winner")
    }

    function handleSlice() {
        setStatusShow("start")
        const updateList = [...filterUserList].filter((item, idx) => !selectedReplacer.includes(idx));

        setFilterUserList(updateList);
        setSelectedReplacer([]);
    }

    function handleChangeList(btnItem: BtnDataItem | null, updateList: number[]) {
        setSelectedReplacer(updateList);

        if (btnItem?.key === 1) handleWinner()
        if (btnItem?.key === 2) setStatusShow("exclude")
    }

    if (statusShow === "winner") return (
        <ContestsViewWinnerWin
            onBack={() => setStatusShow("start")}
            data={updateData}
        />
    )

    if (statusShow === "exclude") return (
        <ContestsViewWinnerExclude
            data={updateData}
            onBack={() => setStatusShow("start")}
            onExclude={handleSlice}
            onClose={onClose}
        />
    )

    return (
        <UserSelectListModal
            list={departmentItems}
            btnList={btnList}
            title={"Записать участника"}
            onClose={handleChangeList}
        />
    )
};

export default ContestsViewWinner;