import axios from 'axios'
import { HOST_URL } from './config'
import Cookies from 'js-cookie'
import { store } from "../redux";
// import store from '../store';

axios.defaults.baseURL = HOST_URL

axios.defaults.headers['Content-Type'] = 'application/json'

axios.interceptors.request.use((config: any) => {
    const accessToken = Cookies.get('token') || localStorage.getItem("token") || store.getState()?.profile?.token;

    if (accessToken && !config?.dontAuth) {
        config.headers['Authorization'] = 'Bearer ' + accessToken
    }

    if (config?.formData) {
        config.headers['Content-Type'] = 'multipart/form-data'
    }

    if (!config.data) config.data = {}

    return config
},(error) => {
    if (error.response && error.response.status === 404) {
        return Promise.resolve(error.response)
    }
    return Promise.reject(error)
})
