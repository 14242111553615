import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getPersonData } from "../../redux";

export const CongratulatedCard = () => {
    const personalData = useSelector(getPersonData);

    const [data, setData] = useState([
        {
            "id": 139454,
            "user_id": 232236,
            "badge_id": 269,
            "sender_id": 0,
            "causes": "",
            "comment": "",
            "date": 1723850100,
            "workspace_badge": {
                "id": 269,
                "ws_id": 1620,
                "title": "бейдж а",
                "description": "<p>бейдж а</p>",
                "image": "https://api.vsk-trust.store/images/workspaces_badges/CoJA66ktU8_749x749.png",
                "card": "https://api.vsk-trust.store/images/workspaces_badges/CGxksWfqd3_1200x963.jpeg"
            },
            "sender": null
        },
    ])

    useEffect(() => {
        if (personalData?.data) {
            personalData?.data.badges && setData(personalData?.data.badges?.ws)
        }
    }, [personalData]);

    return (
        <div className='layout-congratulations'>
            <div className='layout-congratulations__header'>
                <h1>Поздравили</h1>

                <p>{data.length}</p>
            </div>
            
            <div className='layout-congratulations__content'>
                {data.slice(0, 6).map((item, idx) => (
                    <div className={`layout-congratulations__block ${idx === 0 ? 'first' : ''}`} key={idx}>
                        <img crossOrigin="anonymous" src={item?.workspace_badge?.image} alt="" />
                    </div>
                ))}
            </div>
        </div>
    )
}