import TextOverflow from "../../../utils/text-overflow/TextOverflow";
import { CommentIcon, EyeIcon, HeartIcon, LikeIcon, PaperClipIcon } from "../../../img";
import NewsDateIcon from "../../../img/icons/news/NewsDateIcon";
import { getDateByFormat, getYearMonthDay, isTillMd } from "../../../utils";
import { IIdeas } from "../../../api/requests/ideas/interface";
import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import { BlankImage } from "../../ui/blank-image/BlankImage";

interface DropdownItem {
  label: string;
  value: number | string;
}

interface IIdeasCard {
  data: IIdeas
}
export const IdeasCard: FC<IIdeasCard> = ({
  data,
}) => {
  const isMobile = isTillMd();
  const navigate = useNavigate();

  const [statusList, setStatusList] = useState<DropdownItem[]>([
    {
      label: "Все",
      value: 5
    },
    {
      label: "На проверке",
      value: 0
    },
    {
      label: "Обсуждение",
      value: 1
    },
    {
      label: "Одобрены",
      value: 2
    },
    {
      label: "Отклонены",
      value: 3
    },
    {
      label: "Поддерживаю",
      value: 4
    },
  ])

  const {
    id,
    title,
    image,
    category_title,
    date,
    views,
    comments_count,
    status
  } = data


  const statusFind = statusList.find(item => item.value == (status || ""));

  return (
    <div className='ideas-card' onClick={() => navigate(`/ideas/show/${id}`)}>
      <div className={"feed-news-card__img"}>
        <BlankImage image={image}/>

        {/*{clip && (*/}
        {/*  <div className={"feed-news-card__clip"}>*/}
        {/*    <PaperClipIcon color={"#FFFFFF"} />*/}
        {/*  </div>*/}
        {/*)}*/}

        {(status || status == 0 )&& (
          <div className={`feed-news-card__active ${statusFind?.value}`}>
            <div className={`${statusFind?.label}`}></div>

            <p>{statusFind?.label || "Неопределен"}</p>
          </div>
        )}
      </div>

      <div className='ideas-card__infoBox'>

        <h5>{ TextOverflow({title, lines: 3}) }</h5>

        {/*{ isLikeIcon ? (*/}
        {/*    <div className='news-card__infoBox__top'>*/}
        {/*      <h5>{ TextOverflow({title, lines: 3}) }</h5>*/}

        {/*      <div*/}
        {/*        // onClick={ () => {*/}
        {/*        //     handleLike(item.id)*/}
        {/*        // } }*/}
        {/*        className={ `employees-block__likeIcon ${ isLiked ? 'active' : '' }` }*/}
        {/*      >*/}
        {/*        <HeartIcon />*/}
        {/*      </div>*/}
        {/*    </div>*/}
        {/*  ) :*/}
        {/*  <h5>{ TextOverflow({title, lines: 3}) }</h5>*/}
        {/*}*/}

        {!isMobile && (
          <div className={ `ideas-card__catTitleStyle` }>{ category_title ? category_title : 'Без категории' }</div>
        )}

        <div className='ideas-card__content'>
          <div className='ideas-card__dateBox'>
            <NewsDateIcon size={ '1.25rem' }
                          style={ {marginRight: isMobile ? '0.375rem' : '0.5rem', minWidth: '1.25rem'} } />
            <div>{ getDateByFormat(date * 1000, "DD MMMM, YYYY") }</div>
          </div>

          <div className='ideas-card__stats'>
            <div className='ideas-card__stats__block'>
              <EyeIcon />

              <h1>{ views }</h1>
            </div>

            <div className='ideas-card__stats__block'>
              <CommentIcon />

              <h1>{ comments_count }</h1>
            </div>

            {/*<div className='news-card__stats__block'>*/}
            {/*  <LikeIcon color='#00A7B5' />*/}

            {/*  <h1>{ likes_count }</h1>*/}
            {/*</div>*/}
          </div>
        </div>
      </div>
    </div>
  )
}