import { ArrowExitIcon } from "../../img";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { PollsCard, PollsQuestionsModal } from "../../components/polls";
import { $getPollsList } from "../../api/requests/polls";
import { IPolls, IPolls2 } from "../../api/requests/polls/interface";
import { isMd } from "../../utils";

export const PollsPage = () => {
    const navigate = useNavigate();
    const isMobile = isMd();

    const [isOpenModal, setIsOpenModal] = useState(false)
    const [selectedData, setSelectedData] = useState<IPolls2>()
    const [pollsData, setPollsData] = useState([])

    const handleSelectItem = (item: IPolls2) => {
        setIsOpenModal(true)
        setSelectedData(item)
    }
    const handleNavigation = () => {
        const pathArray = location.pathname.split('/');
        if (pathArray.length > 1) {
            pathArray.pop();
            const newPath = pathArray.join('/') || '/';
            navigate(newPath);
        } else {
            navigate('/');
        }
    }

    useEffect(() => {
        $getPollsList().then(res => {
            if (!res.data) return;

            setPollsData(res.data)
        })
    }, [])

    return (
        <div className='polls'>
            <div className='layout-inner__header profile-header'>
                { !isMobile && (
                    <div className='layout-inner__header__title'>
                        <div className='header__exitIcon' onClick={ handleNavigation }>
                            <ArrowExitIcon />
                        </div>
                        <h1>Опросы</h1>
                    </div>
                ) }
            </div>

            <div className='polls-content'>
                {pollsData.map((item, idx) => (
                    <div  key={idx} onClick={() => handleSelectItem(item)}>
                        <PollsCard data={item} />
                    </div>
                ))}
            </div>

            <PollsQuestionsModal
                isOpenModal={isOpenModal}
                onClose={() =>  setIsOpenModal(false)}
                data={selectedData}
                setData={setSelectedData}
            />
        </div>
    )
}