import { FC } from "react";
import { IBaseIconProps } from "../types";

export const ProfileConfidentialIcon: FC<IBaseIconProps> = (
    {
        color = "#00A7B5",
        size = 24
    }) => {
    return (
        <svg width={size} height={size} viewBox="0 0 24 24" fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <g>
                <path
                    d="M6.59688 8.80002V7.88574C6.59688 4.84683 9.00545 2.40002 11.9969 2.40002C14.9883 2.40002 17.3969 4.84683 17.3969 7.88574V8.80002M6.59688 8.80002C5.60688 8.80002 4.79688 9.62288 4.79688 10.6286V19.7714C4.79688 20.7772 5.60688 21.6 6.59688 21.6H17.3969C18.3869 21.6 19.1969 20.7772 19.1969 19.7714V10.6286C19.1969 9.62288 18.3869 8.80002 17.3969 8.80002M6.59688 8.80002H17.3969"
                    stroke={color} stroke-width="2" stroke-linecap="round" />
            </g>
        </svg>
    );
};
