import noImageBanner from "../../../img/noimage.png";
import { useEffect, useState } from "react";

interface GroupCreatePreviewProps {
    data: any;
}
const GroupCreatePreview = (
    {
        data
    }: GroupCreatePreviewProps
) => {
    const [upload, setUpload] = useState("");

    const handleImageUpload = (file: File): void => {
        if (file) {
            const reader = new FileReader()

            reader.onloadend = () => {
                if (reader.result && typeof reader.result === 'string') {
                    setUpload(reader.result)
                }
            }

            reader.readAsDataURL(file)
        }
    }

    useEffect(() => {
        if (typeof data.image === "string") {
            setUpload(data.image)
        } else {
            handleImageUpload(data.image)
        }
    }, [data.image]);

    return (
        <div className="contests__create__preview-items">
            <div className="contests__create__preview-item">
                <div className="groups__create__preview-item__header">
                    <div className="groups__create__preview-img">
                        <img src={upload || noImageBanner} />
                    </div>

                    <h3>{data.title || "Название"}</h3>
                </div>

                <div className="contests__create__preview-item__content sport__create__preview mt-3">
                    <p className={"contests__create__preview-item__content__text"}>
                        {data.text}
                    </p>
                </div>
            </div>
        </div>
    );
};

export default GroupCreatePreview;