import { useDispatch, useSelector } from "react-redux";

import moment, { Moment } from "moment";
import { isMd } from "../../../utils";
import {
    getPersonData,
    getProfileData,
    getProfileVarsData,
    setOpenPopup,
    setPopupData
} from "../../../redux";
import { vacationColors, vacationStatus } from "./constans";
import Button from "../../../components/ui/form/Buttons/Button";
import { useRef, useState } from "react";
import { ArrowRightIcon } from "../../../img";

interface VacationUserData {
    panel: string;
    expand: string;
    handleExpand: (val: boolean | string) => void
    indicator: number;
    for_user: string;
    created_at?: string;
    scheduled_start_date?: string;
    scheduled_end_date?: string;
    intended_start_date?: string;
    intended_end_date?: string;
    actual_start_date?: string;
    actual_end_date?: string;
    status?: string;
}

export const VacationUser = (
    {
        panel,
        expand,
        handleExpand,
        indicator,
        ...props
    }: VacationUserData
) => {
    const isMobile = isMd();

    const dispatch = useDispatch();
    const currentUser = useSelector(getPersonData);

    const isAdmin = currentUser?.workspace_role?.role === 1;

    const vars = useSelector(getProfileVarsData);

    const color = indicator >= 0 && vacationColors[indicator % vacationColors.length];

    const {
        for_user,
        created_at,
        scheduled_start_date,
        scheduled_end_date,
        intended_start_date,
        intended_end_date,
        actual_start_date,
        actual_end_date,
        status
    }: any = props || {};
    const { firstname, lastname, photo, structure, staff, vacation_info } = for_user || {};

    const contentHeight = useRef<any>(null);

    const formatDate = (start: string, end: string) => {
        return start && end && `${moment(start)?.format("DD.MM")} - ${moment(end)?.format("DD.MM")}`;
    };

    const handleMonth = (date: Moment) => {
        // dispatch(setMonth(moment(date).format('YYYY-MM-DD')));
    };

    const handleOpenBookingPopup = () => {
        handleMonth(moment());

        dispatch(
            setPopupData({
                ...props
            })
        );
        dispatch(setOpenPopup());
    };

    const handleOpenEditPopup = () => {
        handleMonth(actual_start_date || intended_start_date || scheduled_start_date);

        dispatch(
            setPopupData({
                ...props,
                start_date: actual_start_date || intended_start_date || scheduled_start_date,
                end_date: actual_end_date || intended_end_date || scheduled_end_date,
                indicator,
                isEdit: true,
                isCancel: status === 0
            })
        );
        dispatch(setOpenPopup());
    };

    const handleOpenConfirmPopup = () => {
        handleMonth(actual_start_date || intended_start_date || scheduled_start_date);

        dispatch(
            setPopupData({
                ...props,
                start_date: actual_start_date || intended_start_date || scheduled_start_date,
                end_date: actual_end_date || intended_end_date || scheduled_end_date,
                indicator,
                isConciliate: true,
                isAccept: status === 2,
                isRefuse: status === -1,
                isCancel: status === 0
            })
        );
        dispatch(setOpenPopup());
    };

    function handleChangeAccordion() {
        if (isMobile) {
            isAdmin && status === 0
                ? handleOpenEditPopup()
                : isAdmin && indicator === undefined
                    ? handleOpenBookingPopup()
                    : handleOpenConfirmPopup()
        } else {
            handleExpand(panel === expand ? false : panel)
        }
    }

    return (
        <>
            <div
                className={"accordion__wrapper"}
            >
                {/*<div*/}
                {/*    className={"accordion__wrapper-after"}*/}
                {/*    style={{*/}
                {/*        backgroundColor: color || ""*/}
                {/*    }}*/}
                {/*></div>*/}
                <div
                    className={`accordion__question-container`}
                    id={`panel-${panel}-header`}
                    onClick={handleChangeAccordion}
                    // expandIcon={!isMobile && <ArrowDropDownIcon />}
                >
                    <div className={"accordion__title"}>
                        <div
                            className={"accordion__avatar"}
                            style={{
                                backgroundColor: `${vars.colors.secondaryColor}1a`
                            }}
                        >
                            {photo && <img src={photo} />}

                            {!photo && firstname && firstname.slice(0, 1)}
                        </div>

                        <div className={"accordion__info"}>
                            <div className={"accordion__name"}>{`${firstname} ${lastname}`}</div>

                            <div className={"accordion__position"}>{staff?.title}</div>

                            {status !== undefined &&
                              <div
                                className={"accordion__status"}
                                style={{
                                    color: isMobile ? vars.colors.secondaryColor : vars.colors.fourthColor
                                }}
                              >
                                  {vacationStatus[status]}
                              </div>
                            }
                        </div>
                    </div>

                    {!isMobile && (
                        <div className={`accordion__arrow ${(!isMobile && expand === panel) ? "active" : ""}`}><ArrowRightIcon color={"#00000080"} /></div>
                    )}
                </div>

                <div
                    ref={contentHeight}
                    className={`answer-container ${(!isMobile && expand === panel) ? "active" : ""}`}
                    style={
                        (!isMobile && expand === panel)
                            ? { height: contentHeight.current.scrollHeight }
                            : { height: "0px" }
                    }
                >
                    <div className={"accordion__content"}>
                        <div className={"accordion__department"}>{structure?.title}</div>

                        <div className={"accordion__vacation"}>
                            {created_at && (
                                <div className={"accordion__item"}>
                                    {"Забронировано"}

                                    <span
                                        className={"accordion__date"}>{moment(created_at).format("DD MMMM - HH:mm")}</span>
                                </div>
                            )}

                            {status !== undefined && (
                                <div className={"accordion__item"}>
                                    {"Дата отпуска"}

                                    <span className={"accordion__date"}>
                                        {(actual_start_date &&
                                                actual_end_date &&
                                                formatDate(actual_start_date, actual_end_date)) ||
                                            (intended_start_date &&
                                                intended_end_date &&
                                                formatDate(intended_start_date, intended_end_date)) ||
                                            (scheduled_start_date &&
                                                scheduled_end_date &&
                                                formatDate(scheduled_start_date, scheduled_end_date))}
                                    </span>
                                </div>
                            )}

                            <div className={"accordion__item"}>
                                Доступные дни отпуска
                                <span>{vacation_info?.unused_vacation < 0 ? 0 : vacation_info?.unused_vacation}</span>
                            </div>
                        </div>

                        <div className={"accordion__buttons"}>
                            {isAdmin && indicator === undefined && (
                                <Button
                                    text={"Забронировать"}
                                    className={"accordion__button accordion__action"}
                                    onClick={handleOpenBookingPopup}
                                />
                            )}

                            {indicator >= 0 && (
                                <>
                                    {isAdmin && (
                                        <Button
                                            text={"Редактировать"}
                                            className={"btn btn-primary accordion__button accordion__action"}
                                            onClick={handleOpenEditPopup}
                                            style={{
                                                color: vars.colors.fourthColor,
                                                backgroundColor: vars.colors.secondaryColor,
                                                "&:hover": {
                                                    backgroundColor: vars.colors.secondaryColor
                                                }
                                            }}
                                        />
                                    )}

                                    {status !== 0 && (
                                        <Button
                                            text={"Согласовать"}
                                            className={"btn btn-primary accordion__button accordion__action ml-0"}
                                            onClick={handleOpenConfirmPopup}
                                        />
                                    )}
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
