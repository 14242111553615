import DialogContainer from "../../../components/ui/dialog/DialogContainer";
import Button from "../../../components/ui/form/Buttons/Button";
import { SportDateData, SportViewItemData } from "../../../api/requests/sport/interface";
import ToggleList from "../../../components/ui/form/Toggle/ToggleList";
import { Controller, useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { $joinEnrollSport } from "../../../api/requests/sport";
import { useSelector } from "react-redux";
import { getCurrentRole, getPersonData } from "../../../redux";

interface TrainingSessionViewModalSignProps {
    isOpen: boolean;
    onClose: (value: boolean) => void;
    setIsOpen: (value: boolean) => void;
    data?: SportViewItemData | null;
}

interface FormData {
    date: string;
    time: string;
}

interface dateItem {
    text: string;
    disabled: boolean;
}

const TrainingSessionViewModalSign = (
    {
        data,
        isOpen,
        onClose,
        setIsOpen
    }: TrainingSessionViewModalSignProps
) => {
    const userData = useSelector(getPersonData);
    const currentRole = useSelector(getCurrentRole);

    const isAdmin = false;

    const [isStatusComplete, setIsStatusComplete] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const {
        handleSubmit,
        control,
        reset,
        formState: { isValid }
    } = useForm<FormData>();

    const filteredSchedule = (schedule: SportDateData[], key: "date" | "time") => {
        return [...schedule].filter((item, index, self) =>
            index === self.findIndex(t => t[key] === item[key])
        )
    };

    const trainingDateList = (): dateItem[] => {
        if (!data?.schedules) return [];

        return [...filteredSchedule(data?.schedules, "date")].map(item => ({
            ...item,
            text: item.date || "",
            disabled: false
        }));
    };

    const [trainingTimeList, setTrainingTimeList] = useState<any[]>([]);

    useEffect(() => {
        if (data?.schedules) {
            const updateList = [...filteredSchedule(data?.schedules, "time")].map(item => ({
                ...item,
                text: item.time || "",
                disabled: true
            }));

            setTrainingTimeList(updateList);
        }
    }, [data, isOpen]);

    function handleCloseModal() {
        onClose(false);
        reset();
        setIsStatusComplete(false);
    }

    function onSubmit(request: any) {
        const findItem = data?.schedules.find(item => (item.date === request.date) && (item.time === request.time))

        if (findItem) {
            setIsLoading(true);
            $joinEnrollSport(isAdmin, {
                user_id: isAdmin ? userData.id : undefined,
                schedule_id: findItem.id,
            }, { newApi: true }).then(res => {
                setIsLoading(false);
                if (res.error || res.response) return;

                setIsStatusComplete(true);
            })
        }
    }

    return (
        <DialogContainer
            isOpen={isOpen}
            setIsOpen={() => {
                setIsOpen(false);
                reset();
                setIsStatusComplete(false);
            }}
            isShowCloseBtn={!isStatusComplete}
            widthProps={427}
            label={!isStatusComplete && "Запись на тренировку"}
        >
            {isStatusComplete ? (
                <div className={"sport__view__modal"}>
                    <div className={"sport__view__modal__content-complete"}>
                        <span>💪</span>

                        <h3>Поздравляем</h3>

                        <p>
                            Вы успешно записались.
                            Хорошей тренировки!
                        </p>
                    </div>

                    <div className={"sport__view__modal__btn"}>
                        <Button
                            text={"Отлично"}
                            className={"btn btn-light w-100"}
                            onClick={handleCloseModal}
                        />
                    </div>
                </div>
            ) : (
                <form className={"sport__view__modal"} onSubmit={handleSubmit(onSubmit)}>
                    <div className="sport__view__modal__content">
                        <Controller
                            name="date"
                            control={control}
                            rules={{
                                required: {
                                    value: true,
                                    message: ""
                                }
                            }}
                            render={({ field: { onChange, value } }) => (
                                <ToggleList
                                    title={"Дата тренировки"}
                                    list={trainingDateList()}
                                    onChange={(item) => {
                                        onChange(item.text);
                                        if (!data?.schedules) return;

                                        const updateList = [...data.schedules]
                                        .filter(i => i.date === item.date)
                                        .map(i => ({
                                            ...i,
                                            text: i.time || "",
                                            disabled: false
                                        }));

                                        setTrainingTimeList(updateList);
                                    }}
                                    value={value}
                                />
                            )}
                        />

                        <Controller
                            name="time"
                            control={control}
                            rules={{
                                required: {
                                    value: true,
                                    message: ""
                                }
                            }}
                            render={({ field: { onChange, value } }) => (
                                <ToggleList
                                    title={"Время тренировки"}
                                    list={trainingTimeList}
                                    onChange={(item) => onChange(item.text)}
                                    value={value}
                                />
                            )}
                        />

                        <div className="sport__content-card-user">
                            <img src={data?.trainer.photo} alt=""
                                 className="sport__content-card-user__image" />

                            <div className="sport__content-card-user__content">
                                <h1 className="sport__content-card-user__content__name">{data?.trainer.name}</h1>
                                <p className="sport__content-card-user__content__position">{data?.trainer.position}</p>
                            </div>
                        </div>
                    </div>

                    <div className={"sport__view__modal__btn"}>
                        <Button
                            type={"submit"}
                            text={"Записаться"}
                            className={"btn btn-primary w-100"}
                            disabled={!isValid}
                            loading={isLoading}
                        />
                    </div>
                </form>
            )}
        </DialogContainer>
    );
};

export default TrainingSessionViewModalSign;