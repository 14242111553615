import { FC, useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { changeProfileVarsData, changeToken, getPersonData, store } from "../redux";
import AuthSwiper from '../components/auth/AuthSwiper'
import Cookies from "js-cookie";
import { successNotify } from "../utils";

const SignInLayout: FC = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();

    const userData = useSelector(getPersonData);
    const token = Cookies.get('token') || localStorage.getItem("token");

    const searchParams = new URLSearchParams(location.search);

    const newToken = searchParams.get("newtoken");

    useEffect(() => {
        if (userData?.id || token) navigate('/')
    }, [userData, token])

    useEffect(() => {
        newToken && auth(newToken);
    }, [newToken]);

    function auth(token: string) {
        successNotify('Вы успешно авторизованы')

        Cookies.set('token', token)
        localStorage.setItem("token", token)
        store.dispatch(changeToken(token))
        dispatch(
            changeProfileVarsData({
                token: token
            })
        )
        navigate('/')
    }

    return (
        <main>
            <section className="auth__content">
                <div className="auth__content_left">
                    <Outlet />
                </div>
                <div className="auth__content_right">
                    <AuthSwiper />
                </div>
            </section>
        </main>
    )
}
export default SignInLayout
