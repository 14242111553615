import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import Cookies from "js-cookie";

import { $authSign } from "../../api/requests/auth";
import { phoneNumberFieldMask, successNotify } from "../../utils";
import { transformData } from "../../api";
import { changeProfileVarsData, changeToken, store } from "../../redux";

import Button from "../ui/form/Buttons/Button";
import TextField from "../ui/form/Fields/TextField";
import MaskTextField from "../ui/form/Fields/MaskTextField";

type Inputs = {
    mail: string;
    password: string;
}

export default function LoginForm() {
	const navigate = useNavigate();
    const dispatch = useDispatch();

    const isVsk = process.env.REACT_APP_API_URL === "https://api.vsk-trust.store/"

    const [errorMessage, setErrorMessage] = useState<string>('');
    const [loginMail, setLoginMail] = useState<string>('');
    const [passwordMail, setPasswordMail] = useState<string>('');
    const [isEye, setIsEye] = useState<boolean>(false);

    const changeTypePassword = (type: boolean): void => {
        setIsEye(!type);
    }

    async function onSubmit(inputs: Inputs) {
        setErrorMessage('');

        const requestData = isVsk
            ? { phone: inputs.mail, password: inputs.password }
            : { mail: inputs.mail, password: inputs.password }

        await $authSign(requestData, { formData: true }).then((res) => {
            if (res && res?.token) {
                successNotify('Вы успешно авторизованы')

                Cookies.set('token', res.token)
                localStorage.setItem("token", res.token)
                store.dispatch(changeToken(res.token))
                dispatch(
                    changeProfileVarsData({
                        token: res.token
                    })
                )
				navigate('/')
            }
        })
    }

    return (
        <form
            onSubmit={(event) => {
                event.preventDefault();

                onSubmit({ mail: loginMail, password: passwordMail })
            }}
            className="auth__login__form"
        >
            <div>
                <div className="form-block">
                    <TextField
                        type={isVsk ? "text" : "email"}
                        value={loginMail}
                        onChange={setLoginMail}
                        label={'Почта'}
                        errorMessage={errorMessage}
                        id={"login"}
                        autoFocus
                    />

                    <TextField
                        type={isEye ? 'text' : 'password'}
                        value={passwordMail}
                        onChange={setPasswordMail}
                        append={
                            <>
                                <Button
                                    className="btn btn-link"
                                    text={
                                        <img
                                            src={'/images/icons/question.svg'}
                                            width="21"
                                        />
                                    }
                                />

                                <Button
                                    className="btn btn-link"
                                    text={
                                        <img
                                            src={
                                                isEye
                                                    ? '/images/icons/eye.svg'
                                                    : '/images/icons/hide-eye.svg'
                                            }
                                            width="24"
                                        />
                                    }
                                    onClick={() => changeTypePassword(isEye)}
                                />
                            </>
                        }
                        label={'Пароль'}
                        id={"password"}
                        errors={{ password: { message: errorMessage || "" } }}
                    />
                </div>

                <div className="auth__start show">
                    <Button
                        className="btn btn-primary"
                        type={'submit'}
                        text={'Войти'}
                    />

                    <Link to={'/register'} className="btn btn-gray">
                        Зарегистрироваться
                    </Link>
                </div>
            </div>
        </form>
    )
}
