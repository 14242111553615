import { useNavigate, useParams } from "react-router-dom";
import { isMd } from "../../../utils";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { ArrowExitIcon } from "../../../img";
import ImageField from "../../../components/ui/form/ImageField/ImageField";
import TextField from "../../../components/ui/form/Fields/TextField";
import ToggleBlock from "../../../components/ui/form/Toggle/ToggleBlock";
import Dropdown from "../../../components/ui/form/Dropdown/Dropdown";
import Button from "../../../components/ui/form/Buttons/Button";
import { ICreateRequestIdea, IEditRequestIdea } from "../../../api/requests/ideas/interface";
import { $createIdea, $deleteIdea, $editIdea, $getIdeasCategories, $getIdeasItem } from "../../../api/requests/ideas";
import { useSelector } from "react-redux";
import { getPersonData, getProfileData } from "../../../redux";
import { IdeasCreateEditPreview } from "./IdeasCreateEditPreview";
import TextArea from "../../../components/ui/form/Fields/TextArea";
import FileField from "../../../components/ui/form/FileField/FileField";

interface DropdownItem {
  label: string;
  value: number | string;
}

export const IdeasCreateEdit = () => {
  const navigate = useNavigate();
  const isMobile = isMd();
  const profileData = useSelector(getPersonData);

  const pathname = location.pathname;
  const searchParams = new URLSearchParams(location.search);
  const {id: ideaId} = useParams();
  const isDraft = searchParams.get("is_draft");

  const isEdit: boolean = pathname.includes("edit");

  const [imageSrc, setImageSrc] = useState(null);

  const [ categoryList, setCategoryList ] = useState<DropdownItem[]>([
    {
      label: "Все категории",
      value: 'all'
    },
    {
      label: "Без категории",
      value: 0
    },
  ]);

  const [ editData, setEditData ] = useState<ICreateRequestIdea | null>(null);
  const [ ideaData, setIdeaData ] = useState<IEditRequestIdea>()

  const [ isShow, setIsShow ] = useState(true);

  const testBtnCreate = isEdit ? "Сохранить" : "Опубликовать";

  const {
    handleSubmit,
    control,
    watch,
    reset,
    formState: {isValid},
    setValue
  } = useForm<ICreateRequestIdea>(
    (isEdit && editData) ? {
      values: {
        image: editData.image,
        title: editData.title,
        text: editData.text,
        cat_id: getCategoryItem(editData.cat_id) || getCategoryItem(0),
        comments: editData.comments,
        workspace_id: profileData.workspace_id
      }
    } : {}
  );

  const handleNavigation = () => {
    navigate(-1);
  };

  useEffect(() => {
    init();
  }, []);


  function init() {
    if (isEdit && ideaId) {
      $getIdeasItem({id: ideaId}, {formData: true}).then((res: any) => {
        if (!res) return;

        setIdeaData(res)

        setEditData({
          image: res?.image,
          title: res?.title,
          text: res?.text,
          cat_id: res?.category_id,
          comments: res?.comments,
          workspace_id: res?.workspace_id
        });
      });
    }

    $getIdeasCategories({id: profileData.workspace_id, forceToResend: 0}, {formData: true}).then(res => {
      if (!res) return;

      const updatedList = res?.map((item) => {
        return {
          value: item.id,
          label: item.title
        }
      })


      setCategoryList([ ...updatedList, ...categoryList ])
    })
  }

  function getCategoryItem(id: number) {
    return categoryList.find((item) => item.value === id)
  }

  function onSubmit() {
    const requestData = isEdit ?
      {
        id: ideaData?.id,
        image: watch().image,
        title: watch().title,
        text: watch().text,
        cat_id: watch().cat_id.value,
        comments: watch().comments,
        workspace_id: profileData.workspace_id,
        date: ideaData?.date,
        status: ideaData?.status,
        category_id: ideaData?.category_id,
        category_title: ideaData?.category_title,
        points: ideaData?.points,
        push_dispatched: ideaData?.push_dispatched,
        name: ideaData?.name,
        user_id: ideaData?.user_id,
        notifications_comments: ideaData?.notifications_comments,
        group_id: ideaData?.group_id,
        vote_status: ideaData?.vote_status,
        can_delete: ideaData?.can_delete,
        comments_count: ideaData?.comments_count
      }
      : {
        image: watch().image,
        title: watch().title,
        text: watch().text,
        cat_id: watch().cat_id.value,
        comments: watch().comments || 0,
        workspace_id: profileData.workspace_id,
      };

    if (isEdit) return handleEditData(requestData);

    $createIdea(requestData, {formData: true}).then((res: any) => {
      if (!res.data) return;

      reset();
    })

    navigate("/ideas");
  }

  function handleEditData(requestData: IEditRequestIdea) {
    $editIdea(requestData, {formData: true}).then(response => {
      if (!response) return;

      reset();
      navigate(`/ideas/show/${ideaData?.id}`);
    })
  }

  function handleDelete() {
    $deleteIdea({id: ideaId}, {formData: true}).then(res => {
        if (!res) return;

        reset();
        navigate("/ideas");
    })
  }

  function handleSaveDraft() {
    const requestData = {
      image: watch().image,
      title: watch().title,
      text: watch().text,
      cat_id: watch().cat_id.value,
      comments: watch().comments,
      workspace_id: profileData.workspace_id
    };

    if (requestData.image) {
      return;
    }

    $createIdea(requestData, {formData: true}).then(res => {
      if (!res) return;

      reset();
      navigate("/ideas");
    })
  }


  return (
    <div className='ideas__create'>
      <div className="layout-inner__header profile-header contests__create__header">
        { !isMobile && (
          <div className="layout-inner__header__title">
            <div className="header__exitIcon" onClick={ handleNavigation }>
              <ArrowExitIcon />
            </div>

            <h1>{ editData ? 'Редактировать идею ' : "Предложить идею" }</h1>

            { isDraft && <h5>Черновик</h5> }
          </div>
        ) }
      </div>

      <div className='ideas__create__block'>
        <div className='container'>
          <div className='ideas__create__row row'>
            <div className="ideas__create__block-left">
              <form
                onSubmit={ handleSubmit(onSubmit) }
                className="ideas__create__form"
              >
                <Controller
                  name="image"
                  control={ control }
                  rules={ {
                    required: {
                      value: true,
                      message: "Прикрепите картинку"
                    }
                  } }
                  render={ ({field: {onChange, value}}) => (
                    <FileField
                      className={"form-image"}
                      upload={ value }
                      label="Изображение"
                      accept = ".png, .jpg, .svg"
                      setUpload={onChange }
                    />
                  ) }
                />

                <Controller
                  name="title"
                  control={ control }
                  rules={ {
                    required: {
                      value: true,
                      message: "Напишите название"
                    }
                  } }
                  render={ ({field: {onChange, value}}) => (
                    <TextField
                      value={ value || ''}
                      className=""
                      title="Заголовок"
                      placeholder={ "Введите заголовок" }
                      name="name"
                      onChange={ onChange }
                    />
                  ) }
                />

                <Controller
                  name="text"
                  control={ control }
                  rules={ {
                    required: {
                      value: true,
                      message: "Напишите описание"
                    }
                  } }
                  render={ ({field: {onChange, value}}) => (
                    <TextArea
                      value={ value || ''}
                      className=""
                      title="Описание"
                      placeholder={ "Введите описание" }
                      // name="desc"
                      onChange={ onChange }
                      // errors={errors}
                    />
                  ) }
                />

                <Controller
                  name="cat_id"
                  control={ control }
                  rules={ {
                    required: {
                      value: true,
                      message: "Выберете категорию"
                    }
                  } }
                  render={ ({field: {onChange, value}}) => (
                    <Dropdown
                      className={ "form-select" }
                      placeholder={ "Категория не выбрана" }
                      label={ "Категория" }
                      options={ [
                        ...categoryList
                      ] }
                      value={ value }
                      onChange={ onChange }
                    />
                  ) }
                />

                <Controller
                  name="comments"
                  control={ control }
                  rules={ {
                    required: {
                      value: false,
                      message: ""
                    }
                  } }
                  render={ ({field: {onChange, value}}) => (
                    <ToggleBlock
                      isChecked={ value === 1 }
                      className="ideas__create__form-toggle"
                      desc="Комментарии"
                      handleChange={ () => setValue('comments', value === 1 ? 0 : 1) }
                    />
                  ) }
                />

                <div className={ "ideas__create__form__buttons d-flex gap-3" }>
                  { isEdit && (
                    <Button
                      text={ "Удалить" }
                      className={ "btn btn-red w-100" }
                      onClick={ handleDelete }
                    />
                  ) }

                  <Button
                    text={ "В черновик" }
                    className={ "btn btn-light w-100" }
                    onClick={ handleSaveDraft }
                  />

                  <Button
                    onClick={ onSubmit }
                    type={ "submit" }
                    text={ testBtnCreate }
                    className={ "btn btn-primary w-100" }
                  />
                </div>
              </form>
            </div>

            {!isMobile && (
              <div className="ideas__create__block-right">
                <div className={ "ideas__create__preview" }>
                  <ToggleBlock
                    isChecked={ isShow }
                    className="ideas__create__form-toggle"
                    desc="Предпросмотр"
                    handleChange={ setIsShow }
                  />

                  { isShow && (
                    <IdeasCreateEditPreview
                      data={ watch() }
                    />
                  ) }
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}