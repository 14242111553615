import React, { FC } from 'react'
import { IBaseIconProps } from "./types"

export const UploadIcon: FC<IBaseIconProps> = ({
    color = '#00A7B5',
    size = 24,
}) => {
    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M12.3369 14.9425L12.3369 3M12.3369 3L7.40429 7.56318M12.3369 3L17.2695 7.56318M4.33594 18.9663V20.3827C4.74064 20.778 5.28954 21 5.86189 21H18.81C19.3823 21 19.9312 20.778 20.3359 20.3827V18.9663"
                stroke={color}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}
