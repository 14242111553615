import { FC, useEffect, useState } from "react";
import TextField from "./Fields/TextField";
import Button from "./Buttons/Button";
import { withDebounce } from "../../../functions";
import { CloseIcon, SearchIcon } from "../../../img";
import { Skeleton } from "../skeleton";
import { $getVacationBookingUsers } from "../../../api/requests/vacation";

interface Search {
    params?: any
    selectItem?: any
    onChange: (item: any) => void
    isShowBtnDelete?: boolean
}

export interface UserData {
    id: number;
    name: string;
    image: string;
    status?: number;
    user_id?: number;
}

export const SearchItem: FC<Search> = (
    {
        params,
        selectItem,
        onChange,
        isShowBtnDelete = false
    }
) => {
    const [userValue, setUserValue] = useState<string>('');
    const [userImg, setUserImg] = useState<string>('/img/icon/sidebar/users-blue.svg');

    const [listUsers, setListUsers] = useState<any[]>([]);
    const [selectItemLocal, setSelectItemLocal] = useState<any | null>(selectItem || null);

    const [ isFocused, setIsFocused ] = useState(false);
    const [ isLoading, setIsLoading ] = useState(false);

    useEffect(() => {
        const handleDocumentClick = (event: MouseEvent) => {
            const classEvent = (event.target as any).className;

            setIsFocused(['form-control'].includes(classEvent))
        };

        document.addEventListener('click', handleDocumentClick);

        return () => document.removeEventListener('click', handleDocumentClick);
    }, []);

    useEffect(() => {
        if (!selectItemLocal || !selectItem) return;

        if (userImg !== (selectItemLocal.image || selectItem.image)) {
            setUserValue(selectItemLocal.name || selectItem.name)
            setUserImg(selectItemLocal.image || selectItem.image)
        }
    }, [selectItem])

    function onChangeValueName(value: string) {
        setUserValue(value);
        // clearItem();
        onChange('');

        const requestData = params.isAdmin && params.department
            ? `department_id=${params.department}&search=${userValue}`
            : `search=${userValue}`

        $getVacationBookingUsers(requestData).then(res => {
            if (res && !res.data) return;

            setListUsers(res.data.data)
        })
    }

    function onChangeSelectItem(item: any) {
        onChange(item);

        setUserValue(`${item.name}_${item.username}`);
        setUserImg(item.image);
        setSelectItemLocal(item);

        setListUsers([]);
    }

    function clearItem() {
        setSelectItemLocal(null);
        setUserValue('');
        setUserImg('/img/icon/sidebar/users-blue.svg');
    }

    function deleteItem() {
        clearItem();
        onChange(null);
    }

    return (
        <div className={'search__block'}>
            <div className="search__block-field">
                <TextField
                    value={userValue}
                    onChange={(value: string) => withDebounce(() => onChangeValueName(value))}
                    placeholder={'Поиск'}
                    prepend={<SearchIcon color={"black"}/>}
                    className={`search__block-textField ${listUsers && 'active'}`}
                />

                {isShowBtnDelete && (
                    <div className={'search__block__clear'}>
                        <Button
                            text={(
                                <CloseIcon color={'#FF0000'} opacity={'1'}/>
                            )}
                            onClick={deleteItem}
                            className={'btn btn-icon'}
                        />
                    </div>
                )}
            </div>

            {((listUsers || isLoading) && (userValue && userValue.length) && isFocused) ? (
                <div className="search__block-dialog">
                    <div className="search__block-items">
                        {isLoading && (
                            <>
                                <Skeleton />
                                <Skeleton />
                                <Skeleton />
                            </>
                        )}


                        {listUsers.map((item) => (
                            <div
                                className="search__block-item"
                                key={'search__block-item' + item.id}
                                onClick={() => onChangeSelectItem(item)}
                            >
                                <div className="search__block-img">
                                    <img src={item.image || '/img/avatars.png'}/>
                                </div>

                                <p>{ item.name }_{ item.username }</p>
                            </div>
                        ))}
                    </div>
                </div>
            ) : null}
        </div>
    )
}
