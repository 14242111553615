import { IApiHandlerParams } from "../../interface";
import api from "../../index";
import { IChangeStatuses, IResponseStatuses } from "../statuses/interface";
import { IRequestUsers, IResponseUsers } from "./interface";

export const $getUsers = (
    body: IRequestUsers,
    handler?: IApiHandlerParams
): Promise<IResponseUsers> =>
    api.post("api/workspaces/users", body, {
        handler
    });

export const $getUser = (
    id: string | number,
    handler?: IApiHandlerParams
): Promise<any> =>
    api.get(`api/v1/user/user_page/${id}`, {
        handler
    });

export const $getPersonalPage = (
    data?: any,
    handler?: IApiHandlerParams
): Promise<any> =>
    api.get("api/v1/user/user_page", {
        request: {
            params: data,
        },
        handler
    });

export const $getBadges = (
    handler?: IApiHandlerParams
): Promise<any> =>
    api.get(`api/badges/list`, {
        handler
    });

export const $sendBadge = (
    data: any,
    handler?: IApiHandlerParams
): Promise<any> =>
    api.post('api/badges/send', data, {
        // mock: require('./mocks/complete.form.calculate.json'),
        handler,
    })
