import { fabric } from 'fabric';

import { generateUUID, getDrawnShapePosition, setDrawnShapeSettings } from './utils';
import { API_URL } from "../../../api/config";

function getPhotoLoad(item: any) {
    if (!item || !item.member) return `${API_URL}/booking_plus.png`;

    return `${API_URL}/photoDefault.png`;
}

export function getRoomShape(container: any, canvas: any, details: any, onSuccess: any) {
    const center = getDrawnShapePosition(canvas);

    const defaultOption = {
        id: generateUUID(),
        left: center.x,
        top: center.y,
    };

    const quadWidth = 240;
    const quadHeight = 80;

    defaultOption.left -= quadWidth;
    defaultOption.top -= quadHeight;

    const bg = new fabric.Rect({
        fill: 'rgba(255, 255, 255, 0.8)',
        width: quadWidth,
        height: quadHeight,
        rx: 8,
        ry: 8,
    });

    const title = new fabric.Text(details.request.name, {
        fill: '#000000',

        top: 20,
        left: 68,

        fontSize: 12,
        fontWeight: 600,
        fontFamily: 'Montserrat',
        textAlign: 'center',
    });

    const desc = new fabric.Text(details?.member?.name || 'Не назначен', {
        fill: '#000000',

        top: 40,
        left: 68,

        fontSize: 12,
        fontWeight: 400,
        fontFamily: 'Montserrat',
        textAlign: 'center',
    });

    fabric.Image.fromURL(getPhotoLoad(details), (img: any) => {
        img.scaleToWidth(40);
        img.scaleToHeight(40);

        img.top = 20;
        img.left = 20;

        const item = new fabric.Group([bg, title, desc, img], {
            ...defaultOption,
            width: quadWidth,
            height: quadHeight,
        });

        setDrawnShapeSettings(item);

        onSuccess(item);
    });
}
