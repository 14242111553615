import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { fabric } from "fabric";

import {
    $getWorkplaceMapData,
    $getWorkplaceMapSearch, $pinImportWorkplaceMap, $searchWorkplaceBooking
} from "../../../api/requests/workplace";
import { getWorkplaceShape } from "./GetWorkplaceShape";
import { getMeetingRoomShape } from "./getMeetingRoomShape";
import { getRoomShape } from "./GetRoomShape";
import { setDrawnShapeSettings } from "./utils";
import { failureNotify, isMd, successNotify } from "../../../utils";
import { withDebounce } from "../../../functions";
import BookingWorkplaceDialog from "./components/BookingWorkplaceDialog";

import {
    getCurrentDate, getOpenPopup,
    getPlan,
    getSelected,
    getPopupData,
    setCurrentDate,
    setFloors,
    setPlan,
    setSelected,
    setPopupData,
    setOpenPopup
} from "../../../redux/reducers/workplace/workplaceSlice";

import { getPersonData } from "../../../redux";

export const ServicesWorkplaceMap = () => {
    const isMobile = isMd();
    const navigate = useNavigate();

    const currentDate = useSelector(getCurrentDate);

    const containerRef = useRef<any>(null);
    const canvasRef = useRef<any>(null);
    const mouseCoords = useRef({
        startX: 0,
        startY: 0,
        scrollLeft: 0,
        scrollTop: 0
    });

    const [canvas, setCanvas] = useState<any>();
    const [props, setProps] = useState<any>({});
    const [showUsersList, setShowUsersList] = useState(true);
    const [isLoading, setIsLoading] = useState(true);
    const [isUsersLoaded, setIsUsersLoaded] = useState(false);
    const [users, setUsers] = useState<any[]>([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [isNextPage, setIsNextPage] = useState(false);

    const { workspace_id: wsId, ...rest } = useSelector(getPersonData);

    const { city, building, floor, isLoading: isLoadingFloor } = useSelector(getSelected);

    const dispatch = useDispatch();

    const [isLoadingMap, setIsLoadingMap] = useState(false);

    const [isUpdateData, setIsUpdateData] = useState(false);

    const [pinnedSuccess, setPinnedSuccess] = useState<any>();
    const [pinnedLoaded, setPinnedLoaded] = useState(false);
    const [pinnedError, setPinnedError] = useState();

    const getSearch = () => {
        $getWorkplaceMapSearch(floor?.id || 0).then(res => {
            const shapes = canvas.getObjects();

            if (canvas) {
                if (res?.data) {
                    shapes.forEach((item: any) => {
                        if (item?.details?.type === "workplace") {
                            if (
                                item?.details?.id !== res?.data?.find((search: any) => search?.id === item?.details?.id)?.id
                            ) {
                                item.set({
                                    opacity: 0.5
                                });
                            } else {
                                item.set({
                                    opacity: 1
                                });
                            }
                        }
                    });

                    canvas.renderAll();
                } else {
                    shapes.forEach((item: any) => {
                        if (item?.details?.type === "workplace") {
                            item.set({
                                opacity: 1
                            });
                        }
                    });

                    canvas.renderAll();
                }
            }
        });
    };

    const importPinned = (data: any) => {
        setPinnedLoaded(true);

        $pinImportWorkplaceMap(data, { formData: true }).then(res => {
            setPinnedLoaded(false);
            setPinnedSuccess(res);
        }).catch(res => {
            setPinnedError(res.error);
        });
    };

    const plan = useSelector(getPlan);
    const openPopup = useSelector(getOpenPopup);
    const popupData = useSelector(getPopupData);

    const [startMovement, setStartMovement] = useState({ x: 0, y: 0 });
    const [lastMovement, setLastMovement] = useState({ x: 0, y: 0 });

    const [isMouseDown, setIsMouseDown] = useState(false);

    const [size, setSize] = useState({ width: 1440, height: 700 });

    // Отлавливание клика
    const handleShapeClick = (shape: any) => {
        if (!shape) return;

        dispatch(setPopupData(shape.details));

        if (shape.details.type === "workplace") {
            dispatch(setOpenPopup());
        }

        if (shape.details.type === "meeting-room") {
            navigate(
                `/booking/meeting-room?city_id=${city.id}&building_id=${building.id}&floor_id=${floor.id}&id=${shape.details.request.room.id}`
            );
        }
    };

    const refreshObject = (object: any) => {
        if (object.details.type === "workplace") {
            const workplace = props?.workplaces?.find((w: any) => +w?.number === +object?.details?.request?.place);

            const today = moment(currentDate).format("yyyy-MM-DD");
            const todayBooking = workplace?.bookings?.find((b: any) => b.date_in_string === today);
            const todayInitiator =
                todayBooking?.items?.length > 0
                    ? todayBooking.items[0]?.for_user
                    : null;

            object.details.access = workplace?.access;
            object.details.is_department = !!workplace?.department;
            object.details.department_title = workplace?.department?.title;
            object.details.is_pinned = !!workplace?.for_user;
            object.details.member = workplace?.for_user || todayInitiator;

            if (!object.details.member) {
                object.details.request = {
                    place: object?.details?.request?.place,
                    number_name: object.details.request?.number_name,
                    peripheries: object.details.request?.peripheries,
                };
            } else if (todayBooking?.items && todayBooking.items.length > 0) {
                object.details.booking = {
                    ...object.details?.booking,
                    id: todayBooking.items[0].id,
                    start_time: todayBooking.items[0].start_time,
                    end_time: todayBooking.items[0].end_time,
                };
            }

            getWorkplaceShape(canvasRef.current, canvas, object.details, (item: any) => {
                item.details = object.details;

                item.set({
                    left: object.left,
                    top: object.top,
                    scaleX: object.scaleX,
                    scaleY: object.scaleY,
                });

                if (!item.details.access && item.details.is_department) {
                    item.set({
                        visible: false,
                    });
                }

                canvas.remove(object);

                canvas.add(item);
                setDrawnShapeSettings(item, true);
                renderAll();
            });
        }

        if (object.details.type === "meeting-room") {
            getMeetingRoomShape(containerRef.current, canvas, object.details, (item: any) => {
                item.details = object.details;

                item.set({
                    left: object.left,
                    top: object.top,
                    scaleX: object.scaleX,
                    scaleY: object.scaleY
                });

                canvas.remove(object);

                canvas.add(item);
                renderAll();
            });
        }

        if (object.details.type === "room") {
            getRoomShape(containerRef.current, canvas, object.details, (item: any) => {
                item.details = object.details;

                item.set({
                    left: object.left,
                    top: object.top,
                    scaleX: object.scaleX,
                    scaleY: object.scaleY
                });

                canvas.remove(object);
                canvas.add(item);
                renderAll();
            });
        }

        setDrawnShapeSettings(object);
    };

    const getBookedObjectById = (id: string) => {
        return getBookedWorkplaces(floor, currentDate).find(
            item => item.workplace.id === id
        );
    };

    const refreshAllObjects = () => {
        canvas.forEachObject((object: any) => {
            if (object) {
                const bookedObject = getBookedObjectById(object.details.id);

                if (object.details.type === "workplace" && bookedObject) {
                    const updateObj = {
                        ...object,
                        details: {
                            ...object.details,
                            member: bookedObject.items[0].for_user,
                            booking: bookedObject.items[0],
                        },
                    }

                    refreshObject(updateObj);

                    handleShapeEdit(updateObj);
                } else {
                    refreshObject(object);

                    handleShapeEdit(object);
                }
            }
        });

        withDebounce(() => setIsLoadingMap(false));
    };

    const handleShapeEdit = (shape: any) => {
        shape.editable = true;
        shape.hasControls = true;
        shape.hasBorders = true;
        shape.hasRotatingPoint = false;

        shape?.setControlsVisibility && shape.setControlsVisibility({
            mt: false,
            mb: false,
            ml: false,
            mr: false,
            tl: true,
            tr: true,
            br: true,
            bl: true
        });
    };

    const getData = () => {
        if (floor) {
            withDebounce(() => {
                $getWorkplaceMapData(
                    floor ? floor.building_id : 0,
                    { date: currentDate },
                    { newApi: true }
                ).then(res => {
                    if (res && res?.data?.floors) {
                        const thisFloor = res.data.floors.find((item: any) => item.id === floor?.id);

                        thisFloor && setProps(thisFloor);
                    }
                });
            });
        }
    };

    useEffect(() => {
        if (props.map && props.map.json_map) {
            const data = JSON.parse(props.map.json_map);

            if (data) {
                canvas.loadFromJSON(data, () => {
                    initBGImage(() => {
                        refreshAllObjects();
                    });
                });
            } else {
                initBGImage(() => {
                    refreshAllObjects();
                });
            }
        }
    }, [props]);

    const initBG = () => {
        if (!canvas || !containerRef.current) return;

        // Устанавливаем размеры Canvas
        canvas.setDimensions({
            width: size.width,
            height: size.height
        });

        let zoom = size.width / 1440;
        if (zoom < 1) {
            zoom = 1;
        }

        canvas.setZoom(zoom);
    };

    const initBGImage = (onSuccess: any) => {
        canvas.selection = false;

        initBG();

        fabric.Image.fromURL(plan, (img: any) => {
            img.scaleToWidth(1440);
            img.scaleToHeight(700);

            img.set({
                top: 0,
                left: 0,
                right: 0
            });

            let itemJson = localStorage.getItem("booking-front");
            const item = itemJson ? JSON.parse(itemJson) : null;

            if (item?.floor) {
                item.floor.map = {
                    ...item.floor.map,
                    width: img.width,
                    height: img.height
                };

                localStorage.setItem("booking-front", JSON.stringify(item));
            }

            canvas.setBackgroundImage(img, canvas.renderAll.bind(canvas));
            if (onSuccess) onSuccess();
        });
    };

    const renderAll = () => {
        canvas.renderAll();
    };

    const handleResize = () => {
        setIsLoadingMap(true);

        initBGImage(() => {
            canvas.renderAll();
            getData();
        });
    };

    const handleMoveStart = (e: any) => {
        const touches = e.changedTouches;

        handleDown(e);

        for (let i = 0; i < touches.length; i++) {
            setStartMovement({
                x: touches[i].clientX,
                y: touches[i].clientY
            });
        }
    };

    const handleMove = (e: any) => {
        const width = window.innerWidth;

        const touches = e.changedTouches;

        for (let i = 0; i < touches.length; i++) {
            const diffX = lastMovement.x + (startMovement.x - touches[i].clientX);
            const resultDiffX =
                diffX + width > 1440 ? 1440 - width : diffX < 0 ? 0 : diffX;

            const diffY = lastMovement.y + (startMovement.y - touches[i].clientY);
            const resultDiffY = diffY > 200 ? 200 : diffY < 0 ? 0 : diffY;

            canvasRef.current.style.transform = `translate(${-resultDiffX}px, ${-resultDiffY}px)`;
        }
    };

    const handleMoveEnd = (e: any) => {
        const touches = e.changedTouches;

        for (let i = 0; i < touches.length; i++) {
            setLastMovement({
                x: lastMovement.x + startMovement.x - touches[i].clientX,
                y: lastMovement.y + startMovement.y - touches[i].clientY
            });
        }
    };

    const handleDragStart = (e: any) => {
        if (!containerRef.current) return;
        const slider: any = containerRef.current;
        const startX = e.pageX - slider.offsetLeft;
        const startY = e.pageY - slider.offsetTop;
        const { scrollLeft } = slider;
        const { scrollTop } = slider;
        mouseCoords.current = { startX, startY, scrollLeft, scrollTop };
        setIsMouseDown(true);
        document.body.style.cursor = "grabbing";
    };

    const handleDragEnd = () => {
        setIsMouseDown(false);
        if (!containerRef.current) return;
        document.body.style.cursor = "default";
    };

    const handleDrag = (e: any) => {
        if (!isMouseDown || !containerRef.current) return;
        e.preventDefault();

        const slider: any = containerRef.current;

        const x = e.pageX - slider.offsetLeft;
        const y = e.pageY - slider.offsetTop;
        const walkX = (x - mouseCoords.current.startX) * 1.5;
        const walkY = (y - mouseCoords.current.startY) * 1.5;

        slider.scrollLeft = mouseCoords.current.scrollLeft - walkX;
        slider.scrollTop = mouseCoords.current.scrollTop - walkY;
    };

    const handleDown = (event: any) => {
        const activeObject = canvas.getActiveObject();
        event.preventDefault();

        if (activeObject) {
            handleShapeClick(activeObject);
        }
    };

    const getBookedWorkplaces = (floor: any, currentDate: any) => {
        let bookedBookings: any[] = [];

        floor?.workplaces?.forEach((workplace: any) => {
            workplace.bookings.forEach((booking: any) => {
                if (booking.date_in_string === currentDate && !!booking.items.length) {
                    bookedBookings = [...bookedBookings, { ...booking, workplace }];
                }
            });
        });

        return bookedBookings;
    }

    const handleZoomIn = () => {
        if (!showUsersList) return;

        if (size.width * 1.2 <= 1440 * 3) {
            setSize({ width: size.width * 1.2, height: size.height * 1.2 });
        }
    };

    const handleZoomOut = () => {
        if (!showUsersList) return;

        if (size.width / 1.2 >= 1440) {
            setSize({ width: size.width / 1.2, height: size.height / 1.2 });
        }
    };

    const handleZoomReset = () => {
        if (!showUsersList) return;

        if (canvas) {
            const shapes = canvas.getObjects();

            shapes.forEach((item: any) => {
                if (item?.details?.type === "workplace") {
                    item.set({
                        opacity: 1,
                        scaleX: 1,
                        scaleY: 1
                    });
                }
            });
            canvas.viewportTransform = [1, 0, 0, 1, 0, 0];
            canvas.renderAll();
        }
    };

    useEffect(() => {
        let itemJson: any = localStorage.getItem("booking-front");
        const savedWsId: any = localStorage.getItem("booking-front-ws-id");

        // reload if change workspace
        // if (!savedWsId || (parseInt(savedWsId, 10) !== wsId) && wsId) {
        //     localStorage.setItem("booking-front", "");
        //     localStorage.setItem("booking-front-ws-id", wsId.toString());
        //     itemJson = null;
        //     window.location.reload();
        // }

        if (!itemJson) return;

        const item = JSON.parse(itemJson);

        if (item) {
            dispatch(setSelected(item));
            dispatch(setFloors(item?.building?.floors));
            dispatch(setPlan(item?.floor?.map?.filename_url));
        }

        return () => {
            document.body.style.overflow = "auto";
        };
    }, []);

    useEffect(() => {
        if (!currentDate) {
            const date = moment(new Date()).format("yyyy-MM-DD");
            dispatch(setCurrentDate(date));
        }
    }, [currentDate]);

    /**
     * Step - 1
     */
    useEffect(() => {
        if (plan) {
            if (!canvas) {
                setIsLoading(true);
                // @ts-ignore
                setCanvas(new fabric.Canvas("canvas"), {
                    fireRightClick: true
                });
            } else {
                setIsLoading(false);
                canvas.clear();
            }
        }
    }, [plan]);
    /**
     * Step - 2
     */
    useEffect(() => {
        if (canvas) {
            canvas.clear();
            handleResize();
        }

        if (canvas && floor && floor.building_id) {
            initBGImage(() => {
                canvas.renderAll();
                getData();
            });
        }
    }, [floor, currentDate]);
    /**
     * Step - 3
     */
    useEffect(() => {
        if (isLoadingFloor && canvas) {
            canvas.getActiveObjects().forEach((o: any) => {
                canvas.remove(o);
            });
        }
    }, [isLoadingFloor]);
    /**
     * Step - 4
     */
    useEffect(() => {
        if (isUpdateData) {
            getData();
        }
    }, [isUpdateData]);

    useEffect(() => {
        if (pinnedLoaded) {
            if (pinnedError) {
                failureNotify(pinnedError);

                return;
            }

            getData();
            successNotify(pinnedSuccess?.message);
        }
    }, [pinnedLoaded]);

    useEffect(() => {
        // floor && withDebounce(() => getUsers({ page: 1 }))
    }, [floor]);

    function getUsers(item: any) {
        setIsUsersLoaded(true);

        const isPush = ![+item?.page, 1].includes(currentPage);

        $searchWorkplaceBooking(floor?.id || 0, {
            name: item?.search || undefined,
            page: item?.page || 1,
            date: currentDate || undefined
        }, { newApi: true }).then(res => {
            setIsUsersLoaded(false);
            if (!res.data) return;

            setIsNextPage(res.data?.last_page !== res.data?.current_page);
            setCurrentPage(res.data?.current_page || 1);

            const list = [...res.data?.data].map(i => ({
                ...i,
                name: i?.booked_user?.name || i?.number_name || "",
                photo: i?.booked_user?.photo,
                svg: i?.booked_user ? undefined : (
                    <svg width="40" height="40" viewBox="0 0 40 40" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                        <rect width="40" height="40" rx="20" fill="#E9F0F9" />
                        <path fillRule="evenodd" clipRule="evenodd"
                              d="M13.25 13C13.25 12.5858 13.5858 12.25 14 12.25H26C26.4142 12.25 26.75 12.5858 26.75 13V21C26.75 21.4142 26.4142 21.75 26 21.75H20.75V26.25H29C29.4142 26.25 29.75 26.5858 29.75 27C29.75 27.4142 29.4142 27.75 29 27.75H11C10.5858 27.75 10.25 27.4142 10.25 27C10.25 26.5858 10.5858 26.25 11 26.25H19.25V21.75H14C13.5858 21.75 13.25 21.4142 13.25 21V13ZM17.5117 16.4416C17.8196 16.7186 17.8446 17.1929 17.5675 17.5007L17.5575 17.5119C17.2804 17.8197 16.8062 17.8447 16.4983 17.5676C16.1904 17.2905 16.1654 16.8163 16.4425 16.5084L16.4525 16.4973C16.7296 16.1894 17.2039 16.1645 17.5117 16.4416ZM20.5675 17.5007C20.8446 17.1929 20.8196 16.7186 20.5117 16.4416C20.2038 16.1645 19.7296 16.1894 19.4525 16.4973L19.4425 16.5084C19.1654 16.8163 19.1904 17.2905 19.4983 17.5676C19.8062 17.8447 20.2804 17.8197 20.5575 17.5119L20.5675 17.5007Z"
                              fill="black" />
                    </svg>
                )
            }));

            if (isPush) {
                const listUpdate: any[] = [...users];

                listUpdate.push(...list);

                setUsers(listUpdate);

                return;
            }

            setUsers(list);
        });
    }

    return (
        <div className={"workspace-map mt-3"}>
            <div
                className={"workspace-map-container"}
                onTouchStart={handleMoveStart}
                onTouchMove={handleMove}
                onTouchEnd={handleMoveEnd}
                onMouseDown={handleDragStart}
                onMouseUp={handleDragEnd}
                onMouseMove={handleDrag}
                ref={containerRef}
            >
                {plan && (
                    <div
                        id="map"
                        className={"workspace-map-block"}
                        ref={canvasRef}
                        onMouseDown={handleDown}
                        style={{
                            opacity: (isLoading || isLoadingFloor || isLoadingMap) ? 0 : 1
                        }}
                    >
                        <canvas className={"workspace-map-canvas"} id="canvas" />
                    </div>
                )}

                {!plan && (
                    <div className={"workspace-map-empty"}>
                        <img
                            className={"emptyImage"}
                            src={"/images/icons/ImgEmptyBooking.svg"}
                            alt="План этажа"
                        />

                        <p className={"emptyText"}>Выберите город, офис и этаж</p>
                    </div>
                )}
            </div>

            <BookingWorkplaceDialog
                isOpen={openPopup}
                onClose={() => dispatch(setOpenPopup())}
                selectedProps={{
                    city,
                    building,
                    floor,
                    request: popupData,
                    selectedMember: {
                        photo: popupData?.photo,
                        name: popupData?.name
                    }
                }}
            />
        </div>
    );
};
