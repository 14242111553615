import { useEffect, useRef, useState } from "react";
import { getCurrentRole, getPersonData, updateRouteTitle } from "../../../redux";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
    $deleteIdea,
    $editIdea,
    $getIdeasItem,
    $getIdeasList, $sendNotificationIdeas,
    $voteIdea
} from "../../../api/requests/ideas";
import { IIdeas, IIdeasItem } from "../../../api/requests/ideas/interface";
import { ArrowExitIcon, CommentIcon, EyeIcon, LikeIcon, ThreeDotsIcon } from "../../../img";
import { getDateByFormat, isMd, successNotify } from "../../../utils";
import { ClockIcon } from "@mui/x-date-pickers";
import { CommentMessenger } from "../../../components/comment-messenger";
import { IdeasCard, IdeasPointsModal } from "../../../components/ideas";
import CompleteModal, { BtnDataItem } from "../../../components/ui/dialog/CompleteModal";
import { BlankImage } from "../../../components/ui/blank-image/BlankImage";
import { ProfileBalanceIcon } from "../../../img/icons/layout-components";
import UserSelectListDialog from "../../../components/ui/dialog/UserSelectListDialog";
import { EyeIcon2 } from "../../../img/icons/news/EyeIcon2";

interface DropdownItem {
    label: string;
    value: number | string;
}

export const IdeasShowPage = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const currentRole = useSelector(getCurrentRole);

    const isAdmin = currentRole === 1;

    const dropdownRef = useRef<any>(null);

    const isMobile = isMd();

    const profileData = useSelector(getPersonData);
    const { id: ideasId } = useParams();

    const [ideasList, setIdeasList] = useState<IIdeas[]>([]);
    const [ideaData, setIdeaData] = useState<IIdeasItem>();

    const [isCommentLoading, setIsCommentLoading] = useState(false);

    const [isModalOpen, setIsModalOpen] = useState(0);
    const [editDropdownOpen, setEditDropdownOpen] = useState(false);

    const [statusList, setStatusList] = useState<DropdownItem[]>([
        {
            label: "Все",
            value: 5
        },
        {
            label: "На проверке",
            value: 0
        },
        {
            label: "Обсуждение",
            value: 1
        },
        {
            label: "Одобрены",
            value: 2
        },
        {
            label: "Отклонено",
            value: 3
        },
        {
            label: "Поддерживаю",
            value: 4
        }
    ]);

    const btnListRemove = [
        {
            text: "Удалить",
            key: 0,
            className: "btn btn-danger w-100"
        },
        {
            text: "Отмена",
            key: 1,
            className: "btn btn-light w-100"
        }
    ];

    const [editDropdownItems, setEditDropdownItems] = useState([
        {
            label: "Вынести на обсуждение",
            value: 1
        },
        {
            label: "Одобрить",
            value: 2
        },
        {
            label: "Отклонить",
            value: 3
        },
        {
            label: "Разослать уведомления",
            value: 4
        }
    ]);

    const statusFind = statusList.find(item => item.value == (ideaData?.status || ""));

    const getVotedUsers = (status: string | number) => {
        if (ideaData) {
            return ideaData?.votes?.filter((item) => item.status == status);
        } else {
            return [];
        }
    };
    const handleVote = (pick: number, status: number) => {
        if (ideasId && status !== 0) {
            setIsCommentLoading(true);

            $voteIdea({ id: ideasId, pick: pick }, { formData: true }).then(res => {
                setIsCommentLoading(false);
            });
        }
    };

    const handleChangeStatus = (status: number) => {
        setIsCommentLoading(true);

        if (ideasId) {
            if (status == 4) {
                $sendNotificationIdeas({ id: +ideasId }, { formData: true }).then(res => {

                    setIsCommentLoading(false);
                    setEditDropdownOpen(false);
                });
            }
            else {
                $editIdea({ id: +ideasId, status }, { formData: true }).then(res => {
                    setIsCommentLoading(false);
                    setEditDropdownOpen(false);
                });
            }
        }
    };

    const handleDeleteIdea = (select: BtnDataItem) => {
        if (select.key == 1) {
            setIsModalOpen(0);
        } else {
            $deleteIdea({ id: ideasId }, { formData: true }).then(res => {
                setIsModalOpen(0);
                navigate(-1);
            });
        }
    };

    useEffect(() => {
        init();
    }, [isCommentLoading]);

    useEffect(() => {
        $getIdeasList({
            workspace_id: profileData?.workspace_id,
            offset: 0,
            limit: 30
        }, { formData: true }).then(res => {
            if (!res) return;

            setIdeasList(res);
        });
    }, []);

    useEffect(() => {
        dispatch(updateRouteTitle({ key: "ideas_show", title: ideaData?.title }));
    }, [ideaData]);

    useEffect(() => {
        const handleClickOutside = (event: any) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setEditDropdownOpen(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    function init() {
        if (ideasId) {
            $getIdeasItem({ id: ideasId }, { formData: true }).then(res => {
                if (!res) return;

                setIdeaData(res);
            });
        }
    }

    function handleShare() {
        const url = window.location.href;
        navigator.clipboard.writeText(url);

        successNotify("Ссылка успешно скопирована").then();
    }


    return (
        <div className="ideas-show">
            <CompleteModal
                icon={"🥲"}
                title={"Вы уверены, что хотите удалить идею?"}
                isOpen={isModalOpen === 2}
                setIsOpen={() => setIsModalOpen(0)}
                btnList={btnListRemove}
                onClick={handleDeleteIdea}
            />

            {ideasId && (
                <IdeasPointsModal
                    id={+ideasId}
                    isOpen={isModalOpen === 1}
                    onClose={() => {
                        setIsModalOpen(0);
                        init();
                    }}
                    setLoading={setIsCommentLoading}
                />
            )}

            <UserSelectListDialog
                isOpen={isModalOpen == 3 || isModalOpen == 4}
                list={getVotedUsers(isModalOpen == 3 ? 1 : 2)}
                onClose={() => setIsModalOpen(0)}
                isClose
                isSearch
                isSingleSelect
                title={isModalOpen == 3 ? 'Поддерживают' : 'Выступают против'}
            />

            <div className="layout-inner__header profile-header">
                {!isMobile && (
                    <div className="layout-inner__header__title">
                        <div className="header__exitIcon" onClick={() => navigate(-1)}>
                            <ArrowExitIcon />
                        </div>
                        <h1>{ideaData?.title}</h1>
                    </div>
                )}
            </div>

            <div className="ideas-show-content">
                <div className="ideas-show-content__left">
                    <div className="ideas-show-info">
                        <div className={"ideas-show-info__image"}>
                            <BlankImage image={ideaData?.image || ""} />

                            {/*{clip && (*/}
                            {/*  <div className={"feed-news-card__clip"}>*/}
                            {/*    <PaperClipIcon color={"#FFFFFF"} />*/}
                            {/*  </div>*/}
                            {/*)}*/}

                            {(ideaData?.status || ideaData?.status == 0) && (
                                <div className={`feed-news-card__active ${statusFind?.value}`}>
                                    <div className={`${statusFind?.label}`}></div>

                                    <p>{statusFind?.label || "Неопределен"}</p>
                                </div>
                            )}
                        </div>

                        <div className="ideas-show-info__content">
                            <div className="ideas-show-info__header">
                                {isMobile && (
                                    <h5 className="ideas-show-info__title">{ideaData?.title}</h5>
                                )}

                                <div className="ideas-show-info__header__practice">
                                    {ideaData?.category_title ? ideaData.category_title : "Без категории"}
                                </div>

                                <div className={"ideas-show-info__header__edit"}>
                                    {isAdmin && (
                                        <div className="ideas-show-info__header__edit__icon"
                                             onClick={() => setEditDropdownOpen(!editDropdownOpen)}>
                                            <ThreeDotsIcon />
                                        </div>
                                    )}

                                    {editDropdownOpen && (
                                        <div className="ideas-show-info__header__edit__dropdown"
                                             ref={dropdownRef}>
                                            {editDropdownItems.map((item, idx) => (

                                                <div
                                                    key={`edit-dropdown-item-${idx}`}
                                                    className={`ideas-show-info__header__edit__dropdown__item ${ideaData?.status == item.value ? "active" : ""}`}
                                                    onClick={() => handleChangeStatus(item.value)}
                                                >
                                                    {item.label}
                                                </div>
                                            ))}

                                            <div
                                                className="ideas-show-info__header__edit__dropdown__delete"
                                                onClick={() => setIsModalOpen(2)}>
                                                Удалить
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>

                            <div className="ideas-show-info__editor">
                                <p>{ideaData?.text} </p>
                                {/*<div dangerouslySetInnerHTML={{ __html: ideaData?.text }}/>*/}
                            </div>

                            <div className="ideas-show-info__profile">
                                <p className="ideas-show-info__profile__label">Автор</p>
                                <div className="ideas-show-info__profile__block">
                                    <BlankImage image={ideaData?.photo || ''} classname='ideas-show-info__profile__block__image'/>


                                    <div className="ideas-show-info__profile__block__right">
                                        <h5>{ideaData?.name}</h5>

                                        <p>Руководитель отдела</p>
                                    </div>
                                </div>

                                {isAdmin && (
                                    <div className="ideas-show-info__profile__button"
                                         onClick={() => setIsModalOpen(1)}>
                                        <div className="ideas-show-info__profile__button__left">
                                            <ProfileBalanceIcon />

                                            {ideaData?.points}
                                        </div>

                                        <h5>Отправить баллы</h5>
                                    </div>
                                )}
                            </div>

                            {isMobile && (
                                <div className="ideas-show-info__stats">
                                    <div className="ideas-show-info__stats__block">
                                        <CommentIcon color="#000" size={20} />

                                        <p>{ideaData?.comments_count}</p>
                                    </div>

                                    <div className="ideas-show-info__stats__block">
                                        <EyeIcon color="#000" size={20} />

                                        <p>{ideaData?.views}</p>
                                    </div>
                                </div>
                            )}

                            <div className="ideas-show-info__bottom">
                                <div className="ideas-show-info__date">
                                    <ClockIcon />
                                    <p>{getDateByFormat(ideaData?.date ? ideaData?.date * 1000 : 0, "DD MMMM, YYYY")}</p>
                                </div>

                                {!isMobile && (
                                    <div className="ideas-show-info__stats">
                                        <div className="ideas-show-info__stats__block">
                                            <CommentIcon color="#000" size={20} />

                                            <p>{ideaData?.comments_count}</p>
                                        </div>

                                        <div className="ideas-show-info__stats__block">
                                            <EyeIcon2 color="#000" size={20} />

                                            <p>{ideaData?.views}</p>
                                        </div>
                                    </div>
                                )}
                            </div>

                            <div className="ideas-show__votes">
                                <div
                                    className={`ideas-show__votes__block ideas-show__votes__block__${ideaData?.vote_status == 1 ? "voted" : "accept"}`}
                                    onClick={() => handleVote(ideaData?.vote_status == 1 ? 0 : 1, ideaData?.status || 0)}
                                >
                                    <div className="ideas-show__votes__block__left">
                                        <div className="icon">
                                            <LikeIcon
                                                color={ideaData?.vote_status == 1 ? "rgba(0, 0, 0, 0.5)" : "#00A7B5"} />
                                        </div>

                                        <p className="title">{ideaData?.vote_status == 1 ? "Вы поддержали " : "Поддержать"}</p>
                                    </div>

                                    <div className="layout-thanks__list">
                                        {getVotedUsers(1).slice(0, 7)?.map((
                                            item,
                                            index
                                        ) => {
                                            return (
                                                <div key={index} className="layout-thanks__item"
                                                     onClick={(e) => {
                                                         e.stopPropagation();
                                                         navigate(`/user/${item.user_id}`);
                                                     }}
                                                >
                                                    {item.photo && <img src={item.photo} />}

                                                    {!item.photo && item.name &&
                                                        <div
                                                            className="layout-thanks__name">{item.name?.slice(0, 1)}</div>}
                                                </div>
                                            );
                                        })}

                                        {getVotedUsers(1)?.length > 7 &&
                                            <div
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    setIsModalOpen(3)
                                                }}
                                                className="layout-thanks__count">{`+${getVotedUsers(1).length - 7}`}</div>}
                                    </div>
                                </div>

                                <div
                                    className="ideas-show__votes__block ideas-show__votes__block__reject"
                                    onClick={() => handleVote(ideaData?.vote_status == 2 ? 0 : 2, ideaData?.status || 0)}>
                                    <div className="ideas-show__votes__block__left">
                                        <div className="icon">
                                            <LikeIcon color="#FF0000" />
                                        </div>

                                        <p className="title">Выступить против</p>
                                    </div>

                                    <div className="layout-thanks__list">
                                        {getVotedUsers(2).slice(0, 7)?.map((
                                            item,
                                            index
                                        ) => {
                                            return (
                                                <div key={index} className="layout-thanks__item"
                                                     onClick={(e) => {
                                                         e.stopPropagation();
                                                         navigate(`/user/${item.user_id}`);
                                                     }}
                                                >
                                                    {item.photo && <img src={item.photo} />}

                                                    {!item.photo && item.name &&
                                                        <div
                                                            className="layout-thanks__name">{item.name?.slice(0, 1)}</div>}
                                                </div>
                                            );
                                        })}

                                        {getVotedUsers(2)?.length > 7 &&
                                            <div
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    setIsModalOpen(4)
                                                }}
                                                className="layout-thanks__count">
                                                {`+${getVotedUsers(2).length - 7}`}
                                            </div>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {ideaData?.comments == 1 && ideaData?.comments_array ? (
                        <CommentMessenger
                            comments_data={ideaData?.comments_array}
                            id={ideaData.id}
                            setIsLoading={setIsCommentLoading}
                            url={"/ideas"}
                            accept={""}
                            isNotification={+ideaData.notifications_comments}
                            isExpert={isAdmin}
                        />
                    ) : null}
                </div>

                <div className="ideas-show-content__right">
                    {ideasList.slice(0, 4).map((item) => (
                        <IdeasCard data={item} />
                    ))}
                </div>
            </div>
        </div>
    );
};