import { useEffect, useState } from "react";
import { $getGroupList, $showGroupById } from "../../api/requests/groups";
import { useLocation, useNavigate } from "react-router-dom";
import noImageBanner from "../../img/noimage.png";
import { SettingIcon } from "../../img";
import { ThreePeopleBorderIcon } from "../../img/icons/employees/ThreePeopleBorderIcon";
import Button from "../../components/ui/form/Buttons/Button";
import { LogoutIcon } from "../../img/icons/LogoutIcon";
import GroupsUsersModal from "./GroupsUsersModal";
import {
    getCurrentGroupData,
    setCurrentGroupData
} from "../../redux/reducers/workplace/workplaceSlice";
import { useDispatch, useSelector } from "react-redux";
import { getCurrentRole, getPersonData } from "../../redux";

const GroupMainSlide = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const searchParams = new URLSearchParams(location.search);

    const currentGroup = useSelector(getCurrentGroupData);
    const currentRole = useSelector(getCurrentRole);
    const personalData = useSelector(getPersonData);

    const groupId = searchParams.get("group_id") || personalData?.group_id;

    const [isOpenModalUsers, setIsOpenModalUsers] = useState(false);

    const isShowSettingGroup = (+currentRole === 1) || (+currentGroup?.role === 1) || (+currentGroup?.role === 2)

    useEffect(() => {
        if (groupId) {
            if (+groupId !== 0) {
                init();
            } else {
                dispatch(setCurrentGroupData(null));
            }
        }
    }, [groupId]);

    function init() {
        $getGroupList().then(res => {
            if (!res?.length) return;

            const findSelectGroup = res.find(item => +item.id === +groupId);

            if (findSelectGroup) {
                dispatch(setCurrentGroupData(findSelectGroup));
            }
        });
    }

    function handleToMain() {
        if (groupId && (+groupId !== 0))
            $showGroupById({ id: "0" }, { formData: true }).then(() => {
            });

        navigate("/?group_id=0");
    }

    if (!currentGroup) return null;

    return (
        <div className={"container"}>
            <div className={"d-flex justify-content-between"}>
                <div className="layout-vacation-modal__block__right groups__create__preview-img">
                    <img src={currentGroup.image || noImageBanner} alt="" />

                    <div className="layout-vacation-modal__block__desc">
                        <div className={"groups__main__title"}>
                            <h1>{currentGroup.title}</h1>

                            <span onClick={() => setIsOpenModalUsers(true)}>
                                <ThreePeopleBorderIcon />

                                {currentGroup.members_count}
                            </span>
                        </div>

                        <p className="layout-vacation-modal__block__desc__department">
                            {currentGroup.text}
                        </p>
                    </div>
                </div>

                <div className={"groups__main__btn"}>
                    {isShowSettingGroup && (
                        <Button
                            className={"btn-icon"}
                            onClick={() => navigate("/groups/setting/settings")}
                            text={<SettingIcon color={"#00A7B5"} width={24} height={24} />}
                        />
                    )}

                    <Button
                        className={"btn-icon"}
                        onClick={handleToMain}
                        text={<LogoutIcon />}
                    />
                </div>
            </div>

            <GroupsUsersModal
                isOpen={isOpenModalUsers}
                onClose={() => setIsOpenModalUsers(false)}
                usersList={currentGroup?.members}
            />
        </div>
    );
};

export default GroupMainSlide;