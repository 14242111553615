import { useEffect, useState } from "react";
import { BtnDataItem } from "../../components/ui/dialog/CompleteModal";
import DialogContainer from "../../components/ui/dialog/DialogContainer";
import UserSelectListModal from "../../components/ui/dialog/UserSelectListModal";
import { useNavigate } from "react-router-dom";

interface TrainerModalSingUserProps {
    isOpen: boolean;
    onClose: () => void;
    usersList: any[];
}

const GroupsUsersModal = (
    {
        isOpen,
        onClose,
        usersList
    }: TrainerModalSingUserProps
) => {
    const navigate = useNavigate();

    const [departmentItems, setDepartmentItems] = useState<any[]>([]);

    const changeDataItem = (item: any) => {
        return {
            id: item.id,
            image: item.photo,
            name: item.name,
            department: item.text
        };
    };

    useEffect(() => {
        if (usersList) {
            setDepartmentItems([...usersList].map(item => changeDataItem(item)));
        }
    }, [usersList]);

    function handleChangeList(btnItem: BtnDataItem | null, updateList: number[]) {
        if (updateList.length) {
            const user = departmentItems[updateList[0]];

            navigate(`/user/${user.id}`);
        }

        onClose();
    }

    return (
        <DialogContainer
            isOpen={isOpen}
            setIsOpen={onClose}
            isShowCloseBtn={false}
            classNameModal={"p-0"}
            widthProps={427}
        >
            <UserSelectListModal
                list={departmentItems}
                title={"Участники группы"}
                onClose={handleChangeList}
                isClose
                isSearch
                isSingleSelect
            />
        </DialogContainer>
    );
};

export default GroupsUsersModal;