import { CreateContestFormData } from "../../contests/ContestsCreateEdit";
import { FC, useEffect, useState } from "react";
import { ICreateRequestIdea, IIdeasResponseCategories } from "../../../api/requests/ideas/interface";
import noImageBanner from "../../../img/noimage.png";
import Button from "../../../components/ui/form/Buttons/Button";
import { ShareIcon } from "../../../img/icons/ShareIcon";
import { CoinCompetitionIcon, EyeIcon } from "../../../img";
import { ClockIcon } from "../../../img/icons/ClockIcon";
import moment from "moment/moment";
import { ThreePeopleBorderIcon } from "../../../img/icons/employees/ThreePeopleBorderIcon";

interface IdeasCreateEditPreviewProps {
  data: ICreateRequestIdea;
  handleSubmit?: () => void;
  isHideBtn?: boolean;
}
export const IdeasCreateEditPreview: FC<IdeasCreateEditPreviewProps> = ({
  data,
  handleSubmit,
  isHideBtn
}) => {
  const [imageUrl, setImageUrl] = useState<File | string | null>(null)

  useEffect(() => {
    if (data.image && (typeof data.image !== "string")) {
      const image = URL.createObjectURL(data.image)

      setImageUrl(image)
    }
    else {
      setImageUrl(data.image)
    }
  }, [data.image])

  return (
    <div className="ideas__create__preview-items">
      <div className="ideas__create__preview-img">
        <img src={imageUrl || noImageBanner} />

      </div>

      <div className="ideas__create__preview-item">
        <div className="ideas__create__preview-item__header">
          <div className={"d-flex align-items-center gap-3"}>
            <h3>{data.title || "Название"}</h3>
          </div>

          {data.cat_id && (
            <div
              className="ideas__create__preview-item__header-category"
            >
              <div
                className={"ideas__create__preview-item__header-category-item"}>
                {data.cat_id?.label}
              </div>
            </div>
          )}
        </div>

        <div className="ideas__create__preview-item__content">
          <p className={"contests__create__preview-item__content__text"}>{data?.text}</p>
        </div>
      </div>

    </div>
  )
}