import { ArrowExitIcon, ArrowRightIcon, HeartIcon } from "../../img";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { $getEmployees } from "../../api/requests/employees";
import { $getFavoritesNews } from "../../api/requests/news";
import { PollsCard } from "../../components/polls";
import { CompetitionsCard } from "../../components/competitions";
import { NewsCard } from "../../components/news";
import { isMd } from "../../utils";

interface IEmployeesContent {
    id: number,
    name: string,
    text: string,
    position: string,
    image: string,
    isLiked: boolean,
    number: string,
    email: string,
    birth: string,
    skills: any,
    leader: any,
    replacer: any,
}

export const FavoritesPage = () => {
    const navigate = useNavigate();
    const isMobile = isMd()

    const [ employeesData, setEmployeesData ] = useState<IEmployeesContent[]>([]);
    const [ newsData, setNewsData ] = useState([])


    const handleNavigation = () => {
        navigate(-1);
    };

    const handleLike = (id: number) => {
        const updatedList: any = employeesData.map((item) => {
            if (item.id === id) {
                return {
                    ...item,
                    isLiked: !item.isLiked
                }
            } else {
                return item
            }
        })

        setEmployeesData(updatedList)
    };

    useEffect(() => {
        $getEmployees().then(res => {
                if (!res.data) return;

                // const updatedList = res.data.filter((item: any) => item.isLiked)
                setEmployeesData(res.data)
            }
        )

        $getFavoritesNews().then(res => {
            if (!res.data) return

            setNewsData(res.data)
        })
    }, [])


    return (
        <div className='favorites'>
            <div className='layout-inner__header profile-header'>
                { !isMobile && (
                    <div className='layout-inner__header__title'>
                        <div className='header__exitIcon' onClick={ handleNavigation }>
                            <ArrowExitIcon />
                        </div>
                        <h1>Избранное</h1>
                    </div>
                ) }
            </div>

            <div className='favorites-content'>
                <div className="layout-right-block">
                    <div className="layout-right-block__header">
                        <h1 className="layout-right-block__header__title"> Сотрудники </h1>

                        <div onClick={() => navigate('/favorites/employees')} className='layout-right-block__header__link'>
                            <h4>Открыть все</h4>
                            <ArrowRightIcon color={ "#000" } />
                        </div>
                    </div>

                    <div className="favorites-employees employees-content">
                        { employeesData.map((
                            item,
                            idx
                        ) => (
                            <div className='employees-block' key={ idx }>
                                <div className='employees-block__top'>
                                    <img className='employees-block__image' src={ item?.image } alt="" />

                                    <div
                                        onClick={ () => {
                                            handleLike(item.id)
                                        } }
                                        className={ `employees-block__likeIcon ${ item.isLiked ? 'active' : '' }` }
                                    >
                                        <HeartIcon />
                                    </div>
                                </div>

                                <div className='employees-block__content' onClick={ () => {
                                    // setDialog(true)
                                    // setActivePerson({...item})
                                } }>
                                    <h1 className='employees-block__title'>{ item?.name }</h1>

                                    <p className='employees-block__text'>{ item?.text }</p>

                                    <p className='employees-block__position'>{ item?.position }</p>
                                </div>
                            </div>
                        )) }
                    </div>
                </div>

                <div className="layout-right-block">
                    <div className="layout-right-block__header">
                        <h1 className="layout-right-block__header__title"> Новости </h1>

                        <div onClick={() => navigate('/favorites/news')} className='layout-right-block__header__link'>
                            <h4>Открыть все</h4>
                            <ArrowRightIcon color={ "#000" } />
                        </div>
                    </div>

                    <div className="favorites__news">
                        { newsData.slice(0, 4).map((
                            item,
                            idx
                        ) => (
                            <NewsCard data={item} key={idx} isLikeIcon/>
                        )) }
                    </div>
                </div>

                <div className="layout-right-block">
                    <div className="layout-right-block__header">
                        <h1 className="layout-right-block__header__title"> Опросы </h1>

                        <div onClick={() => navigate('/favorites/polls')} className='layout-right-block__header__link'>
                            <h4>Открыть все</h4>
                            <ArrowRightIcon color={ "#000" } />
                        </div>
                    </div>

                    <div className="favorites__news">
                        {newsData.slice(0, 2).map((item, idx) => (
                            <PollsCard data={item} key={idx} isLikeIcon/>
                        ))}
                    </div>
                </div>

                <div className="layout-right-block">
                    <div className="layout-right-block__header">
                        <h1 className="layout-right-block__header__title"> Конкурсы </h1>

                        <div onClick={() => navigate('/favorites/competitions')} className='layout-right-block__header__link'>
                            <h4>Открыть все</h4>
                            <ArrowRightIcon color={ "#000" } />
                        </div>
                    </div>

                    <div className="favorites__news">
                        {newsData.slice(0, 3).map((item, idx) => (
                            <CompetitionsCard data={item} key={idx} isLikeIcon/>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}