import { FC, useEffect, useState } from "react";
import {
    CloseIcon,
    LikeIcon,
    MessengerSendIcon,
    PaperClipIcon,
    PaperClipSmallIcon, StarIcon
} from "../../img";
import { ICommentsMessenger } from "../../api/requests/blogs/interface";
import { getYearMonthDay, isTillMd } from "../../utils";
import {
    $deleteComment,
    $likeComment,
    $pickExpertComment,
    $postComment
} from "../../api/requests/comment";
import { useSelector } from "react-redux";
import { getIsAdmin } from "../../redux";
import { BlankAva } from "../ui/avatars/BlankAva";
import { TrashIcon } from "../../img/icons/TrashIcon";
import CompleteModal from "../ui/dialog/CompleteModal";
import { IRequestExpertComment } from "../../api/requests/comment/interface";
import moment from "moment";

interface CommentMessengerChatBoxProps {
    comments_data: ICommentsMessenger[];
    item_id: number;
    setIsLoading: any;
    url: string;
    isClip?: boolean;
    isExpert?: boolean;
}

export const CommentMessengerChatBox: FC<CommentMessengerChatBoxProps> = (
    {
        comments_data,
        item_id,
        setIsLoading,
        url,
        isClip,
        isExpert
    }
) => {
    const isMobile = isTillMd();
    const isAdmin = useSelector(getIsAdmin);

    const [commentsData, setCommentsData] = useState<ICommentsMessenger[]>(comments_data);

    const [text, setText] = useState("");
    const [isChildCommentOpen, setIsChildCommentOpen] = useState(-1);
    const [attachments, setAttachments] = useState<any>([]);

    const [selectedCommentId, setSelectedCommentId] = useState(-1);
    const [isLiked, setIsLiked] = useState(0);

    const btnListRemove = [
        {
            text: "Удалить",
            key: 0,
            className: "btn btn-red w-100"
        },
        {
            text: "Отмена",
            key: 1,
            className: "btn btn-light w-100"
        }
    ];

    const handleOpenChildInput = (index: number) => {
        setIsChildCommentOpen(index);
    };

    const handlePostComment = (
        parent_id: number
    ) => {
        setIsLoading(true);
        $postComment({
            id: item_id,
            parent_id,
            text,
            attachments
        }, `${url}/createcomment`, { formData: true }).then(res => {
            setText("");
            setIsLoading(false);
            setIsChildCommentOpen(-1);
        });
    };

    const handleDeleteComment = (select: any) => {
        if (select.key === 0) {
            setIsLoading(true);

            if (selectedCommentId >= 0) {
                $deleteComment({ id: selectedCommentId }, `${url}/deletecomment`, { formData: true }).then(res => {
                    setIsLoading(false);
                    setSelectedCommentId(-1);
                });
            }

            return;
        }

        setSelectedCommentId(-1);
    };

    const handleLike = (
        id: number,
        user_liked: number,
        isChild?: number
    ) => {
        const updatedList = commentsData.map((item: any) => {
            if(item.id === id && isChild) return {
                ...item,
                children: item.children.map((i: any) => {
                    if (i.id === isChild) return {
                        ...i,
                        user_liked,
                        likes: user_liked === 0 ? i.likes - 1 : i.likes + 1
                    }

                    return i
                })
            }

            if (item.id === id) return {
                ...item,
                user_liked,
                likes: user_liked === 0 ? item.likes - 1 : item.likes + 1
            }

            return item
        });

        setCommentsData(updatedList);

        $likeComment({
            id: isChild ? isChild : id,
            like: user_liked
        }, `${url}/commentLike`, { formData: true }).then(res => {
        });
    };

    const handleGetCommentImages = (event: any, idComment: number, isChild: number) => {
        const file = event.target?.files[0];

        if (file) {
            setAttachments([...attachments, file]);
        }
    };

    const handleDeleteCommentImages = (index: number) => {
        const updatedList = attachments.filter((
            item: any,
            idx: number
        ) => idx !== index);

        setAttachments(updatedList);
    };

    const handlePickExpert = (comment_id: number, pick: number) => {
        setIsLoading(true);

        $pickExpertComment(
            {
                id: item_id,
                comment_id,
                pick
            },
            `${url}/pickexpert`,
            { formData: true })
        .then(res => {
            setIsLoading(false);
        });
    };

    useEffect(() => {
        setCommentsData(comments_data);
    }, [comments_data]);

    useEffect(() => {
    }, [isLiked]);

    return (
        <div className="news-chat">
            <CompleteModal
                isOpen={selectedCommentId >= 0}
                setIsOpen={() => setSelectedCommentId(-1)}
                icon={"🥲"}
                title={"Вы уверены, что хотите удалить сообщение?"}
                btnList={btnListRemove}
                onClick={handleDeleteComment}
            />
            {commentsData.map((
                item,
                idx: number
            ) => (
                <div className="news-chat__block" key={idx}>
                    <div className="news-chat__top">
                        <div className="news-chat__info">
                            {item.photo ? (
                                <img src={item.photo} alt="" className="news-chat__info__avatar" />
                            ) : (
                                <BlankAva
                                    colorBg={"#CCD9E0"}
                                    colorText={"black"}
                                    text={item.name}
                                />
                            )}

                            <div className="news-chat__info__desc">
                                <div className="news-chat__info__desc__left">
                                    <h1 className="news-chat__info__name">{item.name}</h1>


                                    {item.is_expert ? (
                                        <div className='news-chat__info__expert'>эксперт</div>
                                    ) : ''}

                                </div>



                                <p className="news-chat__info__date">
                                    {moment(item.date ? item.date * 1000 : "").format("DD MMMM, YYYY")}</p>
                            </div>
                        </div>

                        {/*{isAdmin && (*/}
                        {/*  <div className='news-chat__top__buttons'>*/}
                        {/*    <div className='news-chat__top__buttons__item'>*/}
                        {/*      <PaperClipSmallIcon/>*/}
                        {/*    </div>*/}
                        {/*    <div className='news-chat__top__buttons__item'>*/}
                        {/*      <TrashIcon/>*/}
                        {/*    </div>*/}
                        {/*  </div>*/}
                        {/*)}*/}

                        <div className="news-chat__top__buttons">
                            {isExpert && (
                                <div className="news-chat__top__buttons__item"
                                     onClick={() => handlePickExpert(+item.id, item.is_expert === 0 ? 1 : 0)}
                                >
                                    <StarIcon />
                                </div>
                            )}
                            {isClip && (
                                <div className="news-chat__top__buttons__item">
                                    <PaperClipSmallIcon />
                                </div>
                            )}

                            <div className="news-chat__top__buttons__item"
                                 onClick={() => setSelectedCommentId(item.id)}>
                                <TrashIcon />
                            </div>
                        </div>
                    </div>

                    <div className="news-chat__content">
                        {item.attachments ? (
                            <div className="news-chat-child__attachment">
                                {item.attachments.map((
                                    attachment: any,
                                    idx2
                                ) => (
                                    <img src={attachment?.attachment} alt="" key={idx2} />
                                ))}
                            </div>
                        ) : null}

                        <div dangerouslySetInnerHTML={{ __html: item?.text }} />
                    </div>

                    {url === "/news" && (
                        <div className="news-chat__buttons">
                            <div
                                onClick={() => handleOpenChildInput(idx)}
                                className="news-chat__buttons__answerButton">Ответить
                            </div>

                            <div className="news-chat__buttons__likeButton"
                                 onClick={() => handleLike(item.id, item.user_liked === 0 ? 1 : 0)}
                            >
                                <LikeIcon size={20} color="#000" />

                                <p>{item.likes}</p>
                            </div>
                        </div>
                    )}

                    {item.children ? (
                        <>
                            {item.children.map((
                                child: any,
                                idx3: number
                            ) => (
                                <div className="news-chat-child__block" key={idx3}>
                                    <div className="news-chat-child__leftRow"></div>

                                    <div className="news-chat-child__right">
                                        <div className="news-chat__top">
                                            <div className="news-chat__info">
                                                {child.photo ? (
                                                    <img
                                                        src={child.photo}
                                                        className="news-chat__info__avatar"
                                                    />
                                                ) : (
                                                    <BlankAva
                                                        colorBg={"#CCD9E0"}
                                                        colorText={"black"}
                                                        text={item.name}
                                                    />
                                                )}

                                                <div className="news-chat__info__desc">
                                                    <h1 className="news-chat__info__name">{child.name}</h1>

                                                    <p
                                                        className="news-chat__info__date">{moment(child.date ? child.date * 1000 : "").format("DD MMMM, YYYY")}</p>
                                                </div>
                                            </div>

                                            <div className="news-chat__top__buttons">
                                                {isExpert && (
                                                    <div className="news-chat__top__buttons__item"
                                                         onClick={() => handlePickExpert(+item.id, item.is_expert === 0 ? 1 : 0)}
                                                    >
                                                        <StarIcon />
                                                    </div>
                                                )}
                                                {isClip && (
                                                    <div className="news-chat__top__buttons__item">
                                                        <PaperClipSmallIcon />
                                                    </div>
                                                )}

                                                <div className="news-chat__top__buttons__item"
                                                     onClick={() => setSelectedCommentId(child.id)}>
                                                    <TrashIcon />
                                                </div>
                                            </div>
                                        </div>

                                        <div>
                                            {child.attachments ? (
                                                <div className="news-chat-child__attachment">
                                                    {child.attachments.map((
                                                        attachment: any,
                                                        idx4: number
                                                    ) => (
                                                        <img
                                                            src={attachment?.attachment}
                                                            alt=""
                                                            key={idx4}
                                                        />
                                                    ))}
                                                </div>
                                            ) : null}

                                            {child.text}
                                        </div>

                                        <div className="news-chat__buttons__likeButton"
                                             onClick={() => handleLike(item.id, child.user_liked === 0 ? 1 : 0, child.id)}
                                        >
                                            <LikeIcon size={20} color="#000" />

                                            <p>{isLiked === 0 ? child.likes : child.likes + 1}</p>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </>
                    ) : null}

                    {isChildCommentOpen === idx && (
                        <div className="news-chat-child__block">
                            <div className="news-chat-child__leftRow"></div>

                            <div className="news-chat-child__right">
                            <div className="news-messenger__top__textarea">
                                    <input
                                        type="text"
                                        value={text}
                                        placeholder="Оставить комментарий"
                                        className="news-messenger__top__input"
                                        onChange={(e) => setText(e.target.value)}
                                    />

                                    <div className="news-messenger__top__textarea__buttons">
                                        <label
                                            className="news-messenger__top__textarea__buttons__imageButton">
                                            <PaperClipIcon color={isMobile ? "#00A7B5" : "#000"} />

                                            <input
                                                type="file"
                                                accept=".png, .jpg"
                                                onChange={(event) => handleGetCommentImages(event, item.id, isChildCommentOpen)}
                                                hidden
                                            />
                                        </label>

                                        {isMobile ? (
                                            <MessengerSendIcon />
                                        ) : (
                                            <div
                                                className="news-messenger__top__textarea__buttons__enterButton"
                                                onClick={() => handlePostComment(item.id)}>
                                                Опубликовать
                                            </div>
                                        )}
                                    </div>
                                </div>

                                <div className="news-messenger__top__textarea__attachments">
                                    {attachments?.map((
                                        item: any,
                                        idx: number
                                    ) => (
                                        <div
                                            className="news-messenger__top__textarea__attachments__item"
                                            key={idx}>
                                            <div className="closeIcon"
                                                 onClick={() => handleDeleteCommentImages(idx)}>
                                                <CloseIcon color={"#FF0000"} />
                                            </div>

                                            <p>
                                                {idx !== attachments.length - 1 ? `${item?.name},` : item?.name}
                                            </p>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            ))}
        </div>
    );
};