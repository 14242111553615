import { FC } from "react";
import { isMd, isTillMd } from "../../../utils";
import Preloader from "../../ui/preloader/Preloader";
import { CloseIcon, SearchIcon } from "../../../img";
interface  IHeaderSearchBar {
  isSearch: string
  isLoading: boolean
  setPopup: any
  setSearch: any,
  inputRef?: any
}
export const HeaderSearchBar: FC<IHeaderSearchBar> = ({ isSearch, setSearch, isLoading, setPopup, inputRef }) => {
  const isMobile = isTillMd();

  return (
    <div className='header-search-bar'>
      {isLoading && (
        <Preloader
          style={
            isMobile
              ? {
                position: 'absolute',
                left: '50%',
                top: '80px',
                transform: 'translateX(-50%)',
              }
              : { position: 'absolute', top: '50%', transform: 'translateY(-50%)' }
          }
        />
      )}

      <div className='header-search-bar__searchIcon'>
        <SearchIcon color='#000' size={24}/>
      </div>


      <div className='header-search-bar__block'>
        <input
          ref={inputRef}
          type="text"
          placeholder="Поиск"
          name="search"
          value={isSearch}
          onChange={(e) => {
            setSearch(e.target.value);
            window.localStorage.setItem('search', e.target.value);
          }}
          id="search-filter"
          autoComplete="off"
          className='header-search-bar__input'
          autoFocus={true}
        />
      </div>

      <button
        className='header-search-bar__input__icon'
        onClick={() => {
          if (isSearch) {
            localStorage.removeItem('search');
            setSearch('');
          }
         else {
           setPopup(false)
          }
        }}
      >
        {!isMobile && (
          <div className='header-search-bar__closeIcon'>
            <CloseIcon color='#000'/>
          </div>
        )}
      </button>
    </div>
  )
}