
interface CountFieldProps {
    className?: string;
    id?: string;
    label: string;
    value?: number;
    text?: string;
    onChange: (value: string | number) => void;
    disabled?: boolean;
    max?: number;
    min?: number;
}

const CountField = (
    {
        className = "",
        id = "",
        label,
        value = 0,
        text = "",
        onChange,
        disabled,
        max,
        min
    }: CountFieldProps
) => {

    function handleChangeValue(is: boolean) {
        if (value === 0 && !is) return;

        if ((max && max > value) || (min && min < value)) return;

        onChange(is ? +value + 1 : +value - 1)
    }

    return (
        <div className={"form-group"}>
            {label && (
                <label
                    htmlFor={id}
                    className={"form-label"}
                >
                    {label}
                </label>
            )}

            <div className={`field field-count ${className}`}>
                <div
                    className="field__prepend"
                    onClick={() => handleChangeValue(false)}
                >
                    -
                </div>

                <div className={"form-control form-count"}>
                    {value} {text}
                </div>

                <div
                    className="field__append"
                    onClick={() => handleChangeValue(true)}
                >
                    +
                </div>
            </div>
        </div>
    );
};

export default CountField;
