import { IApiHandlerParams } from "../../../interface";
import api from "../../../index";
import {
    WorkspaceGroupFieldsResponseData,
    WorkspaceGroupResponseData, WorkspaceUpdateGroupData
} from "./interface";

export const $getWorkspaceGroup = (
    handler?: IApiHandlerParams
): Promise<WorkspaceGroupResponseData> =>
    api.get("api/v1/workspace/workspace_group", {
        mock: require('./mocks/workspace.group.list.json'),
        handler
    });

export const $getWorkspaceGroupFields = (
    handler?: IApiHandlerParams
): Promise<WorkspaceGroupFieldsResponseData> =>
    api.get("api/v1/workspace/workspace_group/fields", {
        mock: require('./mocks/workspace.group.fields.list.json'),
        handler
    });

export const $deleteWorkspaceGroupById = (
    id: string | number,
    handler?: IApiHandlerParams
): Promise<any> =>
    api.delete(`api/v1/workspace/workspace_group/${id}`, {
        // mock: require('./mocks/complete.form.calculate.json'),
        handler
    });

export const $updateWorkspaceGroupById = (
    id: string | number,
    data: WorkspaceUpdateGroupData,
    handler?: IApiHandlerParams
): Promise<any> =>
    api.put(`api/v1/workspace/workspace_group/${id}`, data,{
        // mock: require('./mocks/complete.form.calculate.json'),
        handler
    });

export const $createWorkspaceGroup = (
    data: WorkspaceUpdateGroupData,
    handler?: IApiHandlerParams
): Promise<any> =>
    api.post("api/v1/workspace/workspace_group", data, {
        // mock: require('./mocks/complete.form.calculate.json'),
        handler
    });