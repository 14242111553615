import { FC, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { NotificationCard, NotificationCardProps } from "../../notification/notification-card";
import { $getPersonalPage } from "../../../api/requests/user";

interface IHeaderNotificationPopup {
    setPopup: any;
}

export const HeaderNotificationPopup: FC<IHeaderNotificationPopup> = (
    { setPopup }
) => {
    const navigate = useNavigate();

    const [pushesData, setPushesData] = useState<[]>([]);

    useEffect(() => {
        init();
    }, []);

    function init() {
        getProfile();
    }

    function getProfile() {
        $getPersonalPage(null, { newApi: true }).then(res => {
            if (!res) return;

            setPushesData(res.all_notifications);
        });
    }

    function openNotifications() {
        setPopup(false);
        navigate("/notifications");
    }

    return (
        <div className="notifications-popup">
            <div className="notifications-popup-content">
                {pushesData && pushesData.slice(0, 5).map((notification: NotificationCardProps) => (
                    <NotificationCard data={notification} />
                ))}

                {(!pushesData || pushesData.length == 0) && (
                    <div className={"notifications-popup__empty"}>
                        Нет уведомления
                    </div>
                )}

                <div className="notifications-popup__button"
                     onClick={openNotifications}>
                    Открыть все
                </div>
            </div>
        </div>
    );
};