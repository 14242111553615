import { FC } from "react";
import { IBaseIconProps } from "../../types";

export const NewsIcon: FC<IBaseIconProps> = (
    {
        size = 24,
        color = "#000",
        opacity = 0.3
    }
) => {
    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g opacity={opacity}>
                <path
                    d="M2 3V18C2 19.645 3.35503 21 5 21H19C20.645 21 22 19.645 22 18V7H20V18C20 18.565 19.565 19 19 19C18.435 19 18 18.565 18 18V3H2ZM4 5H16V18C16 18.3883 16.279 18.658 16.416 19H5C4.43497 19 4 18.565 4 18V5ZM6 7V10H14V7H6ZM6 12V14H14V12H6ZM6 16V18H14V16H6Z"
                    fill={color}
                />
            </g>
        </svg>
    );
};