import DialogContainer from "../../../components/ui/dialog/DialogContainer";
import UserSelectListModal, {
    BtnDataItem
} from "../../../components/ui/dialog/UserSelectListModal";
import { useEffect, useState } from "react";
import Button from "../../../components/ui/form/Buttons/Button";
import { $getWorkspaceUsers } from "../../../api/requests/workspace-settings";
import { useSelector } from "react-redux";
import { getPersonData } from "../../../redux";
import { $inviteGroup } from "../../../api/requests/groups";
import { successNotify } from "../../../utils";
import { withDebounce } from "../../../functions";

interface WorkspaceSettingGroupInviteUsersProps {
    isOpen: boolean;
    onClose: () => void;
}

const WorkspaceSettingGroupInviteUsers = (
    {
        isOpen,
        onClose
    }: WorkspaceSettingGroupInviteUsersProps
) => {
    const personalData = useSelector(getPersonData);

    const btnList = [
        {
            key: 1,
            text: "Пригласить",
            className: "btn-primary w-100"
        }
    ]

    const [usersData, setUsersData] = useState<any[]>([]);

    const [isStatusComplete, setIsStatusComplete] = useState(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        init();
    }, []);

    function init() {
        const requestData = {
            id: personalData.workspace_id,
            forceToSend: 0,
            type: 1,
            limit: 30
        }

        setIsLoading(true);

        $getWorkspaceUsers(requestData, { formData: true }).then(res => {
            if (!res) return;

            setIsLoading(false);
            setUsersData(res.users);
        });
    }

    function handleChangeList(btnItem: BtnDataItem | null, updateList: number[]) {
        if (!btnItem) return handleClose();

        updateList.map(idx => {
            $inviteGroup({ id: usersData[idx].id }, { formData: true }).then(res => {
                if (res.error || res.response) return;

                withDebounce(() => {
                    setIsStatusComplete(true);
                })
            })
        })
    }

    function handleClose() {
        setIsStatusComplete(false);
        onClose();
    }

    return (
        <DialogContainer
            isOpen={isOpen}
            setIsOpen={onClose}
            isShowCloseBtn={false}
            classNameModal={"p-0"}
            widthProps={427}
        >
            {isStatusComplete ? (
                <div className={"sport__view__modal contests__winner"}>
                    <div className={"sport__view__modal__content-complete"}>
                        <span>💪</span>

                        <h3>Успешно</h3>

                        <p>
                            Вы успешно пригласили пользователей.
                        </p>
                    </div>

                    <div className={"sport__view__modal__btn"}>
                        <Button
                            text={"Отлично"}
                            className={"btn btn-light w-100"}
                            onClick={handleClose}
                        />
                    </div>
                </div>
            ) : (
                <UserSelectListModal
                    list={usersData}
                    btnList={btnList}
                    title={"Приглашение"}
                    onClose={handleChangeList}
                    isClose
                />
            )}
        </DialogContainer>
    )
}

export default WorkspaceSettingGroupInviteUsers;