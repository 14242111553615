import { IBaseIconProps } from "../types";
import { FC } from "react";

export const WorkspaceStoreIcon: FC<IBaseIconProps> = ({
  size = 24,
  color = '#000'
}) => {
  return (
    <svg width={ size } height={ size } viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.5984 8.3998V5.39981C15.5984 3.41158 13.9867 1.7998 11.9984 1.7998C10.0102 1.7998 8.39844 3.41158 8.39844 5.3998V8.3998M4.72571 22.1998H19.2712C20.5565 22.1998 21.5984 21.1772 21.5984 19.9158L20.1075 7.79977C20.1075 6.53835 19.0656 5.51576 17.7803 5.51576H5.92571C4.64039 5.51576 3.59844 6.53835 3.59844 7.79977L2.39844 19.9158C2.39844 21.1772 3.44039 22.1998 4.72571 22.1998Z"
        stroke={ color } strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}