import DialogContainer from "../../../components/ui/dialog/DialogContainer";
import Button from "../../../components/ui/form/Buttons/Button";
import { SportDateData, SportViewItemData } from "../../../api/requests/sport/interface";
import ToggleList from "../../../components/ui/form/Toggle/ToggleList";
import { Controller, useForm } from "react-hook-form";
import { ReactNode, useEffect, useState } from "react";
import { $cancelEnrollSport, $joinEnrollSport } from "../../../api/requests/sport";
import { useSelector } from "react-redux";
import { getCurrentRole, getPersonData } from "../../../redux";

interface TrainingSessionViewModalCancelProps {
    isOpen: boolean;
    onClose: (value: boolean) => void;
    setIsOpen: (value: boolean) => void;
    data?: SportViewItemData | null;
}

interface FormData {
    date: string;
    time: string;
}

interface dateItem extends SportDateData {
    text: string | ReactNode;
    key: string;
}

const TrainingSessionViewModalCancel = (
    {
        data,
        isOpen,
        onClose,
        setIsOpen
    }: TrainingSessionViewModalCancelProps
) => {
    const userData = useSelector(getPersonData);
    const currentRole = useSelector(getCurrentRole);

    const isAdmin = currentRole === 1;

    const [isStatusComplete, setIsStatusComplete] = useState(false);

    const unique_user = data?.unique_users?.find(item => item.id === userData.id)

    const {
        handleSubmit,
        control,
        reset,
        formState: { isValid }
    } = useForm<FormData>();

    const filteredSchedule = (schedules: SportDateData[], key: "date" | "time") => {
        return [...schedules].filter((item, ) =>
            !!item?.user_enrolled
        )
    };

    const trainingDateList = (): dateItem[] => {
        if (!data?.schedules) return [];

        return [...filteredSchedule(data?.schedules, "date")].map(item => ({
            ...item,
            text: (
                <div className="sport__content-card-block-content__bottom__block">
                    <p>{item.week}</p>
                    {item.date && <hr />}
                    {item.date && <p>{item.date}</p>}
                    {item.date && <hr />}
                    <p>{item.time}</p>
                </div>
            ),
            key: `${item.week} ${item.date} ${item.time}`
        }));
    };

    function handleCloseModal() {
        onClose(false);
        reset();
        setIsStatusComplete(false);
    }

    function onSubmit(request: any) {
        const findItem = trainingDateList().find(item => (item.key === request.date));

        if (findItem) {
            $cancelEnrollSport({
                // user_id: userData.id,
                schedule_id: findItem.id,
            }, { newApi: true }).then(res => {
                if (res.error || res.response) return;

                setIsStatusComplete(true);
            })
        }
    }

    return (
        <DialogContainer
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            isShowCloseBtn={!isStatusComplete}
            widthProps={427}
            label={!isStatusComplete && "Выберите время"}
        >
            {isStatusComplete ? (
                <div className={"sport__view__modal"}>
                    <div className={"sport__view__modal__content-complete"}>
                        <span>👍🏻</span>

                        <h3>Успешно</h3>

                        <p>
                            Вы успешно отменили запись на тренировку.
                        </p>
                    </div>

                    <div className={"sport__view__modal__btn"}>
                        <Button
                            text={"Отлично"}
                            className={"btn btn-light w-100"}
                            onClick={handleCloseModal}
                        />
                    </div>
                </div>
            ) : (
                <form className={"sport__view__modal"} onSubmit={handleSubmit(onSubmit)}>
                    <div className="sport__view__modal__content sport__view__modal__content__cancel">
                        <Controller
                            name="date"
                            control={control}
                            rules={{
                                required: {
                                    value: true,
                                    message: ""
                                }
                            }}
                            render={({ field: { onChange, value } }) => (
                                <ToggleList
                                    title={"Для отмены, выберите время на которое вы записались"}
                                    list={trainingDateList()}
                                    onChange={(item) => {
                                        onChange(item.key);
                                    }}
                                    className={"flex-column w-100"}
                                    value={value}
                                    isKey
                                />
                            )}
                        />
                    </div>

                    <div className={"sport__view__modal__btn"}>
                        <Button
                            type={"submit"}
                            text={"Отменить запись"}
                            className={"btn btn-red w-100"}
                            disabled={!isValid}
                        />

                        <Button
                            text={"Отмена"}
                            className={"btn btn-light w-100"}
                            onClick={() => setIsOpen(false)}
                        />
                    </div>
                </form>
            )}
        </DialogContainer>
    );
};

export default TrainingSessionViewModalCancel;