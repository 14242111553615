import { createAsyncThunk } from '@reduxjs/toolkit'
import Cookies from 'js-cookie'
import { failureNotify, successNotify } from '../../utils'
import { $authSign } from '../../api/requests/auth'
import {
    AuthSignBodyResultData,
    AuthSignData,
} from '../../api/requests/auth/interface'
import { store } from "../store";
import { changeToken } from "../reducers";

export const login = createAsyncThunk(
    'profile/login',
    async (data: AuthSignData, { rejectWithValue }) => {
        return $authSign(data, { returnData: false }).then((response: any) => {
            if (!response.result.token) {
                console.log(response.message, 'error profile')

                if (response && response.message) {
                    failureNotify(response.message)
                    return rejectWithValue(response.message)
                }

                return rejectWithValue(response)
            }

            Cookies.set('token', response.result.token)
            localStorage.setItem("token", response.result.token)
            store.dispatch(changeToken(response.result.token))

            successNotify('Вы успешно авторизованы')

            return response.result as AuthSignBodyResultData
        })
    }
)
